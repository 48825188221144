import axios from "axios";
import { adminuploadcsv_Url, changePassword_Url, forgotPassword_Url, getProfileDetails_Url, login_Url, logout_Url, resetPassword_Url, verifyOtp_Url } from "../../apiSheet";
import { ADMIN_LOGOUT_ERROR, ADMIN_LOGOUT_LOADING, ADMIN_LOGOUT_SUCCESS, CHANGE_PASSWORD_ERROR, CHANGE_PASSWORD_LOADING, CHANGE_PASSWORD_SUCCESS, FORGOT_PASSWORD_ERROR, FORGOT_PASSWORD_LOADING, FORGOT_PASSWORD_SUCCESS, LOGIN_ERROR, LOGIN_LOADING, LOGIN_SUCCESS, OTP_VERIFY_ERROR, OTP_VERIFY_LOADING, OTP_VERIFY_SUCCESS, PROFILE_ERROR, PROFILE_LOADING, PROFILE_SUCCESS, RESET_PASSWORD_ERROR, RESET_PASSWORD_LOADING, RESET_PASSWORD_SUCCESS, UPLOAD_MENU_EXCEL_ERROR, UPLOAD_MENU_EXCEL_SUCCESS } from "../../Constants/Constants";

export const adminLogin = (user) => {

  return (dispatch) => {
    dispatch({
      type: LOGIN_LOADING,
    });
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      // method: "post",
      url: login_Url,
      data: user
    };
    axios(requestOptions)
      .then((resp) => {
        dispatch({
          type: LOGIN_SUCCESS,
          response: {
            data: resp.data,
            msg: resp.data.message
          }
        })
      })
      .catch((error) => {
        let data = error.response;
        if (!error.response) {
          dispatch({
            type: LOGIN_ERROR,
            netError: error,
          });

        } else
          if (data.status === 422) {
            let validationError = data.data.message;
            dispatch({
              type: LOGIN_ERROR,
              errors: validationError
            });
          }
      });
  };
}

export const adminLogout = () => {

  return (dispatch) => {
    dispatch({
      type: ADMIN_LOGOUT_LOADING,
    });
    const requestOptions = {
      method: "GET",
      headers: { "Content-Type": "application/json" },
      // method: "post",
      url: logout_Url,
    };
    axios(requestOptions)
      .then((resp) => {
        dispatch({
          type: ADMIN_LOGOUT_SUCCESS,
          response: {
            data: resp.data.data,
            msg: resp.data.message
          }
        })
      })
      .catch((error) => {
        let data = error.response;
        if (!error.response) {
          dispatch({
            type: ADMIN_LOGOUT_ERROR,
            netError: error,
          });

        } else
          if (data.status === 422) {
            let validationError = data.data.message;
            dispatch({
              type: ADMIN_LOGOUT_ERROR,
              errors: validationError
            });
          }
      });
  };
}

//  for get otp 
export const forgotPassword = (detail) => {

  return (dispatch) => {
    dispatch({
      type: FORGOT_PASSWORD_LOADING,
    });
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        'Authorization': `Bearer ${localStorage.getItem("token")}`
      },
      // method: "post",
      url: forgotPassword_Url,
      data: detail
    };
    axios(requestOptions)
      .then((resp) => {
        dispatch({
          type: FORGOT_PASSWORD_SUCCESS,
          response: {
            data: resp.data.data,
            msg: resp.data.message
          }
        })
      })
      .catch((error) => {
        let data = error.response;
        if (!error.response) {
          dispatch({
            type: FORGOT_PASSWORD_ERROR,
            netError: error,
          });

        } else
          if (data.status === 422) {
            let validationError = data.data.message;
            dispatch({
              type: FORGOT_PASSWORD_ERROR,
              errors: validationError
            });
          }
      });
  };
}

// enter otp  

export const verifyOtp = (detail) => {

  return (dispatch) => {
    dispatch({
      type: OTP_VERIFY_LOADING,
    });
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        'Authorization': `Bearer ${localStorage.getItem("token")}`
      },
      // method: "post",
      url: verifyOtp_Url,
      data: detail
    };
    axios(requestOptions)
      .then((resp) => {
        dispatch({
          type: OTP_VERIFY_SUCCESS,
          response: {
            data: resp.data.data,
            msg: resp.data.message
          }
        })
      })
      .catch((error) => {
        let data = error.response;
        if (!error.response) {
          dispatch({
            type: OTP_VERIFY_ERROR,
            netError: error,
          });

        } else
          if (data.status === 422) {
            let validationError = data.data.message;
            dispatch({
              type: OTP_VERIFY_ERROR,
              errors: validationError
            });
          }
      });
  };
}

// reset pass
export const resetPassword = (detail) => {

  return (dispatch) => {
    dispatch({
      type: RESET_PASSWORD_LOADING,
    });
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        'Authorization': `Bearer ${localStorage.getItem("token")}`
      },
      // method: "post",
      url: resetPassword_Url,
      data: detail
    };
    axios(requestOptions)
      .then((resp) => {
        dispatch({
          type: RESET_PASSWORD_SUCCESS,
          response: {
            data: resp.data.data,
            msg: resp.data.message
          }
        })
      })
      .catch((error) => {
        let data = error.response;
        if (!error.response) {
          dispatch({
            type: RESET_PASSWORD_ERROR,
            netError: error,
          });

        } else
          if (data.status === 422) {
            let validationError = data.data.message;
            dispatch({
              type: RESET_PASSWORD_ERROR,
              errors: validationError
            });
          }
      });
  };
}

export const getProfileDetails = () => {

  return (dispatch) => {
    dispatch({
      type: PROFILE_LOADING,
    });
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        'Authorization': `Bearer ${localStorage.getItem("token")}`
      },
      // method: "post",
      url: getProfileDetails_Url
    };
    axios(requestOptions)
      .then((resp) => {
        dispatch({
          type: PROFILE_SUCCESS,
          response: {
            data: resp.data.data,
            msg: resp.data.message
          }
        })
      })
      .catch((error) => {
        let data = error.response;
        if (!error.response) {
          dispatch({
            type: PROFILE_ERROR,
            netError: error,
          });

        } else
          if (data.status === 422) {
            let validationError = data.data.message;
            dispatch({
              type: PROFILE_ERROR,
              errors: validationError
            });
          }
      });
  };
}










export const changePassword = (detail) => {

  return (dispatch) => {
    dispatch({
      type: CHANGE_PASSWORD_LOADING,
    });
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        'Authorization': `Bearer ${localStorage.getItem("token")}`
      },
      url: changePassword_Url,
      data: detail
    };
    axios(requestOptions)
      .then((resp) => {
        dispatch({
          type: CHANGE_PASSWORD_SUCCESS,
          response: {
            data: resp.data.data,
            msg: resp.data.message
          }
        })
      })
      .catch((error) => {
        let data = error.response;
        if (!error.response) {
          dispatch({
            type: CHANGE_PASSWORD_ERROR,
            netError: error,
          });

        } else
          if (data.status === 422) {
            let validationError = data.data.message;
            dispatch({
              type: CHANGE_PASSWORD_ERROR,
              errors: validationError
            });
          }
      });
  };
}

export const AdminUploadCsvMenuItem = (details) => {
  return (dispatch) => {
    const requestOptions = {
      method: "POST",
      url: adminuploadcsv_Url,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "Content-Type": "multipart/form-data",
      },
      data: details
    };
    axios(requestOptions)
      .then((resp) => {
        dispatch({
          type: UPLOAD_MENU_EXCEL_SUCCESS,
        });
      })
      .catch((error) => {
        let data = error.response;
        if (!error.response) {
          dispatch({
            type: UPLOAD_MENU_EXCEL_ERROR,
            msg: error,
          });
        } else if (data.status === 422) {
          let validationError = data.data.message;
          dispatch({
            type: UPLOAD_MENU_EXCEL_ERROR,
            msg: validationError,
          });
        }
      });
  };
};

