import { RESET_UPLOAD_MENU_EXCEL, UPLOAD_MENU_EXCEL_ERROR, UPLOAD_MENU_EXCEL_LOADING, UPLOAD_MENU_EXCEL_SUCCESS } from "../../../Constants/Constants";

let initialState = {
 AdminUploadExcel: {
  loading: false,
  data: null,
  error: false,
  errors: null,
  success: false,
 },
};

export default function AdminimportMenuItemExcel(state = initialState, action) {
 switch (action.type) {
  case UPLOAD_MENU_EXCEL_LOADING:
   return {
    ...state,
    AdminUploadExcel: {
     ...state.AdminUploadExcel,
     loading: true,
     error: false,
     errors: [],
     success: false,
    },
   };

  case UPLOAD_MENU_EXCEL_SUCCESS:
   return {
    ...state,
    AdminUploadExcel: {
     ...state.AdminUploadExcel,
     loading: false,
     error: false,
     errors: [],
     success: true,
     //   data: action.response,
    },
   };

  case UPLOAD_MENU_EXCEL_ERROR:
   return {
    ...state,
    AdminUploadExcel: {
     ...state.AdminUploadExcel,
     loading: false,
     error: true,
     errors: action.errors,
     success: false,
     data: null,
    },
   };

  case RESET_UPLOAD_MENU_EXCEL:
   return {
    ...state,
    AdminUploadExcel: {
     ...state.AdminUploadExcel,
     loading: false,
     error: false,
     errors: null,
     success: false,
    },
   };

  default:
   return state;
 }
}
