import axios from "axios";
import moment from "moment";
import {
  customertotalordercount_Url
} from "../../../Admin/apiSheet";
import {
  adminLoginUrl,
  agentchangepasswordUrl,
  agentsWiseOrder,
  changepasswordUrl,
  createAgentUrl,
  createcustomeeAddressUrl,
  createcustomerUrl,
  createdeliveryItemUrl,
  createMenuItemUrl,
  createordarUrl,
  createPrmUrl,
  CustomerSerachByContactUrl,
  CustomerSerachByNameUrl,
  deleteAgentsUrl,
  deletecustomeeAddressUrl,
  deletecustomerUrl,
  deletedeliveryagentUrl,
  deleteMenuItemUrl,
  deleteordarUrl,
  deletePrmUrl,
  exportPdf_Url,
  exportTipPdf_Url,
  findCustomer_Url,
  forgotPassword_Url,
  freeAgent_Url,
  getAgentsUrl,
  getcustomeeAddressUrl,
  getcustomerByIdUrl,
  getcustomerUrl,
  getdeliveryagentByIDUrl,
  getdeliveryagentUrl,
  getLocationListUrl,
  // GetAll,
  getMenuItemUrl,
  getoneordarUrl,
  getordarUrl,
  getPrmUrl,
  getSuperAdminProfileDetails_Url,
  getswapagent_Url,
  location_wise_customer_Url,
  locationByAgentsUrl,
  locationByMenuUrl,
  locationByOrderUrl,
  locationByPRMUrl,
  locationWiseagentsUrl,
  locationwiseTipDetails,
  reports_Location_Order_Url,
  reports_Order_Url,
  resetPassword_Url,
  SuperadminchangepasswordUrl,
  SuperadminOrderFilter_Url,
  updateAgentsUrl,
  updatecustomeeAddressUrl,
  updatecustomerUrl,
  updatedeliveryagentUrl,
  updateMenuByIdUrl,
  updateMenuItemUrl,
  updateorderUrl,
  UpdatePRM_for_location_Url,
  updatePrmByID_Url,
  updatePrmUrl,
  updateViewLocationUrl,
  upload_csv_Url,
  verifyOtp_Url
} from "../../../superAdmin/ApiSheet";
import {
  ADD_AGENTS_DATA_ERROR,
  ADD_AGENTS_DATA_LOADING,
  ADD_AGENTS_DATA_SUCCESS,
  ADD_CUSTOMER_ADDRESS_ERROR,
  ADD_CUSTOMER_ADDRESS_LOADING,
  ADD_CUSTOMER_ADDRESS_SUCCESS,
  ADD_CUSTOMER_ERROR,
  ADD_CUSTOMER_LOADING,
  ADD_CUSTOMER_SUCCESS,
  ADD_DELIVERY_AGENTS_DATA_ERROR,
  ADD_DELIVERY_AGENTS_DATA_LOADING,
  ADD_DELIVERY_AGENTS_DATA_SUCCESS,
  ADD_MENU_DATA_ERROR,
  ADD_MENU_DATA_LOADING,
  ADD_MENU_DATA_SUCCESS,
  ADD_PRM_DATA_ERROR,
  ADD_PRM_DATA_LOADING,
  ADD_PRM_DATA_SUCCESS,
  ADD_SUPER_ADMIN_ORDER_ERROR,
  ADD_SUPER_ADMIN_ORDER_LOADING,
  ADD_SUPER_ADMIN_ORDER_SUCCESS,
  AGENTS_CHANGE_PASSWORD_ERROR,
  AGENTS_CHANGE_PASSWORD_LOADING,
  AGENTS_CHANGE_PASSWORD_SUCCESS,
  AGENTSWISE_ORDER_ERROR,
  AGENTSWISE_ORDER_LOADING,
  AGENTSWISE_ORDER_SUCCESS,
  CUSTOMER_SERACH_BY_CONTACT_ERROR,
  CUSTOMER_SERACH_BY_CONTACT_LOADING,
  CUSTOMER_SERACH_BY_CONTACT_SUCCESS,
  CUSTOMER_SERACH_BY_NAME_ERROR,
  CUSTOMER_SERACH_BY_NAME_LOADING,
  CUSTOMER_SERACH_BY_NAME_SUCCESS,
  CUSTOMER_TOTAL_ORDER_COUNT_ERROR,
  CUSTOMER_TOTAL_ORDER_COUNT_LOADING,
  CUSTOMER_TOTAL_ORDER_COUNT_SUCCESS,
  DELETE_AGENTS_DATA,
  DELETE_CUSTOMER_ADDRESS_ERROR,
  DELETE_CUSTOMER_ADDRESS_LOADING,
  DELETE_CUSTOMER_ADDRESS_SUCCESS,
  DELETE_CUSTOMER_DATA_ERROR,
  DELETE_CUSTOMER_DATA_LOADING,
  DELETE_CUSTOMER_DATA_SUCCESS,
  DELETE_DELIVERY_AGENTS_DATA_ERROR,
  DELETE_DELIVERY_AGENTS_DATA_LOADING,
  DELETE_DELIVERY_AGENTS_DATA_SUCCESS,
  DELETE_MENUITEMS_DATA_ERROR,
  DELETE_MENUITEMS_DATA_LOADING,
  DELETE_MENUITEMS_DATA_SUCCESS,
  DELETE_PRM_DATA_ERROR,
  DELETE_PRM_DATA_LOADING,
  DELETE_PRM_DATA_SUCCESS,
  DELETE_SUPER_ADMIN_ORDER_ERROR,
  DELETE_SUPER_ADMIN_ORDER_LOADING,
  DELETE_SUPER_ADMIN_ORDER_SUCCESS,
  EXPORT_PDF_ERROR,
  EXPORT_PDF_SUCCESS,
  EXPORT_TIP_PDF_ERROR,
  EXPORT_TIP_PDF_SUCCESS,
  FIND_SUPERADMIN_CUSTOMER_BY_CONTACT_ERROR,
  FIND_SUPERADMIN_CUSTOMER_BY_CONTACT_LOADING,
  FIND_SUPERADMIN_CUSTOMER_BY_CONTACT_SUCCESS,
  GET_AGENTS_DATA_ERROR,
  GET_AGENTS_DATA_LOADING,
  GET_AGENTS_DATA_SUCCESS,
  GET_CUSTOMER_ADDRESS_LOADING,
  GET_CUSTOMER_ADDRESS_SUCCESS,
  GET_CUSTOMER_BY_ID__DATA_ERROR,
  GET_CUSTOMER_BY_ID_DATA_LOADING,
  GET_CUSTOMER_BY_ID_DATA_SUCCESS,
  GET_CUSTOMER_ERROR,
  GET_CUSTOMER_LOADING,
  GET_CUSTOMER_SUCCESS,
  GET_DELIVERY_AGENTS_BY_ID__DATA_ERROR,
  GET_DELIVERY_AGENTS_BY_ID_DATA_LOADING,
  GET_DELIVERY_AGENTS_BY_ID_DATA_SUCCESS,
  GET_DELIVERY_AGENTS_DATA_ERROR,
  GET_DELIVERY_AGENTS_DATA_LOADING,
  GET_DELIVERY_AGENTS_DATA_SUCCESS,
  GET_LOCATIONLIST_DATA_ERROR,
  GET_LOCATIONLIST_DATA_LOADING,
  GET_LOCATIONLIST_DATA_SUCCESS,
  GET_MENU_BY_ID__DATA_ERROR,
  GET_MENU_BY_ID_DATA_LOADING,
  GET_MENU_BY_ID_DATA_SUCCESS,
  GET_MENU_DATA_ERROR,
  GET_MENU_DATA_LOADING,
  GET_MENU_DATA_SUCCESS,
  GET_PRM_BY_ID__DATA_ERROR,
  GET_PRM_BY_ID_DATA_LOADING,
  GET_PRM_BY_ID_DATA_SUCCESS,
  GET_PRM_DATA_ERROR,
  GET_PRM_DATA_LOADING,
  GET_PRM_DATA_SUCCESS,
  GET_SIGNIN_ERROR,
  GET_SIGNIN_LOADING,
  GET_SIGNIN_SUCCESS,
  GET_SUPER_ADMIN_LOCATION_BY_Agents_ERROR,
  GET_SUPER_ADMIN_LOCATION_BY_Agents_LOADING,
  GET_SUPER_ADMIN_LOCATION_BY_Agents_SUCCESS,
  GET_SUPER_ADMIN_LOCATION_BY_MENU_ERROR,
  GET_SUPER_ADMIN_LOCATION_BY_MENU_LOADING,
  GET_SUPER_ADMIN_LOCATION_BY_MENU_SUCCESS,
  GET_SUPER_ADMIN_LOCATION_BY_OREDR_ERROR,
  GET_SUPER_ADMIN_LOCATION_BY_OREDR_LOADING,
  GET_SUPER_ADMIN_LOCATION_BY_OREDR_SUCCESS,
  GET_SUPER_ADMIN_LOCATION_BY_PRM_ERROR,
  GET_SUPER_ADMIN_LOCATION_BY_PRM_LOADING,
  GET_SUPER_ADMIN_LOCATION_BY_PRM_SUCCESS,
  GET_SUPER_ADMIN_LOCATION_WISE_AGENTS_ERROR,
  GET_SUPER_ADMIN_LOCATION_WISE_AGENTS_LOADING,
  GET_SUPER_ADMIN_LOCATION_WISE_AGENTS_SUCCESS,
  GET_SUPER_ADMIN_ORDER_ERROR,
  GET_SUPER_ADMIN_ORDER_LOADING,
  GET_SUPER_ADMIN_ORDER_SUCCESS,
  GET_SUPERADMIN_ORDER_FILTER_ERROR,
  GET_SUPERADMIN_ORDER_FILTER_LOADING,
  GET_SUPERADMIN_ORDER_FILTER_SUCCESS,
  GET_SWAP_AGENT_ERROR,
  GET_SWAP_AGENT_LOADING,
  GET_SWAP_AGENT_SUCCESS,
  GET_VIEW_LOCATION_BY_ID__DATA_ERROR,
  GET_VIEW_LOCATION_BY_ID_DATA_LOADING,
  GET_VIEW_LOCATION_BY_ID_DATA_SUCCESS,
  IMPORT_MENU_EXCEL_ERROR,
  IMPORT_MENU_EXCEL_SUCCESS,
  LOCATION_WISE_CUSTOMER_ERROR,
  LOCATION_WISE_CUSTOMER_LOADING,
  LOCATION_WISE_CUSTOMER_SUCCESS,
  LOCATION_WISE_TOTAL_TIP_ERROR,
  LOCATION_WISE_TOTAL_TIP_LOADING,
  LOCATION_WISE_TOTAL_TIP_SUCCESS,
  removeStorage,
  REPORTS_LOCATION_ORDER_COUNT_ERROR,
  REPORTS_LOCATION_ORDER_COUNT_LOADING,
  REPORTS_LOCATION_ORDER_COUNT_SUCCESS,
  REPORTS_ORDER_COUNT_ERROR,
  REPORTS_ORDER_COUNT_LOADING,
  REPORTS_ORDER_COUNT_SUCCESS,
  RESET_STORE,
  RESET_SUPERADMIN_PASSWORD_ERROR,
  RESET_SUPERADMIN_PASSWORD_LOADING,
  RESET_SUPERADMIN_PASSWORD_SUCCESS,
  SUPERADMIN_CHANGE_PASSWORD_ERROR,
  SUPERADMIN_CHANGE_PASSWORD_LOADING,
  SUPERADMIN_CHANGE_PASSWORD_SUCCESS,
  SUPERADMIN_FORGOT_PASSWORD_ERROR,
  SUPERADMIN_FORGOT_PASSWORD_LOADING,
  SUPERADMIN_FORGOT_PASSWORD_SUCCESS,
  SUPERADMIN_OTP_VERIFY_ERROR,
  SUPERADMIN_OTP_VERIFY_LOADING,
  SUPERADMIN_OTP_VERIFY_SUCCESS,
  SUPERADMIN_PROFILE_ERROR,
  SUPERADMIN_PROFILE_LOADING,
  SUPERADMIN_PROFILE_SUCCESS,
  SUPERADMIN_SEARCH_LOCATION_ERROR,
  SUPERADMIN_SEARCH_LOCATION_LOADING,
  SUPERADMIN_SEARCH_LOCATION_SUCCESS,
  SUPERADMIN_TO_ADMIN_CHANGE_PASSWORD_ERROR,
  SUPERADMIN_TO_ADMIN_CHANGE_PASSWORD_LOADING,
  SUPERADMIN_TO_ADMIN_CHANGE_PASSWORD_SUCCESS,
  UPDATE_AGENTS_DATA_ERROR,
  UPDATE_AGENTS_DATA_LOADING,
  UPDATE_AGENTS_DATA_SUCCESS,
  UPDATE_CUSTOMER_ADDRESS_ERROR,
  UPDATE_CUSTOMER_ADDRESS_LOADING,
  UPDATE_CUSTOMER_ADDRESS_SUCCESS,
  UPDATE_CUSTOMER_ERROR,
  UPDATE_CUSTOMER_LOADING,
  UPDATE_CUSTOMER_SUCCESS,
  UPDATE_DELIVERY_AGENTS_DATA_ERROR,
  UPDATE_DELIVERY_AGENTS_DATA_LOADING,
  UPDATE_DELIVERY_AGENTS_DATA_SUCCESS,
  UPDATE_MENU_DATA_ERROR,
  UPDATE_MENU_DATA_LOADING,
  UPDATE_MENU_DATA_SUCCESS,
  UPDATE_PRM_DATA_ERROR,
  UPDATE_PRM_DATA_LOADING,
  UPDATE_PRM_DATA_SUCCESS,
  UPDATE_PRM_FOR_LOCATION_ERROR,
  UPDATE_PRM_FOR_LOCATION_SUCCESS,
  UPDATE_SUPER_ADMIN_ORDER_ERROR,
  UPDATE_SUPER_ADMIN_ORDER_LOADING,
  UPDATE_SUPER_ADMIN_ORDER_SUCCESS
} from "../../type/Constant";

export const resetReduxStore = (user) => {
  return (dispatch) => {
    dispatch({
      type: RESET_STORE,
    });
  };
};

// .......................  Profile Data  ....................

export const getSuperAdminProfileDetails = () => {
  return (dispatch) => {
    dispatch({
      type: SUPERADMIN_PROFILE_LOADING,
    });

    const requestOptions = {
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: getSuperAdminProfileDetails_Url,
    };
    axios(requestOptions)
      .then((resp) => {
        // let userData = resp.data.data;
        dispatch({
          type: SUPERADMIN_PROFILE_SUCCESS,
          response: {
            data: resp.data.data,
            msg: resp.data.message,
          },
        });
      })
      .catch((error) => {
        let data = error.response;
        if (data?.status === 422) {
          let validationError = data.data.error;
          // alert("action  Error")
          dispatch({
            type: SUPERADMIN_PROFILE_ERROR,
            errors: validationError,
          });
        } else if (data?.status === 401) {
          dispatch({
            type: SUPERADMIN_PROFILE_ERROR,
            errors: "UnAuthorized",
          });
        }
      });
  };
};

// ....................................  SignIn ..................................................

export const LogInDataAction = (user) => {
  return (dispatch) => {
    dispatch({
      type: GET_SIGNIN_LOADING,
    });
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      // method: "post",
      url: adminLoginUrl,
      data: user,
    };
    axios(requestOptions)
      .then((resp) => {
        dispatch({
          type: GET_SIGNIN_SUCCESS,
          response: {
            data: resp.data,
            msg: resp.data.message,
          },
        });
      })
      .catch((error) => {
        let data = error.response;
        if (!error.response) {
          dispatch({
            type: GET_SIGNIN_ERROR,
            netError: error,
          });
        } else if (data?.status === 422) {
          let validationError = data.data.message;
          dispatch({
            type: GET_SIGNIN_ERROR,
            errors: validationError,
          });
        } else if (data?.status === 401) {
          removeStorage();
        }
      });
  };
};

// .........................................  Agents  .........................................................

export const createAgentsdata = (user) => {
  return (dispatch) => {
    dispatch({
      type: ADD_AGENTS_DATA_LOADING,
    });

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: createAgentUrl,
      data: user,
    };
    axios(requestOptions)
      .then((resp) => {
        dispatch({
          type: ADD_AGENTS_DATA_SUCCESS,
          user: resp.data,
          msg: resp.data.message,
        });
      })
      .catch((error) => {
        let data = error.response;
        if (data?.status === 422) {
          let validationError = data.data.message;
          dispatch({
            type: ADD_AGENTS_DATA_ERROR,
            errors: validationError,
          });
        } else if (data?.status === 401) {
          removeStorage();
        }
      });
  };
};

export const getAgentsData = () => {
  return (dispatch) => {
    dispatch({
      type: GET_AGENTS_DATA_LOADING,
    });
    // const id = user.id;

    const requestOptions = {
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: getAgentsUrl,
    };
    axios(requestOptions)
      .then((resp) => {
        let userData = resp.data;
        dispatch({
          type: GET_AGENTS_DATA_SUCCESS,
          payload: userData,
          data: resp.data,
        });
      })
      .catch((error) => {
        let data = error.response;
        if (data?.status === 422) {
          let validationError = data.data.error;
          // alert("action  Error")
          dispatch({
            type: GET_AGENTS_DATA_ERROR,
            errors: validationError,
          });
        } else if (data?.status === 401) {
          removeStorage();
        }
      });
  };
};

export const getLocationListData = () => {
  return (dispatch) => {
    dispatch({
      type: GET_LOCATIONLIST_DATA_LOADING,
    });
    // const id = user.id;

    const requestOptions = {
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: getLocationListUrl,
    };
    axios(requestOptions)
      .then((resp) => {
        let userData = resp.data;
        dispatch({
          type: GET_LOCATIONLIST_DATA_SUCCESS,
          payload: userData,
          data: resp.data,
        });
      })
      .catch((error) => {
        let data = error.response;
        if (data?.status === 422) {
          let validationError = data.data.error;
          // alert("action  Error")
          dispatch({
            type: GET_LOCATIONLIST_DATA_ERROR,
            errors: validationError,
          });
        } else if (data?.status === 401) {
          removeStorage();
        }
      });
  };
};

export const deleteAgentsData = (id) => {
  return function (dispatch) {
    dispatch({
      type: DELETE_AGENTS_DATA,
    });

    const requestOptions = {
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: deleteAgentsUrl + `/${id}`,
    };

    axios(requestOptions)
      .then((re) => {
        dispatch({
          type: DELETE_AGENTS_DATA,
          payload: id,
        });
      })
      .catch((error) => {
        let data = error.response;
        if (!error.response) {
          dispatch({
            type: DELETE_AGENTS_DATA,
            netError: error,
          });
        } else if (data?.status === 422) {
          let validationError = data.data.message;
          dispatch({
            type: DELETE_AGENTS_DATA,
            errors: validationError,
          });
        } else if (data?.status === 401) {
          removeStorage();
        }
      });
  };
};

export const updateAgentsData = (details) => {
  return (dispatch) => {
    dispatch({
      type: UPDATE_AGENTS_DATA_LOADING,
    });
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: `${updateAgentsUrl}/${details.id}`,
      data: details.data,
    };
    axios(requestOptions)
      .then((resp) => {
        dispatch({
          type: UPDATE_AGENTS_DATA_SUCCESS,
          response: {
            data: resp.data,
            msg: resp.data.message,
          },
        });
      })
      .catch((error) => {
        let data = error.response;
        if (!error.response) {
          dispatch({
            type: UPDATE_AGENTS_DATA_ERROR,
            netError: error,
          });
        } else if (data?.status === 422) {
          let validationError = data.data.message;
          dispatch({
            type: UPDATE_AGENTS_DATA_ERROR,
            errors: validationError,
          });
        } else if (data?.status === 401) {
          removeStorage();
        }
      });
  };
};

export const getViewLocationById = (id) => {
  return (dispatch) => {
    dispatch({
      type: GET_VIEW_LOCATION_BY_ID_DATA_LOADING,
    });
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: `${updateViewLocationUrl}/${id}`,
    };
    axios(requestOptions)
      .then((resp) => {
        dispatch({
          type: GET_VIEW_LOCATION_BY_ID_DATA_SUCCESS,
          response: {
            data: resp.data,
            msg: resp.data.message,
          },
        });
      })
      .catch((error) => {
        let data = error.response;
        if (!error.response) {
          dispatch({
            type: GET_VIEW_LOCATION_BY_ID__DATA_ERROR,
            netError: error,
          });
        } else if (data?.status === 422) {
          let validationError = data.data.message;
          dispatch({
            type: GET_VIEW_LOCATION_BY_ID__DATA_ERROR,
            errors: validationError,
          });
        } else if (data?.status === 401) {
          removeStorage();
        }
      });
  };
};

// ............................................   PRM ACTION .........................................

export const getPrmDataAction = () => {
  // alert("action loading")
  return (dispatch) => {
    dispatch({
      type: GET_PRM_DATA_LOADING,
    });

    // const id = user.id;

    const requestOptions = {
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: getPrmUrl,
    };
    axios(requestOptions)
      .then((resp) => {
        let userData = resp.data;
        dispatch({
          type: GET_PRM_DATA_SUCCESS,
          payload: userData,
          data: resp.data,
        });
      })
      .catch((error) => {
        let data = error.response;
        if (data?.status === 422) {
          let validationError = data.data.error;
          // alert("action  Error")
          dispatch({
            type: GET_PRM_DATA_ERROR,
            errors: validationError,
          });
        } else if (data?.status === 401) {
          removeStorage();
        }
      });
  };
};

export const createPrmDataAction = (user) => {
  return (dispatch) => {
    dispatch({
      type: ADD_PRM_DATA_LOADING,
    });

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: createPrmUrl,
      data: user,
    };
    axios(requestOptions)
      .then((resp) => {
        dispatch({
          type: ADD_PRM_DATA_SUCCESS,
          user: resp.data,
          msg: resp.data.message,
        });
      })

      .catch((error) => {
        let data = error.response;
        if (data?.status === 422) {
          let validationError = data.data.message;
          dispatch({
            type: ADD_PRM_DATA_ERROR,
            errors: validationError,
          });
        } else if (data?.status === 401) {
          removeStorage();
        }
      });
  };
};

export const updatePrmDataAction = (details) => {
  return (dispatch) => {
    dispatch({
      type: UPDATE_PRM_DATA_LOADING,
    });

    // const id = user.id;
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: updatePrmUrl + `/${details.id}`,
      data: details.data,
    };

    axios(requestOptions)
      .then((resp) => {
        dispatch({
          type: UPDATE_PRM_DATA_SUCCESS,
          response: {
            data: resp.data.data,
            msg: resp.data.message,
          },
        });
      })
      .catch((error) => {
        let data = error.response;
        if (!error.response) {
          dispatch({
            type: UPDATE_PRM_DATA_ERROR,
            netError: error,
          });
        } else if (data?.status === 422) {
          let validationError = data.data.message;
          dispatch({
            type: UPDATE_PRM_DATA_ERROR,
            errors: validationError,
          });
        } else if (data?.status === 401) {
          removeStorage();
        }
      });
  };
};

export const getPrmById = (id) => {
  return (dispatch) => {
    dispatch({
      type: GET_PRM_BY_ID_DATA_LOADING,
    });
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: `${updatePrmByID_Url}/${id}`,
    };
    axios(requestOptions)
      .then((resp) => {
        dispatch({
          type: GET_PRM_BY_ID_DATA_SUCCESS,
          response: {
            data: resp.data,
            msg: resp.data.message,
          },
        });
      })
      .catch((error) => {
        let data = error.response;
        if (!error.response) {
          dispatch({
            type: GET_PRM_BY_ID__DATA_ERROR,
            netError: error,
          });
        } else if (data?.status === 422) {
          let validationError = data.data.message;
          dispatch({
            type: GET_PRM_BY_ID__DATA_ERROR,
            errors: validationError,
          });
        } else if (data?.status === 401) {
          removeStorage();
        }
      });
  };
};

export const deletePrmData = (id) => {
  return (dispatch) => {
    dispatch({
      type: DELETE_PRM_DATA_LOADING,
    });
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: `${deletePrmUrl}/${id}`,
    };
    axios(requestOptions)
      .then((resp) => {
        dispatch({
          type: DELETE_PRM_DATA_SUCCESS,
          response: {
            data: resp.data,
            msg: resp.data.message,
          },
        });
      })
      .catch((error) => {
        let data = error.response;
        if (!error.response) {
          dispatch({
            type: DELETE_PRM_DATA_ERROR,
            netError: error,
          });
        } else if (data?.status === 422) {
          let validationError = data.data.message;
          dispatch({
            type: DELETE_PRM_DATA_ERROR,
            errors: validationError,
          });
        } else if (data?.status === 401) {
          removeStorage();
        }
      });
  };
};

// ..........................................................   Menu Action  ........................................................

export const getMenuDataAction = () => {
  return (dispatch) => {
    dispatch({
      type: GET_MENU_DATA_LOADING,
    });
    // const id = user.id;
    const requestOptions = {
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: getMenuItemUrl,
    };
    axios(requestOptions)
      .then((resp) => {
        let userData = resp.data;
        dispatch({
          type: GET_MENU_DATA_SUCCESS,
          payload: userData,
          data: resp.data,
        });
      })
      .catch((error) => {
        let data = error.response;
        if (data?.status === 422) {
          let validationError = data.data.error;
          // alert("action  Error")
          dispatch({
            type: GET_MENU_DATA_ERROR,
            errors: validationError,
          });
        } else if (data?.status === 401) {
          removeStorage();
        }
      });
  };
};

export const createMenuDataAction = (user) => {
  return (dispatch) => {
    dispatch({
      type: ADD_MENU_DATA_LOADING,
    });

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: createMenuItemUrl,
      data: user,
    };
    axios(requestOptions)
      .then((resp) => {
        dispatch({
          type: ADD_MENU_DATA_SUCCESS,
          user: resp.data.data,
          msg: resp.data,
        });
      })
      .catch((error) => {
        let data = error.response;
        if (data?.status === 422) {
          let validationError = data.data.error;
          dispatch({
            type: ADD_MENU_DATA_ERROR,
            errors: validationError,
          });
        } else if (data?.status === 401) {
          removeStorage();
        }
      });
  };
};

export const updateMenuDataAction = (details) => {
  return (dispatch) => {
    dispatch({
      type: UPDATE_MENU_DATA_LOADING,
    });
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: updateMenuItemUrl + `/${details.id}`,
      data: details.data,
    };

    axios(requestOptions)
      .then((resp) => {
        dispatch({
          type: UPDATE_MENU_DATA_SUCCESS,
          data: resp.data.data,
          msg: resp.data,
        });
      })
      .catch((error) => {
        let data = error.response;
        if (!error.response) {
          dispatch({
            type: UPDATE_MENU_DATA_ERROR,
            netError: error,
          });
        } else if (data?.status === 422) {
          let validationError = data.data.message;
          dispatch({
            type: UPDATE_MENU_DATA_ERROR,
            errors: validationError,
          });
        } else if (data?.status === 401) {
          removeStorage();
        }
      });
  };
};

export const getMenuById = (id) => {
  return (dispatch) => {
    dispatch({
      type: GET_MENU_BY_ID_DATA_LOADING,
    });
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: `${updateMenuByIdUrl}/${id}`,
    };
    axios(requestOptions)
      .then((resp) => {
        dispatch({
          type: GET_MENU_BY_ID_DATA_SUCCESS,
          response: {
            data: resp.data,
            msg: resp.data.message,
          },
        });
      })
      .catch((error) => {
        let data = error.response;
        if (!error.response) {
          dispatch({
            type: GET_MENU_BY_ID__DATA_ERROR,
            netError: error,
          });
        } else if (data?.status === 422) {
          let validationError = data.data.message;
          dispatch({
            type: GET_MENU_BY_ID__DATA_ERROR,
            errors: validationError,
          });
        } else if (data?.status === 401) {
          removeStorage();
        }
      });
  };
};

export const deleteMenuData = (id) => {
  return (dispatch) => {
    dispatch({
      type: DELETE_MENUITEMS_DATA_LOADING,
    });
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: `${deleteMenuItemUrl}/${id}`,
    };
    axios(requestOptions)
      .then((resp) => {
        dispatch({
          type: DELETE_MENUITEMS_DATA_SUCCESS,
          response: {
            data: resp.data.data,
            msg: resp.data.message,
          },
        });
      })
      .catch((error) => {
        let data = error.response;
        if (!error.response) {
          dispatch({
            type: DELETE_MENUITEMS_DATA_ERROR,
            netError: error,
          });
        } else if (data?.status === 422) {
          let validationError = data.data.message;
          dispatch({
            type: DELETE_MENUITEMS_DATA_ERROR,
            errors: validationError,
          });
        } else if (data?.status === 401) {
          removeStorage();
        }
      });
  };
};

// .......................................................  Drivers  ...........................................

export const getDeliveryAgentsDataAction = (details) => {
  // alert("action loading")
  return (dispatch) => {
    dispatch({
      type: GET_DELIVERY_AGENTS_DATA_LOADING,
    });

    // const id = user.id;

    const requestOptions = {
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: `${getdeliveryagentUrl}/${details?.id !== "all" ? details?.id : ""}`,
    };
    axios(requestOptions)
      .then((resp) => {
        let userData = resp.data;
        dispatch({
          type: GET_DELIVERY_AGENTS_DATA_SUCCESS,
          payload: userData,
          data: resp.data,
        });
      })
      .catch((error) => {
        let data = error.response;
        if (data?.status === 422) {
          let validationError = data.data.error;
          // alert("action  Error")
          dispatch({
            type: GET_DELIVERY_AGENTS_DATA_ERROR,
            errors: validationError,
          });
        } else if (data?.status === 401) {
          removeStorage();
        }
      });
  };
};

export const createDeliveryAgentsDataAction = (user) => {
  // alert("Action loading")
  return (dispatch) => {
    dispatch({
      type: ADD_DELIVERY_AGENTS_DATA_LOADING,
    });

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: createdeliveryItemUrl,
      data: user,
    };
    axios(requestOptions)
      .then((resp) => {
        dispatch({
          type: ADD_DELIVERY_AGENTS_DATA_SUCCESS,
          user: resp.data,
          msg: resp.data.message,
        });
      })

      .catch((error) => {
        let data = error.response;
        if (data?.status === 422) {
          let validationError = data.data.message;
          dispatch({
            type: ADD_DELIVERY_AGENTS_DATA_ERROR,
            errors: validationError,
          });
        } else if (data?.status === 401) {
          removeStorage();
        }
      });
  };
};

export const updateDeliveryAgentsDataAction = (details) => {
  return (dispatch) => {
    dispatch({
      type: UPDATE_DELIVERY_AGENTS_DATA_LOADING,
    });
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: `${updatedeliveryagentUrl}/${details.id}`,
      data: details.data,
    };
    axios(requestOptions)
      .then((resp) => {
        dispatch({
          type: UPDATE_DELIVERY_AGENTS_DATA_SUCCESS,
          response: {
            data: resp.data,
            msg: resp.data.message,
          },
        });
      })
      .catch((error) => {
        let data = error.response;
        if (!error.response) {
          dispatch({
            type: UPDATE_DELIVERY_AGENTS_DATA_ERROR,
            netError: error,
          });
        } else if (data?.status === 422) {
          let validationError = data.data.message;
          dispatch({
            type: UPDATE_DELIVERY_AGENTS_DATA_ERROR,
            errors: validationError,
          });
        } else if (data?.status === 401) {
          removeStorage();
        }
      });
  };
};

export const deleteDeliveryAgentsData = (id) => {
  return (dispatch) => {
    dispatch({
      type: DELETE_DELIVERY_AGENTS_DATA_LOADING,
    });
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: `${deletedeliveryagentUrl}/${id}`,
    };
    axios(requestOptions)
      .then((resp) => {
        dispatch({
          type: DELETE_DELIVERY_AGENTS_DATA_SUCCESS,
          response: {
            data: resp.data,
            msg: resp.data.message,
          },
        });
      })
      .catch((error) => {
        let data = error.response;
        if (!error.response) {
          dispatch({
            type: DELETE_DELIVERY_AGENTS_DATA_ERROR,
            netError: error,
          });
        } else if (data?.status === 422) {
          let validationError = data.data.message;
          dispatch({
            type: DELETE_DELIVERY_AGENTS_DATA_ERROR,
            errors: validationError,
          });
        } else if (data?.status === 401) {
          removeStorage();
        }
      });
  };
};

export const getDeliveryAgentsById = (id) => {
  return (dispatch) => {
    dispatch({
      type: GET_DELIVERY_AGENTS_BY_ID_DATA_LOADING,
    });
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: `${getdeliveryagentByIDUrl}/${id}`,
    };
    axios(requestOptions)
      .then((resp) => {
        dispatch({
          type: GET_DELIVERY_AGENTS_BY_ID_DATA_SUCCESS,
          response: {
            data: resp.data,
            msg: resp.data.message,
          },
        });
      })
      .catch((error) => {
        let data = error.response;
        if (!error.response) {
          dispatch({
            type: GET_DELIVERY_AGENTS_BY_ID__DATA_ERROR,
            netError: error,
          });
        } else if (data?.status === 422) {
          let validationError = data.data.message;
          dispatch({
            type: GET_DELIVERY_AGENTS_BY_ID__DATA_ERROR,
            errors: validationError,
          });
        } else if (data?.status === 401) {
          removeStorage();
        }
      });
  };
};

// ...................................................... Cutomer .....................................

export const getCutomerDataAction = () => {
  // alert("action loading")
  return (dispatch) => {
    dispatch({
      type: GET_CUSTOMER_LOADING,
    });

    // const id = user.id;

    const requestOptions = {
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: getcustomerUrl,
    };
    axios(requestOptions)
      .then((resp) => {
        let userData = resp.data;
        dispatch({
          type: GET_CUSTOMER_SUCCESS,
          payload: userData,
          data: resp.data,
        });
      })
      .catch((error) => {
        let data = error.response;
        if (data?.status === 422) {
          let validationError = data.data.error;
          // alert("action  Error")
          dispatch({
            type: GET_CUSTOMER_ERROR,
            errors: validationError,
          });
        } else if (data?.status === 401) {
          removeStorage();
        }
      });
  };
};

export const createCutomerDataAction = (user) => {
  return (dispatch) => {
    dispatch({
      type: ADD_CUSTOMER_LOADING,
    });

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: createcustomerUrl,
      data: user,
    };
    axios(requestOptions)
      .then((resp) => {
        dispatch({
          type: ADD_CUSTOMER_SUCCESS,
          user: resp.data,
          msg: resp.data.message,
        });
      })

      .catch((error) => {
        let data = error.response;
        if (data?.status === 422) {
          let validationError = data.data.message;
          dispatch({
            type: ADD_CUSTOMER_ERROR,
            errors: validationError,
          });
        } else if (data?.status === 401) {
          removeStorage();
        }
      });
  };
};

export const updateCutomerDataAction = (details) => {
  return (dispatch) => {
    dispatch({
      type: UPDATE_CUSTOMER_LOADING,
    });
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: `${updatecustomerUrl}/${details.id}`,
      data: details.data,
    };
    axios(requestOptions)
      .then((resp) => {
        dispatch({
          type: UPDATE_CUSTOMER_SUCCESS,
          response: {
            data: resp.data,
            msg: resp.data.message,
          },
        });
      })
      .catch((error) => {
        let data = error.response;
        if (!error.response) {
          dispatch({
            type: UPDATE_CUSTOMER_ERROR,
            netError: error,
          });
        } else if (data?.status === 422) {
          let validationError = data.data.message;
          dispatch({
            type: UPDATE_CUSTOMER_ERROR,
            errors: validationError,
          });
        } else if (data?.status === 401) {
          removeStorage();
        }
      });
  };
};

export const getViewCustomerById = (id) => {
  return (dispatch) => {
    dispatch({
      type: GET_CUSTOMER_BY_ID_DATA_LOADING,
    });
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: `${getcustomerByIdUrl}/${id}`,
    };
    axios(requestOptions)
      .then((resp) => {
        dispatch({
          type: GET_CUSTOMER_BY_ID_DATA_SUCCESS,
          response: {
            data: resp.data,
            msg: resp.data.message,
          },
        });
      })
      .catch((error) => {
        let data = error.response;
        if (!error.response) {
          dispatch({
            type: GET_CUSTOMER_BY_ID__DATA_ERROR,
            netError: error,
          });
        } else if (data?.status === 422) {
          let validationError = data.data.message;
          dispatch({
            type: GET_CUSTOMER_BY_ID__DATA_ERROR,
            errors: validationError,
          });
        } else if (data?.status === 401) {
          removeStorage();
        }
      });
  };
};

export const deleteCutomerData = (id) => {
  return (dispatch) => {
    dispatch({
      type: DELETE_CUSTOMER_DATA_LOADING,
    });
    const requestOptions = {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: `${deletecustomerUrl}/${id}`,
    };
    axios(requestOptions)
      .then((resp) => {
        dispatch({
          type: DELETE_CUSTOMER_DATA_SUCCESS,
          response: {
            data: resp.data.data,
            msg: resp.data.message,
          },
        });
      })
      .catch((error) => {
        let data = error.response;
        if (!error.response) {
          dispatch({
            type: DELETE_CUSTOMER_DATA_ERROR,
            netError: error,
          });
        } else if (data?.status === 422) {
          let validationError = data.data.message;
          dispatch({
            type: DELETE_CUSTOMER_DATA_ERROR,
            errors: validationError,
          });
        } else if (data?.status === 401) {
          removeStorage();
        }
      });
  };
};

// ............................................  Customer Address  ..................................................

export const getCutomerAddressDataAction = (user) => {
  return (dispatch) => {
    dispatch({
      type: GET_CUSTOMER_ADDRESS_LOADING,
    });

    const requestOptions = {
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: getcustomeeAddressUrl,
    };
    axios(requestOptions)
      .then((resp) => {
        let userData = resp.data;
        dispatch({
          type: GET_CUSTOMER_ADDRESS_SUCCESS,
          payload: userData,
          data: resp.data,
        });
      })
      .catch((error) => {
        let data = error.response;
        if (data?.status === 422) {
          let validationError = data.data.error;
          // alert("action  Error")
          dispatch({
            type: GET_CUSTOMER_ADDRESS_LOADING,
            errors: validationError,
          });
        } else if (data?.status === 401) {
          removeStorage();
        }
      });
  };
};

export const createCutomerAddressDataAction = (user) => {
  // alert(user,"useer")
  return (dispatch) => {
    dispatch({
      type: ADD_CUSTOMER_ADDRESS_LOADING,
    });

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: createcustomeeAddressUrl,
      data: user,
    };
    axios(requestOptions)
      .then((resp) => {
        dispatch({
          type: ADD_CUSTOMER_ADDRESS_SUCCESS,
          user: resp.data,
          msg: resp.data,
        });
      })

      .catch((error) => {
        let data = error.response;
        if (data?.status === 422) {
          let validationError = data.data.error;
          dispatch({
            type: ADD_CUSTOMER_ADDRESS_ERROR,
            errors: validationError,
          });
        } else if (data?.status === 401) {
          removeStorage();
        }
      });
  };
};

export const updateCutomerAddressDataAction = (user) => {
  return (dispatch) => {
    dispatch({
      type: UPDATE_CUSTOMER_ADDRESS_LOADING,
    });

    const id = user.id;
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: updatecustomeeAddressUrl + `/${id}`,
      data: user,
    };

    axios(requestOptions)
      .then((resp) => {
        dispatch({
          type: UPDATE_CUSTOMER_ADDRESS_SUCCESS,
          data: resp.data.data,
          msg: resp.data,
        });
      })
      .catch((error) => {
        let data = error.response;
        if (data?.status === 422) {
          let validationError = data.data.error;
          dispatch({
            type: UPDATE_CUSTOMER_ADDRESS_ERROR,
            errors: validationError,
          });
        } else if (data?.status === 401) {
          removeStorage();
        }
      });
  };
};

// export const deleteCutomerAddressData = (id) => {
//   const requestOptions = {
//     method: "DELETE",
//     headers: {
//       "content-type": "application/json",
//       Authorization: `Bearer ${localStorage.getItem("token")}`,
//     },
//     url: deletecustomeeAddressUrl + `/${id}`,
//   };
//   return function (dispatch) {
//     axios(requestOptions)
//       .then((resp) => {
//         dispatch({
//           type: DELETE_CUSTOMER_ADDRESS_SUCCESS,
//           payload: id,
//           flag: resp.data?.address_deleted,
//         });
//       })
//       .catch((error) => {
//         let data = error.response;
//         if (data?.status === 422) {
//           let validationError = data.data.message;
//           dispatch({
//             type: DELETE_CUSTOMER_ADDRESS_ERROR,
//             errors: validationError,
//             flag: data?.data?.address_deleted,
//           });
//         } else if (data?.status === 401) {
//           window.location.reload();
//         }
//       });
//   };
// };

export const deleteCutomerAddressData = (id) => {
  return (dispatch) => {
    dispatch({
      type: DELETE_CUSTOMER_ADDRESS_LOADING,
    });
    const requestOptions = {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: `${deletecustomeeAddressUrl}/${id}`,
    };
    axios(requestOptions)
      .then((resp) => {
        dispatch({
          type: DELETE_CUSTOMER_ADDRESS_SUCCESS,
          response: {
            data: resp.data.data,
            msg: resp.data.message,
            flag: resp.data.address_deleted,
          },
        });
      })
      .catch((error) => {
        let data = error.response;
        if (!error.response) {
          dispatch({
            type: DELETE_CUSTOMER_ADDRESS_ERROR,
            netError: error,
          });
        } else if (data?.status === 422) {
          let validationError = data.data.message;
          dispatch({
            type: DELETE_CUSTOMER_ADDRESS_ERROR,
            errors: validationError,
            flag: data?.data?.address_deleted,
          });
        } else if (data?.status === 401) {
          removeStorage();
        }
      });
  };
};
// ............................................  Order  ..................................................

export const getOrderDataAction = () => {
  return (dispatch) => {
    dispatch({
      type: GET_SUPER_ADMIN_ORDER_LOADING,
    });

    const requestOptions = {
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: getordarUrl,
    };
    axios(requestOptions)
      .then((resp) => {
        let userData = resp.data;
        dispatch({
          type: GET_SUPER_ADMIN_ORDER_SUCCESS,
          response: {
            data: resp.data.data,
            fullData: resp?.data,
            msg: resp.data.message,
          },
          // payload: userData,
          // data: resp.data.data,
        });
      })
      .catch((error) => {
        let data = error.response;
        if (!error.response) {
          dispatch({
            type: GET_SUPER_ADMIN_ORDER_ERROR,
            netError: error,
          });
        } else if (data?.status === 422) {
          let validationError = data.data.message;
          dispatch({
            type: GET_SUPER_ADMIN_ORDER_ERROR,
            errors: validationError,
          });
        } else if (data?.status === 401) {
          removeStorage();
        }
      });
  };
};

export const getOneOrderDataAction = async (id) => {
  const requestOptions = {
    method: "GET",
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
    url: `${getoneordarUrl}/${id}`,
  };
  try {
    const resp = await axios(requestOptions);
    return {
      data: resp.data.data,
      fullData: resp?.data,
      msg: resp.data.message,
    };
  } catch (error) {
    let data = error.response;
    if (!error.response) {
      // dispatch({
      //   type: GET_SUPER_ADMIN_ORDER_ERROR,
      //   netError: error,
      // });
    } else if (data?.status === 422) {
      let validationError = data.data.message;
      // dispatch({
      //   type: GET_SUPER_ADMIN_ORDER_ERROR,
      //   errors: validationError,
      // });
    } else if (data?.status === 401) {
      removeStorage();
    }
    return null
  }
};

export const getOrderFilterAction = (details) => {
  return (dispatch) => {
    dispatch({
      type: GET_SUPERADMIN_ORDER_FILTER_LOADING,
    });
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: `${SuperadminOrderFilter_Url}`,
      data: details,
    };
    axios(requestOptions)
      .then((resp) => {
        dispatch({
          type: GET_SUPERADMIN_ORDER_FILTER_SUCCESS,
          response: {
            data: resp.data.data,
            fullData: resp?.data,
            msg: resp.data.message,
          },
        });
      })
      .catch((error) => {
        let data = error.response;
        if (!error.response) {
          dispatch({
            type: GET_SUPERADMIN_ORDER_FILTER_ERROR,
            netError: error,
          });
        } else if (data?.status === 422) {
          let validationError = data.data.message;
          dispatch({
            type: GET_SUPERADMIN_ORDER_FILTER_ERROR,
            errors: validationError,
          });
        } else if (data?.status === 401) {
          removeStorage();
        }
      });
  };
};

export const createOrderDataAction = (details) => {
  return (dispatch) => {
    dispatch({
      type: ADD_SUPER_ADMIN_ORDER_LOADING,
    });
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: `${createordarUrl}`,
      data: details,
    };
    axios(requestOptions)
      .then((resp) => {
        dispatch({
          type: ADD_SUPER_ADMIN_ORDER_SUCCESS,
          response: {
            data: resp.data.data,
            msg: resp.data.message,
          },
        });
      })
      .catch((error) => {
        let data = error.response;
        if (!error.response) {
          dispatch({
            type: ADD_SUPER_ADMIN_ORDER_ERROR,
            netError: error,
          });
        } else if (data?.status === 422) {
          let validationError = data.data.message;
          dispatch({
            type: ADD_SUPER_ADMIN_ORDER_ERROR,
            errors: validationError,
          });
        } else if (data?.status === 401) {
          removeStorage();
        }
      });
  };
};

export const deleteOrderData = (id) => {
  return (dispatch) => {
    dispatch({
      type: DELETE_SUPER_ADMIN_ORDER_LOADING,
    });
    const requestOptions = {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: `${deleteordarUrl}/${id}`,
    };
    axios(requestOptions)
      .then((resp) => {
        dispatch({
          type: DELETE_SUPER_ADMIN_ORDER_SUCCESS,
          response: {
            data: resp.data.data,
            msg: resp.data.message,
          },
        });
      })
      .catch((error) => {
        let data = error.response;
        if (!error.response) {
          dispatch({
            type: DELETE_SUPER_ADMIN_ORDER_ERROR,
            netError: error,
          });
        } else if (data?.status === 422) {
          let validationError = data.data.message;
          dispatch({
            type: DELETE_SUPER_ADMIN_ORDER_ERROR,
            errors: validationError,
          });
        } else if (data?.status === 401) {
          removeStorage();
        }
      });
  };
};

export const findCustomer = (contactDetails) => {
  return (dispatch) => {
    dispatch({
      type: FIND_SUPERADMIN_CUSTOMER_BY_CONTACT_LOADING,
    });
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: `${findCustomer_Url}`,
      data: contactDetails,
    };
    axios(requestOptions)
      .then((resp) => {
        dispatch({
          type: FIND_SUPERADMIN_CUSTOMER_BY_CONTACT_SUCCESS,
          response: {
            data: resp.data.data,
            msg: resp.data.message,
          },
        });
      })
      .catch((error) => {
        let data = error.response;
        if (!error.response) {
          dispatch({
            type: FIND_SUPERADMIN_CUSTOMER_BY_CONTACT_ERROR,
            netError: error,
          });
        } else if (data?.status === 422) {
          let validationError = data.data.message;
          dispatch({
            type: FIND_SUPERADMIN_CUSTOMER_BY_CONTACT_ERROR,
            errors: validationError,
          });
        } else if (data?.status === 401) {
          removeStorage();
        }
      });
  };
};

export const getLocationByMenuDataAction = (id) => {
  return (dispatch) => {
    dispatch({
      type: GET_SUPER_ADMIN_LOCATION_BY_MENU_LOADING,
    });
    const requestOptions = {
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: `${locationByMenuUrl}/${id}`,
    };
    axios(requestOptions)
      .then((resp) => {
        let userData = resp.data;
        dispatch({
          type: GET_SUPER_ADMIN_LOCATION_BY_MENU_SUCCESS,
          response: {
            data: resp.data.data,
            msg: resp.data.message,
          },
          // payload: userData,
          // data: resp.data.data,
        });
      })
      .catch((error) => {
        let data = error.response;
        if (!error.response) {
          dispatch({
            type: GET_SUPER_ADMIN_LOCATION_BY_MENU_ERROR,
            netError: error,
          });
        } else if (data?.status === 422) {
          let validationError = data.data.message;
          dispatch({
            type: GET_SUPER_ADMIN_LOCATION_BY_MENU_ERROR,
            errors: validationError,
          });
        } else if (data?.status === 401) {
          removeStorage();
        }
      });
  };
};

export const getLocationByAgentsDataAction = (id) => {
  return (dispatch) => {
    dispatch({
      type: GET_SUPER_ADMIN_LOCATION_BY_Agents_LOADING,
    });
    const requestOptions = {
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: `${locationByAgentsUrl}/${id}`,
    };
    axios(requestOptions)
      .then((resp) => {
        let userData = resp.data;
        dispatch({
          type: GET_SUPER_ADMIN_LOCATION_BY_Agents_SUCCESS,
          response: {
            data: resp.data.data,
            msg: resp.data.message,
          },
          // payload: userData,
          // data: resp.data.data,
        });
      })
      .catch((error) => {
        let data = error.response;
        if (!error.response) {
          dispatch({
            type: GET_SUPER_ADMIN_LOCATION_BY_Agents_ERROR,
            netError: error,
          });
        } else if (data?.status === 422) {
          let validationError = data.data.message;
          dispatch({
            type: GET_SUPER_ADMIN_LOCATION_BY_Agents_ERROR,
            errors: validationError,
          });
        } else if (data?.status === 401) {
          removeStorage();
        }
      });
  };
};

export const getFreeAgentData = (details) => {
  return (dispatch) => {
    dispatch({
      type: GET_SUPER_ADMIN_LOCATION_BY_Agents_LOADING,
    });
    const requestOptions = {
      method: "POST",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: freeAgent_Url,
      data: details.details,
    };
    axios(requestOptions)
      .then((resp) => {
        let userData = resp.data;
        dispatch({
          type: GET_SUPER_ADMIN_LOCATION_BY_Agents_SUCCESS,
          response: {
            data: resp.data.agent,
            msg: resp.data.message,
          },
          // payload: userData,
          // data: resp.data.data,
        });
      })
      .catch((error) => {
        let data = error.response;
        if (!error.response) {
          dispatch({
            type: GET_SUPER_ADMIN_LOCATION_BY_Agents_ERROR,
            netError: error,
          });
        } else if (data?.status === 422) {
          let validationError = data.data.message;
          dispatch({
            type: GET_SUPER_ADMIN_LOCATION_BY_Agents_ERROR,
            errors: validationError,
          });
        } else if (data?.status === 401) {
          removeStorage();
        }
      });
  };
};

export const getLocationByOrderDataAction = (id) => {
  return (dispatch) => {
    dispatch({
      type: GET_SUPER_ADMIN_LOCATION_BY_OREDR_LOADING,
    });
    const requestOptions = {
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: `${locationByOrderUrl}/${id}`,
    };
    axios(requestOptions)
      .then((resp) => {
        let userData = resp.data;
        dispatch({
          type: GET_SUPER_ADMIN_LOCATION_BY_OREDR_SUCCESS,
          response: {
            data: resp.data.data,
            msg: resp.data.message,
          },
          // payload: userData,
          // data: resp.data.data,
        });
      })
      .catch((error) => {
        let data = error.response;
        if (!error.response) {
          dispatch({
            type: GET_SUPER_ADMIN_LOCATION_BY_OREDR_ERROR,
            netError: error,
          });
        } else if (data?.status === 422) {
          let validationError = data.data.message;
          dispatch({
            type: GET_SUPER_ADMIN_LOCATION_BY_OREDR_ERROR,
            errors: validationError,
          });
        } else if (data?.status === 401) {
          removeStorage();
        }
      });
  };
};

export const getLocationByPRMDataAction = (id) => {
  return (dispatch) => {
    dispatch({
      type: GET_SUPER_ADMIN_LOCATION_BY_PRM_LOADING,
    });
    const requestOptions = {
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: `${locationByPRMUrl}/${id}`,
    };
    axios(requestOptions)
      .then((resp) => {
        let userData = resp.data;
        dispatch({
          type: GET_SUPER_ADMIN_LOCATION_BY_PRM_SUCCESS,
          response: {
            data: resp.data.data,
            msg: resp.data.message,
          },
          // payload: userData,
          // data: resp.data.data,
        });
      })
      .catch((error) => {
        let data = error.response;
        if (!error.response) {
          dispatch({
            type: GET_SUPER_ADMIN_LOCATION_BY_PRM_ERROR,
            netError: error,
          });
        } else if (data?.status === 422) {
          let validationError = data.data.message;
          dispatch({
            type: GET_SUPER_ADMIN_LOCATION_BY_PRM_ERROR,
            errors: validationError,
          });
        } else if (data?.status === 401) {
          removeStorage();
        }
      });
  };
};

// ........................   Change Password .........................

export const changePassword = (detail) => {
  return (dispatch) => {
    dispatch({
      type: SUPERADMIN_CHANGE_PASSWORD_LOADING,
    });
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: changepasswordUrl,
      data: detail,
    };
    axios(requestOptions)
      .then((resp) => {
        dispatch({
          type: SUPERADMIN_CHANGE_PASSWORD_SUCCESS,
          response: {
            data: resp.data.data,
            msg: resp.data.message,
          },
        });
      })
      .catch((error) => {
        let data = error.response;
        if (!error.response) {
          dispatch({
            type: SUPERADMIN_CHANGE_PASSWORD_ERROR,
            netError: error,
          });
        } else if (data?.status === 422) {
          let validationError = data.data.message;
          dispatch({
            type: SUPERADMIN_CHANGE_PASSWORD_ERROR,
            errors: validationError,
          });
        } else if (data?.status === 401) {
          removeStorage();
        }
      });
  };
};

export const SuperadminchangePassword = (detail) => {
  return (dispatch) => {
    dispatch({
      type: SUPERADMIN_TO_ADMIN_CHANGE_PASSWORD_LOADING,
    });
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: SuperadminchangepasswordUrl,
      data: detail,
    };
    axios(requestOptions)
      .then((resp) => {
        dispatch({
          type: SUPERADMIN_TO_ADMIN_CHANGE_PASSWORD_SUCCESS,
          response: {
            data: resp.data.data,
            msg: resp.data.message,
          },
        });
      })
      .catch((error) => {
        let data = error.response;
        if (!error.response) {
          dispatch({
            type: SUPERADMIN_TO_ADMIN_CHANGE_PASSWORD_ERROR,
            netError: error,
          });
        } else if (data?.status === 422) {
          let validationError = data.data.message;
          dispatch({
            type: SUPERADMIN_TO_ADMIN_CHANGE_PASSWORD_ERROR,
            errors: validationError,
          });
        } else if (data?.status === 401) {
          removeStorage();
        }
      });
  };
};

//  for get otp
export const forgotPassword = (detail) => {
  return (dispatch) => {
    dispatch({
      type: SUPERADMIN_FORGOT_PASSWORD_LOADING,
    });
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      // method: "post",
      url: forgotPassword_Url,
      data: detail,
    };
    axios(requestOptions)
      .then((resp) => {
        dispatch({
          type: SUPERADMIN_FORGOT_PASSWORD_SUCCESS,
          response: {
            data: resp.data.data,
            msg: resp.data.message,
          },
        });
      })
      .catch((error) => {
        let data = error.response;
        if (!error.response) {
          dispatch({
            type: SUPERADMIN_FORGOT_PASSWORD_ERROR,
            netError: error,
          });
        } else if (data?.status === 422) {
          let validationError = data.data.message;
          dispatch({
            type: SUPERADMIN_FORGOT_PASSWORD_ERROR,
            errors: validationError,
          });
        } else if (data?.status === 401) {
          removeStorage();
        }
      });
  };
};

// enter otp

export const verifyOtp = (detail) => {
  return (dispatch) => {
    dispatch({
      type: SUPERADMIN_OTP_VERIFY_LOADING,
    });
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      // method: "post",
      url: verifyOtp_Url,
      data: detail,
    };
    axios(requestOptions)
      .then((resp) => {
        dispatch({
          type: SUPERADMIN_OTP_VERIFY_SUCCESS,
          response: {
            data: resp.data.data,
            msg: resp.data.message,
          },
        });
      })
      .catch((error) => {
        let data = error.response;
        if (!error.response) {
          dispatch({
            type: SUPERADMIN_OTP_VERIFY_ERROR,
            netError: error,
          });
        } else if (data?.status === 422) {
          let validationError = data.data.message;
          dispatch({
            type: SUPERADMIN_OTP_VERIFY_ERROR,
            errors: validationError,
          });
        } else if (data?.status === 401) {
          removeStorage();
        }
      });
  };
};

// reset pass
export const resetPassword = (detail) => {
  return (dispatch) => {
    dispatch({
      type: RESET_SUPERADMIN_PASSWORD_LOADING,
    });
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      // method: "post",
      url: resetPassword_Url,
      data: detail,
    };
    axios(requestOptions)
      .then((resp) => {
        dispatch({
          type: RESET_SUPERADMIN_PASSWORD_SUCCESS,
          response: {
            data: resp.data.data,
            msg: resp.data.message,
          },
        });
      })
      .catch((error) => {
        let data = error.response;
        if (!error.response) {
          dispatch({
            type: RESET_SUPERADMIN_PASSWORD_ERROR,
            netError: error,
          });
        } else if (data?.status === 422) {
          let validationError = data.data.message;
          dispatch({
            type: RESET_SUPERADMIN_PASSWORD_ERROR,
            errors: validationError,
          });
        } else if (data?.status === 401) {
          removeStorage();
        }
      });
  };
};

export const getLocationWiseAgentsDataAction = (id) => {
  return (dispatch) => {
    dispatch({
      type: GET_SUPER_ADMIN_LOCATION_WISE_AGENTS_LOADING,
    });
    const requestOptions = {
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: `${locationWiseagentsUrl}/${id}`,
    };
    axios(requestOptions)
      .then((resp) => {
        let userData = resp.data;
        dispatch({
          type: GET_SUPER_ADMIN_LOCATION_WISE_AGENTS_SUCCESS,
          response: {
            data: resp.data.data,
            msg: resp.data.message,
          },
          // payload: userData,
          // data: resp.data.data,
        });
      })
      .catch((error) => {
        let data = error.response;
        if (!error.response) {
          dispatch({
            type: GET_SUPER_ADMIN_LOCATION_WISE_AGENTS_ERROR,
            netError: error,
          });
        } else if (data?.status === 422) {
          let validationError = data.data.message;
          dispatch({
            type: GET_SUPER_ADMIN_LOCATION_WISE_AGENTS_ERROR,
            errors: validationError,
          });
        } else if (data?.status === 401) {
          removeStorage();
        }
      });
  };
};

export const CustomerSearchByContactAction = (detail) => {
  return (dispatch) => {
    dispatch({
      type: CUSTOMER_SERACH_BY_CONTACT_LOADING,
    });
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      // method: "post",
      url: CustomerSerachByContactUrl,
      data: detail,
    };
    axios(requestOptions)
      .then((resp) => {
        dispatch({
          type: CUSTOMER_SERACH_BY_CONTACT_SUCCESS,
          response: {
            data: resp.data.data,
            msg: resp.data.message,
          },
        });
      })
      .catch((error) => {
        let data = error.response;
        if (!error.response) {
          dispatch({
            type: CUSTOMER_SERACH_BY_CONTACT_ERROR,
            netError: error,
          });
        } else if (data?.status === 422) {
          let validationError = data.data.message;
          dispatch({
            type: CUSTOMER_SERACH_BY_CONTACT_ERROR,
            errors: validationError,
          });
        } else if (data?.status === 401) {
          removeStorage();
        }
      });
  };
};

export const CustomerSearchByNameAction = (detail) => {
  return (dispatch) => {
    dispatch({
      type: CUSTOMER_SERACH_BY_NAME_LOADING,
    });
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      // method: "post",
      url: CustomerSerachByNameUrl + `/${detail.id}`,
      data: detail,
    };
    axios(requestOptions)
      .then((resp) => {
        dispatch({
          type: CUSTOMER_SERACH_BY_NAME_SUCCESS,
          response: {
            data: resp.data.data,
            msg: resp.data.message,
          },
        });
      })
      .catch((error) => {
        let data = error.response;
        if (!error.response) {
          dispatch({
            type: CUSTOMER_SERACH_BY_NAME_ERROR,
            netError: error,
          });
        } else if (data?.status === 422) {
          let validationError = data.data.message;
          dispatch({
            type: CUSTOMER_SERACH_BY_NAME_ERROR,
            errors: validationError,
          });
        } else if (data?.status === 401) {
          removeStorage();
        }
      });
  };
};

export const exportPdf = (details) => {
  return (dispatch) => {
    const requestOptions = {
      method: "GET",
      url: `${exportPdf_Url}/${details}`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      responseType: "blob",
    };
    axios(requestOptions)
      .then((resp) => {
        const blob = new Blob([resp.data]);
        const fileName = `Panos_Web_${moment().format("DDMMyyyy")}_${Math.floor(
          100000 + Math.random() * 9000
        )}.pdf`;
        const a = document.createElement("a");
        a.href = window.URL.createObjectURL(blob);
        a.download = fileName;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        dispatch({
          type: EXPORT_PDF_SUCCESS,
        });
      })
      .catch((error) => {
        let data = error.response;
        if (!error.response) {
          dispatch({
            type: EXPORT_PDF_ERROR,
            msg: error,
          });
        } else if (data?.status === 422) {
          let validationError = data.data.message;
          dispatch({
            type: EXPORT_PDF_ERROR,
            msg: validationError,
          });
        } else if (data?.status === 401) {
          removeStorage();
        }
      });
  };
};

export const getagentsByOrderDataAction = (id) => {
  return (dispatch) => {
    dispatch({
      type: AGENTSWISE_ORDER_LOADING,
    });
    const requestOptions = {
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: `${agentsWiseOrder}/${id}`,
    };
    axios(requestOptions)
      .then((resp) => {
        let userData = resp.data;
        dispatch({
          type: AGENTSWISE_ORDER_SUCCESS,
          response: {
            data: resp.data.data,
            msg: resp.data.message,
          },
          // payload: userData,
          // data: resp.data.data,
        });
      })
      .catch((error) => {
        let data = error.response;
        if (!error.response) {
          dispatch({
            type: AGENTSWISE_ORDER_ERROR,
            netError: error,
          });
        } else if (data?.status === 422) {
          let validationError = data.data.message;
          dispatch({
            type: AGENTSWISE_ORDER_ERROR,
            errors: validationError,
          });
        } else if (data?.status === 401) {
          removeStorage();
        }
      });
  };
};

export const agentschangePassword = (detail) => {
  return (dispatch) => {
    dispatch({
      type: AGENTS_CHANGE_PASSWORD_LOADING,
    });
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: agentchangepasswordUrl,
      data: detail,
    };
    axios(requestOptions)
      .then((resp) => {
        dispatch({
          type: AGENTS_CHANGE_PASSWORD_SUCCESS,
          response: {
            data: resp.data.data,
            msg: resp.data.message,
          },
        });
      })
      .catch((error) => {
        let data = error.response;
        if (!error.response) {
          dispatch({
            type: AGENTS_CHANGE_PASSWORD_ERROR,
            netError: error,
          });
        } else if (data?.status === 422) {
          let validationError = data.data.message;
          dispatch({
            type: AGENTS_CHANGE_PASSWORD_ERROR,
            errors: validationError,
          });
        } else if (data?.status === 401) {
          removeStorage();
        }
      });
  };
};

export const ViewLocationTotaltipAction = (id) => {
  return (dispatch) => {
    dispatch({
      type: LOCATION_WISE_TOTAL_TIP_LOADING,
    });
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: `${locationwiseTipDetails}/${id}`,
    };
    axios(requestOptions)
      .then((resp) => {
        dispatch({
          type: LOCATION_WISE_TOTAL_TIP_SUCCESS,
          response: {
            data: resp.data,
            msg: resp.data.message,
          },
        });
      })
      .catch((error) => {
        let data = error.response;
        if (!error.response) {
          dispatch({
            type: LOCATION_WISE_TOTAL_TIP_ERROR,
            netError: error,
          });
        } else if (data?.status === 422) {
          let validationError = data.data.message;
          dispatch({
            type: LOCATION_WISE_TOTAL_TIP_ERROR,
            errors: validationError,
          });
        } else if (data?.status === 401) {
          removeStorage();
        }
      });
  };
};

export const exportTipPdfAction = (details) => {
  return (dispatch) => {
    const requestOptions = {
      method: "GET",
      url: `${exportTipPdf_Url}/${details.id}?startDate=${details?.startDate}&endDate=${details?.endDate}`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      responseType: "blob",
      data: details,
    };
    axios(requestOptions)
      .then((resp) => {
        const blob = new Blob([resp.data]);
        const fileName = `Panos_Web_${moment().format("DDMMyyyy")}_${Math.floor(
          100000 + Math.random() * 9000
        )}.pdf`;
        const a = document.createElement("a");
        a.href = window.URL.createObjectURL(blob);
        a.download = fileName;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        dispatch({
          type: EXPORT_TIP_PDF_SUCCESS,
          response: {
            data: resp.data,
            msg: resp.data.message,
          },
        });
      })
      .catch((error) => {
        let data = error.response;
        if (!error.response) {
          dispatch({
            type: EXPORT_TIP_PDF_ERROR,
            msg: error,
          });
        } else if (data?.status === 422) {
          let validationError = data.data.message;
          dispatch({
            type: EXPORT_TIP_PDF_ERROR,
            msg: validationError,
          });
        } else if (data?.status === 401) {
          removeStorage();
        }
      });
  };
};

export const reportsOrderCountAction = (detail) => {
  return (dispatch) => {
    dispatch({
      type: REPORTS_ORDER_COUNT_LOADING,
    });
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: reports_Order_Url,
      data: detail,
    };
    axios(requestOptions)
      .then((resp) => {
        dispatch({
          type: REPORTS_ORDER_COUNT_SUCCESS,
          response: {
            data: resp.data,
            msg: resp.data.message,
          },
        });
      })
      .catch((error) => {
        let data = error.response;
        if (!error.response) {
          dispatch({
            type: REPORTS_ORDER_COUNT_ERROR,
            netError: error,
          });
        } else if (data?.status === 422) {
          let validationError = data.data.message;
          dispatch({
            type: REPORTS_ORDER_COUNT_ERROR,
            errors: validationError,
          });
        } else if (data?.status === 401) {
          removeStorage();
        }
      });
  };
};

export const reportsLocationOrderCountAction = (detail) => {
  return (dispatch) => {
    dispatch({
      type: REPORTS_LOCATION_ORDER_COUNT_LOADING,
    });
    const requestOptions = {
      method: "POSt",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: reports_Location_Order_Url + `/${detail.location}`,
      data: {
        ezCater: detail.ezCater,
      },
    };
    axios(requestOptions)
      .then((resp) => {
        dispatch({
          type: REPORTS_LOCATION_ORDER_COUNT_SUCCESS,
          response: {
            data: resp.data,
            msg: resp.data.message,
          },
        });
      })
      .catch((error) => {
        let data = error.response;
        if (!error.response) {
          dispatch({
            type: REPORTS_LOCATION_ORDER_COUNT_ERROR,
            netError: error,
          });
        } else if (data?.status === 422) {
          let validationError = data.data.message;
          dispatch({
            type: REPORTS_LOCATION_ORDER_COUNT_ERROR,
            errors: validationError,
          });
        } else if (data?.status === 401) {
          removeStorage();
        }
      });
  };
};

export const customerordercountAction = (detail) => {
  return (dispatch) => {
    dispatch({
      type: CUSTOMER_TOTAL_ORDER_COUNT_LOADING,
    });
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: customertotalordercount_Url + `/${detail?.id}`,
      data: detail.detail,
    };
    axios(requestOptions)
      .then((resp) => {
        dispatch({
          type: CUSTOMER_TOTAL_ORDER_COUNT_SUCCESS,
          response: {
            data: resp.data,
            msg: resp.data.message,
          },
        });
      })
      .catch((error) => {
        let data = error.response;
        if (!error.response) {
          dispatch({
            type: CUSTOMER_TOTAL_ORDER_COUNT_ERROR,
            netError: error,
          });
        } else if (data?.status === 422) {
          let validationError = data.data.message;
          dispatch({
            type: CUSTOMER_TOTAL_ORDER_COUNT_ERROR,
            errors: validationError,
          });
        } else if (data?.status === 401) {
          removeStorage();
        }
      });
  };
};

export const UpdateOrderAction = (detail) => {

  // console.log("logging details data",detail.data, detail)
  return (dispatch) => {
    dispatch({
      type: UPDATE_SUPER_ADMIN_ORDER_LOADING,
    });
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: updateorderUrl + `/${detail?.id}`,
      data: detail?.details ?? detail?.data,
      // data: detail.data,
    };
    axios(requestOptions)
      .then((resp) => {
        dispatch({
          type: UPDATE_SUPER_ADMIN_ORDER_SUCCESS,
          response: {
            data: resp.data,
            msg: resp.data.message,
          },
        });
      })
      .catch((error) => {
        let data = error.response;
        if (!error.response) {
          dispatch({
            type: UPDATE_SUPER_ADMIN_ORDER_ERROR,
            netError: error,
          });
        } else if (data?.status === 422) {
          let validationError = data.data.message;
          dispatch({
            type: UPDATE_SUPER_ADMIN_ORDER_ERROR,
            errors: validationError,
          });
        } else if (data?.status === 401) {
          removeStorage();
        }
      });
  };
};

export const getswapagantAction = (id) => {
  return (dispatch) => {
    dispatch({
      type: GET_SWAP_AGENT_LOADING,
    });
    const requestOptions = {
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: `${getswapagent_Url}/${id.locationID}/${id.agentID}`,
    };
    axios(requestOptions)
      .then((resp) => {
        let userData = resp.data;
        dispatch({
          type: GET_SWAP_AGENT_SUCCESS,
          response: {
            data: resp.data.data,
            msg: resp.data.message,
          },
          // payload: userData,
          // data: resp.data.data,
        });
      })
      .catch((error) => {
        let data = error.response;
        if (!error.response) {
          dispatch({
            type: GET_SWAP_AGENT_ERROR,
            netError: error,
          });
        } else if (data?.status === 422) {
          let validationError = data.data.message;
          dispatch({
            type: GET_SWAP_AGENT_ERROR,
            errors: validationError,
          });
        } else if (data?.status === 401) {
          removeStorage();
        }
      });
  };
};

export const UploadCsvMenuItem = (details) => {
  return (dispatch) => {
    const requestOptions = {
      method: "POST",
      url: upload_csv_Url,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "Content-Type": "multipart/form-data",
      },
      data: details,
    };
    axios(requestOptions)
      .then((resp) => {
        dispatch({
          type: IMPORT_MENU_EXCEL_SUCCESS,
        });
      })
      .catch((error) => {
        let data = error.response;
        if (!error.response) {
          dispatch({
            type: IMPORT_MENU_EXCEL_ERROR,
            msg: error,
          });
        } else if (data.status === 422) {
          let validationError = data.data.message;
          dispatch({
            type: IMPORT_MENU_EXCEL_ERROR,
            msg: validationError,
          });
        }
      });
  };
};

export const UpdatePRMForLocation = (details) => {
  return (dispatch) => {
    const requestOptions = {
      method: "POST",
      url: UpdatePRM_for_location_Url,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      data: details,
    };
    axios(requestOptions)
      .then((resp) => {
        dispatch({
          type: UPDATE_PRM_FOR_LOCATION_SUCCESS,
          response: {
            data: resp.data.data,
          },
        });
      })
      .catch((error) => {
        let data = error.response;
        if (!error.response) {
          dispatch({
            type: UPDATE_PRM_FOR_LOCATION_ERROR,
            msg: error,
          });
        } else if (data.status === 422) {
          let validationError = data.data.message;
          dispatch({
            type: UPDATE_PRM_FOR_LOCATION_ERROR,
            msg: validationError,
          });
        } else if (data?.status === 401) {
          removeStorage();
        }
      });
  };
};

export const getlocationwisecustomerAction = (details) => {
  // console.log(details, "detailshdhd");
  // console.log("details", details.sorting);
  return (dispatch) => {
    dispatch({
      type: LOCATION_WISE_CUSTOMER_LOADING,
    });
    const requestOptions = {
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      // url:
      //   details?.location !== "all" && details?.location !== ""
      //     ? `${location_wise_customer_Url}?location=${details.location}&spend=${details.sorting}`
      //     : `${location_wise_customer_Url}?spend=${details.sorting}`,

      url: `${location_wise_customer_Url}?${
        details.sorting && `spend=${details.sorting}`
      }${details.location && `&location=${details.location}`}${
        details.startDate &&
        details.endDate &&
        `&startDate=${details.startDate}&endDate=${details.endDate}`
      }`,
    };
    axios(requestOptions)
      .then((resp) => {
        let userData = resp.data;
        dispatch({
          type: LOCATION_WISE_CUSTOMER_SUCCESS,
          response: {
            data: resp.data.data,
            msg: resp.data.message,
          },
          // payload: userData,
          // data: resp.data.data,
        });
      })
      .catch((error) => {
        let data = error.response;
        if (!error.response) {
          dispatch({
            type: LOCATION_WISE_CUSTOMER_ERROR,
            netError: error,
          });
        } else if (data?.status === 422) {
          let validationError = data.data.message;
          dispatch({
            type: LOCATION_WISE_CUSTOMER_ERROR,
            errors: validationError,
          });
        } else if (data?.status === 401) {
          removeStorage();
        }
      });
  };
};

export const locationSearch = (search) => {
  return (dispatch) => {
    dispatch({
      type: SUPERADMIN_SEARCH_LOCATION_LOADING,
    });
    const requestOptions = {
      method: "GET",
      host: "localhost",
      headers: {
        "Content-Type": "application/json",
      },
      // method: "post",
      url: `https://api.tomtom.com/search/2/search/${search}.json?key=MBmod2HFOg5gGooeNQlNAh9R4dOEp7L7`,
    };
    axios(requestOptions)
      .then((resp) => {
        // console.log("first", resp)
        dispatch({
          type: SUPERADMIN_SEARCH_LOCATION_SUCCESS,
          response: {
            data: resp.data,
          },
        });
      })
      .catch((error) => {
        let data = error.response;
        if (!error.response) {
          dispatch({
            type: SUPERADMIN_SEARCH_LOCATION_ERROR,
            netError: error,
          });
        } else if (data?.status === 422) {
          let validationError = data.data.message;
          dispatch({
            type: SUPERADMIN_SEARCH_LOCATION_ERROR,
            errors: validationError,
          });
        } else if (data?.status === 401) {
          removeStorage();
        }
      });
  };
};
