import React from "react";
import { Form } from "react-bootstrap";
import classNames from "classnames";
import Toaster from "../../../Components/Toaster/Toaster";
import {
  RESET_RESET_SUPERADMIN_PASSWORD,
  validEmailRegex,
  validPassword,
} from "../../../Store/type/Constant";
import {
  forgotPassword,
  resetPassword,
  verifyOtp,
} from "../../../Store/Action/CreateAgents/UserAction";
import { toast } from "react-toastify";
import { useState } from "react";
import { useNavigate } from "react-router";
import { useEffect } from "react";
import { connect } from "react-redux";
import { Loader } from "rsuite";

const ForgotPassword = (props) => {
  const errorNotify = (msg) => toast.error(msg);
  const successNotify = (msg) => toast.success(msg);

  const [loading, setLoading] = useState(false)

  const [visible, setvisible] = useState(false);
  const [Confirmvisible, setConfirmvisible] = useState(false);

  const navigate = useNavigate();

  const [state, setState] = useState({
    email: "",
    otp: "",
    password: "",
    confirm_password: "",
  });
  const [error, setError] = useState({
    email: false,
    otp: false,
    password: false,
    confirm_password: false,
  });

  const [isOtpGet, setIsOtpGet] = useState(false);
  const [isSetPassword, setIsSetPassword] = useState(false);

  const onChangeInput = (e) => {
    const { name, value } = e.target;
    setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));

    switch (name) {
      case "email":
        if (value === "") {
          setError((prevState) => ({
            ...prevState,
            email: "Enter Email",
          }));
        } else if (!validEmailRegex.test(value)) {
          setError((prevState) => ({
            ...prevState,
            email: "Enter Valid Email",
          }));
        } else {
          setError((prevState) => ({
            ...prevState,
            email: false,
          }));
        }
        break;

      case "otp":
        if (value === "") {
          setError((prevState) => ({
            ...prevState,
            otp: "Enter OTP",
          }));
        } else {
          setError((prevState) => ({
            ...prevState,
            otp: false,
          }));
        }
        break;

      case "password":
        if (value === "") {
          setError((prevState) => ({
            ...prevState,
            password: "Enter Password",
          }));
        } else if (!validPassword.test(value)) {
          setError((prevState) => ({
            ...prevState,
            password: "Enter Valid Alphanumeric Password",
          }));
        } else {
          setError((prevState) => ({
            ...prevState,
            password: false,
          }));
        }
        break;

      case "confirm_password":
        if (value === "") {
          setError((prevState) => ({
            ...prevState,
            confirm_password: "Enter Confirm password",
          }));
        } else {
          setError((prevState) => ({
            ...prevState,
            confirm_password: false,
          }));
        }
        break;

      default:
        break;
    }
  };

  const handleSendOtp = (e) => {
    e.preventDefault();
    if (state.email === "") {
      setError((prevState) => ({
        ...prevState,
        email: "Enter email",
      }));
    }

    if (state.email !== "" && !error.email) {
      props.forgotPassword({ email: state.email });
      setLoading(true)
    }
  };

  useEffect(() => {
    if (props.forgotPasswordReducer.success) {

      setTimeout(() => {
        setIsOtpGet(true);
        setLoading(false)
      }, 700)
    }
    if (props.forgotPasswordReducer.error) {
      setTimeout(() => {
        errorNotify(props.forgotPasswordReducer.errors);
        setError((prevState) => ({
          ...prevState,
          email: "Enter Valid Email",
        }));
        setLoading(false)
      }, 700)

    }
  }, [props.forgotPasswordReducer]);

  const handleVerifyOtp = (e) => {
    e.preventDefault();

    if (state.email === "") {
      setError((prevState) => ({
        ...prevState,
        email: "Enter email",
      }));
    } else if (state.otp === "") {
      setError((prevState) => ({
        ...prevState,
        otp: "Enter Otp",
      }));
    }

    if (state.email !== "" && state.otp !== "" && !error.email && !error.otp) {
      const details = {
        email: state.email,
        otp: state.otp,
      };
      props.verifyOtp(details);
      setLoading(true)
    }
  };

  useEffect(() => {
    if (props.verifyOtpReducer.success) {

      setTimeout(() => {
        setIsSetPassword(true);
        setLoading(false)
      }, 700)
    } else if (props.verifyOtpReducer.error) {
      setTimeout(() => {
        errorNotify(props.verifyOtpReducer.errors);
        setError((prevState) => ({
          ...prevState,
          otp: "Enter Valid OTP",
        }));
        setLoading(false)
      }, 700)

    }
  }, [props.verifyOtpReducer]);

  const handleResetPassword = (e) => {
    e.preventDefault();
    if (state.password === "") {
      setError((prevState) => ({
        ...prevState,
        password: "Enter Password",
      }));
    } else if (state.confirm_password === "") {
      setError((prevState) => ({
        ...prevState,
        confirm_password: "Enter Confirm password",
      }));
    }

    if (state.confirm_password !== state.password) {
      errorNotify("Password and Confirm Password not match");
      setError((prevState) => ({
        ...prevState,
        confirm_password: "Confirm Password Not Match",
      }));
    } else {
      if (
        state.email !== "" &&
        state.otp !== "" &&
        state.password !== "" &&
        !error.email &&
        !error.otp &&
        !error.password
      ) {
        const details = {
          email: state.email,
          password: state.password,
          confirm_password: state.confirm_password,
        };
        props.resetPassword(details);
        setLoading(true)
      }
    }
  };

  useEffect(() => {
    if (props.resetPasswordReducer.success) {

      setTimeout(() => {
        successNotify("Password reset Successfully.");
        setTimeout(() => {
          setLoading(false)
          navigate("/superadmin/login");
          props.resetResetPassword();
        }, 2400);
      }, 700)




    }
  }, [props.resetPasswordReducer]);

  const passwordvisible = () => {
    setvisible(!visible);
  };
  const Confirmpasswordvisible = () => {
    setConfirmvisible(!Confirmvisible);
  };

  return (
    <div className="login-page">
      <div className="row h-100">
        <div className="col-12 col-lg-5">
          <div className="login-left">
            <div className="login-logo">
              <a href="#">
                <img crossOrigin="anonymous" src="/images/logo.png" />
              </a>
            </div>
            <h1 className="mb-4 text-red">Forgot Password </h1>

            <div>
              <div className="form-group position-relative has-icon-left mb-4">
                <input
                  type="email"
                  className={classNames("form-control form-control-xl", {
                    requireField: error.email,
                  })}
                  placeholder="E-mail"
                  onChange={onChangeInput}
                  name="email"
                  value={state.email}
                  disabled={isOtpGet}
                />
                <div className="form-control-icon">
                  <i className="fa fa-user-o"></i>
                </div>
              </div>

              {isOtpGet && (
                <div className="form-group position-relative has-icon-left mb-4">
                  <input
                    type="text"
                    placeholder="OTP"
                    className={classNames("form-control form-control-xl", {
                      requireField: error.otp,
                    })}
                    onChange={onChangeInput}
                    name="otp"
                    disabled={isSetPassword}
                    value={state.otp}
                  />
                  <div className="form-control-icon">
                    <i className="fa fa-lock"></i>
                  </div>
                </div>
              )}

              {/* passworc */}
              {isSetPassword && (
                <div className="form-group position-relative has-icon-left mb-4">
                  <input
                    type={!!visible ? "text" : "password"}
                    placeholder="Set new password"
                    className={classNames("form-control form-control-xl", {
                      requireField: error.password,
                    })}
                    onChange={onChangeInput}
                    name="password"
                    value={state.password}
                  />
                  <div className="form-control-icon">
                    <i className="fa fa-lock"></i>
                  </div>
                  <div
                    className="form-control-eye-icon pointer"
                    onClick={passwordvisible}
                  >
                    {visible ? (
                      <i className="fa fa-eye"></i>
                    ) : (
                      <i className="fa fa-eye-slash"></i>
                    )}
                  </div>
                </div>
              )}
              {isSetPassword && (
                <div className="form-group position-relative has-icon-left mb-4">
                  <input
                    type={!!Confirmvisible ? "text" : "password"}
                    placeholder="Set confirm Password"
                    className={classNames("form-control form-control-xl", {
                      requireField: error.confirm_password,
                    })}
                    onChange={onChangeInput}
                    name="confirm_password"
                    value={state.confirm_password}
                  />
                  <div className="form-control-icon">
                    <i className="fa fa-lock"></i>
                  </div>
                  <div
                    className="form-control-eye-icon pointer"
                    onClick={Confirmpasswordvisible}
                  >
                    {Confirmvisible ? (
                      <i className="fa fa-eye"></i>
                    ) : (
                      <i className="fa fa-eye-slash"></i>
                    )}
                  </div>
                </div>
              )}

              {!isOtpGet && !isSetPassword && (
                <button
                  type="submit"
                  className="btn bg-red text-white pointer btn-block btn-lg shadow-lg mt-5"
                  onClick={!loading ? handleSendOtp : () => { }}
                >
                  {loading ? <Loader /> : 'Send OTP'}
                </button>
              )}
              {isOtpGet && !isSetPassword && (
                <button
                  type="submit"
                  className="btn bg-red text-white pointer btn-block btn-lg shadow-lg mt-5"
                  onClick={!loading ? handleVerifyOtp : () => { }}
                >
                  {loading ? <Loader /> : 'Verify Otp'}
                </button>
              )}
              {isOtpGet && isSetPassword && (
                <button
                  type="submit"
                  className="btn bg-red text-white pointer btn-block btn-lg shadow-lg mt-5"
                  onClick={!loading ? handleResetPassword : () => { }}
                >
                  {loading ? <Loader /> : 'Reset Password'}
                </button>
              )}

              <div className="text-center mt-3 text-lg fs-4">
                {/* <div className="text-red pointer" onClick={() => navigate('/admin/forgotpassword')}>Forgot password?</div> */}
              </div>
              <div className="text-center mt-3 text-lg fs-4">
                <div
                  className="text-red pointer"
                  onClick={() => navigate("/superadmin/login")}
                >
                  Login
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-7 d-none d-lg-block">
          <div
            className="d-flex justify-content-center align-items-center"
            style={{
              height: "100%",
              backgroundImage: `url('/images/panos-bg.jpg')`,
            }}
          ></div>
        </div>
      </div>
      <Toaster />
    </div>
  );
};

const mapStateToProp = (state) => ({
  forgotPasswordReducer: state.SuperadminPasswordReducer.forgotPassword,
  verifyOtpReducer: state.SuperadminPasswordReducer.verifyOtp,
  resetPasswordReducer: state.SuperadminPasswordReducer.resetPassword,
});

const mapDispatchToProps = (dispatch) => ({
  forgotPassword: (detail) => dispatch(forgotPassword(detail)),
  verifyOtp: (detail) => dispatch(verifyOtp(detail)),
  resetPassword: (detail) => dispatch(resetPassword(detail)),
  resetResetPassword: () => dispatch({ type: RESET_RESET_SUPERADMIN_PASSWORD }),
  // resetLogout: () => dispatch({ type: RESET_ADMIN_LOGOUT }),
});

export default connect(mapStateToProp, mapDispatchToProps)(ForgotPassword);
