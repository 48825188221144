import React, { useState } from "react";
import { Form, Modal, ModalBody } from "react-bootstrap";
import classNames from "classnames";
import Toaster from "../../../Components/Toaster/Toaster";
import {
  contactValidation,
  countryCodeValidation,
  validEmailRegex,
  validPassword,
} from "../../Constants/Constants";
import { toast } from "react-toastify";
import { connect } from "react-redux";
import { addPrm } from "../../Services/Actions/PrmAction";
import MaskedInput from "react-text-mask";
import { Us_Pattern_Number } from "../../../Store/type/Constant";
import { Loader } from "rsuite";
import { useEffect } from "react";

function AddPrm(props) {
  const notifyError = (msg) => toast.error(msg);
  const [visible, setvisible] = useState(false);
  const [Confirmvisible, setConfirmvisible] = useState(false);

  const [state, setState] = useState({
    prmName: "",
    prmEmail: "",
    contactNo: "",
    countryCode: "91",
    prmPassword: "",
    confirmPassword: "",
    status: "active",
  });
  const [error, setError] = useState({
    prmName: false,
    prmEmail: false,
    contactNo: false,
    countryCode: false,
    prmPassword: false,
    confirmPassword: false,
  });

  const [passwordCheck, setpasswordCheck] = useState({
    spacialcharacter: false,
    capitalLatter: false,
    smallLatter: false,
    passwordNumber: false,
    length: false,
  });

  const [btnLoading, setbtnLoading] = useState(false);

  useEffect(() => {
    if (props.addPrmReducer.success) {
      setbtnLoading(false);
    }
    if (props.addPrmReducer.error) {
      setbtnLoading(false);
    }
  }, [props.addPrmReducer]);

  var res = state?.contactNo
    ?.replace(/\D+/g, "")
    .replace(/^(\d{3})(\d{3})(\d{4}).*/, "$1$2$3");

  const handleInput = (e) => {
    const { name, value } = e.target;

    if (name === "countryCode") {
      if (value.length <= 4) {
        setState((prevState) => ({
          ...prevState,
          countryCode: value === "+" ? parseInt("0") : parseInt(value),
        }));
      } else return;
    } else if (e.target.name === "contactNo") {
      if (e.target.value.length <= 14) {
        setState((prevState) => ({
          ...prevState,
          [e.target.name]: e.target.value.replace(
            /^(\([0-9]{3}\) |[0-9]{3}-)[0-9]{3}-[0-9]{4}$/
          ),
        }));
      }
    }
    setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));

    if (name === "prmPassword") {
      if (/[A-Z]/.test(value)) {
        setpasswordCheck((prevState) => ({
          ...prevState,
          capitalLatter: "Capital Latter",
        }));
      } else {
        setpasswordCheck((prevState) => ({
          ...prevState,
          capitalLatter: false,
        }));
      }
      if (/[a-z]/.test(value)) {
        setpasswordCheck((prevState) => ({
          ...prevState,
          smallLatter: "Small Latter",
        }));
      } else {
        setpasswordCheck((prevState) => ({
          ...prevState,
          smallLatter: false,
        }));
      }
      if (/[0-9]/.test(e.target.value)) {
        setpasswordCheck((prevState) => ({
          ...prevState,
          passwordNumber: "Number",
        }));
      } else {
        setpasswordCheck((prevState) => ({
          ...prevState,
          passwordNumber: false,
        }));
      }
      if (value.length >= 8) {
        setpasswordCheck((prevState) => ({
          ...prevState,
          length: "length",
        }));
      } else {
        setpasswordCheck((prevState) => ({
          ...prevState,
          length: false,
        }));
      }
      if (/[$&+,:;=?@#|'<>.^*()%!-]/.test(value)) {
        setpasswordCheck((prevState) => ({
          ...prevState,
          spacialcharacter: "Spacial Character",
        }));
      } else {
        setpasswordCheck((prevState) => ({
          ...prevState,
          spacialcharacter: false,
        }));
      }
    }

    switch (name) {
      case "prmName":
        if (value === "") {
          setError((prevState) => ({
            ...prevState,
            prmName: "Enter Prm name",
          }));
        } else {
          setError((prevState) => ({
            ...prevState,
            prmName: false,
          }));
        }
        break;

      case "prmEmail":
        if (value === "") {
          setError((prevState) => ({
            ...prevState,
            prmEmail: "Enter Email",
          }));
        } else if (!validEmailRegex.test(value)) {
          setError((prevState) => ({
            ...prevState,
            prmEmail: "Enter Valid Email",
          }));
        } else {
          setError((prevState) => ({
            ...prevState,
            prmEmail: false,
          }));
        }
        break;

      case "contactNo":
        if (e.target.value.length == 0) {
          setError((prevState) => ({
            ...prevState,
            contactNo: "contactNo can not be empty",
          }));
        } else if (!Us_Pattern_Number.test(e.target.value)) {
          setError((prevState) => ({
            ...prevState,
            contactNo: "Enter valid contactNo",
          }));
        } else {
          setError((prevState) => ({
            ...prevState,
            contactNo: false,
          }));
        }
        break;

      case "countryCode":
        if (value === "") {
          setError((prevState) => ({
            ...prevState,
            countryCode: "Enter Country code",
          }));
        } else if (!countryCodeValidation.test(value)) {
          setError((prevState) => ({
            ...prevState,
            countryCode: "Enter country code",
          }));
        } else {
          setError((prevState) => ({
            ...prevState,
            countryCode: false,
          }));
        }
        break;

      case "prmPassword":
        if (value === "") {
          setError((prevState) => ({
            ...prevState,
            prmPassword: "Enter Agent password",
          }));
        } else {
          setError((prevState) => ({
            ...prevState,
            prmPassword: false,
          }));
        }
        break;

      case "confirmPassword":
        if (value === "") {
          setError((prevState) => ({
            ...prevState,
            confirmPassword: "Enter Confirm password",
          }));
        } else {
          setError((prevState) => ({
            ...prevState,
            confirmPassword: false,
          }));
        }
        break;

      default:
        break;
    }
  };

  const handleCancel = () => {
    props.setIsAddPrm(false);
  };

  const handleAddPrm = () => {
    if (state.prmName === "") {
      setError((prevState) => ({
        ...prevState,
        prmName: "Enter Prm name",
      }));
    }
    if (state.prmEmail === "") {
      setError((prevState) => ({
        ...prevState,
        prmEmail: "Enter Email",
      }));
    }
    if (state.contactNo == "") {
      setError((prevState) => ({
        ...prevState,
        contactNo: "contactNo can not be empty",
      }));
    } else if (!Us_Pattern_Number.test(state.contactNo)) {
      setError((prevState) => ({
        ...prevState,
        contactNo: "Enter valid contactNo",
      }));
    } else {
      setError((prevState) => ({
        ...prevState,
        contactNo: false,
      }));
    }
    if (state.countryCode === "") {
      setError((prevState) => ({
        ...prevState,
        countryCode: "Enter Country code",
      }));
    }
    if (state.prmPassword === "") {
      setError((prevState) => ({
        ...prevState,
        prmPassword: "Enter Agent password",
      }));
    }
    if (state.confirmPassword === "") {
      setError((prevState) => ({
        ...prevState,
        confirmPassword: "Enter Confirm password",
      }));
    }

    if (state.confirmPassword !== state.prmPassword) {
      notifyError("password does not match");
    } else {
      if (
        state.prmName !== "" &&
        state.prmEmail !== "" &&
        state.contactNo !== "" &&
        state.countryCode !== "" &&
        state.prmPassword !== "" &&
        state.confirmPassword !== "" &&
        !error.prmName &&
        !error.contactNo &&
        !error.countryCode &&
        !error.prmEmail &&
        !error.prmPassword &&
        !error.confirmPassword
      ) {
        const details = {
          name: state.prmName,
          email: state.prmEmail,
          password: state.prmPassword,
          number: res,
          countrycode: state.countryCode,
          status: state.status === "active" ? 1 : 0,
          location: localStorage.getItem("userid"),
          type: "2",
        };
        props.addPrmList(details);
        props.setIsAddPrm(false);
        setbtnLoading(true);
      }
    }
  };

  const passwordvisible = () => {
    setvisible(!visible);
  };
  const Confirmpasswordvisible = () => {
    setConfirmvisible(!Confirmvisible);
  };

  return (
    <>
      <Modal.Header closeButton>
        <Modal.Title>Add PRM</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form className="row">
          <div className="">
            <div className="row">
              <Form.Group className="mt-4 col-6">
                <Form.Label style={{ fontWeight: "bold" }}>Name:</Form.Label>
                <Form.Control
                  name="prmName"
                  type="text"
                  placeholder="Enter PRM Name"
                  autoComplete="off"
                  value={state.prmName}
                  onChange={handleInput}
                  className={classNames({
                    requireField: error.prmName,
                  })}
                />
              </Form.Group>
              <Form.Group className="mt-4 col-6">
                <Form.Label style={{ fontWeight: "bold" }}>Email Address:</Form.Label>
                <Form.Control
                  name="prmEmail"
                  type="text"
                  placeholder="Enter Email Address"
                  value={state.prmEmail}
                  onChange={handleInput}
                  autoComplete="off"
                  className={classNames({
                    requireField: error.prmEmail,
                  })}
                />
              </Form.Group>
            </div>

            <Form.Group className="mt-4">
              <Form.Label style={{ fontWeight: "bold" }}>
                Contact No:
              </Form.Label>
              <div className="row">
                {/* <div className="col-2">
                <Form.Control
                  name="countryCode"
                  type="text"
                  placeholder="+91"
                  value={`+${state.countryCode}`}
                  onChange={handleInput}
                  className={classNames({
                    requireField: error.countryCode,
                  })}
                />
              </div> */}
                <div className="col-6">
                  {/* <Form.Control
                    name="contactNo"
                    type="number"
                    placeholder="Enter Contact No"
                    value={state.contactNo}
                    onChange={handleInput}
                    className={classNames({
                      requireField: error.contactNo,
                    })}
                  /> */}
                  <MaskedInput
                    mask={[
                      "(",
                      /[0-9]/,
                      /[0-9]/,
                      /[0-9]/,
                      ")",
                      " ",
                      /[0-9]/,
                      /[0-9]/,
                      /[0-9]/,
                      "-",
                      /[0-9]/,
                      /[0-9]/,
                      /[0-9]/,
                      /[0-9]/,
                    ]}
                    // type="number"
                    name="contactNo"
                    guide={false}
                    id="my-input-id number"
                    placeholder="Enter Contact No"
                    value={state.contactNo}
                    autoComplete="off"
                    onChange={handleInput}
                    className={classNames("form-control", {
                      requireField: error.contactNo,
                    })}
                  />
                </div>
              </div>
            </Form.Group>

            <div className="row">
              <Form.Group className="mt-4 col-6">
                <Form.Label style={{ fontWeight: "bold" }}>
                  Password:
                </Form.Label>
                <div className="password-field">
                  <Form.Control
                    name="prmPassword"
                    type={!!visible ? "text" : "password"}
                    placeholder="Enter Password"
                    value={state.prmPassword}
                    autoComplete="off"
                    onChange={handleInput}
                    className={classNames({
                      requireField: error.prmPassword,
                    })}
                  />

                  <div
                    className="form-control-eye-icon pointer"
                    onClick={passwordvisible}
                  >
                    {visible ? (
                      <i
                        className="fa fa-eye"
                        style={{ marginLeft: "250px" }}
                      ></i>
                    ) : (
                      <i
                        className="fa fa-eye-slash "
                        style={{ marginLeft: "250px" }}
                      ></i>
                    )}
                  </div>
                </div>
              </Form.Group>
              <Form.Group className="mt-4 col-6">
                <Form.Label style={{ fontWeight: "bold" }}>
                  Confirm Password:
                </Form.Label>
                <div className="password-field">
                  <Form.Control
                    name="confirmPassword"
                    placeholder="Enter Confirm Password"
                    type={!!Confirmvisible ? "text" : "password"}
                    value={state.confirmPassword}
                    autoComplete="off"
                    onChange={handleInput}
                    className={classNames({
                      requireField: error.confirmPassword,
                    })}
                  />
                  <div
                    className="form-control-eye-icon pointer"
                    onClick={Confirmpasswordvisible}
                  >
                    {Confirmvisible ? (
                      <i
                        className="fa fa-eye"
                        style={{ marginLeft: "250px" }}
                      ></i>
                    ) : (
                      <i
                        className="fa fa-eye-slash"
                        style={{ marginLeft: "250px" }}
                      ></i>
                    )}
                  </div>
                </div>
              </Form.Group>
            </div>
            <div className="row">
              <div
                className="col-md-12 col-12 mt-2"
                style={{ fontWeight: "bold" }}
              >
                <div
                  style={{
                    color: passwordCheck?.length ? "green" : "red",
                  }}
                >
                  <span>
                    <i
                      className={`fa ${
                        passwordCheck?.length
                          ? "fa-circle-check text-success"
                          : "fa-circle-xmark text-danger"
                      } text-success fs-5 me-2`}
                    ></i>
                    Must be at least 8 characters.
                  </span>
                </div>
              </div>

              <div
                className="col-md-12 col-12 mt-2"
                style={{ fontWeight: "bold" }}
              >
                <div
                  style={{
                    color: passwordCheck?.capitalLatter ? "green" : "red",
                  }}
                >
                  <span>
                    <i
                      className={`fa ${
                        passwordCheck?.capitalLatter
                          ? "fa-circle-check text-success"
                          : "fa-circle-xmark text-danger"
                      } text-success fs-5 me-2`}
                    ></i>
                    Must be at least One upper case character ( A,B,C...etc )
                  </span>
                </div>
              </div>
              <div
                className="col-md-12 col-12 mt-2"
                style={{ fontWeight: "bold" }}
              >
                <div
                  style={{
                    color: passwordCheck?.smallLatter ? "green" : "red",
                  }}
                >
                  <span>
                    <i
                      className={`fa ${
                        passwordCheck?.smallLatter
                          ? "fa-circle-check text-success"
                          : "fa-circle-xmark text-danger"
                      } text-success fs-5 me-2`}
                    ></i>
                    Must be at least One lower case character ( a,b,c...etc )
                  </span>
                </div>
              </div>

              <div
                className="col-md-12 col-12 mt-2"
                style={{ fontWeight: "bold" }}
              >
                <div
                  style={{
                    color: passwordCheck?.spacialcharacter ? "green" : "red",
                  }}
                >
                  <span>
                    {" "}
                    <i
                      className={`fa ${
                        passwordCheck?.spacialcharacter
                          ? "fa-circle-check text-success"
                          : "fa-circle-xmark text-danger"
                      } text-success fs-5 me-2`}
                    ></i>
                    Must conatin at least special character ( #,@,$,...etc )
                  </span>
                </div>
              </div>
              <div
                className="col-md-12 col-12 mt-2"
                style={{ fontWeight: "bold" }}
              >
                <div
                  style={{
                    color: passwordCheck?.passwordNumber ? "green" : "red",
                  }}
                >
                  <i
                    className={`fa ${
                      passwordCheck?.passwordNumber
                        ? "fa-circle-check text-success"
                        : "fa-circle-xmark text-danger"
                    } text-success fs-5 me-2`}
                  ></i>
                  {/* <i className="fa fa-circle-xmark text-danger fs-4"></i> */}
                  Must conatin at least one number ( 1,2,3,...etc )
                </div>
              </div>
            </div>
            <div className="col-md-3 col-4 mt-3">
              <Form.Label>Status:</Form.Label>
              <Form.Select
                value={state?.status}
                onChange={(e) => {
                  setState((prevState) => ({
                    ...prevState,
                    status: e.target.value,
                  }));
                }}
              >
                <option value="active">Active</option>
                <option value="Inactive">Inactive</option>
              </Form.Select>
            </div>
          </div>
        </Form>
      </Modal.Body>
      <Modal.Footer className="mt-3">
        {/* <div className='py-2 px-4 border rounded pointer bg-secondary text-white mx-2' onClick={handleCancel}>Cancel</div> */}
        <div
          className="py-2 px-4 border rounded pointer text-white bg-red mx-2"
          onClick={handleAddPrm}
        >
          {btnLoading ? <Loader /> : "Submit"}
        </div>
      </Modal.Footer>

      <Toaster />
    </>
  );
}

const mapStateToProp = (state) => ({
  // addAgent: state.DeliveryAgentReducer.addAgent,
  updatePrmReducer: state.PrmReducer.updatePrm,
  addPrmReducer: state.PrmReducer.addPrm,
});

const mapDispatchToProps = (dispatch) => ({
  addPrmList: (details) => dispatch(addPrm(details)),
});

export default connect(mapStateToProp, mapDispatchToProps)(AddPrm);
