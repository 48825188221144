import React from "react";

const Privecy = () => {
  return (
    <>
      <div className="header">
        <div className="container">
          <nav className="navbar navbar-inverse" role="navigation">
            <div className="navbar-header pt-4">
              <a href="#" className="navbar-brand">
                <img
                  src={"/images/logo.png"}
                  className="img-fluid privacy-logo"
                  alt="Caterize logo"
                />
              </a>
            </div>
          </nav>
        </div>
      </div>

      <div className="welcome mb-5">
        <div className="container">
          <div className="col-md-10 mt-5">
            <h4 className="text-danger fw-bold">Privacy Policy </h4>
            This privacy policy sets out how caterize.io uses and protects any
            information that you give us, when you use this website.
            <br />
            <br />
            caterize.io is committed to ensuring that your privacy is protected.
            Should we ask you to provide certain information by which you can be
            identified when using this website, then you can be assured that it
            will only be used in accordance with this privacy statement.
            <br />
            <br />
            caterize.io may change this policy from time to time by updating
            this page. You should check this page from time to time to ensure
            that you are happy with any changes. This policy is effective from
            June 01, 2023.
            <br />
            <br />
            <b>What we collect? </b> <br />
            We may collect the following information:
            <br />
            <br />
            * name and date of birth <br />
            * contact information including phone number and email address{" "}
            <br />
            * demographic information such as postcode, preferences and
            interests <br />
            * address for food delivery <br />
            <br />
            <b>What we do with the information we gather? </b>
            <br />
            We require this information to understand your needs and provide you
            with a better service, and in particular for the following reasons:
            <br />
            <br />
            <b>Delivery of Food </b>
            <br />
            We use the Information to deliver Food at your Home/ office.
            <br />
            <br />
            <b>Internal record keeping. </b>
            <br />
            We may use the information to improve our products and services. We
            may periodically send promotional emails about new products, special
            offers or other information which we think you may find interesting
            using the email address which you have provided.
            <br />
            <br />
            From time to time, we may also use your information to contact you
            for market research purposes. We may contact you by email, phone,
            fax or mail. We may use the information to customise the website
            according to your interests.
            <br />
            <br />
            <b>Security </b>
            <br />
            We are committed to ensuring that your information is secure. In
            order to prevent unauthorised access or disclosure we have put in
            place suitable physical, electronic and managerial procedures to
            safeguard and secure the information we collect online.
            <br />
            <br />
            <b>How we use cookies? </b> <br />
            A cookie is a small file which asks permission to be placed on your
            computer's hard drive. Once you agree, the file is added and the
            cookie helps analyse web traffic or lets you know when you visit a
            particular site. Cookies allow web applications to respond to you as
            an individual. The web application can tailor its operations to your
            needs, likes and dislikes by gathering and remembering information
            about your preferences.
            <br />
            <br />
            We use traffic log cookies to identify which pages are being used.
            This helps us analyze data about webpage traffic and improve our
            website in order to tailor it to customer needs. We only use this
            information for statistical analysis purposes and then the data is
            removed from the system.
            <br />
            <br />
            Overall, cookies help us provide you with a better website, by
            enabling us to monitor which pages you find useful and which you do
            not. A cookie in no way gives us access to your computer or any
            information about you, other than the data you choose to share with
            us.
            <br />
            <br />
            You can choose to accept or decline cookies. Most web browsers
            automatically accept cookies, but you can usually modify your
            browser setting to decline cookies if you prefer. This may prevent
            you from taking full advantage of the website.
            <br />
            <br />
            <b>Links to other websites </b>
            <br />
            Our website may contain links to other websites of interest.
            However, once you have used these links to leave our site, you
            should note that we do not have any control over that other website.
            Therefore, we cannot be responsible for the protection and privacy
            of any information which you provide whilst visiting such sites and
            such sites are not governed by this privacy statement. You should
            exercise caution and look at the privacy statement applicable to the
            website in question.
            <br />
            <br />
            <b>Controlling your personal information </b>
            <br />
            <br />
            You may choose to restrict the collection or use of your personal
            information in the following ways:
            <br />
            <br />
            * whenever you are asked to fill in a form on the website, look for
            the box that you can click to indicate that you do not want the
            information to be used by anybody for direct marketing purposes.
            <br />
            <br />
            * if you have previously agreed to us using your personal
            information for direct marketing purposes, you may change your mind
            at any time by writing to or emailing us at info@caterize.io
            <br />
            <br />
            * We will not sell, distribute or lease your personal information to
            third parties unless we have your permission or are required by law
            to do so. We may use your personal information to send you
            promotional information about third parties which we think you may
            find interesting if you tell us that you wish this to happen.
            <br />
            <br />
            * You may request details of personal information which we hold
            about you under the Data Protection Act 1998. A small fee will be
            payable. If you would like a copy of the information held on you
            please write to - info@caterize.io .
            <br />
            <br />* If you believe that any information we are holding on you is
            incorrect or incomplete, please write to or email us as soon as
            possible, at the above address. We will promptly correct any
            information found to be incorrect.
          </div>
        </div>
      </div>

      <footer id="footer" className="privacy-footer">
        <div className="bottom-footer">
          <div className="container">
            <p className="text-white mb-0 text-center">
              Copyright &copy;
              <script>document.write(new Date().getFullYear());</script>{" "}
              caterize.io, All Rights Reserved.
            </p>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Privecy;
