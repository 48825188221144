import { GET_CUSTOMER_ADDRESS_ERROR, GET_CUSTOMER_ADDRESS_LOADING, GET_CUSTOMER_ADDRESS_SUCCESS } from "../type/Constant";

let initialState = {
  loading: false,
  user: [],
  error: false,
  errors: [],
  success: false
};

export default function getCustomerAddressReducer(state = initialState, action) {
  switch (action.type) {
    case GET_CUSTOMER_ADDRESS_LOADING:
      return { ...state, success: false,success:false};
     
    case GET_CUSTOMER_ADDRESS_SUCCESS:  
      return { ...state, loading: false,success:true,user:action.payload};
 
    case GET_CUSTOMER_ADDRESS_ERROR:
      return { ...state,error: true, errors: action.errors };
   
    default:
      return state;
  }
}


