import React, { useEffect, useState } from "react";
import { Form, Modal } from "react-bootstrap";
import { validPassword } from "../../Constants/Constants";
import classNames from "classnames";
import { connect } from "react-redux";
import { changePassword } from "../../Services/Actions/AuthAction";
import { useNavigate } from "react-router";
import Toaster from "../../../Components/Toaster/Toaster";
import { toast } from "react-toastify";

function ChangePassword(props) {
  const navigate = useNavigate();

  const successTost = (msg) => toast.success(msg);
  const errorNotify = (msg) => toast.error(msg);

  const [visible, setvisible] = useState(false);
  const [Confirmvisible, setConfirmvisible] = useState(false);
  const [OldVisible, setOldVisible] = useState(false);

  const [state, setState] = useState({
    old_password: "",
    new_password: "",
    confirm_password: "",
  });

  const [error, setError] = useState({
    old_password: false,
    new_password: false,
    confirm_password: false,
  });

  useEffect(() => {
    if (props.changePasswordReducer.success) {
      successTost("Password changed successfully.");
      setTimeout(() => {
        navigate("/admin/dashboard");
        props.resetChangePassword();
      }, 2200);
    } else if (props.changePasswordReducer.error) {
      errorNotify(props.changePasswordReducer.errors);
    }
  }, [props.changePasswordReducer]);

  const handleInput = (e) => {
    const { name, value } = e.target;

    setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));

    switch (name) {
      case "old_password":
        if (value === "") {
          setError((prevState) => ({
            ...prevState,
            old_password: "Enter Old Password",
          }));
        } else if (!validPassword.test(value)) {
          setError((prevState) => ({
            ...prevState,
            old_password:
              "Password contail atleast 1 number, 1 charecter and 1 symbol",
          }));
        } else {
          setError((prevState) => ({
            ...prevState,
            old_password: false,
          }));
        }
        break;

      case "new_password":
        if (value === "") {
          setError((prevState) => ({
            ...prevState,
            new_password: "Enter new Password",
          }));
        } else if (!validPassword.test(value)) {
          setError((prevState) => ({
            ...prevState,
            new_password:
              "Password contail atleast 1 number, 1 charecter and 1 symbol",
          }));
        } else {
          setError((prevState) => ({
            ...prevState,
            new_password: false,
          }));
        }
        break;

      case "confirm_password":
        if (value === "") {
          setError((prevState) => ({
            ...prevState,
            confirm_password: "Enter confirm new Password",
          }));
        } else if (!validPassword.test(value)) {
          setError((prevState) => ({
            ...prevState,
            confirm_password:
              "Password contail atleast 1 number, 1 charecter and 1 symbol",
          }));
        } else {
          setError((prevState) => ({
            ...prevState,
            confirm_password: false,
          }));
        }
        break;

      default:
        break;
    }
  };

  const handleResetPassword = (e) => {
    e.preventDefault();
    if (state.old_password === "") {
      setError((prevState) => ({
        ...prevState,
        old_password: "Enter Old Password",
      }));
    } else if (state.new_password === "") {
      setError((prevState) => ({
        ...prevState,
        new_password: "Enter new Password",
      }));
    } else if (state.confirm_password === "") {
      setError((prevState) => ({
        ...prevState,
        confirm_password: "Enter confirm new password",
      }));
    } else if (state.new_password !== state.confirm_password) {
      setError((prevState) => ({
        ...prevState,
        confirm_password: "new password and confirm password not match",
      }));
    }

    if (state.new_password !== state.confirm_password) {
      errorNotify("Password does not match")
    }

    if (
      state.old_password !== "" &&
      state.new_password !== "" &&
      state.confirm_password !== "" &&
      !error.old_password &&
      !error.new_password &&
      !error.confirm_password &&
      state.confirm_password === state.new_password
    ) {
      const details = {
        current_password: state.old_password,
        new_password: state.new_password,
        confirm_password: state.confirm_password,
      };

      props.changePassword(details);
    }
  };

  const passwordvisible = () => {
    setvisible(!visible);
  };
  const Confirmpasswordvisible = () => {
    setConfirmvisible(!Confirmvisible);
  };
  const OldPasswordVisible = () => {
    setOldVisible(!OldVisible);
  };

  return (
    <div>
      <Form className="row">
        <div className="col-12 col-md-6">
          <div className="form-group position-relative has-icon-left mb-4">
            <input
              type={!!OldVisible ? "text" : "password"}
              placeholder="Enter Old Password"
              name="old_password"
              value={state.old_password}
              onChange={handleInput}
              className={classNames("form-control form-control-xl", {
                requireField: error.old_password,
              })}
            />
            <div className="form-control-icon">
              <i className="fa fa-lock"></i>
            </div>
            <div
              className="form-control-eye-icon pointer"
              onClick={OldPasswordVisible}
            >
              {OldVisible ? (
                <i className="fa fa-eye" style={{ marginLeft: "250px" }}></i>
              ) : (
                <i className="fa fa-eye-slash " style={{ marginLeft: "250px" }}></i>
              )}
            </div>
          </div>
          {/* <Form.Group className='mt-4'>
      <Form.Label style={{ fontWeight: 'bold' }}>
       New Password :
      </Form.Label>
      <Form.Control
       name='new_password'
       type='password'
       placeholder='Enter new Password'
       value={state.new_password}
       onChange={handleInput}
       className={classNames({
        "requireField": error.new_password
       })}
      />
     </Form.Group> */}
          <div className="form-group position-relative has-icon-left mb-4">
            <input
              type={!!visible ? "text" : "password"}
              placeholder="Enter New Password"
              name="new_password"
              value={state.new_password}
              onChange={handleInput}
              className={classNames("form-control form-control-xl", {
                requireField: error.new_password,
              })}
            />
            <div className="form-control-icon">
              <i className="fa fa-lock"></i>
            </div>
            <div
              className="form-control-eye-icon pointer"
              onClick={passwordvisible}
            >
              {visible ? (
                <i className="fa fa-eye" style={{ marginLeft: "250px" }}></i>
              ) : (
                <i className="fa fa-eye-slash " style={{ marginLeft: "250px" }}></i>
              )}
            </div>
          </div>

          <div className="form-group position-relative has-icon-left mb-4">
            <input
              type={!!Confirmvisible ? "text" : "password"}
              name="confirm_password"
              placeholder="Enter Confirm Password"
              value={state.confirm_password}
              onChange={handleInput}
              className={classNames("form-control form-control-xl", {
                requireField: error.confirm_password,
              })}
            />
            <div className="form-control-icon">
              <i className="fa fa-lock"></i>
            </div>
            <div
              className="form-control-eye-icon pointer"
              onClick={Confirmpasswordvisible}
            >
              {Confirmvisible ? (
                <i className="fa fa-eye" style={{ marginLeft: "250px" }}></i>
              ) : (
                <i className="fa fa-eye-slash" style={{ marginLeft: "250px" }}></i>
              )}
            </div>
          </div>
          <Modal.Footer className="mt-3">
            {/* <div className='py-2 px-4 border rounded pointer bg-secondary text-white mx-2' onClick={handleCancel}>Cancel</div> */}
            <div
              className="py-2 px-4 border rounded pointer text-white bg-red mx-2"
              onClick={handleResetPassword}
            >
              Change Password
            </div>
          </Modal.Footer>
        </div>
      </Form>
      <Toaster />
    </div>
  );
}

const mapStateToProp = (state) => ({
  changePasswordReducer: state.PasswordReducer.changePassword,
});

const mapDispatchToProps = (dispatch) => ({
  changePassword: (details) => dispatch(changePassword(details)),
});

export default connect(mapStateToProp, mapDispatchToProps)(ChangePassword);
