import {
  ADD_ORDER_ERROR,
  ADD_ORDER_LOADING,
  ADD_ORDER_SUCCESS,
  ADMIN_AGENT_WISE_ORDER_ERROR,
  ADMIN_AGENT_WISE_ORDER_LOADING,
  ADMIN_AGENT_WISE_ORDER_SUCCESS,
  ADMIN_ORDER_FILTER_ERROR,
  ADMIN_ORDER_FILTER_LOADING,
  ADMIN_ORDER_FILTER_SUCCESS,
  DELETE_ORDER_ERROR,
  DELETE_ORDER_LOADING,
  DELETE_ORDER_SUCCESS,
  GET_ADMIN_DELIVERY_AGENTS_DATA_ERROR,
  GET_ADMIN_DELIVERY_AGENTS_DATA_LOADING,
  GET_ADMIN_DELIVERY_AGENTS_DATA_SUCCESS,
  GET_ORDER_BY_ID_ERROR,
  GET_ORDER_BY_ID_LOADING,
  GET_ORDER_BY_ID_SUCCESS,
  GET_ORDER_ERROR,
  GET_ORDER_LOADING,
  GET_ORDER_SUCCESS,
  RESET_ADD_ORDER,
  RESET_ADMIN_AGENT_WISE_ORDER,
  RESET_ADMIN_GET_DELIVERY_DATA,
  RESET_ADMIN_ORDER_FILTER,
  RESET_DELETE_ORDER,
  RESET_GET_ORDER,
  RESET_GET_ORDER_BY_ID,
  RESET_UPDATE_ORDER,
  UPDATE_ORDER_ERROR,
  UPDATE_ORDER_LOADING,
  UPDATE_ORDER_SUCCESS,
} from "../../../Constants/Constants";

let initialState = {
  getOrder: {
    loading: false,
    data: null,
    error: false,
    errors: null,
    success: false,
    pagination: {},
  },
  getOrderById: {
    loading: false,
    data: null,
    error: false,
    errors: null,
    success: false,
  },
  updateOrder: {
    loading: false,
    data: null,
    error: false,
    errors: null,
    success: false,
  },
  deleteOrder: {
    loading: false,
    data: null,
    error: false,
    errors: null,
    success: false,
  },
  addOrder: {
    loading: false,
    data: null,
    error: false,
    errors: null,
    success: false,
  },
  getadminagents: {
    loading: false,
    data: null,
    error: false,
    errors: null,
    success: false,
  },
  adminagentwiseorder: {
    loading: false,
    data: null,
    error: false,
    errors: null,
    success: false,
  },
  orderfilterAdmin: {
    loading: false,
    data: null,
    error: false,
    errors: null,
    success: false,
  },
};

export default function OrderReducer(state = initialState, action) {
  switch (action.type) {
    case GET_ORDER_LOADING:
      return {
        ...state,
        getOrder: {
          ...state.getOrder,
          loading: true,
          error: false,
          errors: [],
          success: false,
          pagination: {},
        },
      };

    case GET_ORDER_SUCCESS:
      return {
        ...state,
        getOrder: {
          ...state.getOrder,
          loading: false,
          error: false,
          errors: [],
          success: true,
          data: action.response.data,
          pagination: action.response.pagination,
        },
      };

    case GET_ORDER_ERROR:
      return {
        ...state,
        getOrder: {
          ...state.getOrder,
          loading: false,
          error: true,
          errors: action.errors,
          success: false,
          data: null,
          pagination: {},
        },
      };

    case RESET_GET_ORDER:
      return {
        ...state,
        getOrder: {
          ...state.getOrder,
          loading: false,
          error: false,
          errors: null,
          success: false,
          pagination: {},
        },
      };

    //  get order by id
    case GET_ORDER_BY_ID_LOADING:
      return {
        ...state,
        getOrderById: {
          ...state.getOrderById,
          loading: true,
          error: false,
          errors: [],
          success: false,
        },
      };

    case GET_ORDER_BY_ID_SUCCESS:
      return {
        ...state,
        getOrderById: {
          ...state.getOrderById,
          loading: false,
          error: false,
          errors: [],
          success: true,
          data: action.response.data ?? "alpha",
        },
      };

    case GET_ORDER_BY_ID_ERROR:
      return {
        ...state,
        getOrderById: {
          ...state.getOrderById,
          loading: false,
          error: true,
          errors: action.errors,
          success: false,
          data: null,
        },
      };

    case RESET_GET_ORDER_BY_ID:
      return {
        ...state,
        getOrderById: {
          ...state.getOrderById,
          loading: false,
          error: false,
          errors: null,
          success: false,
        },
      };

    //  update order by id
    case UPDATE_ORDER_LOADING:
      return {
        ...state,
        updateOrder: {
          ...state.updateOrder,
          loading: true,
          error: false,
          errors: [],
          success: false,
        },
      };

    case UPDATE_ORDER_SUCCESS:
      return {
        ...state,
        updateOrder: {
          ...state.updateOrder,
          loading: false,
          error: false,
          errors: [],
          success: true,
          data: action.response.data,
        },
      };

    case UPDATE_ORDER_ERROR:
      return {
        ...state,
        updateOrder: {
          ...state.updateOrder,
          loading: false,
          error: true,
          errors: action.errors,
          success: false,
          data: null,
        },
      };

    case RESET_UPDATE_ORDER:
      return {
        ...state,
        updateOrder: {
          ...state.updateOrder,
          loading: false,
          error: false,
          errors: null,
          success: false,
        },
      };

    //  delete order by id
    case DELETE_ORDER_LOADING:
      return {
        ...state,
        deleteOrder: {
          ...state.deleteOrder,
          loading: true,
          error: false,
          errors: [],
          success: false,
        },
      };

    case DELETE_ORDER_SUCCESS:
      return {
        ...state,
        deleteOrder: {
          ...state.deleteOrder,
          loading: false,
          error: false,
          errors: [],
          success: true,
          data: action.response.data,
        },
      };

    case DELETE_ORDER_ERROR:
      return {
        ...state,
        deleteOrder: {
          ...state.deleteOrder,
          loading: false,
          error: true,
          errors: action.errors,
          success: false,
          data: null,
        },
      };

    case RESET_DELETE_ORDER:
      return {
        ...state,
        deleteOrder: {
          ...state.deleteOrder,
          loading: false,
          error: false,
          errors: null,
          success: false,
        },
      };

    //  add order
    case ADD_ORDER_LOADING:
      return {
        ...state,
        addOrder: {
          ...state.addOrder,
          loading: true,
          error: false,
          errors: [],
          success: false,
        },
      };

    case ADD_ORDER_SUCCESS:
      return {
        ...state,
        addOrder: {
          ...state.addOrder,
          loading: false,
          error: false,
          errors: [],
          success: true,
          data: action.response.data,
        },
      };

    case ADD_ORDER_ERROR:
      return {
        ...state,
        addOrder: {
          ...state.addOrder,
          loading: false,
          error: true,
          errors: action.errors,
          success: false,
          data: null,
        },
      };

    case RESET_ADD_ORDER:
      return {
        ...state,
        addOrder: {
          ...state.addOrder,
          loading: false,
          error: false,
          errors: null,
          success: false,
        },
      };

    case GET_ADMIN_DELIVERY_AGENTS_DATA_LOADING:
      return {
        ...state,
        getadminagents: {
          ...state.getadminagents,
          loading: true,
          error: false,
          errors: [],
          success: false,
          pagination: {},
        },
      };

    case GET_ADMIN_DELIVERY_AGENTS_DATA_SUCCESS:
      return {
        ...state,
        getadminagents: {
          ...state.getadminagents,
          loading: false,
          error: false,
          errors: [],
          success: true,
          data: action.data,
        },
      };

    case GET_ADMIN_DELIVERY_AGENTS_DATA_ERROR:
      return {
        ...state,
        getadminagents: {
          ...state.getadminagents,
          loading: false,
          error: true,
          errors: action.errors,
          success: false,
          data: null,
          pagination: {},
        },
      };

    case RESET_ADMIN_GET_DELIVERY_DATA:
      return {
        ...state,
        getadminagents: {
          ...state.getadminagents,
          loading: false,
          error: false,
          errors: null,
          success: false,
          pagination: {},
        },
      };

    //  agent wise order
    case ADMIN_AGENT_WISE_ORDER_LOADING:
      return {
        ...state,
        adminagentwiseorder: {
          ...state.adminagentwiseorder,
          loading: true,
          error: false,
          errors: [],
          success: false,
        },
      };

    case ADMIN_AGENT_WISE_ORDER_SUCCESS:
      return {
        ...state,
        adminagentwiseorder: {
          ...state.adminagentwiseorder,
          loading: false,
          error: false,
          errors: [],
          success: true,
          data: action.data,
          fullData: action.fullData,
        },
      };

    case ADMIN_AGENT_WISE_ORDER_ERROR:
      return {
        ...state,
        adminagentwiseorder: {
          ...state.adminagentwiseorder,
          loading: false,
          error: true,
          errors: action.errors,
          success: false,
          data: null,
        },
      };

    case RESET_ADMIN_AGENT_WISE_ORDER:
      return {
        ...state,
        adminagentwiseorder: {
          ...state.adminagentwiseorder,
          loading: false,
          error: false,
          errors: null,
          success: false,
        },
      };

    // ............filter data

    case ADMIN_ORDER_FILTER_LOADING:
      return {
        ...state,
        orderfilterAdmin: {
          ...state.orderfilterAdmin,
          loading: true,
          error: false,
          errors: [],
          success: false,
        },
      };

    case ADMIN_ORDER_FILTER_SUCCESS:
      return {
        ...state,
        orderfilterAdmin: {
          ...state.orderfilterAdmin,
          loading: false,
          error: false,
          errors: [],
          success: true,
          data: action.response.data,
          fullData: action.response.fullData,
        },
      };

    case ADMIN_ORDER_FILTER_ERROR:
      return {
        ...state,
        orderfilterAdmin: {
          ...state.orderfilterAdmin,
          loading: false,
          error: true,
          errors: action.errors,
          success: false,
          data: null,
        },
      };

    case RESET_ADMIN_ORDER_FILTER:
      return {
        ...state,
        orderfilterAdmin: {
          ...state.orderfilterAdmin,
          loading: false,
          error: false,
          errors: null,
          success: false,
        },
      };

    default:
      return state;
  }
}
