import React, { useEffect, useState } from "react";
import { Form, Modal } from "react-bootstrap";
import { connect } from "react-redux";
import { RESET_UPDATE_MENU } from "../../Constants/Constants";
import {
  getMenuItemsById,
  updatemenuitem,
} from "../../Services/Actions/menuAction";
import classNames from "classnames";
import $ from "jquery";
import { toast } from "react-toastify";
import { Loader } from "rsuite";

function UpdateMenuItems(props) {
  const notifyError = (msg) => toast.error(msg);
  const [btnLoading, setbtnLoading] = useState(false);
  const [changeImage, setChangeImage] = useState(false);
  const [state, setState] = useState({
    // itemImage: "",
    itemName: "",
    itemPrice: "",
    status: "active",
    isPizza: false,
    note: "",
  });
  // const [Image, setImage] = useState(null);
  // const [prevImg, setPrevImg] = useState(Image);

  const [error, setError] = useState({
    // itemImage: false,
    itemName: false,
    itemPrice: false,
    note: false,
  });

  useEffect(() => {
    if (props.itemId) {
      props.getMenuItems(props.itemId);
    }
  }, [props.itemId]);

  useEffect(() => {
    if (props.updatedData.success) {
      setbtnLoading(false);
    }
  }, [props.updatedData]);

  useEffect(() => {
    $(".dropify")?.dropify();
  }, []);

  useEffect(() => {
    if (props.getdata.success) {
      const data = props.getdata.data;
      setState({
        itemName: data.item_name,
        // itemImage: data.image,
        itemPrice: parseFloat(data.price)?.toFixed(2),
        note: data.note ? data?.note : "",
        isPizza: data.is_pizza === 1 ? true : false,
        status: data.status === 1 ? "active" : "Inactive",
      });
      // setImage(data.image);
      // setPrevImg(data.image);
    }
  }, [props.getdata]);

  const handleInput = (e) => {
    const { name, value } = e.target;

    if (name === "note") {
      if (value.length <= 256) {
        setState((prevState) => ({
          ...prevState,
          [name]: value,
        }));
      } else return;
    } else {
      setState((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }

    // if (name === "itemImage") {
    //   setImage(e.target.files[0]);
    //   setPrevImg(URL.createObjectURL(e.target.files[0]));
    //   setChangeImage(true);
    // }

    switch (name) {
      case "itemName":
        if (value === "") {
          setError((prevState) => ({
            ...prevState,
            itemName: "Enter Item Name",
          }));
        } else {
          setError((prevState) => ({
            ...prevState,
            itemName: false,
          }));
        }
        break;
      case "itemPrice":
        if (value === "") {
          setError((prevState) => ({
            ...prevState,
            itemPrice: "Enter Item Price",
          }));
        } else {
          setError((prevState) => ({
            ...prevState,
            itemPrice: false,
          }));
        }
        break;
      // case "itemImage":
      //   if (value === "") {
      //     setError((prevState) => ({
      //       ...prevState,
      //       itemImage: "Enter Item Price",
      //     }));
      //   } else if (!RegExp(/^image/gim).test(e.target.files[0].type)) {
      //     setError((prevState) => ({
      //       ...prevState,
      //       itemImage: "Only accept image file",
      //     }));
      //   } else {
      //     setError((prevState) => ({
      //       ...prevState,
      //       itemImage: false,
      //     }));
      //   }
      //   break;

      case "note":
        if (value.length >= 256) {
          setError((prevState) => ({
            ...prevState,
            note: "Enter Only 256 Characters",
          }));
        } else {
          setError((prevState) => ({
            ...prevState,
            note: false,
          }));
        }
        break;

      default:
        break;
    }
  };

  const handleCancel = () => {
    props.setIsUpdateItem(false);
  };

  const handleUpdate = () => {
    if (
      state.itemName !== "" &&
      // state.itemImage !== "" &&
      state.itemPrice !== "" &&
      !error.itemName &&
      !error.itemPrice &&
      // !error.itemImage &&
      !error.note
    ) {
      const details = {
        id: props.itemId,
        data: {
          item_name: state.itemName,
          note: state.note,
          price: parseFloat(state.itemPrice)?.toFixed(2),
          // ...(changeImage && { image: Image }),
          is_pizza: state.isPizza ? "1" : "0",
          status: state.status === "active" ? 1 : 0,
          type: "2",
        },
      };
      props.updateMenuItems(details);
      props.setIsUpdateItem(false);
      setbtnLoading(true);
    }
    // if (error.itemImage) {
    //   notifyError("Only accept image file");
    // }

    // props.setIsAddMenu(false)
  };

  // const imgDefault = (e) => {
  //   e.target.src = "/images/menu-placeholder.jpg";
  //   e.target.onerror = null;
  // };

  return (
    <>
      <Modal.Header closeButton>
        <Modal.Title>Update Menu Item</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form className="row">
          <div className="">
            <div className="row">
              <Form.Group className="mt-4 col-6">
                <Form.Label style={{ fontWeight: "bold" }}>
                  Item Name:
                </Form.Label>
                <Form.Control
                  name="itemName"
                  type="text"
                  placeholder="Enter Item Name"
                  value={state.itemName}
                  onChange={handleInput}
                  className={classNames({
                    requireField: error.itemName,
                  })}
                />
              </Form.Group>
              <Form.Group className="mt-4 col-6">
                <Form.Label style={{ fontWeight: "bold" }}>Price:</Form.Label>
                <Form.Control
                  name="itemPrice"
                  type="number"
                  placeholder="Enter Item Price"
                  value={state.itemPrice}
                  onChange={handleInput}
                  className={classNames({
                    requireField: error.itemPrice,
                  })}
                />
              </Form.Group>
            </div>

            {/* <Form.Group className="mt-4">
              <Form.Label style={{ fontWeight: "bold" }}>Image:</Form.Label>
              <div className="custom-file-upload">
                <input
                  type="file"
                  crossOrigin="anonymous"
                  name="itemImage"
                  id="itemImage"
                  accept="image/*"
                  label="image"
                  onChange={handleInput}
                  className={classNames({
                    requireField: error.image,
                  })}
                />
                <label htmlFor="itemImage">
                  {prevImg ? (
                    <div className="uploader-prev">
                      <img
                        className="img-fluid"
                        crossOrigin="anonymous"
                        onError={imgDefault}
                        alt="droped image"
                        src={prevImg}
                      />
                      <span className="replace-btn">Replace</span>
                    </div>
                  ) : (
                    <div className="upload-placeholder text-center">
                      <i
                        className="fa fa-cloud-upload fa-3x"
                        aria-hidden="true"
                      ></i>
                      <h3>Upload File</h3>
                    </div>
                  )}
                </label>
              </div>
            </Form.Group> */}

            <Form.Group className="mt-4">
              <div className="row">
                <div className="col-12 col-md-3">
                  <Form.Label
                    className="d-block"
                    style={{ fontWeight: "bold" }}
                  >
                    Is Pizza?
                  </Form.Label>
                  <Form.Check
                    type="switch"
                    id="custom-switch"
                    checked={state.isPizza}
                    className={"pizzaBtn ms-0"}
                    onChange={(e) =>
                      setState((prevState) => ({
                        ...prevState,
                        isPizza: e.target.checked,
                      }))
                    }
                  />
                </div>
                <div className="col-md-3 col-4">
                  <Form.Label style={{ fontWeight: "bold" }}>
                    Status:
                  </Form.Label>
                  <Form.Select
                    value={state.status}
                    onChange={(e) => {
                      setState((prevState) => ({
                        ...prevState,
                        status: e.target.value,
                      }));
                    }}
                  >
                    <option value="active">Active</option>
                    <option value="Inactive">Inactive</option>
                  </Form.Select>
                </div>
              </div>
            </Form.Group>
          </div>
          <div className="col-12 mt-2">
            <Form.Label>Note:</Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              type="text"
              placeholder="Enter Note"
              value={state?.note}
              name="note"
              label="note"
              onChange={handleInput}
              id="note"
              className={classNames("mb-3", {
                requireField: error.note,
              })}
            />
          </div>
        </Form>
      </Modal.Body>
      <Modal.Footer className="mt-3">
        {/* <div className='py-2 px-4 border rounded pointer bg-secondary text-white mx-2' onClick={handleCancel}>Cancel</div> */}
        <div
          className="py-2 px-4 border rounded pointer text-white bg-red mx-2"
          onClick={handleUpdate}
        >
          {btnLoading ? <Loader /> : " Submit"}
        </div>
      </Modal.Footer>
    </>
  );
}

const mapStateToProp = (state) => ({
  getdata: state.MenuItemsReducer.getMenuById,
  updatedData: state.MenuItemsReducer.updateMenu,
});

const mapDispatchToProps = (dispatch) => ({
  getMenuItems: (id) => dispatch(getMenuItemsById(id)),
  updateMenuItems: (details) => dispatch(updatemenuitem(details)),
  ResetUpdate: () => dispatch({ type: RESET_UPDATE_MENU }),
});

export default connect(mapStateToProp, mapDispatchToProps)(UpdateMenuItems);
