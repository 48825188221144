import React, { useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
// import videoUrl from "../assets/production ID_5106444.mp4";

const Register = () => {
  const [registerValue, setregisterValue] = useState([]);

  const inputChnage = (e) => {
    setregisterValue({ ...registerValue, [e.target.name]: e.target.value });
  };
  const onSubmite = (e) => {
    e.preventDefault();
    localStorage.setItem("registerValue", registerValue);
  };

  return (
    <>
      <div className="auth-bg d-flex justify-content-center">
        {/* <video autoplay="autoplay" muted loop="true" /> */}
        <div className="auth-form">
          <h2 className="text-center">Registration</h2>
          <Form onSubmit={onSubmite}>
            {/* Login information */}
            <h3 className="mb-3">Login Information</h3>
            <Row>
              <Col sm={6}>
                <Form.Group className="mb-3">
                  <Form.Label className="mb-0">Username</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder=""
                    onChange={inputChnage}
                    name="username"
                  />
                </Form.Group>
              </Col>
              <Col sm={6}>
                <Form.Group className="mb-3">
                  <Form.Label className="mb-0">Email</Form.Label>
                  <Form.Control
                    type="email"
                    placeholder=""
                    onChange={inputChnage}
                    name="email"
                  />
                </Form.Group>
              </Col>
              <Col sm={6}>
                <Form.Group className="mb-3">
                  <Form.Label className="mb-0">Password</Form.Label>
                  <Form.Control
                    type="password"
                    placeholder=""
                    onChange={inputChnage}
                    name="password"
                  />
                </Form.Group>
              </Col>
              <Col sm={6}>
                <Form.Group className="mb-3">
                  <Form.Label className="mb-0">Confirm Password</Form.Label>
                  <Form.Control
                    type="password"
                    placeholder=""
                    onChange={inputChnage}
                    name="confirmPassword"
                  />
                </Form.Group>
              </Col>
            </Row>

            {/* Personal information */}
            <h3 className="mt-5 mb-3">Personal Information</h3>
            <Row>
              <Col sm={6}>
                <Form.Group className="mb-3">
                  <Form.Label className="mb-0">Full Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder=""
                    onChange={inputChnage}
                    name="name"
                  />
                </Form.Group>
              </Col>
              <Col sm={6}>
                <Form.Group className="mb-3">
                  <Form.Label className="mb-0">Country</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder=""
                    onChange={inputChnage}
                    name="country"
                  />
                </Form.Group>
              </Col>
              <Col sm={6}>
                <Form.Group className="mb-3">
                  <Form.Label className="mb-0">Company</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder=""
                    onChange={inputChnage}
                    name="company"
                  />
                </Form.Group>
              </Col>
              <Col sm={6}>
                <Form.Group className="mb-3">
                  <Form.Label className="mb-0">Mobile No</Form.Label>
                  <Form.Control
                    type="number"
                    placeholder=""
                    onChange={inputChnage}
                    name="mobile"
                  />
                </Form.Group>
              </Col>
            </Row>
            {/* Contact information */}
            <h3 className="mt-5 mb-0">Contact Detail</h3>
            <small className="mb-3 d-block">
              Fill at least one contact detail
            </small>
            <Row>
              <Col sm={6}>
                <Form.Group className="mb-3">
                  <Form.Label className="mb-0">Whatspp</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder=""
                    onChange={inputChnage}
                    name="wp"
                  />
                </Form.Group>
              </Col>
              <Col sm={6}>
                <Form.Group className="mb-3">
                  <Form.Label className="mb-0">weChat</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder=""
                    onChange={inputChnage}
                    name="weChat"
                  />
                </Form.Group>
              </Col>
              <Col sm={6}>
                <Form.Group className="mb-3">
                  <Form.Label className="mb-0">Skype</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder=""
                    onChange={inputChnage}
                    name="skype"
                  />
                </Form.Group>
              </Col>
            </Row>
            <div className="mb-3">
              <label htmlFor="formFile" className="form-label">
                Business Proof Document *
              </label>
              <input
                className="form-control form-control-lg w-100"
                type="file"
                id="formFile"
                onChange={inputChnage}
                name="file"
              />
            </div>
            {/* <Form.Group className="mb-3">
                                    <Form.Check type="checkbox" label="Remember Me" />
                              </Form.Group> */}
            <Button type="submite" className="mt-5">
              Submit
            </Button>
          </Form>
        </div>
        {/* <div className="reg-text">Already have an account? <Link to="/login" className="text-primary ms-2 fw-semibold">Login</Link></div> */}
      </div>
    </>
  );
};

export default Register;
