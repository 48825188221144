import { RESET_UPDATE_PRM_FOR_LOCATION, UPDATE_PRM_FOR_LOCATION_ERROR, UPDATE_PRM_FOR_LOCATION_LOADING, UPDATE_PRM_FOR_LOCATION_SUCCESS } from "../type/Constant";

let initialState = {
 updatePRMForLocation: {
  loading: false,
  data: null,
  error: false,
  errors: null,
  success: false
 }
};

export default function UpdatePrmForLocationReducer(state = initialState, action) {
 switch (action.type) {
  // profile
  case UPDATE_PRM_FOR_LOCATION_LOADING:
   return { ...state, updatePRMForLocation: { ...state.updatePRMForLocation, loading: true, error: false, errors: [], success: false } };

  case UPDATE_PRM_FOR_LOCATION_SUCCESS:
   return { ...state, updatePRMForLocation: { ...state.updatePRMForLocation, loading: false, error: false, errors: [], success: true, data: action.response.data } };

  case UPDATE_PRM_FOR_LOCATION_ERROR:
   return { ...state, updatePRMForLocation: { ...state.updatePRMForLocation, loading: false, error: true, errors: action.errors, success: false, data: null } };

  case RESET_UPDATE_PRM_FOR_LOCATION:
   return { ...state, updatePRMForLocation: { ...state.updatePRMForLocation, loading: false, error: false, errors: null, success: false } };

  default:
   return state;
 }
}


