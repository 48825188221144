import React from "react";
import { Link, useLocation } from "react-router-dom";
import classNames from "classnames";
function AdminSidebar() {
  const getPath = useLocation();
  return (
    <>
      <aside>
        <ul>
          <li>
            <Link
              to="/admin/dashboard"
              className={classNames("text-decoration-none", {
                active: getPath.pathname === "/admin/dashboard" ? "active" : "",
              })}
            >
              <i className="ri-dashboard-line"></i> Dashboard
            </Link>
          </li>
          <li>
            <Link
              to="/admin/order"
              className={classNames("text-decoration-none", {
                active: getPath.pathname === "/admin/order" ? "active" : "",
              })}
            >
              <i className="ri-shopping-basket-line"></i> Orders
            </Link>
          </li>
          <li>
            <Link
              to="/admin/customer"
              className={classNames("text-decoration-none", {
                active: getPath.pathname === "/admin/customer",
              })}
            >
              <i className="ri-team-line"></i>Customers
            </Link>
          </li>
          <li>
            <Link
              to="/admin/prm"
              className={classNames("text-decoration-none", {
                active: getPath.pathname === "/admin/prm" ? "active" : "",
              })}
            >
              <i className="ri-service-line"></i> PRM
            </Link>
          </li>
          <li>
            <Link
              to="/admin/menus"
              className={classNames("text-decoration-none", {
                active: getPath.pathname === "/admin/menus" ? "active" : "",
              })}
            >
              <i className="ri-ancient-gate-line"></i> Menu Items
            </Link>
          </li>
          <li>
            <Link
              to="/admin/delivery_agent"
              className={classNames("text-decoration-none", {
                active:
                  getPath.pathname === "/admin/delivery_agent" ? "active" : "",
              })}
            >
              <i className="ri-user-3-line"></i> Drivers
            </Link>
          </li>
          <li>
            <Link
              to="/admin/reports"
              className={classNames("text-decoration-none", {
                active: getPath.pathname === "/admin/reports",
              })}
            >
              <i className="ri-file-chart-line"></i>Reports
            </Link>
          </li>
        </ul>
      </aside>
    </>
  );
}

export default AdminSidebar;
