import { ADD_MENU_DATA_ERROR, ADD_MENU_DATA_LOADING, ADD_MENU_DATA_SUCCESS, RESET_ADD_MENU_DATA } from "../type/Constant";

let initialState = {
  loading: false,
  user: [],
  error: false,
  errors: [],
  success: false,
  payload: {}
};

export default function createMenusReducer(state = initialState, action) {
  switch (action.type) {
    case ADD_MENU_DATA_LOADING:
      return { ...state, error: false, loading: true, success: false };

    case ADD_MENU_DATA_SUCCESS:
      return { ...state, error: false, loading: false, success: true, message:action.msg };

    case ADD_MENU_DATA_ERROR:
      return { ...state, error: true, loading: false, success: false, errors: [] }

      case RESET_ADD_MENU_DATA:
        return { ...state, error: false, loading: false, success: false };

    default:
      return state;
  }
}


