import {
  EXPORT_PDF_ERROR,
  EXPORT_PDF_LOADING,
  EXPORT_PDF_SUCCESS,
  EXPORT_TIP_PDF_ERROR,
  EXPORT_TIP_PDF_LOADING,
  EXPORT_TIP_PDF_SUCCESS,
  RESET_EXPORT_PDF,
  RESET_EXPORT_TIP_PDF,
} from "../type/Constant";

let initialState = {
  exportpdf: {
    loading: false,
    data: null,
    error: false,
    errors: null,
    success: false,
  },
  exporttipPdf: {
    loading: false,
    data: null,
    error: false,
    errors: null,
    success: false,
  },
};

export default function exportpdfReducer(state = initialState, action) {
  switch (action.type) {
    case EXPORT_PDF_LOADING:
      return {
        ...state,
        exportpdf: {
          ...state.exportpdf,
          loading: true,
          error: false,
          errors: [],
          success: false,
        },
      };

    case EXPORT_PDF_SUCCESS:
      return {
        ...state,
        exportpdf: {
          ...state.exportpdf,
          loading: false,
          error: false,
          errors: [],
          success: true,
        //   data: action.response,
        },
      };

    case EXPORT_PDF_ERROR:
      return {
        ...state,
        exportpdf: {
          ...state.exportpdf,
          loading: false,
          error: true,
          errors: action.errors,
          success: false,
          data: null,
        },
      };

    case RESET_EXPORT_PDF:
      return {
        ...state,
        exportpdf: {
          ...state.exportpdf,
          loading: false,
          error: false,
          errors: null,
          success: false,
        },
      };

    // ...........................................................

    case EXPORT_TIP_PDF_LOADING:
      return {
        ...state,
        exporttipPdf: {
          ...state.exporttipPdf,
          loading: true,
          error: false,
          errors: [],
          success: false,
        },
      };

    case EXPORT_TIP_PDF_SUCCESS:
      return {
        ...state,
        exporttipPdf: {
          ...state.exporttipPdf,
          loading: false,
          error: false,
          errors: [],
          success: true,
        //   data: action.response.data,
        },
      };

    case EXPORT_TIP_PDF_ERROR:
      return {
        ...state,
        exporttipPdf: {
          ...state.exporttipPdf,
          loading: false,
          error: true,
          errors: action.errors,
          success: false,
          data: null,
        },
      };

    case RESET_EXPORT_TIP_PDF:
      return {
        ...state,
        exporttipPdf: {
          ...state.exporttipPdf,
          loading: false,
          error: false,
          errors: null,
          success: false,
        },
      };

    default:
      return state;
  }
}
