import { RESET_SUPERADMIN_PROFILE, SUPERADMIN_PROFILE_ERROR, SUPERADMIN_PROFILE_LOADING, SUPERADMIN_PROFILE_SUCCESS } from "../type/Constant";

let initialState = {
  profileDetails: {
    loading: false,
    data: null,
    error: false,
    errors: null,
    success: false
  }
};

export default function getSuperAdminProfileReducer(state = initialState, action) {
  switch (action.type) {
    // profile
    case SUPERADMIN_PROFILE_LOADING:
      return { ...state, profileDetails: { ...state.profileDetails, loading: true, error: false, errors: [], success: false } };

    case SUPERADMIN_PROFILE_SUCCESS:
      return { ...state, profileDetails: { ...state.profileDetails, loading: false, error: false, errors: [], success: true, data: action.response.data } };

    case SUPERADMIN_PROFILE_ERROR:
      return { ...state, profileDetails: { ...state.profileDetails, loading: false, error: true, errors: action.errors, success: false, data: null } };

    case RESET_SUPERADMIN_PROFILE:
      return { ...state, profileDetails: { ...state.profileDetails, loading: false, error: false, errors: null, success: false } };

    default:
      return state;
  }
}


