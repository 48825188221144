function customSort(a, b) {
  // Check if either address has municipality as Atlanta
  const isAtlantaA = a.address.municipality === "Atlanta";
  const isAtlantaB = b.address.municipality === "Atlanta";

  // Check if either address has countrySubdivisionCode as GA
  const isGACodeA = a.address.countrySubdivisionCode === "GA";
  const isGACodeB = b.address.countrySubdivisionCode === "GA";

  // Priority conditions
  if (isAtlantaA && !isAtlantaB) {
    return -1; // Address A has higher priority
  } else if (!isAtlantaA && isAtlantaB) {
    return 1; // Address B has higher priority
  } else if (isGACodeA && !isGACodeB) {
    return -1; // Address A has higher priority
  } else if (!isGACodeA && isGACodeB) {
    return 1; // Address B has higher priority
  } else {
    return 0; // No specific priority, maintain the order
  }
}
export const sortLocation = (data) => {
  return data.sort(customSort);
};

export function sortByProperty(array = [], property = "name") {
  if (!Array.isArray(array) || typeof property !== 'string') {
    throw new Error('Invalid arguments');
  }

  return array.slice().sort((a, b) => {
    const nameA = a[property]?.toLowerCase() || '';
    const nameB = b[property]?.toLowerCase() || '';

    if (nameA < nameB) return -1;
    if (nameA > nameB) return 1;
    return 0;
  });
}