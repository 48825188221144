import React, { useEffect, useMemo, useState } from "react";
import { Button, Form, Modal, Table } from "react-bootstrap";
import { connect, useDispatch, useSelector } from "react-redux";
import DeletePopUp from "../DeletePopup";
// import {
//   deleteAgentsData,
//   getLocationListData,
// } from "../../../Componant/Store/Action/CreateAgents/UserAction";
// import Footer from "../../../Componant/Userlayout/Footer";
// import Header from "../../../Componant/Userlayout/Header";
// import withRouter

import {
  ViewLocationTotaltipAction,
  createAgentsdata,
  deleteAgentsData,
  exportTipPdfAction,
} from "../../../Store/Action/CreateAgents/UserAction";
import { getLocationListData } from "../../../Store/Action/CreateAgents/UserAction";
import Header from "../Userlayout/Header";
import Footer from "../Userlayout/Footer";
import {
  contactValidation,
  countryCodeValidation,
  RESET_ADD_AGENTS_DATA,
  RESET_EXPORT_TIP_PDF,
  RESET_UPDATE_AGENTS_DATA,
  Sales_tax_Validation,
  Us_Pattern_Number,
  validEmailRegex,
  validPassword,
} from "../../../Store/type/Constant";
import { toast } from "react-toastify";
import EditLocation from "../EditLocation/EditLocation";
import classNames from "classnames";
import { Pagination } from "@mui/material";
import Toaster from "../../../Components/Toaster/Toaster";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import { columns, data } from "./locationData";
import FixedHeaderStory from "react-data-table-component";
// import FilterComponent from "../../../Components/FilterComponent/FilterComponent";
import $, { error } from "jquery";
import Spinner from "../../../Components/Spinner/Spinner";
import { Link } from "react-router-dom";
import ViewTipDetails from "./ViewTipDetails";
import { Loader } from "rsuite";
import MaskedInput from "react-text-mask";
import ReactDatePicker from "react-datepicker";

const FilterComponent = ({ filterText, onFilter, onClear }) => (
  <>
    <div className="form-outline mb-lg-5 mb-3">
      <label className="form-label me-2 mb-0" htmlFor="form1">
        {/* Search : */}
      </label>
      <input
        id="search"
        type="text"
        className="form-control"
        placeholder="Search"
        aria-label="Search Input"
        value={filterText}
        onChange={onFilter}
      />
    </div>
    {/* <ClearButton type="button" onClick={onClear}>
      X
    </ClearButton> */}
  </>
);

const Location = (props) => {
  // const { id } = useParams();

  const dispatch = useDispatch();
  // const [apiData, setapiData] = useState([]);
  const [show, setShow] = useState(false);
  const [EditShow, setEditShow] = useState(false);
  const [pdfloader, setpdfloader] = useState(false);
  const [pdfID, setpdfID] = useState();

  const [ShowLocationTip, setShowLocationTip] = useState(false);

  const [loading, setLoading] = useState(false);

  const [selectedId, setSelctedId] = useState();
  const [adminId, setadminId] = useState();
  const notifyError = (msg) => toast.error(msg);

  const successMsg = (msg) => toast.success(msg);

  const [visible, setvisible] = useState(false);
  const [Confirmvisible, setConfirmvisible] = useState(false);
  const [btnLoading, setbtnLoading] = useState(false);

  const [pdfshow, setpdfshow] = useState(false);
  const [isOpen, setIsOpen] = useState(true);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [error, seterror] = useState(false);

  const handleClosepdf = () => setpdfshow(false);
  const handleShowpdf = () => setpdfshow(true);

  const [state, setState] = useState({
    search: "",
  });

  const [pagination, setpagination] = useState({
    page: 1,
    limit: 10,
    totalpage: 1,
  });

  const handleShow = () => setShow(true);

  const handleChangePage = (event, value) => {
    props.getLocationListData({
      page: value,
      limit: 10,
      totalpage: 1,
    });
  };

  const agents = useSelector((state) => state.getAgentsReducer.user.data);

  const LocationList = useSelector(
    (state) => state.getLocationListReducer.user.data
  );

  const [isDelete, setIsDelete] = useState(false);
  const [deleteId, setDeleteId] = useState(null);

  const [locationname, setlocationname] = useState();

  useEffect(() => {
    props.getLocationListData({
      page: 2,
      limit: 10,
    });
  }, []);

  const [allinputvalue, setallinputvalue] = useState({
    location_name: "",
    email: "",
    number: "",
    name: "",
    password: "",
    color: "",
    confirmpassword: "",
    countrycode: "",
    status: "active",
    salestax: "",
  });

  const [errors, setErrors] = useState({
    location_name: false,
    email: false,
    number: false,
    name: false,
    password: false,
    color: false,
    confirmpassword: false,
    countrycode: false,
    salestax: false,
  });

  var res = allinputvalue?.number
    ?.replace(/\D+/g, "")
    .replace(/^(\d{3})(\d{3})(\d{4}).*/, "$1$2$3");

  const [passwordCheck, setpasswordCheck] = useState({
    spacialcharacter: false,
    capitalLatter: false,
    smallLatter: false,
    passwordNumber: false,
    length: false,
  });

  const handleClose = () => {
    setallinputvalue({
      location_name: "",
      email: "",
      number: "",
      name: "",
      password: "",
      color: "",
      confirmpassword: "",
      countrycode: "",
      salestax: "",
      status: "active",
    });
    setErrors({
      location_name: false,
      email: false,
      number: false,
      name: false,
      password: false,
      color: false,
      confirmpassword: false,
      countrycode: false,
      salestax: false,
    });
    setpasswordCheck({
      spacialcharacter: false,
      capitalLatter: false,
      smallLatter: false,
      passwordNumber: false,
      length: false,
    });
    setShow(false);
  };

  useEffect(() => {
    setLoading(props.getLocationListReducer.loading);
    if (props.getLocationListReducer?.success) {
      const data = props.getLocationListReducer.data;
      const _pagination = props.getLocationListReducer.pagination;
      // setpagination({ ..._pagination, page: parseInt(_pagination.page) - 1 });
      // setapiData([...data])
    }
  }, [props.getLocationListReducer]);

  useEffect(() => {
    setLoading(props.createAgentsReducer.loading);
    if (props.createAgentsReducer?.success) {
      props.getLocationListData({
        page: 1,
        limit: 10,
      });
      setTimeout(() => {
        successMsg("Location created successfully. ");
      }, 100);

      props.resetlocation();
      handleClose();
      setbtnLoading(false);
    }
  }, [props.createAgentsReducer]);

  useEffect(() => {
    if (props.getLocationListReducer.loading) {
      setLoading(true);
    }
  }, [props.getLocationListReducer]);

  useEffect(() => {
    if (props.createAgentsReducer?.error) {
      notifyError(props.createAgentsReducer.errors);
      props.resetlocation();
      setbtnLoading(false);
    }
  }, [props.createAgentsReducer]);

  useEffect(() => {
    if (props.exporttippdfReducer?.success) {
      setpdfloader(false);
    }
  }, [props.exporttippdfReducer]);

  const getValue = (e) => {
    const { name, value } = e.target;
    // setallinputvalue((prevState) => ({
    //   ...prevState,
    //   [name]: value,
    // }));
    if (e.target.name === "number") {
      if (e.target.value.length <= 14) {
        setallinputvalue((prevState) => ({
          ...prevState,
          [e.target.name]: e.target.value.replace(
            /^(\([0-9]{3}\) |[0-9]{3}-)[0-9]{3}-[0-9]{4}$/
          ),
        }));
      } else return;
    }
    setallinputvalue((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));

    if (e.target.name == "salestax") {
      if (e.target.value.length <= 3) {
        setallinputvalue((prevState) => ({
          ...prevState,
          salestax: e.target.value,
        }));
      } else return;
    }

    if (e.target.name == "color") {
      setallinputvalue((prevState) => ({
        ...prevState,
        [e.target.name]: e.target.value.toUpperCase(),
      }));
    }

    if (e.target.name === "password") {
      if (/[A-Z]/.test(e.target.value)) {
        setpasswordCheck((prevState) => ({
          ...prevState,
          capitalLatter: "Capital Latter",
        }));
      } else {
        setpasswordCheck((prevState) => ({
          ...prevState,
          capitalLatter: false,
        }));
      }
      if (/[a-z]/.test(e.target.value)) {
        setpasswordCheck((prevState) => ({
          ...prevState,
          smallLatter: "Small Latter",
        }));
      } else {
        setpasswordCheck((prevState) => ({
          ...prevState,
          smallLatter: false,
        }));
      }
      if (/[0-9]/.test(e.target.value)) {
        setpasswordCheck((prevState) => ({
          ...prevState,
          passwordNumber: "Number",
        }));
      } else {
        setpasswordCheck((prevState) => ({
          ...prevState,
          passwordNumber: false,
        }));
      }
      if (e.target.value.length >= 8) {
        setpasswordCheck((prevState) => ({
          ...prevState,
          length: "length",
        }));
      } else {
        setpasswordCheck((prevState) => ({
          ...prevState,
          length: false,
        }));
      }
      if (/[$&+,:;=?@#|'<>.^*()%!-]/.test(e.target.value)) {
        setpasswordCheck((prevState) => ({
          ...prevState,
          spacialcharacter: "Spacial Character",
        }));
      } else {
        setpasswordCheck((prevState) => ({
          ...prevState,
          spacialcharacter: false,
        }));
      }
    }

    switch (name) {
      case "location_name":
        if (e.target.value == "") {
          setErrors((prevState) => ({
            ...prevState,
            location_name: "Location name can not be empty",
          }));
        } else {
          setErrors((prevState) => ({
            ...prevState,
            location_name: false,
          }));
        }
        break;

      case "name":
        if (e.target.value == "") {
          setErrors((prevState) => ({
            ...prevState,
            name: "name can not be empty",
          }));
        } else {
          setErrors((prevState) => ({
            ...prevState,
            name: false,
          }));
        }
        break;

      case "number":
        if (e.target.value.length == 0) {
          setErrors((prevState) => ({
            ...prevState,
            number: "number can not be empty",
          }));
        } else if (!Us_Pattern_Number.test(e.target.value)) {
          setErrors((prevState) => ({
            ...prevState,
            number: "Enter valid number",
          }));
        } else {
          setErrors((prevState) => ({
            ...prevState,
            number: false,
          }));
        }
        break;

      case "countrycode":
        if (e.target.value.length == 0) {
          setErrors((prevState) => ({
            ...prevState,
            countrycode: "countrycode can not be empty",
          }));
        } else if (!countryCodeValidation.test(e.target.value)) {
          setErrors((prevState) => ({
            ...prevState,
            countrycode: "confirmpassword can not be match",
          }));
        } else {
          setErrors((prevState) => ({
            ...prevState,
            countrycode: false,
          }));
        }
        break;

      case "email":
        if (e.target.value === "") {
          setErrors((prevState) => ({
            ...prevState,
            email: "Location Email can not be empty",
          }));
        } else if (!validEmailRegex.test(e.target.value)) {
          setErrors((prevState) => ({
            ...prevState,
            email: "Enter valid email",
          }));
        } else {
          setErrors((prevState) => ({
            ...prevState,
            email: false,
          }));
        }
        break;

      case "password":
        if (e.target.value.length == 0) {
          setErrors((prevState) => ({
            ...prevState,
            password: "Password can not be empty",
          }));
        } else if (!validPassword.test(e.target.value)) {
          setErrors((prevState) => ({
            ...prevState,
            password: "Enter valid alphanumeric password",
          }));
        } else {
          setErrors((prevState) => ({
            ...prevState,
            password: false,
          }));
        }
        break;

      case "confirmpassword":
        if (e.target.value.length == 0) {
          setErrors((prevState) => ({
            ...prevState,
            confirmpassword: "confirmpassword can not be empty",
          }));
        } else if (allinputvalue.password === allinputvalue.confirmpassword) {
          setErrors((prevState) => ({
            ...prevState,
            confirmpassword: "confirmpassword can not be match",
          }));
        } else {
          setErrors((prevState) => ({
            ...prevState,
            confirmpassword: false,
          }));
        }
        break;

      case "color":
        if (e.target.value.length == 0) {
          setErrors((prevState) => ({
            ...prevState,
            color: "Location Color can not be empty",
          }));
        } else {
          setErrors((prevState) => ({
            ...prevState,
            color: false,
          }));
        }
        break;

      case "salestax":
        if (e.target.value.length == 0) {
          setErrors((prevState) => ({
            ...prevState,
            salestax: "Location salestax can not be empty",
          }));
        } else if (!Sales_tax_Validation.test(e.target.value)) {
          setErrors((prevState) => ({
            ...prevState,
            salestax: "Enter valid salestax",
          }));
        } else {
          setErrors((prevState) => ({
            ...prevState,
            salestax: false,
          }));
        }
        break;

      default:
    }
  };

  const submiteForm = (e) => {
    const ischeck = LocationList?.find((v, i) => {
      return v?.location_name == allinputvalue.location_name;
    });

    const isColorcheck = LocationList?.find((v, i) => {
      return (v?.color).toUpperCase() == allinputvalue.color.toUpperCase();
    });

    const isNumbercheck = LocationList?.find((v, i) => {
      return v?.locationdata.number == allinputvalue.number;
    });

    if (!!ischeck) {
      setErrors((prevState) => ({
        ...prevState,
        location_name: "location_name must be unique",
      }));
      notifyError("Location name must be unique");
    }

    if (!!isColorcheck) {
      setErrors((prevState) => ({
        ...prevState,
        color: "color must be unique",
      }));
      notifyError("Color must be unique");
    }

    if (!!isNumbercheck) {
      setErrors((prevState) => ({
        ...prevState,
        number: "number must be unique",
      }));
      notifyError("Number must be unique");
    }

    if (allinputvalue.location_name == "") {
      setErrors((prevState) => ({
        ...prevState,
        location_name: "Location name can not be empty",
      }));
    }

    if (allinputvalue.name == "") {
      setErrors((prevState) => ({
        ...prevState,
        name: "name can not be empty",
      }));
    }

    if (allinputvalue.email === "") {
      setErrors((prevState) => ({
        ...prevState,
        email: "email can not be empty",
      }));
    } else if (!validEmailRegex.test(allinputvalue.email)) {
      setErrors((prevState) => ({
        ...prevState,
        email: "Enter valid email",
      }));
    } else {
      setErrors((prevState) => ({
        ...prevState,
        email: false,
      }));
    }

    if (allinputvalue.password == "") {
      setErrors((prevState) => ({
        ...prevState,
        password: "password can not be empty",
      }));
    } else if (!validPassword.test(allinputvalue.password)) {
      setErrors((prevState) => ({
        ...prevState,
        password: "Enter valid alphanumeric password",
      }));
    } else {
      setErrors((prevState) => ({
        ...prevState,
        password: false,
      }));
    }

    if (allinputvalue.password !== allinputvalue.confirmpassword) {
      setErrors((prevState) => ({
        ...prevState,
        confirmpassword: "password cant not match",
        password: "password can not match",
      }));
    }
    if (allinputvalue.color == "") {
      setErrors((prevState) => ({
        ...prevState,
        color: "Location color can not be empty",
      }));
    }

    if (allinputvalue.number == "") {
      setErrors((prevState) => ({
        ...prevState,
        number: "number can not be empty",
      }));
    } else if (!Us_Pattern_Number.test(allinputvalue.number)) {
      setErrors((prevState) => ({
        ...prevState,
        number: "Enter valid number",
      }));
    } else {
      setErrors((prevState) => ({
        ...prevState,
        number: false,
      }));
    }

    if (allinputvalue.confirmpassword == "") {
      setErrors((prevState) => ({
        ...prevState,
        confirmpassword: "confirmpassword can not be empty",
      }));
    }

    if (allinputvalue.salestax == "") {
      setErrors((prevState) => ({
        ...prevState,
        salestax: "salestax can not be empty",
      }));
    } else if (!Sales_tax_Validation.test(allinputvalue.salestax)) {
      setErrors((prevState) => ({
        ...prevState,
        salestax: "Enter valid salestax",
      }));
    } else {
      setErrors((prevState) => ({
        ...prevState,
        salestax: false,
      }));
    }

    if (
      allinputvalue.location_name !== "" &&
      allinputvalue.name !== "" &&
      allinputvalue.email !== "" &&
      allinputvalue.password !== "" &&
      !errors.password &&
      allinputvalue.number !== "" &&
      allinputvalue.confirmpassword !== "" &&
      allinputvalue.confirmpassword === allinputvalue.password &&
      allinputvalue.salestax !== "" &&
      !errors.salestax &&
      !errors.password
    ) {
      dispatch(
        createAgentsdata({
          location_name: allinputvalue.location_name,
          name: allinputvalue.name,
          email: allinputvalue.email,
          password: allinputvalue.password,
          color: allinputvalue.color.toUpperCase(),
          number: res,
          countrycode: allinputvalue.countrycode,
          confirmpassword: allinputvalue.confirmpassword,
          salestax: allinputvalue.salestax,
          status: allinputvalue.status === "active" ? 1 : 0,
        })
      );
      setbtnLoading(true);
      // handleClose();
    }
  };

  const columns = [
    {
      name: "Name",
      selector: (row) => row.location,
      sortable: true,
    },
    {
      name: "Admin Name",
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: "Email Address",
      selector: (row) => row.email,
      sortable: true,
    },
    {
      name: "Color",
      selector: (row) => row.color,
      // width: "100px",
    },
    {
      name: "Status",
      selector: (row) => row.status,
      // width: "110px",
    },
    {
      name: "Tip Report",
      selector: (row) => row.Tip_Report,
      // width: "150px",
    },
    {
      name: "Action",
      selector: (row) => row.action,
      width: "150px",
    },
  ];

  const data = LocationList?.map((data, i) => {
    return {
      id: data._id,
      location: data.location_name ? data.location_name : "N/A",
      name: data.locationdata?.name ? data.locationdata?.name : "N/A",
      email: data.locationdata?.email ? data.locationdata?.email : "N/A",
      color: (
        <div
          className="colorBox"
          style={{
            backgroundColor: data.color,
          }}
        ></div>
      ),
      status:
        data.status === 1 ? (
          <span className="badge bg-dark" style={{ width: "67px" }}>
            Active
          </span>
        ) : (
          <span className="badge bg-red" style={{ width: "67px" }}>
            InActive
          </span>
        ),
      Tip_Report: (
        <button
          className="btn p-0"
          onClick={() => {
            handleShowpdf(true);
            setStartDate("");
            setEndDate("");
            setpdfID(data?._id);
          }}
        >
          <i
            className="fa-regular fa-file-pdf fs-4 pointer"
            aria-hidden="true"
          ></i>
        </button>
      ),

      action: (
        <>
          <button
            className="btn p-0"
            onClick={() => {
              setShowLocationTip(true);
              setSelctedId(data?._id);
            }}
          >
            <i className="fa fa-eye pointer fs-4 me-2" aria-hidden="true"></i>
          </button>
          <button
            className="btn p-0"
            onClick={() => {
              setEditShow(true);
              setSelctedId(data?._id);
              setadminId(data?.assigned_user);
            }}
          >
            <i
              className="fa fa-pencil-square-o pointer fs-4"
              aria-hidden="true"
            ></i>
          </button>
          {/* <button
            className="btn p-0"
            onClick={() => {
              // onDelete(v._id);
              setIsDelete(true);
              setDeleteId(data?._id);
            }}
          > 
            <i
              className="fa fa-trash pointer fs-4 ms-1 text-danger"
              aria-hidden="true"
            ></i>
          </button> */}
        </>
      ),
    };
  });

  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const filteredItems = data?.filter(
    (item) =>
      (item.location &&
        item.location.toLowerCase().includes(filterText.toLowerCase())) ||
      (item.name &&
        item.name.toLowerCase().includes(filterText.toLowerCase())) ||
      (item.email &&
        item.email.toLowerCase().includes(filterText.toLowerCase()))
  );

  const subHeaderComponentMemo = React.useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <FilterComponent
        onFilter={(e) => setFilterText(e.target.value)}
        onClear={handleClear}
        filterText={filterText}
      />
    );
  }, [filterText, resetPaginationToggle]);

  const UpdateArray = (search) => {
    var query = search;

    $(".filter-string").each(function (i, v) {
      if ($(v).text().toLowerCase().indexOf(query) === -1) {
        $(v).parents(".pay-row").addClass("d-none");
      } else {
        $(v).parents(".pay-row").removeClass("d-none");
      }
    });
  };

  const passwordvisible = () => {
    setvisible(!visible);
  };
  const Confirmpasswordvisible = () => {
    setConfirmvisible(!Confirmvisible);
  };

  useEffect(() => {
    if (
      startDate !== "" &&
      startDate !== null &&
      (endDate === "" || endDate === null) &&
      !isOpen
    ) {
      toast.error("Please select the next date in the picker.");
    }
  }, [isOpen]);

  return (
    <>
      <Header />
      <main className="userMain">
        <div className="content-block">
          <div className="d-flex justify-content-between align-items-center mb-4">
            <div className="d-flex justify-content-center align-items-center">
              <Link to={"/"} className="text-dark">
                <i className="fa fa-house fs-2 me-3"></i>
              </Link>
              <h3 className="me-3">/</h3>
              <h3 className="fw-semibold">Location</h3>
            </div>
            {/* {LocationList?.length > 6 && ( */}
            {/* <div
              className="d-flex justify-content-end"
              onClick={() => {
                handleShow();
              }}
            >
              <div className="border rounded pointer px-4 py-2 text-white bg-red">
                Create Location
              </div>
            </div> */}
            {/* )} */}
          </div>
          {loading ? (
            <Spinner />
          ) : (
            <>
              {" "}
              {LocationList && LocationList.length !== 0 ? (
                <>
                  {/* <div className="d-md-flex d-none">
                    <div className="search-section">
                      <i className="mdi mdi-magnify search-ico"></i>
                      <input
                        type="text"
                        className="form-control"
                        id="search"
                        name="search"
                        value={state.search}
                        placeholder="Search"
                        onChange={Searchval}
                        autoComplete="off"
                      />
                    </div>
                  </div> */}

                  <div className="pay-row">
                    <DataTable
                      columns={columns}
                      data={filteredItems}
                      pagination
                      paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
                      subHeader
                      subHeaderComponent={subHeaderComponentMemo}
                    />
                  </div>
                </>
              ) : (
                <div className="text-center h5 mt-5">
                  {" "}
                  No Location available
                </div>
              )}
            </>
          )}
        </div>
      </main>
      <Footer />
      <DeletePopUp
        isOpen={isDelete}
        isClose={setIsDelete}
        handleEvent={() => dispatch(deleteAgentsData(deleteId))}
        confirmMsg="Are you sure about deleting this location?"
      />

      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        size="lg"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Create Location</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="form-area">
            <Form
              className="row"
              // onSubmit={submiteForm}
              autoComplete="off"
            >
              <div className="col-6">
                <Form.Group className="mb-3">
                  <Form.Label>Name:</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Location Name"
                    name="location_name"
                    onChange={getValue}
                    label="location_name"
                    value={allinputvalue?.location_name}
                    id="location_name"
                    className={classNames({
                      requireField: errors.location_name,
                    })}
                  />
                </Form.Group>
              </div>
              <div className="col-6">
                <Form.Group className="mb-3">
                  <Form.Label>Admin Name:</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Admin Name"
                    name="name"
                    onChange={getValue}
                    label="name"
                    value={allinputvalue?.name}
                    id="name"
                    className={classNames({
                      requireField: errors.name,
                    })}
                  />
                </Form.Group>
              </div>
              <div className="col-6">
                {" "}
                <Form.Group className="mb-3">
                  <Form.Label>Email Address: </Form.Label>
                  <Form.Control
                    id="email"
                    type="email"
                    placeholder="Enter Email Address"
                    name="email"
                    onChange={getValue}
                    label="email"
                    value={allinputvalue?.email}
                    className={classNames({
                      requireField: errors.email,
                    })}
                  />
                </Form.Group>
              </div>
              <div className="col-6">
                <Form.Label>Contact No.:</Form.Label>
                <MaskedInput
                  mask={[
                    "(",
                    /[0-9]/,
                    /[0-9]/,
                    /[0-9]/,
                    ")",
                    " ",
                    /[0-9]/,
                    /[0-9]/,
                    /[0-9]/,
                    "-",
                    /[0-9]/,
                    /[0-9]/,
                    /[0-9]/,
                    /[0-9]/,
                  ]}
                  name="number"
                  placeholder="Enter Contact No"
                  guide={false}
                  id="my-input-id number"
                  value={allinputvalue?.number}
                  onChange={getValue}
                  className={classNames("form-control mb-3", {
                    requireField: errors.number,
                  })}
                />
              </div>
              <div className="d-flex justify-contant space-beetwen">
                <div className="row">
                  {" "}
                  {/* <div className="col-3">
                      <Form.Group className="mb-3">
                        <Form.Label>Code :</Form.Label>
                        <Form.Control
                          type="number"
                          placeholder="+91"
                          // value={`+${prmApiData.countrycode}`}
                          name="countrycode"
                          label="countrycode"
                          onChange={getValue}
                          id="countrycode"
                          value={allinputvalue?.countrycode}
                          className={classNames({
                            requireField: errors.countrycode,
                          })}
                        />
                      </Form.Group>
                    </div> */}
                </div>
              </div>

              {/* <div className="row"> */}
              <Form.Group className="col-6 mb-3">
                <Form.Label style={{ fontWeight: "bold" }}>
                  Password:
                </Form.Label>
                <div className="password-field">
                  <Form.Control
                    name="password"
                    type={!!visible ? "text" : "password"}
                    placeholder="Enter Password"
                    value={allinputvalue.password}
                    onChange={getValue}
                    className={classNames({
                      requireField: errors.password,
                    })}
                  />
                  <div
                    className="form-control-eye-icon pointer"
                    onClick={passwordvisible}
                  >
                    {visible ? (
                      <i
                        className="fa fa-eye"
                        style={{ marginLeft: "250px" }}
                      ></i>
                    ) : (
                      <i
                        className="fa fa-eye-slash "
                        style={{ marginLeft: "250px" }}
                      ></i>
                    )}
                  </div>
                </div>
              </Form.Group>

              <Form.Group className="col-6 mb-3">
                <Form.Label style={{ fontWeight: "bold" }}>
                  Confirm Password:
                </Form.Label>
                <div className="password-field">
                  <Form.Control
                    name="confirmpassword"
                    placeholder="Enter Confirm Password"
                    type={!!Confirmvisible ? "text" : "password"}
                    value={allinputvalue.confirmpassword}
                    onChange={getValue}
                    className={classNames({
                      requireField: errors.confirmpassword,
                    })}
                  />
                  <div
                    className="form-control-eye-icon pointer"
                    onClick={Confirmpasswordvisible}
                  >
                    {Confirmvisible ? (
                      <i
                        className="fa fa-eye"
                        style={{ marginLeft: "250px" }}
                      ></i>
                    ) : (
                      <i
                        className="fa fa-eye-slash"
                        style={{ marginLeft: "250px" }}
                      ></i>
                    )}
                  </div>
                </div>
              </Form.Group>
              <div className="col-md-4 col-4">
                <Form.Group className="mb-3">
                  <Form.Label>Sales Tax (In Percentage):</Form.Label>
                  <Form.Control
                    type="salestax"
                    placeholder="Enter sales tax amount"
                    name="salestax"
                    id="salestax"
                    value={allinputvalue?.salestax}
                    onChange={getValue}
                    className={classNames({
                      requireField: errors.salestax,
                    })}
                  />
                </Form.Group>
              </div>
              <div className="row mb-3">
                <div
                  className="col-md-12 col-12 mt-2"
                  style={{ fontWeight: "bold" }}
                >
                  <div
                    style={{
                      color: passwordCheck?.length ? "green" : "red",
                    }}
                  >
                    <span>
                      <i
                        className={`fa ${
                          passwordCheck?.length
                            ? "fa-circle-check text-success"
                            : "fa-circle-xmark text-danger"
                        } text-success fs-5 me-2`}
                      ></i>
                      Must be at least 8 characters.
                    </span>
                  </div>
                </div>

                <div
                  className="col-md-12 col-12 mt-2"
                  style={{ fontWeight: "bold" }}
                >
                  <div
                    style={{
                      color: passwordCheck?.capitalLatter ? "green" : "red",
                    }}
                  >
                    <span>
                      <i
                        className={`fa ${
                          passwordCheck?.capitalLatter
                            ? "fa-circle-check text-success"
                            : "fa-circle-xmark text-danger"
                        } text-success fs-5 me-2`}
                      ></i>
                      Must be at least One upper case character ( A,B,C...etc )
                    </span>
                  </div>
                </div>
                <div
                  className="col-md-12 col-12 mt-2"
                  style={{ fontWeight: "bold" }}
                >
                  <div
                    style={{
                      color: passwordCheck?.smallLatter ? "green" : "red",
                    }}
                  >
                    <span>
                      <i
                        className={`fa ${
                          passwordCheck?.smallLatter
                            ? "fa-circle-check text-success"
                            : "fa-circle-xmark text-danger"
                        } text-success fs-5 me-2`}
                      ></i>
                      Must be at least One lower case character ( a,b,c...etc )
                    </span>
                  </div>
                </div>

                <div
                  className="col-md-12 col-12 mt-2"
                  style={{ fontWeight: "bold" }}
                >
                  <div
                    style={{
                      color: passwordCheck?.spacialcharacter ? "green" : "red",
                    }}
                  >
                    <span>
                      {" "}
                      <i
                        className={`fa ${
                          passwordCheck?.spacialcharacter
                            ? "fa-circle-check text-success"
                            : "fa-circle-xmark text-danger"
                        } text-success fs-5 me-2`}
                      ></i>
                      Must conatin at least special character ( #,@,$,...etc )
                    </span>
                  </div>
                </div>
                <div
                  className="col-md-12 col-12 mt-2"
                  style={{ fontWeight: "bold" }}
                >
                  <div
                    style={{
                      color: passwordCheck?.passwordNumber ? "green" : "red",
                    }}
                  >
                    <i
                      className={`fa ${
                        passwordCheck?.passwordNumber
                          ? "fa-circle-check text-success"
                          : "fa-circle-xmark text-danger"
                      } text-success fs-5 me-2`}
                    ></i>
                    {/* <i className="fa fa-circle-xmark text-danger fs-4"></i> */}
                    Must conatin at least one number ( 1,2,3,...etc )
                  </div>
                </div>
              </div>
              {/* </div> */}

              <div className="col-2">
                <Form.Group className="mb-3">
                  <Form.Label>Color : </Form.Label>
                  <Form.Control
                    type="color"
                    // placeholder="Enter Color"
                    name="color"
                    onChange={getValue}
                    label="color"
                    id="color"
                    // value={"#ffff"}
                    className={classNames({
                      requireField: errors.color,
                    })}
                  />
                </Form.Group>
              </div>
              <div className="col-md-3 col-4">
                <Form.Label>Status:</Form.Label>
                <Form.Select
                  value={allinputvalue?.status}
                  onChange={(e) => {
                    setallinputvalue((prevState) => ({
                      ...prevState,
                      status: e.target.value,
                    }));
                  }}
                >
                  <option value="active">Active</option>
                  <option value="Inactive">Inactive</option>
                </Form.Select>
              </div>

              {/* <div className="">
                        <Button
                          variant="primary"
                          type="submit"
                          className="mt-4"
                        >
                          Submit
                        </Button>
                      </div> */}
            </Form>
          </div>
        </Modal.Body>
        <Modal.Footer>
          {/* <Button variant="secondary" onClick={handleClose}>
                Close
              </Button> */}
          <div
            className="border rounded pointer px-4 py-2 text-white bg-red"
            onClick={submiteForm}
          >
            {btnLoading ? <Loader /> : "Submit"}
          </div>
        </Modal.Footer>
      </Modal>

      {selectedId && (
        <Modal
          show={EditShow}
          onHide={() => setEditShow(false)}
          size="lg"
          backdrop="static"
        >
          <EditLocation
            selectedId={selectedId}
            adminId={adminId}
            setEditShow={setEditShow}
          />
        </Modal>
      )}

      {selectedId && (
        <Modal
          show={ShowLocationTip}
          onHide={() => setShowLocationTip(false)}
          size="lg"
          backdrop="static"
        >
          {" "}
          <ViewTipDetails
            selectedId={selectedId}
            setShowLocationTip={setShowLocationTip}
          />
        </Modal>
      )}
      <Toaster />
      {pdfloader && (
        <div
          className=" text-center mt-3"
          style={{
            position: "absolute",
            top: "0px",
            bottom: "0px",
            right: "0px",
            left: "0px",
            backgroundColor: "rgba(0, 0, 0, 0.3)",
          }}
        >
          <div className="d-flex justify-content-center align-items-center h-100 w-100">
            <div className="mt-3" style={{ opacity: 1 }}>
              <Spinner />
            </div>
          </div>
        </div>
      )}

      <Modal
        show={pdfshow}
        onHide={handleClosepdf}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Select Date</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group className="col col-lg-6 col-md-3">
            <Form.Label className="mb-1" style={{ marginBottom: "-20px" }}>
              From / To Date:
            </Form.Label>
            <ReactDatePicker
              showYearDropdown
              onInputClick={() => setIsOpen(true)}
              onClickOutside={() => setIsOpen(false)}
              selectsRange={true}
              startDate={startDate}
              endDate={endDate}
              placeholderText="MM-DD-YYYY"
              dateFormat={"MM-dd-yyyy"}
              className={classNames("form-control", {
                requireField: error,
              })}
              onChange={(date) => {
                if (!!date) {
                  setStartDate(date[0]);
                  setEndDate(date[1]);
                }
                if (date[1]) {
                  setIsOpen(false);
                }
              }}
              // isClearable={true}
            />
          </Form.Group>
          <div className="d-flex justify-content-end">
            <div
              className="border rounded pointer px-4 py-2 text-white bg-red"
              onClick={() => {
                // if (
                //   startDate !== "" &&
                //   startDate !== null &&
                //   (endDate === "" || endDate === null) &&
                //   !isOpen
                // ) {
                // } else {
                // console.log("63526", {
                //   startDate: startDate,
                //   endDate: endDate,
                //   id: pdfID,
                // });
                props.exportTipPdfAction({
                  startDate: startDate,
                  endDate: endDate,
                  id: pdfID,
                });
                handleClosepdf(true);
                setpdfloader(true);
                // }
              }}
            >
              Export PDF
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

const mapStateToProp = (state) => ({
  // submit: state.getAgentsReducer,
  getLocationListReducer: state.getLocationListReducer,
  createAgentsReducer: state.createAgentsReducer,
  editAgentsReducer: state.editAgentsReducer,
  getViewLocationByIdReducer: state.getViewLocationByIdReducer.LocationTotalTip,
  exporttippdfReducer: state.exportpdfReducer.exporttipPdf,
});

const mapDispatchToProps = (dispatch) => ({
  resetlocation: () => dispatch({ type: RESET_ADD_AGENTS_DATA }),
  resetUpdatelocation: () => dispatch({ type: RESET_UPDATE_AGENTS_DATA }),
  getLocationListData: (Details) => dispatch(getLocationListData(Details)),
  exportTipPdfAction: (Details) => dispatch(exportTipPdfAction(Details)),
  resetExportPdf: () => dispatch({ type: RESET_EXPORT_TIP_PDF }),
});
export default connect(mapStateToProp, mapDispatchToProps)(Location);
