import { CUSTOMER_TOTAL_ORDER_COUNT_ERROR, CUSTOMER_TOTAL_ORDER_COUNT_LOADING, CUSTOMER_TOTAL_ORDER_COUNT_SUCCESS, RESET_CUSTOMER_TOTAL_ORDER_COUNT } from "../type/Constant";

    let initialState = {
    customerordercount: {
      loading: false,
      data: null,
      error: false,
      errors: null,
      success: false,
    },
  };
  
  export default function customerordercountReducer(state = initialState, action) {
    switch (action.type) {
      case CUSTOMER_TOTAL_ORDER_COUNT_LOADING:
        return {
          ...state,
          customerordercount: {
            ...state.customerordercount,
            loading: true,
            error: false,
            errors: [],
            success: false,
          },
        };
  
      case CUSTOMER_TOTAL_ORDER_COUNT_SUCCESS:
        return {
          ...state,
          customerordercount: {
            ...state.customerordercount,
            loading: false,
            error: false,
            errors: [],
            success: true,
            data: action.response.data,
          },
        };
  
      case CUSTOMER_TOTAL_ORDER_COUNT_ERROR:
        return {
          ...state,
          customerordercount: {
            ...state.customerordercount,
            loading: false,
            error: true,
            errors: action.errors,
            success: false,
            data: null,
          },
        };
  
      case RESET_CUSTOMER_TOTAL_ORDER_COUNT:
        return {
          ...state,
          customerordercount: {
            ...state.customerordercount,
            loading: false,
            error: false,
            errors: null,
            success: false,
          },
        };
  
      default:
        return state;
    }
  }
  