import {
  LOCATION_WISE_CUSTOMER_ERROR,
  LOCATION_WISE_CUSTOMER_LOADING,
  LOCATION_WISE_CUSTOMER_SUCCESS,
  RESET_LOCATION_WISE_CUSTOMER,
} from "../type/Constant";

let initialState = {
  getlocationwisecustomer: {
    loading: false,
    data: null,
    error: false,
    errors: null,
    success: false,
    pagination: {},
  },
};

export default function getlocationwisecustomerReducer(
  state = initialState,
  action
) {
  switch (action.type) {
    case LOCATION_WISE_CUSTOMER_LOADING:
      return {
        ...state,
        getlocationwisecustomer: {
          ...state.getlocationwisecustomer,
          loading: true,
          error: false,
          errors: [],
          success: false,
        },
      };

    case LOCATION_WISE_CUSTOMER_SUCCESS:
      return {
        ...state,
        getlocationwisecustomer: {
          ...state.getlocationwisecustomer,
          loading: false,
          error: false,
          errors: [],
          success: true,
          data: action.response.data,
          pagination: action.pagination,
        },
      };

    case LOCATION_WISE_CUSTOMER_ERROR:
      return {
        ...state,
        getlocationwisecustomer: {
          ...state.getlocationwisecustomer,
          loading: false,
          error: true,
          errors: action.errors,
          success: false,
          data: null,
        },
      };

      case RESET_LOCATION_WISE_CUSTOMER:
        return {
          ...state,
          getlocationwisecustomer: {
            ...state.getlocationwisecustomer,
            loading: false,
            error: false,
            errors: [],
            success: true,
            data: [],
            pagination: action.pagination,
          },
        };
  

    default:
      return state;
  }
}
