import { ADD_AGENT_ERROR, ADD_AGENT_LOADING, ADD_AGENT_SUCCESS, DELETE_AGENT_ERROR, DELETE_AGENT_LOADING, DELETE_AGENT_SUCCESS, GET_AGENT_BY_ID_ERROR, GET_AGENT_BY_ID_LOADING, GET_AGENT_BY_ID_SUCCESS, GET_AGENT_ERROR, GET_AGENT_LOADING, GET_AGENT_SUCCESS, RESET_ADD_AGENT, RESET_DELETE_AGENT, RESET_GET_AGENT, RESET_GET_AGENT_BY_ID, RESET_UPDATE_AGENT, UPDATE_AGENT_ERROR, UPDATE_AGENT_LOADING, UPDATE_AGENT_SUCCESS } from "../../../Constants/Constants";

let initialState = {
  getAgentList: {
    loading: false,
    data: null,
    pagination: {},
    error: false,
    errors: null,
    success: false
  },
  getAgentById: {
    loading: false,
    data: null,
    error: false,
    errors: null,
    success: false
  },
  updateAgent: {
    loading: false,
    data: null,
    error: false,
    errors: null,
    success: false
  },
  deleteAgent: {
    loading: false,
    data: null,
    error: false,
    errors: null,
    success: false
  },
  addAgent: {
    loading: false,
    data: null,
    error: false,
    errors: null,
    success: false
  },
};

export default function DeliveryAgentReducer(state = initialState, action) {
  switch (action.type) {
    case GET_AGENT_LOADING:
      return { ...state, getAgentList: { ...state.getAgentList, loading: true, error: false, errors: [], success: false, pagination: {} } };

    case GET_AGENT_SUCCESS:
      return { ...state, getAgentList: { ...state.getAgentList, loading: false, error: false, errors: [], success: true, data: action.response.data, pagination: action.response.pagination } };

    case GET_AGENT_ERROR:
      return { ...state, getAgentList: { ...state.getAgentList, loading: false, error: true, errors: action.errors, success: false, data: null, pagination: {} } };

    case RESET_GET_AGENT:
      return { ...state, getAgentList: { ...state.getAgentList, loading: false, error: false, errors: null, success: false, pagination: {} } };


    //  add agent
    case ADD_AGENT_LOADING:
      return { ...state, addAgent: { ...state.addAgent, loading: true, error: false, errors: [], success: false } };

    case ADD_AGENT_SUCCESS:
      return { ...state, addAgent: { ...state.addAgent, loading: false, error: false, errors: [], success: true, data: action.response.data } };

    case ADD_AGENT_ERROR:
      return { ...state, addAgent: { ...state.addAgent, loading: false, error: true, errors: action.errors, success: false, data: null } };

    case RESET_ADD_AGENT:
      return { ...state, addAgent: { ...state.addAgent, loading: false, error: false, errors: null, success: false } };


    //  update Menu by id
    case UPDATE_AGENT_LOADING:
      return { ...state, updateAgent: { ...state.updateAgent, loading: true, error: false, errors: [], success: false } };

    case UPDATE_AGENT_SUCCESS:
      return { ...state, updateAgent: { ...state.updateAgent, loading: false, error: false, errors: [], success: true, data: action.response.data } };

    case UPDATE_AGENT_ERROR:
      return { ...state, updateAgent: { ...state.updateAgent, loading: false, error: true, errors: action.errors, success: false, data: null } };

    case RESET_UPDATE_AGENT:
      return { ...state, updateAgent: { ...state.updateAgent, loading: false, error: false, errors: null, success: false } };


    //  delete agent by id
    case DELETE_AGENT_LOADING:
      return { ...state, deleteAgent: { ...state.deleteAgent, loading: true, error: false, errors: [], success: false } };

    case DELETE_AGENT_SUCCESS:
      return { ...state, deleteAgent: { ...state.deleteAgent, loading: false, error: false, errors: [], success: true, data: action.response.data } };

    case DELETE_AGENT_ERROR:
      return { ...state, deleteAgent: { ...state.deleteAgent, loading: false, error: true, errors: action.errors, success: false, data: null } };

    case RESET_DELETE_AGENT:
      return { ...state, deleteAgent: { ...state.deleteAgent, loading: false, error: false, errors: null, success: false } };


    //  get agent by id 
    case GET_AGENT_BY_ID_LOADING:
      return { ...state, getAgentById: { ...state.getAgentById, loading: true, error: false, errors: [], success: false } };

    case GET_AGENT_BY_ID_SUCCESS:
      return { ...state, getAgentById: { ...state.getAgentById, loading: false, error: false, errors: [], success: true, data: action.response.data } };

    case GET_AGENT_BY_ID_ERROR:
      return { ...state, getAgentById: { ...state.getAgentById, loading: false, error: true, errors: action.errors, success: false, data: null } };

    case RESET_GET_AGENT_BY_ID:
      return { ...state, getAgentById: { ...state.getAgentById, loading: false, error: false, errors: null, success: false } };

    default:
      return state;
  }
}
