import { Pagination } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Form, Modal } from "react-bootstrap";
import { connect } from "react-redux";
import Spinner from "../../../Components/Spinner/Spinner";
import { getMenuItems } from "../../Services/Actions/menuAction";
import $ from "jquery";
import DataTable from "react-data-table-component";

const FilterComponent = ({ filterText, onFilter }) => (
  <>
    <div className="">
      <input
        id="search"
        type="text"
        className="form-control "
        placeholder="Search"
        aria-label="Search Input"
        value={filterText}
        onChange={onFilter}
        style={{ marginBottom: "30px", marginLeft: "-20px" }}
      />
    </div>
  </>
);

function AddItems(props) {
  const [loading, setLoading] = useState(true);
  const [items, setItems] = useState([]);

  const [addedProdect, setAddedProduct] = useState([]);

  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);

  const [notes, setNotes] = useState("");
  const [selectedItem, setSelectedItem] = useState("");
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    if (!props.getMenuItesmsReducer.success) {
      props.getMenuItems();
    }
  }, []);

  useEffect(() => {
    setLoading(props.getMenuItesmsReducer.loading);
    if (props.getMenuItesmsReducer.success) {
      const data = props.getMenuItesmsReducer.data.filter(
        (e) => parseInt(e.status) == 1
      );
      setItems([...data]);

      // find cheapst pizza

      // selected pizza
      const selectedItem = data.map((e) => {
        const addedItem = props.addedItem.find(
          (item) => item.menu_item_id === e._id || item?._id === e._id
        );
        return {
          id: e._id,
          quantity: addedItem === undefined ? 0 : parseInt(addedItem.quantity),
          ordernote: addedItem === undefined ? "" : addedItem.ordernote,
        };
      });
      if (selectedItem.length !== 0) {
        const filterData = selectedItem.filter(
          (e) => parseInt(e.status) === 1 && e.is_pizza === 1
        );
        if (filterData.length !== 0) {
          const cheapestItem = filterData?.reduce((a, b) => {
            return a.price < b.price ? a : b;
          });
          props.setChepestPizzaItem({ ...cheapestItem, quantity: 1 });
        } else {
          const cheapestItem = selectedItem[0];
          props.setChepestPizzaItem({ ...cheapestItem, quantity: 1 });
        }
      }
      setAddedProduct([...selectedItem]);
    }
  }, [props.getMenuItesmsReducer]);

  const handleAddItem = (id) => {
    const _item = addedProdect.map((e) => {
      if (e.id === id) {
        return {
          ...e,
          quantity: parseInt(e.quantity) + 1,
        };
      } else return e;
    });

    setAddedProduct([..._item]);
  };

  const handleinput = (id, value) => {
    const _item = addedProdect.map((e) => {
      if (e.id === id) {
        return {
          ...e,
          quantity: value !== "" ? parseInt(value) : 0,
        };
      } else return e;
    });

    setAddedProduct([..._item]);
  };

  const handleRemoveItem = (id) => {
    const _item = addedProdect.map((e) => {
      if (e.id === id && parseInt(e.quantity) > 0) {
        return {
          ...e,
          quantity: parseInt(e.quantity) - 1,
          ordernote: parseInt(e.quantity) - 1 === 0 ? "" : e.ordernote,
        };
      } else return e;
    });

    setAddedProduct([..._item]);
  };

  const handleChangePage = (event, value) => {
    props.getMenuItems({
      page: value,
      limit: 10,
    });
  };

  const handleAddNotes = (id) => {
    handleShow();
    setSelectedItem(id);
    const Order = addedProdect.find((e) => {
      return e.id === id;
    });
    const orederNote = !!Order.ordernote ? Order.ordernote : "";
    setNotes(orederNote);
  };
  const handleOrderNote = (e) => {
    setNotes(e.target.value);
  };

  const handleAddOrder = () => {
    const data = addedProdect
      .filter((e) => parseInt(e.quantity) > 0)
      .map((item) => {
        const _item = items.find((e) => e._id === item.id);
        return {
          ..._item,
          quantity: parseInt(item.quantity),
          ordernote: item.ordernote,
          isfree: "0",
          menu_item_id: item.id,
        };
      });
    props.setAddedItem([...data]);
    props.setIsAddItem(false);
  };

  const columns = [
    {
      name: "Itemname",
      selector: (row) => row.item_name,
      sortable: true,
    },
    {
      name: "Price",
      selector: (row) => row.price,
      sortable: true,
      width: "200px",
    },
    // {
    //   name: "Image",
    //   // selector: (row) => row.image,
    //   sortable: true,
    //   cell: (row) => (
    //     <img
    //       onError={imgDefault}
    //       src={row?.image}
    //       height="70px"
    //       width="90px"
    //       className=" m-3"
    //     />
    //   ),
    // },

    {
      name: "Action",
      selector: (row) => row.action,
      // width: "100px",
    },
  ];
  const imgDefault = (e) => {
    e.target.src = "/images/menu-placeholder.jpg";
    e.target.onerror = null;
  };
  const data = items?.map((data, i) => {
    return {
      id: data._id,
      image: data?.image ? data?.image : "N/A",
      item_name: data?.item_name ? data.item_name : "N/A",
      price: data?.price ? `${"$" + data?.price}` : "N/A",
      action: (
        <>
          <div className="d-flex">
            <button
              className="btn bg-red text-white py-1"
              style={{ width: "35px", height: "30px" }}
              onClick={() => handleRemoveItem(data._id)}
            >
              -
            </button>
            <input
              className="btn bg-red text-white py-1 mx-1"
              style={{ width: "35px", height: "30px" }}
              value={addedProdect?.find((e) => e.id === data._id)?.quantity}
              onChange={(e) => handleinput(data?._id, e.target.value)}
            />
            <button
              className="btn bg-red text-white py-1"
              style={{ width: "35px", height: "30px" }}
              onClick={() => handleAddItem(data._id)}
            >
              +
            </button>
            {parseInt(addedProdect.find((e) => e.id === data._id)?.quantity) >
              0 && (
              <button
                className="btn bg-red text-white py-1 px-3 ms-2"
                onClick={() => handleAddNotes(data._id)}
              >
                Add Note
              </button>
            )}
          </div>
        </>
      ),
    };
  });

  const filteredItems = data?.filter((item) =>
    item.item_name.toLowerCase().includes(filterText.toLowerCase())
  );

  const subHeaderComponentMemo = React.useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <FilterComponent
        onFilter={(e) => setFilterText(e.target.value)}
        onClear={handleClear}
        filterText={filterText}
      />
    );
  }, [filterText, resetPaginationToggle]);
  return (
    <>
      <Modal.Header closeButton>
        <Modal.Title>Add Item</Modal.Title>
      </Modal.Header>
      {loading ? (
        <Spinner />
      ) : (
        <Modal.Body style={{ height: "765px" }}>
          {" "}
          <div className="mt-3" style={{ overflowY: "auto" }}>
            <DataTable
              columns={columns}
              data={filteredItems}
              pagination
              subHeader
              subHeaderComponent={subHeaderComponentMemo}
            />
          </div>
        </Modal.Body>
      )}

      <Modal.Footer className="mt-3">
        {/* <div className='py-2 px-4 border rounded pointer bg-secondary text-white mx-2' onClick={handleCancel}>Cancel</div> */}
        <div
          className="py-2 px-4 border rounded pointer text-white bg-red mx-2"
          onClick={handleAddOrder}
        >
          Add Item
        </div>
      </Modal.Footer>

      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Order Note</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="col-12 mt-2">
            <Form.Group className="mb-3 ">
              <Form.Label>Note:</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Note"
                name="note"
                label="note"
                value={notes}
                onChange={handleOrderNote}
                id="note"
                as="textarea"
                rows={3}
              />
            </Form.Group>
          </div>
          <div className="d-flex justify-content-end">
            <div
              className="border rounded pointer px-4 py-2 text-white bg-red"
              onClick={() => {
                const Order = addedProdect.map((e) => {
                  if (e.id === selectedItem) {
                    return {
                      ...e,
                      ordernote: notes,
                    };
                  } else return e;
                });
                setAddedProduct(Order);
                handleClose();
                setSelectedItem("");
                setNotes("");
              }}
            >
              Save
            </div>
          </div>
        </Modal.Body>
        {/* <Modal.Footer className="mt-3 ">
        </Modal.Footer> */}
      </Modal>
    </>
  );
}

const mapStateToProp = (state) => ({
  getMenuItesmsReducer: state.MenuItemsReducer.getMenuItems,
});

const mapDispatchToProps = (dispatch) => ({
  getMenuItems: (details) => dispatch(getMenuItems(details)),
});

export default connect(mapStateToProp, mapDispatchToProps)(AddItems);
