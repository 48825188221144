import { ADMIN_EXPORT_PDF_ERROR, ADMIN_EXPORT_PDF_LOADING, ADMIN_EXPORT_PDF_SUCCESS, RESET_ADMIN_EXPORT_PDF } from "../../Admin/Constants/Constants";

  
  let initialState = {
    exportadminpdf: {
      loading: false,
      data: null,
      error: false,
      errors: null,
      success: false,
    },
  };
  
  export default function exportadminpdfReducer(state = initialState, action) {
    switch (action.type) {
      case ADMIN_EXPORT_PDF_LOADING:
        return {
          ...state,
          exportadminpdf: {
            ...state.exportadminpdf,
            loading: true,
            error: false,
            errors: [],
            success: false,
          },
        };
  
      case ADMIN_EXPORT_PDF_SUCCESS:
        return {
          ...state,
          exportadminpdf: {
            ...state.exportadminpdf,
            loading: false,
            error: false,
            errors: [],
            success: true,
          //   data: action.response,
          },
        };
  
      case ADMIN_EXPORT_PDF_ERROR:
        return {
          ...state,
          exportadminpdf: {
            ...state.exportadminpdf,
            loading: false,
            error: true,
            errors: action.errors,
            success: false,
            data: null,
          },
        };
  
      case RESET_ADMIN_EXPORT_PDF:
        return {
          ...state,
          exportadminpdf: {
            ...state.exportadminpdf,
            loading: false,
            error: false,
            errors: null,
            success: false,
          },
        };
  
      default:
        return state;
    }
  }
  