import {
  AGENTSWISE_ORDER_ERROR,
  AGENTSWISE_ORDER_LOADING,
  AGENTSWISE_ORDER_SUCCESS,
  RESET_AGENTSWISE_ORDER,
} from "../type/Constant";

let initialState = {
  getagentsByOrder: {
    loading: false,
    data: null,
    error: false,
    errors: null,
    success: false,
    pagination: {},
  },
};

export default function getagentsByOrderReducer(state = initialState, action) {
  switch (action.type) {
    case AGENTSWISE_ORDER_LOADING:
      return {
        ...state,
        getagentsByOrder: {
          ...state.getagentsByOrder,
          loading: true,
          error: false,
          errors: [],
          success: false,
        },
      };

    case AGENTSWISE_ORDER_SUCCESS:
      return {
        ...state,
        getagentsByOrder: {
          ...state.getagentsByOrder,
          loading: false,
          error: false,
          errors: [],
          success: true,
          data: action.response.data,
          pagination: action.pagination,
        },
      };

    case AGENTSWISE_ORDER_ERROR:
      return {
        ...state,
        getagentsByOrder: {
          ...state.getagentsByOrder,
          loading: false,
          error: true,
          errors: action.errors,
          success: false,
          data: null,
        },
      };

    case RESET_AGENTSWISE_ORDER:
      return {
        ...state,
        getagentsByOrder: {
          ...state.getagentsByOrder,
          loading: false,
          error: false,
          errors: false,
          success: false,
          data: null,
        },
      };

    default:
      return state;
  }
}
