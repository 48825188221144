import { GET_MENU_BY_ID_DATA_LOADING, GET_MENU_BY_ID_DATA_SUCCESS, GET_MENU_BY_ID__DATA_ERROR, RESET_GET_MENU_BY_ID_ } from "../type/Constant";

let initialState = {
  getMenuById: {
    loading: false,
    data: null,
    error: false,
    errors: null,
    success: false
  },
};

export default function getMenuByIdReducer(state = initialState, action) {
  switch (action.type) {
   
    case GET_MENU_BY_ID_DATA_LOADING:
      return { ...state, getMenuById: { ...state.getMenuById, loading: true, error: false, errors: [], success: false } };

    case GET_MENU_BY_ID_DATA_SUCCESS:
      return { ...state, getMenuById: { ...state.getMenuById, loading: false, error: false, errors: [], success: true, data: action.response.data } };

    case GET_MENU_BY_ID__DATA_ERROR:
      return { ...state, getMenuById: { ...state.getMenuById, loading: false, error: true, errors: action.errors, success: false, data: null } };

    case RESET_GET_MENU_BY_ID_:
      return { ...state, getMenuById: { ...state.getMenuById, loading: false, error: false, errors: null, success: false } }

    default:
      return state;
  }
}
