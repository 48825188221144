import {
  ADMIN_AGENT_WISE_ORDER_ERROR,
  ADMIN_AGENT_WISE_ORDER_SUCCESS,
  RESET_ADMIN_AGENT_WISE_ORDER,
} from "../../Admin/Constants/Constants";
import {
  ADD_SUPER_ADMIN_ORDER_ERROR,
  ADD_SUPER_ADMIN_ORDER_LOADING,
  ADD_SUPER_ADMIN_ORDER_SUCCESS,
  DELETE_SUPER_ADMIN_ORDER_ERROR,
  DELETE_SUPER_ADMIN_ORDER_LOADING,
  DELETE_SUPER_ADMIN_ORDER_SUCCESS,
  GET_SUPERADMIN_ORDER_FILTER_ERROR,
  GET_SUPERADMIN_ORDER_FILTER_LOADING,
  GET_SUPERADMIN_ORDER_FILTER_SUCCESS,
  GET_SUPER_ADMIN_ORDER_BY_ID_ERROR,
  GET_SUPER_ADMIN_ORDER_BY_ID_LOADING,
  GET_SUPER_ADMIN_ORDER_BY_ID_SUCCESS,
  GET_SUPER_ADMIN_ORDER_ERROR,
  GET_SUPER_ADMIN_ORDER_LOADING,
  GET_SUPER_ADMIN_ORDER_SUCCESS,
  RESET_ADD_SUPER_ADMIN_ORDER,
  RESET_DELETE_SUPER_ADMIN_ORDER,
  RESET_GET_SUPERADMIN_ORDER_FILTER,
  RESET_GET_SUPER_ADMIN_ORDER,
  RESET_GET_SUPER_ADMIN_ORDER_BY_ID,
  RESET_UPDATE_SUPER_ADMIN_ORDER,
  UPDATE_SUPER_ADMIN_ORDER_ERROR,
  UPDATE_SUPER_ADMIN_ORDER_LOADING,
  UPDATE_SUPER_ADMIN_ORDER_SUCCESS,
} from "../type/Constant";

let initialState = {
  getOrder: {
    loading: false,
    data: null,
    error: false,
    errors: null,
    success: false,
    pagination: {},
  },
  getOrderById: {
    loading: false,
    data: null,
    error: false,
    errors: null,
    success: false,
  },
  updateOrder: {
    loading: false,
    data: null,
    error: false,
    errors: null,
    success: false,
  },
  deleteOrder: {
    loading: false,
    data: null,
    error: false,
    errors: null,
    success: false,
  },
  addOrder: {
    loading: false,
    data: null,
    error: false,
    errors: null,
    success: false,
  },
  orderfilterSuperadmin: {
    loading: false,
    data: null,
    error: false,
    errors: null,
    success: false,
  },
};

export default function SuperAdminOrderReducer(state = initialState, action) {
  switch (action.type) {
    case GET_SUPER_ADMIN_ORDER_LOADING:
      return {
        ...state,
        getOrder: {
          ...state.getOrder,
          loading: true,
          error: false,
          errors: [],
          success: false,
        },
      };

    case GET_SUPER_ADMIN_ORDER_SUCCESS:
      return {
        ...state,
        getOrder: {
          ...state.getOrder,
          loading: false,
          error: false,
          errors: [],
          success: true,
          data: action.response.data,
          pagination: action.pagination,
        },
      };

    case GET_SUPER_ADMIN_ORDER_ERROR:
      return {
        ...state,
        getOrder: {
          ...state.getOrder,
          loading: false,
          error: true,
          errors: action.errors,
          success: false,
          data: null,
        },
      };

    case RESET_GET_SUPER_ADMIN_ORDER:
      return {
        ...state,
        getOrder: {
          ...state.getOrder,
          loading: false,
          error: false,
          errors: null,
          success: false,
        },
      };

    //  get Menu by id
    case GET_SUPER_ADMIN_ORDER_BY_ID_LOADING:
      return {
        ...state,
        getOrderById: {
          ...state.getOrderById,
          loading: true,
          error: false,
          errors: [],
          success: false,
        },
      };

    case GET_SUPER_ADMIN_ORDER_BY_ID_SUCCESS:
      return {
        ...state,
        getOrderById: {
          ...state.getOrderById,
          loading: false,
          error: false,
          errors: [],
          success: true,
          data: action.response.data,
        },
      };

    case GET_SUPER_ADMIN_ORDER_BY_ID_ERROR:
      return {
        ...state,
        getOrderById: {
          ...state.getOrderById,
          loading: false,
          error: true,
          errors: action.errors,
          success: false,
          data: null,
        },
      };

    case RESET_GET_SUPER_ADMIN_ORDER_BY_ID:
      return {
        ...state,
        getOrderById: {
          ...state.getOrderById,
          loading: false,
          error: false,
          errors: null,
          success: false,
        },
      };

    //  update Menu by id
    case UPDATE_SUPER_ADMIN_ORDER_LOADING:
      return {
        ...state,
        updateOrder: {
          ...state.updateOrder,
          loading: true,
          error: false,
          errors: [],
          success: false,
        },
      };

    case UPDATE_SUPER_ADMIN_ORDER_SUCCESS:
      return {
        ...state,
        updateOrder: {
          ...state.updateOrder,
          loading: false,
          error: false,
          errors: [],
          success: true,
          data: action.response.data,
        },
      };

    case UPDATE_SUPER_ADMIN_ORDER_ERROR:
      return {
        ...state,
        updateOrder: {
          ...state.updateOrder,
          loading: false,
          error: true,
          errors: action.errors,
          success: false,
          data: null,
        },
      };

    case RESET_UPDATE_SUPER_ADMIN_ORDER:
      return {
        ...state,
        updateOrder: {
          ...state.updateOrder,
          loading: false,
          error: false,
          errors: null,
          success: false,
        },
      };

    //  delete Menu by id
    case DELETE_SUPER_ADMIN_ORDER_LOADING:
      return {
        ...state,
        deleteOrder: {
          ...state.deleteOrder,
          loading: true,
          error: false,
          errors: [],
          success: false,
        },
      };

    case DELETE_SUPER_ADMIN_ORDER_SUCCESS:
      return {
        ...state,
        deleteOrder: {
          ...state.deleteOrder,
          loading: false,
          error: false,
          errors: [],
          success: true,
          data: action.response.data,
        },
      };

    case DELETE_SUPER_ADMIN_ORDER_ERROR:
      return {
        ...state,
        deleteOrder: {
          ...state.deleteOrder,
          loading: false,
          error: true,
          errors: action.errors,
          success: false,
          data: null,
        },
      };

    case RESET_DELETE_SUPER_ADMIN_ORDER:
      return {
        ...state,
        deleteOrder: {
          ...state.deleteOrder,
          loading: false,
          error: false,
          errors: null,
          success: false,
        },
      };

    //  add menu
    case ADD_SUPER_ADMIN_ORDER_LOADING:
      return {
        ...state,
        addOrder: {
          ...state.addOrder,
          loading: true,
          error: false,
          errors: [],
          success: false,
        },
      };

    case ADD_SUPER_ADMIN_ORDER_SUCCESS:
      return {
        ...state,
        addOrder: {
          ...state.addOrder,
          loading: false,
          error: false,
          errors: [],
          success: true,
          data: action.response.data,
        },
      };

    case ADD_SUPER_ADMIN_ORDER_ERROR:
      return {
        ...state,
        addOrder: {
          ...state.addOrder,
          loading: false,
          error: true,
          errors: action.errors,
          success: false,
          data: null,
        },
      };

    case RESET_ADD_SUPER_ADMIN_ORDER:
      return {
        ...state,
        addOrder: {
          ...state.addOrder,
          loading: false,
          error: false,
          errors: null,
          success: false,
        },
      };

    // ......................filter data
    case GET_SUPERADMIN_ORDER_FILTER_LOADING:
      return {
        ...state,
        orderfilterSuperadmin: {
          ...state.orderfilterSuperadmin,
          loading: true,
          error: false,
          errors: [],
          success: false,
        },
      };

    case GET_SUPERADMIN_ORDER_FILTER_SUCCESS:
      return {
        ...state,
        orderfilterSuperadmin: {
          ...state.orderfilterSuperadmin,
          loading: false,
          error: false,
          errors: [],
          success: true,
          data: action.response.data,
          fullData: action?.response?.fullData,
        },
      };

    case GET_SUPERADMIN_ORDER_FILTER_ERROR:
      return {
        ...state,
        orderfilterSuperadmin: {
          ...state.orderfilterSuperadmin,
          loading: false,
          error: true,
          errors: action.errors,
          success: false,
          data: null,
        },
      };

    case RESET_GET_SUPERADMIN_ORDER_FILTER:
      return {
        ...state,
        orderfilterSuperadmin: {
          ...state.orderfilterSuperadmin,
          loading: false,
          error: false,
          errors: null,
          success: false,
        },
      };

    default:
      return state;
  }
}
