import React, { useEffect, useState } from "react";
import { Button, Form, Modal, Table } from "react-bootstrap";
import { connect, useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import {
  createDeliveryAgentsDataAction,
  deleteDeliveryAgentsData,
  getAgentsData,
  getDeliveryAgentsById,
  getDeliveryAgentsDataAction,
  getLocationByAgentsDataAction,
  getLocationWiseAgentsDataAction,
  updateDeliveryAgentsByIdDataAction,
} from "../../../Store/Action/CreateAgents/UserAction";
import {
  contactValidation,
  countryCodeValidation,
  formatPhoneNumber,
  RESET_ADD_DELIVERY_DATA,
  RESET_DELETE_DELIVERY_AGENTS_DATA,
  RESET_UPDATE_DELIVERY_AGENTS_DATA_SUCCESS,
  Us_Pattern_Number,
  validEmailRegex,
  validPassword,
} from "../../../Store/type/Constant";
import DeletePopUp from "../DeletePopup";
import Footer from "../Userlayout/Footer";
import Header from "../Userlayout/Header";
import EditDeliveryAgents from "./EditDeliveryAgents";
import classNames from "classnames";
import { Pagination } from "@mui/material";
import { toast } from "react-toastify";
import Toaster from "../../../Components/Toaster/Toaster";
import DataTable from "react-data-table-component";
import Spinner from "../../../Components/Spinner/Spinner";
import AgentDetails from "./AgentDetails";
import MaskedInput from "react-text-mask";
import { Loader } from "rsuite";

const FilterComponent = ({ filterText, onFilter, onClear }) => (
  <>
    <div className="form-outline order-search">
      <label className="form-label me-2 mb-0" htmlFor="form1">
        {/* Search : */}
      </label>
      <input
        id="search"
        type="text"
        className="form-control"
        placeholder="Search"
        aria-label="Search Input"
        value={filterText}
        onChange={onFilter}
      />
    </div>
    {/* <ClearButton type="button" onClick={onClear}>
      X
    </ClearButton> */}
  </>
);

const DeliveryAgent = (props) => {
  const [show, setShow] = useState(false);
  const [loading, setloading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [onsubmiteloading, setonsubmiteloading] = useState(false);

  const [visible, setvisible] = useState(false);
  const [Confirmvisible, setConfirmvisible] = useState(false);

  const [deleteShow, setdeleteShow] = useState(false);

  const handledeleteShow = () => setdeleteShow(true);
  const handledeleteclose = () => setdeleteShow(false);
  const [btnLoading, setbtnLoading] = useState(false);

  const [DeliveryAgentsId, setDeliveryAgentsId] = useState({});

  const [SelectedAgentDetailsId, setSelectedAgentDetailsId] = useState();

  const [deliveryAgentsApiData, setdeliveryAgentsApiData] = useState({
    name: "",
    location: "",
    email: "",
    password: "",
    number: "",
    confirmpassword: "",
    status: "active",
  });

  const [EditShow, setEditShow] = useState(false);
  const [EditShow1, setEditShow1] = useState(false);

  const [deliveryAgentsList, setdeliveryAgentsList] = useState([]);
  const [location, setlocation] = useState("");

  const notifyError = (msg) => toast.error(msg);

  const [errors, setErrors] = useState({
    name: false,
    location: false,
    email: false,
    password: false,
    number: false,
    confirmpassword: false,
  });

  const [passwordCheck, setpasswordCheck] = useState({
    spacialcharacter: false,
    capitalLatter: false,
    smallLatter: false,
    passwordNumber: false,
    length: false,
  });

  const handleClose = () => {
    setdeliveryAgentsApiData({
      name: "",
      location: "",
      email: "",
      password: "",
      number: "",
      confirmpassword: "",
      status: "active",
    });
    setErrors({
      name: false,
      location: false,
      email: false,
      password: false,
      number: false,
      confirmpassword: false,
    });
    setpasswordCheck({
      spacialcharacter: false,
      capitalLatter: false,
      smallLatter: false,
      passwordNumber: false,
      length: false,
    });
    setShow(false);
  };

  // useEffect(() => {
  //   props.updateDeliveryAgentsByIdDataAction();
  // }, []);

  useEffect(() => {
    if (props.getDeliveryAgentsReducer.loading) {
      setloading(true);
    }
  }, [props.getDeliveryAgentsReducer]);

  const handleShow = () => setShow(true);

  const [isDelete, setIsDelete] = useState(false);
  const [deleteId, setDeleteId] = useState(null);

  const successMsg = (msg) => toast.success(msg);

  const [apiData, setapiData] = useState([]);

  const { user } = useSelector((state) => state);

  const allDeliveryAgents = useSelector(
    (state) => state.getDeliveryAgentsReducer.user.data
  );

  const allLocationData = useSelector(
    (state) => state.getAgentsReducer.user.data
  );
  const LocationByAgents = useSelector(
    (state) => state.getLocationByAgents.data
  );

  const LocationWiseagents = useSelector(
    (state) => state.getLocationWiseAgents.getLocationWiseAgents.data
  );

  useEffect(() => {
    if (location === "all" || location === "") {
      props.getDeliveryAgentsDataAction({ id: location });
    }
  }, [location]);
  useEffect(() => {
    if (props.createDeliveryAgentsReducer.success) {
      setTimeout(() => {
        successMsg("Delivery agent is created successfully. ");
        props.getDeliveryAgentsDataAction({ id: location });
      }, 100);
      props.ResetDeliveryAgents();
      handleClose();
      setonsubmiteloading(false);
      setbtnLoading(false);
    } else if (props.createDeliveryAgentsReducer?.error) {
      setbtnLoading(false);
      setonsubmiteloading(false);
      notifyError(props.createDeliveryAgentsReducer.errors);
    }
  }, [props.createDeliveryAgentsReducer]);

  useEffect(() => {
    if (!!location && location !== "" && location !== "all") {
      dispatch(getLocationWiseAgentsDataAction(location));
    } else if (location == "all") {
      setdeliveryAgentsList([...allDeliveryAgents]);
    }
  }, [location]);

  useEffect(() => {
    if (!!LocationWiseagents && location !== "all") {
      setdeliveryAgentsList([...LocationWiseagents]);
    }
  }, [LocationWiseagents]);

  useEffect(() => {
    if (props.getDeliveryAgentsReducer?.success) {
      const data = props.getDeliveryAgentsReducer.user.data;
      setdeliveryAgentsList(data);
    }
  }, [props.getDeliveryAgentsReducer]);

  useEffect(() => {
    if (props.DeleteDeliveryAgentsReducer.success) {
      setlocation("all");
      setTimeout(() => {
        successMsg("Delivery agent is deleted successfully. ");
        props.ResetdeleteDeliveryAgents();
        props.getDeliveryAgentsDataAction({ id: location });
      }, 100);
    }
  }, [props.DeleteDeliveryAgentsReducer]);

  useEffect(() => {
    if (props.DeleteDeliveryAgentsReducer?.error) {
      notifyError(props.DeleteDeliveryAgentsReducer.errors);
      props.ResetdeleteDeliveryAgents();
    }
  }, [props.DeleteDeliveryAgentsReducer]);

  useEffect(() => {
    if (props.editDeliveryAgentsReducer?.success) {
      setTimeout(() => {
        successMsg("Delivery agent is updated successfully. ");
      }, 100);
      setTimeout(() => {
        props.ResetupdateDeliveryAgents();
        props.getDeliveryAgentsDataAction({ id: location });
      }, 100);
      setbtnLoading(false);
      setEditShow(false);
    }
    // props.ResetupdateDeliveryAgents()
  }, [props.editDeliveryAgentsReducer]);

  useEffect(() => {
    dispatch(
      getAgentsData({
        page: 1,
        limit: 10,
      })
    );
  }, []);

  useEffect(() => {
    setapiData(allLocationData);
  }, [allLocationData]);

  var res = deliveryAgentsApiData?.number
    ?.replace(/\D+/g, "")
    .replace(/^(\d{3})(\d{3})(\d{4}).*/, "$1$2$3");

  const onChangeInput = (e) => {
    // setdeliveryAgentsApiData({
    //   ...deliveryAgentsApiData,
    //   [e.target.name]: e.target.value,
    // });

    if (e.target.name === "number") {
      if (e.target.value.length <= 14) {
        setdeliveryAgentsApiData((prevState) => ({
          ...prevState,
          [e.target.name]: e.target.value.replace(
            /^(\([0-9]{3}\) |[0-9]{3}-)[0-9]{3}-[0-9]{4}$/
          ),
        }));
      }
    }
    setdeliveryAgentsApiData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));

    if (e.target.name === "password") {
      if (/[A-Z]/.test(e.target.value)) {
        setpasswordCheck((prevState) => ({
          ...prevState,
          capitalLatter: "Capital Latter",
        }));
      } else {
        setpasswordCheck((prevState) => ({
          ...prevState,
          capitalLatter: false,
        }));
      }
      if (/[a-z]/.test(e.target.value)) {
        setpasswordCheck((prevState) => ({
          ...prevState,
          smallLatter: "Small Latter",
        }));
      } else {
        setpasswordCheck((prevState) => ({
          ...prevState,
          smallLatter: false,
        }));
      }
      if (/[0-9]/.test(e.target.value)) {
        setpasswordCheck((prevState) => ({
          ...prevState,
          passwordNumber: "Number",
        }));
      } else {
        setpasswordCheck((prevState) => ({
          ...prevState,
          passwordNumber: false,
        }));
      }
      if (e.target.value.length >= 8) {
        setpasswordCheck((prevState) => ({
          ...prevState,
          length: "length",
        }));
      } else {
        setpasswordCheck((prevState) => ({
          ...prevState,
          length: false,
        }));
      }
      if (/[$&+,:;=?@#|'<>.^*()%!-]/.test(e.target.value)) {
        setpasswordCheck((prevState) => ({
          ...prevState,
          spacialcharacter: "Spacial Character",
        }));
      } else {
        setpasswordCheck((prevState) => ({
          ...prevState,
          spacialcharacter: false,
        }));
      }
    }

    switch (e.target.name) {
      case "name":
        if (e.target.value == "") {
          setErrors((prevState) => ({
            ...prevState,
            name: "name can not be empty",
          }));

          // document.getElementById("name").classList.add("error");
        } else {
          setErrors((prevState) => ({
            ...prevState,
            name: false,
          }));

          // document.getElementById("name").classList.remove("error");
        }
        break;

      case "email":
        if (e.target.value === "") {
          setErrors((prevState) => ({
            ...prevState,
            email: "Location Email can not be empty",
          }));
        } else if (!validEmailRegex.test(e.target.value)) {
          setErrors((prevState) => ({
            ...prevState,
            email: "Enter valid email",
          }));
          // document.getElementById("email").classList.add("error");
        } else {
          setErrors((prevState) => ({
            ...prevState,
            email: false,
          }));
        }
        break;

      case "password":
        if (e.target.value.length == 0) {
          setErrors((prevState) => ({
            ...prevState,
            password: "Password can not be empty",
          }));
        } else if (!validPassword.test(e.target.value)) {
          setErrors((prevState) => ({
            ...prevState,
            password: "Enter Valid password",
          }));
        } else if (
          deliveryAgentsApiData.password ===
          deliveryAgentsApiData.confirmpassword
        ) {
          setErrors((prevState) => ({
            ...prevState,
            confirmpassword: "confirmpassword can not be match",
            password: "confirmpassword can not be match",
          }));
        } else {
          setErrors((prevState) => ({
            ...prevState,
            password: false,
            confirmpassword: false,
          }));
        }
        break;

      case "confirmpassword":
        if (e.target.value.length == 0) {
          setErrors((prevState) => ({
            ...prevState,
            confirmpassword: "confirmpassword can not be empty",
          }));
        } else if (
          deliveryAgentsApiData.password ===
          deliveryAgentsApiData.confirmpassword
        ) {
          setErrors((prevState) => ({
            ...prevState,
            confirmpassword: "confirmpassword can not be match",
            password: "confirmpassword can not be match",
          }));
        } else {
          setErrors((prevState) => ({
            ...prevState,
            confirmpassword: false,
            password: false,
          }));

          // document.getElementById("confirmpassword").classList.remove("error");
        }
        break;

      case "number":
        if (e.target.value.length == 0) {
          setErrors((prevState) => ({
            ...prevState,
            number: "number can not be empty",
          }));
        } else if (!Us_Pattern_Number.test(e.target.value)) {
          setErrors((prevState) => ({
            ...prevState,
            number: "Enter valid number",
          }));
        } else {
          setErrors((prevState) => ({
            ...prevState,
            number: false,
          }));
        }
        break;

      case "countrycode":
        if (e.target.value.length == 0) {
          setErrors((prevState) => ({
            ...prevState,
            countrycode: "countrycode can not be empty",
          }));

          // document.getElementById("countryCode").classList.add("error");
        } else if (!countryCodeValidation.test(e.target.value)) {
          setErrors((prevState) => ({
            ...prevState,
            countrycode: "countrycode can not be match",
          }));
        } else {
          setErrors((prevState) => ({
            ...prevState,
            countrycode: false,
          }));

          // document.getElementById("countrycode").classList.remove("error");
        }
        break;

      case "location":
        if (e.target.value.length == 0) {
          setErrors((prevState) => ({
            ...prevState,
            location: "location can not be empty",
          }));
        } else {
          setErrors((prevState) => ({
            ...prevState,
            location: false,
          }));
        }
        break;

      default:
    }
  };

  const onSubmite = (e) => {
    e.preventDefault();
    if (!onsubmiteloading) {
      if (deliveryAgentsApiData.location == "") {
        setErrors((prevState) => ({
          ...prevState,
          location: "location can not be empty",
        }));
        // document.getElementById("location").classList.add("error");
      }

      if (deliveryAgentsApiData.name == "") {
        setErrors((prevState) => ({
          ...prevState,
          name: "name can not be empty",
        }));
        // document.getElementById("name").classList.add("error");
      }

      if (deliveryAgentsApiData.number == "") {
        setErrors((prevState) => ({
          ...prevState,
          number: "number can not be empty",
        }));
      } else if (!Us_Pattern_Number.test(deliveryAgentsApiData.number)) {
        setErrors((prevState) => ({
          ...prevState,
          number: "Enter valid number",
        }));
      } else {
        setErrors((prevState) => ({
          ...prevState,
          number: false,
        }));
      }
      if (deliveryAgentsApiData.email == "") {
        setErrors((prevState) => ({
          ...prevState,
          email: "email can not be empty",
        }));
        // document.getElementById("email").classList.add("error");
      }

      if (deliveryAgentsApiData.password == "") {
        setErrors((prevState) => ({
          ...prevState,
          password: "password can not be empty",
        }));
        // document.getElementById("password").classList.add("error");
      }

      if (deliveryAgentsApiData.confirmpassword == "") {
        setErrors((prevState) => ({
          ...prevState,
          confirmpassword: "confirmpassword can not be empty",
        }));
        // document.getElementById("confirmpassword").classList.add("error");
      }

      if (
        deliveryAgentsApiData.password !== deliveryAgentsApiData.confirmpassword
      ) {
        setErrors((prevState) => ({
          ...prevState,
          confirmpassword: "password cant not match",
          password: "password cant not match",
        }));
      }

      if (deliveryAgentsApiData.countrycode === "") {
        setErrors((prevState) => ({
          ...prevState,
          countrycode: "countrycode can not be empty",
        }));
      }

      if (
        deliveryAgentsApiData.name !== "" &&
        deliveryAgentsApiData.email !== "" &&
        deliveryAgentsApiData.password !== "" &&
        !errors.password &&
        !errors.number &&
        deliveryAgentsApiData.number !== "" &&
        deliveryAgentsApiData.location !== "" &&
        deliveryAgentsApiData.confirmpassword !== "" &&
        deliveryAgentsApiData.confirmpassword == deliveryAgentsApiData.password
      ) {
        dispatch(
          createDeliveryAgentsDataAction({
            name: deliveryAgentsApiData.name,
            location: deliveryAgentsApiData.location,
            email: deliveryAgentsApiData.email,
            password: deliveryAgentsApiData.password,
            number: res,
            confirmpassword: deliveryAgentsApiData.confirmpassword,
            status: deliveryAgentsApiData.status === "active" ? 1 : 0,
            type: "1",
          })
        );
        setonsubmiteloading(true);
        setbtnLoading(true);
      }
    } else return;
  };

  const columns = [
    {
      name: "Name",
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: "Location Name",
      selector: (row) => row.location,
      sortable: true,
    },
    {
      name: "Contact No.",
      selector: (row) => row.number,
      sortable: true,
      // accept: "image/*",
    },
    {
      name: "Email Address",
      selector: (row) => row.email,
      // width: "300px",
    },
    {
      name: "Status",
      selector: (row) => row.status,
      // width: "110px",
    },
    {
      name: "Action",
      selector: (row) => row.action,
      width: "150px",
    },
  ];

  const data = deliveryAgentsList?.map((data, i) => {
    return {
      id: data._id,
      name: data?.name ? data?.name : "N/A",
      location_name: data?.locationdata[0]?.location_name
        ? data?.locationdata[0]?.location_name
        : "N/A",
      location: data?.locationdata[0]?.location_name
        ? data?.locationdata[0]?.location_name
        : "N/A",
      number: data?.number ? formatPhoneNumber(data?.number) : "N/A",
      email: data?.email ? data?.email : "N/A",
      status:
        data?.status === 1 ? (
          <span className="badge bg-dark" style={{ width: "67px" }}>
            Active
          </span>
        ) : (
          <span className="badge bg-red" style={{ width: "67px" }}>
            InActive
          </span>
        ),
      action: (
        <>
          <button
            className="btn p-0"
            onClick={() => {
              setEditShow1(true);
              setSelectedAgentDetailsId(data?._id);
            }}
          >
            <i className="fa fa-eye fs-4 me-2 pointer" aria-hidden="true"></i>
          </button>
          <button
            className="btn p-0"
            onClick={() => {
              setEditShow(true);
              setDeliveryAgentsId(data?._id);
            }}
          >
            <i
              className="fa fa-pencil-square-o me-2 pointer fs-4"
              aria-hidden="true"
            ></i>
          </button>
          <button
            className="btn p-0"
            onClick={() => {
              //   onDelete(v._id);
              handledeleteShow();
              setIsDelete(true);
              setDeleteId(data?._id);
            }}
          >
            <i className="fa fa-trash text-danger fs-4" aria-hidden="true"></i>
          </button>
        </>
      ),
    };
  });

  const handledelete = () => {
    dispatch(deleteDeliveryAgentsData(deleteId));
    handledeleteclose();
  };

  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const filteredItems = data?.filter(
    (item) =>
      (item.location &&
        item.location.toLowerCase().includes(filterText.toLowerCase())) ||
      (item.name &&
        item.name.toLowerCase().includes(filterText.toLowerCase())) ||
      (item.email &&
        item.email.toLowerCase().includes(filterText.toLowerCase()))
  );

  const subHeaderComponentMemo = React.useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <FilterComponent
        onFilter={(e) => setFilterText(e.target.value)}
        onClear={handleClear}
        filterText={filterText}
      />
    );
  }, [filterText, resetPaginationToggle]);

  const passwordvisible = () => {
    setvisible(!visible);
  };
  const Confirmpasswordvisible = () => {
    setConfirmvisible(!Confirmvisible);
  };

  return (
    <>
      <Header />
      <main className="userMain">
        <div className="content-block">
          <div className="d-flex justify-content-between align-items-center mb-4">
            <div className="d-flex justify-content-center align-items-center">
              <Link to={"/"} className="text-dark">
                <i className="fa fa-house fs-2 me-3"></i>
              </Link>
              <h3 className="me-3">/</h3>
              <h3 className="fw-semibold">Drivers</h3>
            </div>
            <div
              className="border rounded pointer px-4 py-2 text-white bg-red"
              onClick={() => {
                handleShow();
                // setdeliveryAgentsApiData({
                //   status: "active",
                // });
              }}
            >
              Create
            </div>
          </div>
          <div className="form-group event-drp col-3 mb-1">
            <Form.Label>Location:</Form.Label>
            <select
              id="location"
              name="location"
              value={location}
              onChange={(e) => setlocation(e.target.value)}
              className="p-2 form-select"
            >
              <option value="" className="d-none">
                Select Location
              </option>
              <option value="all" className="">
                {`${"All Location (Drivers)"}`}
              </option>
              {allLocationData?.map((v, i) => {
                return (
                  <option value={v?._id} key={i}>
                    {v.location_name}
                  </option>
                );
              })}
            </select>
          </div>
          <div className="d-flex justify-content-end">
            <Modal
              show={show}
              onHide={handleClose}
              backdrop="static"
              size="lg"
              keyboard={false}
            >
              <Modal.Header closeButton>
                <Modal.Title>Create Driver</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="form-area">
                  <Form className="row" onSubmit={onSubmite}>
                    <div className="col-md-6">
                      <h6 className="payment">Location:</h6>
                      <div className="form-group event-drp ">
                        <select
                          // className={classNames("form-control team-name payterm", {
                          //   error: errors.location,
                          // })}
                          id="location"
                          name="location"
                          onChange={onChangeInput}
                          className={classNames("form-select mt-1", {
                            requireField: errors.location,
                          })}
                        >
                          <option value="" className="d-none">
                            Select Location *
                          </option>
                          {allLocationData?.map((v, i) => {
                            return (
                              <option key={i} value={v._id}>
                                {v?.location_name}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    </div>
                    <div className="col-6">
                      <Form.Group className="mb-3">
                        <Form.Label>Name:</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter Driver Name"
                          name="name"
                          label="name"
                          autoComplete="new-password"
                          onChange={onChangeInput}
                          value={deliveryAgentsApiData?.name}
                          id="name"
                          className={classNames({
                            requireField: errors.name,
                          })}
                        />
                      </Form.Group>
                    </div>
                    <div className="col-6">
                      {" "}
                      <Form.Group className="mb-3">
                        <Form.Label>Email Address: </Form.Label>
                        <Form.Control
                          type="email"
                          placeholder="Enter Email Address"
                          name="email"
                          label="email"
                          autoComplete="new-password"
                          onChange={onChangeInput}
                          value={deliveryAgentsApiData?.email}
                          id="email"
                          className={classNames({
                            requireField: errors.email,
                          })}
                        />
                      </Form.Group>
                    </div>
                    <div className="col-6">
                      {/* <Form.Group className="mb-3 ">
                        <Form.Control
                        type="number"
                        placeholder="Enter Contact No."
                          name="number"
                          label="number"
                          value={deliveryAgentsApiData?.number}
                          maxLength={10}
                          onChange={onChangeInput}
                          id="number"
                          className={classNames({
                            requireField: errors.number,
                          })}
                          />
                        </Form.Group> */}
                      <Form.Label>Contact No:</Form.Label>
                      <MaskedInput
                        mask={[
                          "(",
                          /[0-9]/,
                          /[0-9]/,
                          /[0-9]/,
                          ")",
                          " ",
                          /[0-9]/,
                          /[0-9]/,
                          /[0-9]/,
                          "-",
                          /[0-9]/,
                          /[0-9]/,
                          /[0-9]/,
                          /[0-9]/,
                        ]}
                        // type="number"
                        name="number"
                        placeholder="Enter Contact No."
                        guide={false}
                        autoComplete="new-password"
                        id="my-input-id number"
                        value={deliveryAgentsApiData?.number}
                        onChange={onChangeInput}
                        className={classNames("form-control mb-3", {
                          requireField: errors.number,
                        })}
                      />
                    </div>
                    {/* <div className="row"> */}
                    <Form.Group className="mb-4 col-6">
                      <Form.Label style={{ fontWeight: "bold" }}>
                        Password:
                      </Form.Label>
                      <div className="password-field">
                        <Form.Control
                          name="password"
                          type={!!visible ? "text" : "password"}
                          placeholder="Enter Password"
                          autoComplete="new-password"
                          value={deliveryAgentsApiData.password}
                          onChange={onChangeInput}
                          className={classNames({
                            requireField: errors.password,
                          })}
                        />
                        <div
                          className="form-control-eye-icon pointer"
                          onClick={passwordvisible}
                        >
                          {visible ? (
                            <i
                              className="fa fa-eye"
                              style={{ marginLeft: "250px" }}
                            ></i>
                          ) : (
                            <i
                              className="fa fa-eye-slash "
                              style={{ marginLeft: "250px" }}
                            ></i>
                          )}
                        </div>
                      </div>
                    </Form.Group>
                    <Form.Group className=" mb-4 col-6">
                      <Form.Label style={{ fontWeight: "bold" }}>
                        Confirm Password:
                      </Form.Label>
                      <div className="password-field">
                        <Form.Control
                          autoComplete="new-password"
                          name="confirmpassword"
                          placeholder="Enter Confirm Password"
                          type={!!Confirmvisible ? "text" : "password"}
                          value={deliveryAgentsApiData.confirmpassword}
                          onChange={onChangeInput}
                          className={classNames({
                            requireField: errors.confirmpassword,
                          })}
                        />
                        <div
                          className="form-control-eye-icon pointer"
                          onClick={Confirmpasswordvisible}
                        >
                          {Confirmvisible ? (
                            <i
                              className="fa fa-eye"
                              style={{ marginLeft: "250px" }}
                            ></i>
                          ) : (
                            <i
                              className="fa fa-eye-slash"
                              style={{ marginLeft: "250px" }}
                            ></i>
                          )}
                        </div>
                      </div>
                    </Form.Group>
                    {/* </div> */}

                    <div className="row">
                      <div
                        className="col-md-12 col-12 mt-2"
                        style={{ fontWeight: "bold" }}
                      >
                        <div
                          style={{
                            color: passwordCheck?.length ? "green" : "red",
                          }}
                        >
                          <span>
                            <i
                              className={`fa ${
                                passwordCheck?.length
                                  ? "fa-circle-check text-success"
                                  : "fa-circle-xmark text-danger"
                              } text-success fs-5 me-2`}
                            ></i>
                            Must be at least 8 characters.
                          </span>
                        </div>
                      </div>

                      <div
                        className="col-md-12 col-12 mt-2"
                        style={{ fontWeight: "bold" }}
                      >
                        <div
                          style={{
                            color: passwordCheck?.capitalLatter
                              ? "green"
                              : "red",
                          }}
                        >
                          <span>
                            <i
                              className={`fa ${
                                passwordCheck?.capitalLatter
                                  ? "fa-circle-check text-success"
                                  : "fa-circle-xmark text-danger"
                              } text-success fs-5 me-2`}
                            ></i>
                            Must be at least One upper case character (
                            A,B,C...etc )
                          </span>
                        </div>
                      </div>
                      <div
                        className="col-md-12 col-12 mt-2"
                        style={{ fontWeight: "bold" }}
                      >
                        <div
                          style={{
                            color: passwordCheck?.smallLatter ? "green" : "red",
                          }}
                        >
                          <span>
                            <i
                              className={`fa ${
                                passwordCheck?.smallLatter
                                  ? "fa-circle-check text-success"
                                  : "fa-circle-xmark text-danger"
                              } text-success fs-5 me-2`}
                            ></i>
                            Must be at least One lower case character (
                            a,b,c...etc )
                          </span>
                        </div>
                      </div>

                      <div
                        className="col-md-12 col-12 mt-2"
                        style={{ fontWeight: "bold" }}
                      >
                        <div
                          style={{
                            color: passwordCheck?.spacialcharacter
                              ? "green"
                              : "red",
                          }}
                        >
                          <span>
                            {" "}
                            <i
                              className={`fa ${
                                passwordCheck?.spacialcharacter
                                  ? "fa-circle-check text-success"
                                  : "fa-circle-xmark text-danger"
                              } text-success fs-5 me-2`}
                            ></i>
                            Must conatin at least special character (
                            #,@,$,...etc )
                          </span>
                        </div>
                      </div>
                      <div
                        className="col-md-12 col-12 mt-2"
                        style={{ fontWeight: "bold" }}
                      >
                        <div
                          style={{
                            color: passwordCheck?.passwordNumber
                              ? "green"
                              : "red",
                          }}
                        >
                          <i
                            className={`fa ${
                              passwordCheck?.passwordNumber
                                ? "fa-circle-check text-success"
                                : "fa-circle-xmark text-danger"
                            } text-success fs-5 me-2`}
                          ></i>
                          {/* <i className="fa fa-circle-xmark text-danger fs-4"></i> */}
                          Must conatin at least one number ( 1,2,3,...etc )
                        </div>
                      </div>
                    </div>

                    <div className="col-md-3 col-4">
                      <Form.Label>Status:</Form.Label>
                      <Form.Select
                        value={deliveryAgentsApiData.status}
                        onChange={(e) => {
                          setdeliveryAgentsApiData((prevState) => ({
                            ...prevState,
                            status: e.target.value,
                          }));
                        }}
                      >
                        <option value="active">Active</option>
                        <option value="Inactive">Inactive</option>
                      </Form.Select>
                    </div>
                    <div className="d-flex justify-content-end">
                      <Button
                        className="border rounded pointer px-4 py-2 text-white bg-red"
                        type="submit"
                        onClick={onSubmite}
                        aria-disabled={btnLoading}
                      >
                        {btnLoading ? <Loader /> : "Submit"}
                      </Button>
                    </div>
                  </Form>
                </div>
              </Modal.Body>
            </Modal>
          </div>
          {allDeliveryAgents && allDeliveryAgents.length !== 0 ? (
            <>
              <DataTable
                columns={columns}
                data={filteredItems}
                pagination
                paginationResetDefaultPage={resetPaginationToggle}
                subHeader
                subHeaderComponent={subHeaderComponentMemo}
              />
            </>
          ) : (
            <></>
          )}
        </div>
      </main>
      <Toaster />
      <Footer />
      {/* <DeletePopUp
        isOpen={isDelete}
        isClose={setIsDelete}
        handleEvent={() => dispatch(deleteDeliveryAgentsData(deleteId))}
      /> */}
      <Modal
        show={deleteShow}
        onHide={handledeleteShow}
        backdrop="static"
        keyboard={false}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <div className="d-flex justify-content-center align-items-center my-4 py-4">
          <div className="text-center">
            <h4 className="text-center mt-3">
              Are you sure about deleting this delivery agent?
            </h4>
            <div className="d-flex justify-content-center align-items-center mt-5">
              <button
                onClick={() => handledeleteclose()}
                className="text-white bg-red border-0 fs-5 rounded py-2 px-5 me-4"
              >
                Cancel
              </button>
              <button
                onClick={() => handledelete()}
                className="text-white bg-red border-0 fs-5 rounded py-2 px-5"
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      </Modal>
      {DeliveryAgentsId && (
        <Modal
          show={EditShow}
          onHide={() => setEditShow(false)}
          size="lg"
          backdrop="static"
        >
          <EditDeliveryAgents
            DeliveryAgentsId={DeliveryAgentsId}
            setEditShow={setEditShow}
          />
        </Modal>
      )}
      {SelectedAgentDetailsId && (
        <Modal
          show={EditShow1}
          onHide={() => setEditShow1(false)}
          size="lg"
          backdrop="static"
          dialogClassName="modal-dialog-scrollable"
        >
          <AgentDetails
            SelectedAgentDetailsId={SelectedAgentDetailsId}
            setEditShow1={setEditShow1}
          />
        </Modal>
      )}
    </>
  );
};

const mapStateToProp = (state) => ({
  createDeliveryAgentsReducer: state.createDeliveryAgentsReducer,
  getDeliveryAgentsReducer: state.getDeliveryAgentsReducer,
  editDeliveryAgentsReducer: state.editDeliveryAgentsReducer,
  DeleteDeliveryAgentsReducer: state.DeleteDeliveryAgentsReducer,
  getLocationWiseAgents: state.getLocationWiseAgents.getLocationWiseAgents,
});

const mapDispatchToProps = (dispatch) => ({
  // getAgentsData: () => dispatch(getAgentsData()),
  getDeliveryAgentsDataAction: (Details) =>
    dispatch(getDeliveryAgentsDataAction(Details)),
  getDeliveryAgentsById: (id) => dispatch(getDeliveryAgentsById(id)),
  getLocationWiseAgentsDataAction: (id) =>
    dispatch(getLocationWiseAgentsDataAction(id)),
  ResetDeliveryAgents: () => dispatch({ type: RESET_ADD_DELIVERY_DATA }),
  ResetdeleteDeliveryAgents: () =>
    dispatch({ type: RESET_DELETE_DELIVERY_AGENTS_DATA }),
  ResetupdateDeliveryAgents: () =>
    dispatch({ type: RESET_UPDATE_DELIVERY_AGENTS_DATA_SUCCESS }),
});
export default connect(mapStateToProp, mapDispatchToProps)(DeliveryAgent);
