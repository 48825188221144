import React from "react";
import { Modal } from "react-bootstrap";

function DeletePopUp({ isOpen, isClose, handleEvent, confirmMsg }) {
  const deleteEvent = () => {
    handleEvent && handleEvent();
    isClose(false);
  };
  return (
    <Modal
      show={isOpen}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
    >
      <div className="d-flex justify-content-center align-items-center my-3 py-3">
        <div className="text-center">
          <i
            className="fa fa-circle-xmark text-red"
            style={{ fontSize: "80px" }}
          ></i>
          {/* <h4 className="text-center mt-3">Are you sure about deleting this item?</h4> */}
          <h4 className="text-center mt-3">{confirmMsg? confirmMsg : "Are you sure you want to delete this record?"}</h4>
          {/* <h4 className="text-center mt-3">Are you sure you want to delete this record? </h4> */}
          <h4 className="text-center mt-3"></h4>
          <div className="d-flex justify-content-center align-items-center mt-5">
            <button
              onClick={() => isClose(false)}
              className="text-white bg-red border-0 fs-5 rounded py-2 px-5 me-4"
            >
              Cancel
            </button>
            <button
              onClick={deleteEvent}
              className="text-white bg-red border-0 fs-5 rounded py-2 px-5"
            >
              Delete
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default DeletePopUp;
