import classNames from "classnames";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import ReactDatePicker from "react-datepicker";
import { connect, useDispatch, useSelector } from "react-redux";
import MaskedInput from "react-text-mask";
import { Loader } from "rsuite";
import { toast } from "react-toastify";
import {
  Us_Pattern_Number,
  formatPhoneNumber,
} from "../../../Store/type/Constant";
import {
  RESET_DELETE_ADMIN_CUSTOMER_ADDRESS,
  RESET_GET_ADMIN_CUSTOMER_DETAILS,
  countryCodeValidation,
  validEmailRegex,
} from "../../Constants/Constants";
import {
  deleteCoustomerAddress,
  editCoustomer,
  getCoustomerById,
} from "../../Services/Actions/CustomerAction";
import Toaster from "../../../Components/Toaster/Toaster";

function AdminEditCustomer(props) {
  const dispatch = useDispatch();

  const [btnLoading, setbtnLoading] = useState(false);
  const [addressIndex, setaddressIndex] = useState();
  const [customerApiData, setcustomerApiData] = useState({
    name: "",
    email: "",
    dob: "",
    note: "",
    number: "",
  });

  const [errors, setErrors] = useState({
    name: false,
    email: false,
    number: false,
    dob: false,
    address: false,
    pincode: false,
    city: false,
    state: false,
    note: false,
  });

  const [addInput, setaddInput] = useState([
    {
      client_name: "",
      address1: "",
      address2: "",
      pincode: "",
      city: "",
      state: "",
    },
  ]);

  const [addInputErrors, setaddInputErrors] = useState([
    {
      client_name: false,
      address1: false,
      address2: false,
      pincode: false,
      city: false,
      state: false,
    },
  ]);

  const coustomerDetailReducer = useSelector(
    (state) => state.CustomerReducer.getCoustomerById
  );


  const deleteAddress = useSelector(
    (state) => state.CustomerReducer.deleteAddress
  );

  useEffect(() => {
    dispatch(
      getCoustomerById({
        id: props.selectedCustomerID,
      })
    );
  }, []);

  useEffect(() => {
    if (coustomerDetailReducer.success) {
      const data = coustomerDetailReducer.data;
      setcustomerApiData((pre) => ({
        ...pre,
        name: data[0]?.name,
        email: data[0]?.email,
        dob:
          data[0]?.dob !== ""
            ? new Date(moment(data[0]?.dob).format("MM-DD-yyyy"))
            : "",
        note: data[0]?.note,
        number: data[0]?.number ? formatPhoneNumber(data[0]?.number) : "N/A",
      }));
      setaddInput(
        data[0]?.address.map((e) => {
          const list = {
            ...e,
            client_name: e.name,
          };
          delete list["name"];
          return list;
        })
      );
      const addressErrorList = data[0]?.address.map((e, i) => {
        return {
          client_name: false,
          address1: false,
          address2: false,
          pincode: false,
          city: false,
          state: false,
        };
      });
      setaddInputErrors(addressErrorList);
      dispatch({ type: RESET_GET_ADMIN_CUSTOMER_DETAILS });
    }
  }, [coustomerDetailReducer]);

  useEffect(() => {
    if (deleteAddress?.flag == 0) {
      toast.error(deleteAddress.errors);
      dispatch({ type: RESET_DELETE_ADMIN_CUSTOMER_ADDRESS });
    } else if (deleteAddress?.flag === 1) {
      dispatch(
        getCoustomerById({
          id: props.selectedCustomerID,
        })
      );
      dispatch({ type: RESET_DELETE_ADMIN_CUSTOMER_ADDRESS });
    }
  }, [deleteAddress]);

  const addInputValue = () => {
    const addtipvalidation = addInput?.map((e, i) => {
      if (
        e.client_name !== "" &&
        e.address1 !== "" &&
        // e.address2 !== "" &&
        e.pincode !== "" &&
        e.pincode.toString().length === 5 &&
        e.city !== "" &&
        e.state !== ""
      ) {
        return true;
      } else return false;
    });
    const x = addtipvalidation?.every((e, i) => {
      return e;
    });
    if (x) {
      setaddInput([
        ...addInput,
        {
          client_name: "",
          address1: "",
          address2: "",
          pincode: "",
          city: "",
          state: "",
        },
      ]);
      setaddInputErrors([
        ...addInputErrors,
        {
          client_name: false,
          address1: false,
          address2: false,
          pincode: false,
          city: false,
          state: false,
        },
      ]);
    } else {
      const error = [...addInputErrors];
      const findIndex = addtipvalidation.findIndex((e) => !e);
      const list = {
        client_name: addInput[findIndex].client_name === "" ? "" : false,
        address1: addInput[findIndex].address1 === "" ? "" : false,
        address2: addInput[findIndex].address2 === "" ? "" : false,
        state: addInput[findIndex].state === "" ? "" : false,
        city: addInput[findIndex].city === "" ? "" : false,
        pincode: addInput[findIndex].pincode === "" ? "" : false,
      };
      error.splice(findIndex, 1, list);
      setaddInputErrors(error);
    }
    // addInputValueError();
  };

  const removeInputeValue = (index) => {
    const list = [...addInput];
    list.splice(index, 1);
    setaddInput(list);

    for (let i = 0; i < addInput.length; i++) {
      if (addInput?.length === 1) {
        const list = [...addInput];
        list.splice(index, 0);
        setaddInput(list);
      }
    }
  };

  const getValue = (e, index) => {
    const nameregx = /^[A-Za-z]+$/;

    if (e.target.name === "number") {
      if (e.target.value.length <= 14) {
        setcustomerApiData((prevState) => ({
          ...prevState,
          [e.target.name]: e.target.value.replace(
            /^(\([0-9]{3}\) |[0-9]{3}-)[0-9]{3}-[0-9]{4}$/
          ),
        }));
      } else return;
    }

    if (e.target.name === "note") {
      if (e.target.value.length <= 256) {
        setcustomerApiData((prevState) => ({
          ...prevState,
          [e.target.name]: e.target.value,
        }));
      } else return;
    }
    if (e.target.name === "pincode") {
      if (e.target.value.length <= 5) {
        setcustomerApiData((prevState) => ({
          ...prevState,
          [e.target.name]: e.target.value,
        }));
      } else return;
    }
    if (e.target.name === "name") {
      if (e.target.value.length <= 65) {
        setcustomerApiData((prevState) => ({
          ...prevState,
          [e.target.name]: e.target.value,
        }));
      } else return;
    } else {
      setcustomerApiData((preve) => ({
        ...preve,
        [e.target.name]: e.target.value,
      }));
    }
    if (
      e.target.name === "client_name" ||
      e.target.name === "address1" ||
      e.target.name === "address2" ||
      e.target.name === "city" ||
      e.target.name === "state" ||
      e.target.name === "pincode"
    ) {
      const list = [...addInput];
      list[index][e.target.name] = e.target.value;
      setaddInput(list);
    }

    switch (e.target.name) {
      case "name":
        if (e.target.value.length == 0) {
          setErrors((prevState) => ({
            ...prevState,
            name: "name can not be empty",
          }));

          document.getElementById("name").classList.add("error");
        } else {
          setErrors((prevState) => ({
            ...prevState,
            name: false,
          }));

          document.getElementById("name").classList.remove("error");
        }
        break;

      case "email":
        if (e.target.value === "") {
          setErrors((prevState) => ({
            ...prevState,
            email: "Email can not be empty",
          }));

          document.getElementById("email").classList.add("error");
        } else if (!validEmailRegex.test(e.target.value)) {
          setErrors((prevState) => ({
            ...prevState,
            email: "Enter valid email",
          }));
        } else {
          setErrors((prevState) => ({
            ...prevState,
            email: false,
          }));
          document.getElementById("email").classList.remove("error");
        }
        break;

      case "password":
        if (e.target.value.length == 0) {
          setErrors((prevState) => ({
            ...prevState,
            password: "Division name can not be empty",
          }));
        } else {
          setErrors((prevState) => ({
            ...prevState,
            password: false,
          }));

          document.getElementById("password").classList.remove("error");
        }
        break;

      case "confirmpassword":
        if (e.target.value.length == 0) {
          setErrors((prevState) => ({
            ...prevState,
            confirmpassword: "confirmpassword can not be empty",
          }));
        } else if (
          customerApiData.password === customerApiData.confirmpassword
        ) {
          setErrors((prevState) => ({
            ...prevState,
            confirmpassword: "confirmpassword can not be match",
          }));
          document.getElementById("confirmpassword").classList.remove("error");
        } else {
          setErrors((prevState) => ({
            ...prevState,
            confirmpassword: false,
          }));
        }
        break;

      case "location":
        if (e.target.value.length == 0) {
          setErrors((prevState) => ({
            ...prevState,
            location: "Division name can not be empty",
          }));

          document.getElementById("location").classList.add("error");
        } else {
          setErrors((prevState) => ({
            ...prevState,
            location: false,
          }));

          document.getElementById("location").classList.remove("error");
        }

        break;

      case "number":
        if (e.target.value == "") {
          setErrors((prevState) => ({
            ...prevState,
            number: "number can not be empty",
          }));
        } else if (!Us_Pattern_Number.test(e.target.value)) {
          setErrors((prevState) => ({
            ...prevState,
            number: "Enter valid number",
          }));
        } else {
          setErrors((prevState) => ({
            ...prevState,
            number: false,
          }));
        }
        break;

      case "city":
        if (e.target.value === "") {
          const list = addInputErrors.map((e, i) => {
            if (i === index) {
              return {
                ...e,
                city: "",
              };
            } else return e;
          });
          setaddInputErrors(list);
        } else {
          const list = addInputErrors.map((e, i) => {
            if (i === index) {
              return {
                ...e,
                city: false,
              };
            } else return e;
          });
          setaddInputErrors(list);
        }
        break;

      case "note":
        if (e.target.value.length == 0) {
          setErrors((prevState) => ({
            ...prevState,
            note: "note can not be empty",
          }));

          document.getElementById("note").classList.add("error");
        } else {
          setErrors((prevState) => ({
            ...prevState,
            note: false,
          }));

          document.getElementById("note").classList.remove("error");
        }
        break;

      case "state":
        if (e.target.value === "") {
          const list = addInputErrors.map((e, i) => {
            if (i === index) {
              return {
                ...e,
                state: "",
              };
            } else return e;
          });
          setaddInputErrors(list);
        } else {
          const list = addInputErrors.map((e, i) => {
            if (i === index) {
              return {
                ...e,
                state: false,
              };
            } else return e;
          });
          setaddInputErrors(list);
        }
        break;

      case "countryCode":
        if (e.target.value.length == 0) {
          setErrors((prevState) => ({
            ...prevState,
            countryCode: "countryCode can not be empty",
          }));
        } else if (!countryCodeValidation.test(e.target.value)) {
          setErrors((prevState) => ({
            ...prevState,
            countryCode: "countryCode can not be match",
          }));
          document.getElementById("countryCode").classList.remove("error");
        } else {
          setErrors((prevState) => ({
            ...prevState,
            countryCode: false,
          }));
        }
        break;

      case "client_name":
        if (e.target.value === "") {
          const list = addInputErrors.map((e, i) => {
            if (i === index) {
              return {
                ...e,
                client_name: "",
              };
            } else return e;
          });
          setaddInputErrors(list);
        } else {
          const list = addInputErrors.map((e, i) => {
            if (i === index) {
              return {
                ...e,
                client_name: false,
              };
            } else return e;
          });
          setaddInputErrors(list);
        }
        break;
      case "address1":
        if (e.target.value === "") {
          const list = addInputErrors.map((e, i) => {
            if (i === index) {
              return {
                ...e,
                address1: "",
              };
            } else return e;
          });
          setaddInputErrors(list);
        } else {
          const list = addInputErrors.map((e, i) => {
            if (i === index) {
              return {
                ...e,
                address1: false,
              };
            } else return e;
          });
          setaddInputErrors(list);
        }
        break;

      case "pincode":
        if (e.target.value === "") {
          const list = addInputErrors.map((e, i) => {
            if (i === index) {
              return {
                ...e,
                pincode: "",
              };
            } else return e;
          });
          setaddInputErrors(list);
        } else if (e.target.value.length < 5) {
          const list = addInputErrors.map((e, i) => {
            if (i === index) {
              return {
                ...e,
                pincode: "",
              };
            } else return e;
          });
          setaddInputErrors(list);
        } else {
          const list = addInputErrors.map((e, i) => {
            if (i === index) {
              return {
                ...e,
                pincode: false,
              };
            } else return e;
          });
          setaddInputErrors(list);
        }
        break;

      default:
    }
  };

  const onSubmite = (e) => {
    const addtipvalidation = addInput?.map((e, i) => {
      if (
        e.client_name !== "" &&
        e.address1 !== "" &&
        // e.address2 !== "" &&
        e.pincode !== "" &&
        e.city !== "" &&
        e.state !== ""
      ) {
        return true;
      } else return false;
    });

    const x = addtipvalidation?.every((e, i) => {
      return e;
    });

    const addtipvalidationError = addInputErrors?.map((e, i) => {
      if (
        e.client_name !== "" &&
        e.address1 !== "" &&
        // e.address2 !== "" &&
        e.pincode !== "" &&
        e.city !== "" &&
        e.state !== ""
      ) {
        return true;
      } else return false;
    });

    const y = addtipvalidationError?.every((e, i) => {
      return e;
    });

    if (customerApiData.location == "") {
      setErrors((prevState) => ({
        ...prevState,
        location: "location can not be empty",
      }));
    }

    if (customerApiData.name == "") {
      setErrors((prevState) => ({
        ...prevState,
        name: "name can not be empty",
      }));
    }

    if (customerApiData.number === "") {
      setErrors((prevState) => ({
        ...prevState,
        number: "number can not be empty",
      }));
    } else if (!Us_Pattern_Number.test(customerApiData.number)) {
      setErrors((prevState) => ({
        ...prevState,
        number: "Enter valid number",
      }));
    } else {
      setErrors((prevState) => ({
        ...prevState,
        number: false,
      }));
    }

    if (addInput.city === "") {
      setErrors((prevState) => ({
        ...prevState,
        city: "city can not be empty",
      }));
    }
    if (addInput.state === "") {
      setErrors((prevState) => ({
        ...prevState,
        state: "state can not be empty",
      }));
    }
    if (customerApiData.note === "") {
      setErrors((prevState) => ({
        ...prevState,
        note: "note can not be empty",
      }));
    }
    if (addInput.client_name === "") {
      setErrors((prevState) => ({
        ...prevState,
        client_name: "name can not be empty",
      }));
    }
    if (addInput.address1 === "") {
      setErrors((prevState) => ({
        ...prevState,
        address1: "address line 1 can not be empty",
      }));
    }
    if (addInput.pincode === "") {
      setErrors((prevState) => ({
        ...prevState,
        pincode: "pincode can not be empty",
      }));
    }
    if (
      customerApiData.name !== "" &&
      // customerApiData.dob !== "" &&
      !errors.number &&
      x &&
      y
    ) {
      const details = {
        name: customerApiData.name,
        email: customerApiData.email,
        countryCode: customerApiData.countryCode,
        number: customerApiData.number,
        dob: customerApiData?.dob
          ? moment(customerApiData.dob).format("MM-DD-yyyy")
          : "",
        city: customerApiData.city,
        state: customerApiData.state,
        address: addInput.map((e) => {
          const list = {
            ...e,
            name: e.client_name,
          };
          delete list["client_name"];
          return list;
        }),
        note: customerApiData.note,
      };
      dispatch(
        editCoustomer({
          id: props.selectedCustomerID,
          details: details,
        })
      );
      props.setEditShow(false);
      setbtnLoading(true);
    }
  };

  return (
    <>
      <Modal.Header closeButton>
        <Modal.Title>Edit Customers</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="form-area">
          <Form className="row" onSubmit={onSubmite}>
            <div className="col-6">
              <Form.Group className="mb-3">
                <Form.Label>Customer Name:</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Customer Name"
                  name="name"
                  onChange={getValue}
                  label="name"
                  id="name"
                  value={customerApiData?.name}
                  className={classNames({
                    requireField: errors.name,
                  })}
                />
              </Form.Group>
            </div>
            <div className="col-6">
              <Form.Label style={{ fontWeight: "bold" }}>
                Date of Birth (Optional):
              </Form.Label>
              <ReactDatePicker
                name="dob"
                showYearDropdown
                // disabled={isContactAvailable}
                placeholderText="MM-DD-YYYY"
                dateFormat={"MM-dd-yyyy"}
                selected={customerApiData.dob}
                autoComplete="off"
                // selected={moment(customerApiData.dob).toDate()}
                className={classNames("form-control", {
                  requireField: errors.dob,
                })}
                maxDate={new Date()}
                onChange={(date) => {
                  setcustomerApiData((prevState) => ({
                    ...prevState,
                    dob: date,
                  }));
                }}
              />
            </div>

            <div className="col-6">
              <Form.Group className="mb-3">
                <Form.Label>Email Address (Optional): </Form.Label>
                <Form.Control
                  id="email"
                  type="email"
                  placeholder="Enter Email Address"
                  name="email"
                  onChange={getValue}
                  label="email"
                  // disabled
                  value={customerApiData?.email}
                />
              </Form.Group>
            </div>
            <div className="col-6">
              <Form.Label>Contact No.:</Form.Label>
              <MaskedInput
                mask={[
                  "(",
                  /[0-9]/,
                  /[0-9]/,
                  /[0-9]/,
                  ")",
                  " ",
                  /[0-9]/,
                  /[0-9]/,
                  /[0-9]/,
                  "-",
                  /[0-9]/,
                  /[0-9]/,
                  /[0-9]/,
                  /[0-9]/,
                ]}
                name="number"
                placeholder="Enter Contact No"
                guide={false}
                id="my-input-id number"
                value={customerApiData?.number}
                onKeyUp={getValue}
                onChange={getValue}
                className={classNames("form-control mb-3", {
                  requireField: errors.number,
                })}
              />
            </div>

            <div className="col-md-12">
              <div className="address-container">
                {addInput?.map((v, i) => {
                  return (
                    <div className="col-12 border-bottom mb-3" key={"add" + i}>
                      <Form.Group className="mb-3">
                        <Form.Label style={{ fontWeight: "bold" }}>
                          Business Name:
                        </Form.Label>
                        <Form.Control
                          onChange={(e) => getValue(e, i)}
                          type="text"
                          placeholder="Enter Business Name"
                          name="client_name"
                          label="client_name"
                          value={v.client_name}
                          id="client_name"
                          className={classNames({
                            requireField: addInputErrors[i]?.client_name === "",
                          })}
                        />
                      </Form.Group>
                      <Form.Group className="mb-3">
                        <Form.Label style={{ fontWeight: "bold" }}>
                          Address Line 1:
                        </Form.Label>
                        <Form.Control
                          onChange={(e) => getValue(e, i)}
                          type="text"
                          placeholder="Enter Address line 1"
                          name="address1"
                          label="address1"
                          value={v.address1}
                          id="address1"
                          className={classNames({
                            requireField: addInputErrors[i]?.address1 === "",
                          })}
                        />
                      </Form.Group>
                      <Form.Group className="mb-3">
                        <Form.Label style={{ fontWeight: "bold" }}>
                          Address Line 2 (Optional):
                        </Form.Label>
                        <Form.Control
                          onChange={(e) => getValue(e, i)}
                          type="text"
                          placeholder="Enter Address line 2"
                          name="address2"
                          label="address2"
                          value={v.address2}
                          id="address2"
                          // className={classNames({
                          //   requireField: addInputErrors[i]?.address2 === "",
                          // })}
                        />
                      </Form.Group>
                      <div className="d-flex align-items-end  w-100">
                        <div className="row gx-2 w-100">
                          <div className="col-4">
                            <Form.Group className="mb-3">
                              <Form.Label>City:</Form.Label>
                              <Form.Control
                                type="text"
                                placeholder="Enter city"
                                name="city"
                                onChange={(e) => getValue(e, i)}
                                value={v.city}
                                label="city"
                                id="city"
                                className={classNames({
                                  requireField: addInputErrors[i]?.city === "",
                                })}
                              />
                            </Form.Group>
                          </div>
                          <div className="col-4">
                            <Form.Group className="mb-3">
                              <Form.Label>State:</Form.Label>
                              <Form.Control
                                type="text"
                                placeholder="Enter State"
                                name="state"
                                onChange={(e) => getValue(e, i)}
                                value={v.state}
                                label="state"
                                id="state"
                                className={classNames({
                                  requireField: addInputErrors[i]?.state === "",
                                })}
                              />
                            </Form.Group>
                          </div>
                          <div className="col-4">
                            <Form.Group className="mb-3">
                              <Form.Label>Zip Code:</Form.Label>
                              <Form.Control
                                type="number"
                                name="pincode"
                                label="pincode"
                                onChange={(e) => getValue(e, i)}
                                value={v.pincode}
                                placeholder="Enter Zip Code"
                                id="pincode"
                                className={classNames({
                                  requireField:
                                    addInputErrors[i]?.pincode === "",
                                })}
                              />
                            </Form.Group>
                          </div>
                        </div>
                        {addInput?.length !== 1 && (
                          <button
                            className="btn bg-dark text-white px-3 ms-2 mb-3"
                            type="button"
                            onClick={() => {
                              setaddressIndex(i);
                              if (v?._id) {
                                dispatch(deleteCoustomerAddress(v?._id));
                              } else {
                                removeInputeValue(i);
                              }
                            }}
                          >
                            <i className="fa fa-minus"></i>
                          </button>
                        )}
                      </div>
                    </div>
                  );
                })}
              </div>
              <button
                className="btn bg-red py-1 text-white add-more_Address"
                type="button"
                onClick={addInputValue}
              >
                <i className="fa-solid fa-plus"></i> Add Address
              </button>
            </div>
            <div className="col-12 mt-2">
              <Form.Label>Note:</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                className="mb-3"
                type="text"
                placeholder="Enter Note"
                value={customerApiData?.note}
                name="note"
                label="note"
                onChange={getValue}
                id="note"
              />
            </div>
            <div className="text-end">
              <Button
                type="button"
                className="border rounded pointer px-4 py-2 text-white bg-red mt-4"
                onClick={onSubmite}
              >
                {btnLoading ? <Loader /> : "Submit"}
              </Button>
            </div>
          </Form>
        </div>
      </Modal.Body>
    </>
  );
}

export default AdminEditCustomer;
