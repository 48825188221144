import { useRef, useEffect } from "react";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";

const AutoComplete = ({ setState, state }) => {
  const autoCompleteRef = useRef();
  const inputRef = useRef();
  const options = {
    componentRestrictions: { country: "us" },
    fields: ["address_components", "geometry", "icon", "name"],
    strictBounds: false,
  };
  useEffect(() => {
    autoCompleteRef.current = new window.google.maps.places.Autocomplete(
      inputRef.current,
      options
    );

    autoCompleteRef.current.addListener("place_changed", async function () {
      const place = await autoCompleteRef.current.getPlace();
      function getAddressComponent(component, place) {
        return place.address_components.find((compone) => {
          return compone.types.includes(component);
        });
      }
      // console.log("here", getAddressComponent("locality", place).long_name);
      setState((prevState) => ({
        ...prevState,
        address1: place?.name,
        city: getAddressComponent("locality", place).long_name,
        state: getAddressComponent("administrative_area_level_1", place)
          .long_name,
        pincode: getAddressComponent("postal_code", place).long_name,
        address: place,
        latitude: place.geometry.location.lat(),
        longitude: place.geometry.location.lng(),
      }));
    });
  }, []);
  return (
    <div>
      <InputGroup className="mb-3">
        <Form.Control
          placeholder="Search location"
          aria-label="searchloaction"
          aria-describedby="basic-addon1"
          ref={inputRef}
        />
      </InputGroup>
    </div>
  );
};
export default AutoComplete;
