import moment from "moment";
import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { connect, useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import Spinner from "../../../Components/Spinner/Spinner";
import Toaster from "../../../Components/Toaster/Toaster";
import {
  getAdminOrderFilterAction
} from "../../Services/Actions/OrderAction";
import OrderDetails from "../AdminOrder/OrderDetails";

function AdminDashboard(props) {
  // const [selectedDate, setSelectedDate] = useState(new Date());
  // const [endDate, setEndDate] = useState(null);

  const [orderData, setorderData] = useState();

  const [orderList, setOrderList] = useState([]);
  const [Loading2, setLoading2] = useState(false);

  const [dateFilter, setDateFilter] = useState("today");
  const [celnderView, setCelnderView] = useState("Month");

  const [IsOrderDetails, setIsOrderDetails] = useState(false);
  const [orderDetailId, setOrderDetailId] = useState(null);

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [dateRange, setDateRange] = useState(["", ""]);
  const [error, seterror] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  // useEffect(() => {
  //   if (!!orderData) {
  //     setOrderList([...orderData]);
  //   }
  // }, [orderData]);

  useEffect(() => {

    // props.getAllOrder({
    //   from_date: "2024-08-06",
    //   to_date: "2024-08-06",
    //   limit: 3,
    // });
    props.getAllOrder({
      from_date: moment().format("yyyy-MM-DD"),
      to_date: moment().format("yyyy-MM-DD"),
      limit: 3,
    });
  }, [dispatch]);
  useEffect(() => {
    // setLoading(props.orderfilterReducer.loading);
    if (props.orderfilterAdminReducer.success) {
      const data = props.orderfilterAdminReducer?.data;
      setOrderList(data);
    
    }
  }, [props.orderfilterAdminReducer]);
  // useEffect(() => {
  //   if (props.GetorderReducer?.success) {
  //     const data = props.GetorderReducer?.data;
  //     console.log(data, "props.GetorderReducer");
  //     setOrderList(data);
  //   }
  // }, [props.GetorderReducer]);

  // useEffect(() => {
  //   if (selectedDate == "" && endDate == "") {
  //     setOrderList(orderData);
  //   }
  // }, [selectedDate, endDate]);

  // useEffect(() => {
  //   if (!!orderData) {
  //     if (
  //       startDate !== "" &&
  //       startDate !== null &&
  //       (endDate === "" || endDate === null)
  //     ) {
  //       const list = orderData.filter((e) => {
  //         return (
  //           moment(startDate, "MM-DD-yyyy") <= moment(e.date, "MM-DD-yyyy")
  //         );
  //       });
  //       setOrderList([...list]);
  //     } else if (
  //       (startDate === "" && startDate === null) ||
  //       (endDate === "" && endDate === null)
  //     ) {
  //       const list = orderData.filter((e) => {
  //         return moment(endDate, "MM-DD-yyyy") >= moment(e.date, "MM-DD-yyyy");
  //       });
  //       setOrderList([...list]);
  //     } else if (
  //       startDate !== "" &&
  //       startDate !== null &&
  //       endDate !== "" &&
  //       endDate !== null
  //     ) {
  //       const list = orderData.filter((e) => {
  //         return (
  //           moment(startDate, "MM-DD-yyyy") <= moment(e.date, "MM-DD-yyyy") &&
  //           moment(endDate, "MM-DD-yyyy") >= moment(e.date, "MM-DD-yyyy")
  //         );
  //       });
  //       setOrderList([...list]);
  //     } else if (startDate === null && endDate === null) {
  //       setOrderList([...orderData]);
  //     }
  //   }
  // }, [startDate, endDate]);

  useEffect(() => {
    if (endDate !== null && endDate !== "") {
      setLoading2(true);
      setTimeout(() => {
        setLoading2(false);
      }, 200);
    }
  }, [endDate]);

  const handleChangePage = (event, value) => {
    props.getMenuItems({
      page: value,
      limit: 10,
    });
  };

  const [isOpen, setIsOpen] = useState(true);

  useEffect(() => {
    if (
      startDate !== "" &&
      startDate !== null &&
      (endDate === "" || endDate === null) &&
      !isOpen
    ) {
      toast.error("Please select the next date in the picker.");
    }
  }, [isOpen]);

  // useEffect(() => {
  //   if (props.GetorderReducer?.success) {
  //     const data = props.GetorderReducer?.data;

  //     const list = data.filter((v, i) => {
  //       const _date = `${new Date(v?.date).getMonth()}-${new Date(
  //         v?.date
  //       ).getDate()}-${new Date(v?.date).getFullYear()}`;
  //       const currentDate = `${new Date().getMonth()}-${new Date().getDate()}-${new Date().getFullYear()}`;
  //       return (
  //         // _date === currentDate
  //         v?.deliveryDateUnform === moment().format("MM-DD-yyyy")
  //         // moment(v?.date).format("MM-DD-yyyy") === moment().format("MM-DD-yyyy")
  //       );
  //     });
  //     // console.log(list, "listing", orderList);

  //     setOrderList(list);
  //   }
  // }, [props.GetorderReducer]);

  // console.log(orderList, "orderList");

  return (
    <React.Fragment>
      <div className="row justify-content-between">
        <div className="col-12 col-lg-4">
          <div className="">
            {/* <div className="">
              <DateRangePicker
              showOneCalendar
              format="MM-dd-yyyy"
              placeholder="MM-DD-YYYY"
              onChange={(date) => {
                if (!!date && orderData.length !== 0) {
                  setSelectedDate(moment(date[0]).format("MM-DD-yyyy"));
                  setEndDate(moment(date[1]).format("MM-DD-yyyy"));
                } else {
                  setOrderList([...orderData]);
                }
              }}
              />
            </div> */}
            {/* <Form.Group className="col col-9">
              <Form.Label className="mt-2">From / To Date:</Form.Label>
              <ReactDatePicker
                showYearDropdown
                onInputClick={() => setIsOpen(true)}
                onClickOutside={() => setIsOpen(false)}
                selectsRange={true}
                startDate={startDate}
                endDate={endDate}
                placeholderText="MM-DD-YYYY"
                dateFormat={"MM-dd-yyyy"}
                onChange={(date) => {
                  if (!!date && orderData.length !== 0) {
                    setDateRange(date);
                    setStartDate(date[0]);
                    setEndDate(date[1]);
                  } else {
                    setOrderList([...orderData]);
                  }
                  if (date[1]) {
                    setIsOpen(false);
                  }
                }}
                className={classNames("form-control", {
                  requireField: error,
                })}
                // isClearable={true}
              />
            </Form.Group> */}
          </div>
        </div>
        <div className="col-12 col-lg-12">
          <div style={{ fontSize: "18px", fontWeight: "700" }} className="mt-4">
            Today's deliveries
          </div>
          <div
            className="border p-3 mt-2"
            style={{
              maxHeight: "620px",
              overflowY: "auto",
            }}
          >
            {Loading2 ? (
              <div className="mt-5">
                <Spinner />
              </div>
            ) : (
              <>
                <div>
                  {orderList?.length !== 0 ? (
                    orderList
                      ?.filter((item, index) => index < 3)
                      .map((item, index) => {
                        return (
                          <div
                            key={index}
                            className="border my-2 p-2 rounded pointer"
                            onClick={() => {
                              setIsOrderDetails(true);
                              setOrderDetailId(item?._id);
                            }}
                          >
                            <div
                              className="d-flex justify-content-between align-items-center text-red"
                              style={{ fontWeight: "700" }}
                            >
                              <div className="">
                                {(item?.order_name).toUpperCase()}
                              </div>
                              <div className="">
                                <div className="d-flex justify-content-end">
                                  ${parseFloat(item.grandtotal).toFixed(2)}
                                </div>
                                <div className="mt-2 d-flex justify-content-end">
                                  {item.isDelivered === 0
                                    ? "Ordered"
                                    : "Delivered"}
                                </div>
                              </div>
                            </div>
                            <div className="">
                              Date:{" "}
                              {moment(
                                item?.deliveryDateUnform,
                                "MM-DD-yyyy"
                              ).format("MMMM Do, YYYY")}
                            </div>
                            <div className="">
                              Time: {item?.deliverytimestring}
                            </div>
                            <div className="">
                              Address:
                              <div style={{ fontWeight: "bold" }}>
                                {item.address[0]?.name}
                              </div>
                              <div>{item.address[0]?.address1},</div>
                              {item.address[0]?.address2 && (
                                <div>{item.address[0]?.address2},</div>
                              )}
                              <div>
                                {item.address[0]?.city},
                                {" " + item.address[0]?.state + " "}
                                {item.address[0]?.pincode}
                              </div>
                            </div>
                          </div>
                        );
                      })
                  ) : (
                    <div className="text-center h5 mt-5 mb-5 text-red">
                      There are no orders
                    </div>
                  )}
                </div>
                {orderList?.length !== 0 && (
                  <>
                    {" "}
                    <div
                      className="see-all text-decoration-none pointer text-center"
                      onClick={() => navigate("/admin/order")}
                    >
                      See all
                    </div>
                  </>
                )}
              </>
            )}
          </div>
        </div>
      </div>

      <Modal
        show={IsOrderDetails}
        size="md"
        onHide={() => setIsOrderDetails(false)}
        dialogClassName="modal-dialog-scrollable"
      >
        <OrderDetails
          setIsOrderDetails={setIsOrderDetails}
          orderDetailId={orderDetailId}
        />
      </Modal>
      <Toaster />
    </React.Fragment>
  );
}

const mapStateToProp = (state) => ({
  GetorderReducer: state.OrderReducer.getOrder,
  orderfilterAdminReducer: state.OrderReducer.orderfilterAdmin,
});

const mapDispatchToProps = (dispatch) => ({
  // getAllOrder: (details) => dispatch(getAllOrder(details)),
  getAllOrder: (Details) => dispatch(getAdminOrderFilterAction(Details)),
});

export default connect(mapStateToProp, mapDispatchToProps)(AdminDashboard);
