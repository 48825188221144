import React, { useEffect, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { connect, useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
// import {
//   deleteMenuData,
//   getMenuDataAction,
// } from "../Store/Action/CreateAgents/UserAction";
// import Footer from "../Userlayout/Footer";
// import Header from "../Userlayout/Header";
import classNames from "classnames";
import $ from "jquery";
import DataTable from "react-data-table-component";
import { exportToExcel } from "react-json-to-excel";
import { toast } from "react-toastify";
import { Loader } from "rsuite";
import Toaster from "../../../Components/Toaster/Toaster";
import {
  UploadCsvMenuItem,
  createMenuDataAction,
  deleteMenuData,
  getAgentsData,
  getLocationByMenuDataAction,
  getMenuDataAction,
} from "../../../Store/Action/CreateAgents/UserAction";
import {
  RESET_ADD_MENU_DATA,
  RESET_DELETE_MENUITEMS_DATA,
  RESET_GET_SUPER_ADMIN_LOCATION_BY_MENU,
  RESET_IMPORT_MENU_EXCEL,
  RESET_UPDATE_MENU_DATA,
} from "../../../Store/type/Constant";
import Footer from "../Userlayout/Footer";
import Header from "../Userlayout/Header";
import EditMenu from "./EditMenu";

const FilterComponent = ({ filterText, onFilter, onClear }) => (
  <>
    <div className="form-outline order-search">
      <label className="form-label me-2 mb-0" htmlFor="form1">
        {/* Search : */}
      </label>
      <input
        id="search"
        type="text"
        className="form-control"
        placeholder="Search"
        aria-label="Search Input"
        value={filterText}
        onChange={onFilter}
      />
    </div>
    {/* <ClearButton type="button" onClick={onClear}>
      X
    </ClearButton> */}
  </>
);

const Menu = (props) => {
  const [isDelete, setIsDelete] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const dispatch = useDispatch();
  const successMsg = (msg) => toast.success(msg);
  const notifyError = (msg) => toast.error(msg);

  const [deleteShow, setdeleteShow] = useState(false);

  const handledeleteShow = () => setdeleteShow(true);
  const handledeleteclose = () => setdeleteShow(false);

  const [PizzaStatus, setPizzaStatus] = useState("All");

  const [pagination, setpagination] = useState({
    page: 1,
    limit: 10,
    totalpage: 1,
  });

  const [selectedMenuId, setSelctedMenuId] = useState();
  const [EditShow, setEditShow] = useState(false);
  const [prevImg, setPrevImg] = useState();
  const [loading, setloading] = useState();

  const [show, setShow] = useState(false);

  const [isDeleteItem, setIsDeleteItem] = useState(false);
  const [deleteItemId, setDeleteItemId] = useState(null);
  const [btnLoading, setbtnLoading] = useState(false);
  const [excelLoading, setexcelLoading] = useState(false);

  const { user } = useSelector((state) => state);

  const [isImportModal, setIsImportModal] = useState(false);
  const [excelFile, setExcelFile] = useState("");
  const [menuList, setmenuList] = useState([]);
  const [location, setlocation] = useState("");

  // image

  const allMenuData = useSelector((state) => state.getMenuReducer.user.data);

  const LocationByMenu = useSelector(
    (state) => state.getLocationByMenu.getLocationByMenu.data
  );

  useEffect(() => {
    if (!!location && location !== "" && location !== "all") {
      dispatch(getLocationByMenuDataAction(location));
    } else if (location == "all") {
      setmenuList([...allMenuData]);
    }
  }, [location]);

  useEffect(() => {
    if (!!LocationByMenu) {
      setmenuList([...LocationByMenu]);
    }
  }, [LocationByMenu]);

  useEffect(() => {
    if (props.getmenureducer?.success) {
      const data = props.getmenureducer.user.data;
      setmenuList(data);
    }
  }, [props.getmenureducer]);

  useEffect(() => {
    dispatch(getMenuDataAction());
  }, []);

  const navigate = useNavigate();
  const [apiData, setapiData] = useState([]);

  const [menuApiData, setmenuApiData] = useState({
    location: "",
    item_name: "",
    price: "",
    is_pizza: "",
    // image: "",
    status: "active",
    note: "",
  });

  const [errors, setErrors] = useState({
    location: false,
    item_name: false,
    price: false,
    is_pizza: false,
    // image: false,
    note: false,
  });

  const handleClose = () => {
    setmenuApiData({
      location: "",
      item_name: "",
      price: "",
      is_pizza: "",
      // image: "",
      status: "active",
      note: "",
    });
    setErrors({
      location: false,
      item_name: false,
      price: false,
      is_pizza: false,
      // image: false,
      note: false,
    });
    setShow(false);
    setPrevImg();
  };
  const handleShow = () => setShow(true);

  useEffect(() => {
    if (!!allMenuData) {
      if (props.getLocationByMenuReducer?.success) {
        const data = props.getLocationByMenuReducer?.data;
        if (PizzaStatus == "All") {
          setmenuList([...data]);
        } else if (PizzaStatus == "Yes") {
          const filterDataPizza = data?.filter((e) => e?.is_pizza == 1);
          setmenuList([...filterDataPizza]);
        } else if (PizzaStatus == "No") {
          const filterDataPizza = data?.filter((e) => e?.is_pizza == 0);
          setmenuList([...filterDataPizza]);
        } else if (PizzaStatus == "All") {
          setmenuList([...data]);
        }
      } else {
        if (PizzaStatus == "All") {
          setmenuList([...allMenuData]);
        } else if (PizzaStatus == "Yes") {
          const filterDataPizza = allMenuData?.filter((e) => e?.is_pizza == 1);
          setmenuList([...filterDataPizza]);
        } else if (PizzaStatus == "No") {
          const filterDataPizza = allMenuData?.filter((e) => e?.is_pizza == 0);
          setmenuList([...filterDataPizza]);
        } else if (PizzaStatus == "All") {
          setmenuList([...allMenuData]);
        }
      }
    }
  }, [PizzaStatus, props.getLocationByMenuReducer]);

  useEffect(() => {
    if (props.exportexcelReducer?.success) {
      setexcelLoading(false);
    }
  }, [props.exportexcelReducer]);

  useEffect(() => {
    if (props.createMenusReducer?.success) {
      setTimeout(() => {
        successMsg("A new item is added to the list.");
      }, 100);
      props.reserMenu();
      props.getMenuDataAction();
      handleClose();
      setbtnLoading(false);
    }
  }, [props.createMenusReducer]);

  useEffect(() => {
    if (props.getmenureducer.loading) {
      setloading(true);
    }
  }, [props.getmenureducer]);

  useEffect(() => {
    if (props.DeleteMenuItemsReducer.success) {
      setlocation("all");
      setTimeout(() => {
        successMsg("A menu item is deleted successfully.");
      }, 100);
      setTimeout(() => {
        props.resetdeletemenu();
      }, 100);
      setIsDeleteItem(false);
      setDeleteItemId(null);
      props.getMenuDataAction();
    }
  }, [props.DeleteMenuItemsReducer]);

  useEffect(() => {
    if (props.DeleteMenuItemsReducer.error) {
      notifyError(props.DeleteMenuItemsReducer.errors);
      props.resetdeletemenu();
      setIsDeleteItem(false);
      setDeleteItemId(null);
    }
  }, [props.DeleteMenuItemsReducer]);

  useEffect(() => {
    if (props.editMenuReducer.success) {
      setTimeout(() => {
        successMsg("A item is updated successfully.");
      }, 100);
      setTimeout(() => {
        props.resetupdatemenu();
      }, 100);
      props.getMenuDataAction();
      setbtnLoading(false);
    }
  }, [props.editMenuReducer]);

  // useEffect(() => {
  //   setloading(props.getmenureducer.loading);
  //   if (props.getmenureducer?.success) {
  //     const data = props.getmenureducer.data.data;
  //     const _pagination = props.getmenureducer.pagination;
  //     // setpagination({ ..._pagination, page: parseInt(_pagination.page) - 1 });
  //     setmenuApiData([...data])
  //   }
  // }, [props.getmenureducer]);

  useEffect(() => {
    if (props.getMenuReducer?.success) {
      props.getMenuDataAction({
        page: 1,
        limit: 10,
      });
      props.reserMenu();
    }
  }, [props.getMenuReducer]);

  const allLocationData = useSelector(
    (state) => state.getAgentsReducer.user.data
  );

  useEffect(() => {
    dispatch(getAgentsData());
  }, []);

  useEffect(() => {
    setapiData(allLocationData);
  }, [allLocationData]);

  const onChangeInput = (e) => {
    const { name, value } = e.target;

    // if (name === "image") {
    //   setmenuApiData((prevState) => ({
    //     ...prevState,
    //     [name]: value,
    //   }));
    //   setImage(e.target.files[0]);
    //   setPrevImg(URL.createObjectURL(e.target.files[0]));
    // }
    if (e.target.name === "note") {
      if (e.target.value.length <= 256) {
        setmenuApiData((prevState) => ({
          ...prevState,
          [e.target.name]: e.target.value,
        }));
      } else return;
    } else {
      setmenuApiData((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }

    switch (e.target.name) {
      // case "image":
      //   if (value == "") {
      //     setErrors((prevState) => ({
      //       ...prevState,
      //       image: "image can not be empty",
      //     }));
      //   } else if (!RegExp(/^image/gim).test(e.target.files[0].type)) {
      //     setErrors((prevState) => ({
      //       ...prevState,
      //       image: "Only accept image file",
      //     }));
      //   } else {
      //     setErrors((prevState) => ({
      //       ...prevState,
      //       image: false,
      //     }));
      //     // document.getElementById("image").classList.remove("error");
      //   }
      //   break;

      case "location":
        if (e.target.value == "") {
          setErrors((prevState) => ({
            ...prevState,
            location: "location can not be empty",
          }));

          // document.getElementById("location").classList.add("error");
        } else {
          setErrors((prevState) => ({
            ...prevState,
            location: false,
          }));

          // document.getElementById("location").classList.remove("error");
        }
        break;

      case "item_name":
        if (e.target.value == "") {
          setErrors((prevState) => ({
            ...prevState,
            item_name: "name can not be empty",
          }));
          // document.getElementById("item_name").classList.add("error");
        } else {
          setErrors((prevState) => ({
            ...prevState,
            item_name: false,
          }));

          // document.getElementById("item_name").classList.remove("error");
        }
        break;

      case "is_pizza":
        if (e.target.value == "") {
          setErrors((prevState) => ({
            ...prevState,
            is_pizza: "is_pizza can not be empty",
          }));

          // document.getElementById("is_pizza").classList.add("error");
        } else {
          setErrors((prevState) => ({
            ...prevState,
            is_pizza: false,
          }));

          // document.getElementById("is_pizza").classList.remove("error");
        }
        break;

      case "price":
        if (e.target.value == "") {
          setErrors((prevState) => ({
            ...prevState,
            price: "price can not be empty",
          }));

          // document.getElementById("price").classList.add("error");
        } else {
          setErrors((prevState) => ({
            ...prevState,
            price: false,
          }));
          // document.getElementById("price").classList.remove("error");
        }
        break;

      case "note":
        if (value.length >= 256) {
          setErrors((prevState) => ({
            ...prevState,
            note: "Enter Only 256 Characters",
          }));
        } else {
          setErrors((prevState) => ({
            ...prevState,
            note: false,
          }));
        }
        break;

      default:
    }
  };

  const onSubmite = (e) => {
    e.preventDefault();

    if (menuApiData.location === "") {
      setErrors((prevState) => ({
        ...prevState,
        location: "location can not be empty",
      }));
      // document.getElementById("location").classList.add("error");
    }

    if (menuApiData.item_name == "") {
      setErrors((prevState) => ({
        ...prevState,
        item_name: "item_name can not be empty",
      }));
      // document.getElementById("item_name").classList.add("error");
    }

    // if (menuApiData.sale_tax_rate == "") {
    //   setErrors((prevState) => ({
    //     ...prevState,
    //     sale_tax_rate: "sale_tax_rate can not be empty",
    //   }));
    //   document.getElementById("sale_tax_rate").classList.add("error");
    // }

    if (menuApiData.price == "") {
      setErrors((prevState) => ({
        ...prevState,
        price: "price can not be empty",
      }));
      // document.getElementById("price").classList.add("error");
    }

    // if (menuApiData.pizza == "") {
    //   setErrors((prevState) => ({
    //     ...prevState,
    //     pizza: "pizza can not be empty",
    //   }));
    //   document.getElementById("pizza").classList.add("error");
    // }

    // if (menuApiData.image == "") {
    //   setErrors((prevState) => ({
    //     ...prevState,
    //     image: "image can not be empty",
    //   }));
    //   // document.getElementById("image").classList.add("error");
    // }

    if (
      menuApiData.location !== "" &&
      menuApiData.item_name !== "" &&
      menuApiData.price !== "" &&
      // menuApiData.image !== "" &&
      !errors.location &&
      !errors.item_name &&
      // !errors.image &&
      !errors.price &&
      !errors.note
    ) {
      dispatch(
        createMenuDataAction({
          location: menuApiData.location,
          item_name: menuApiData.item_name,
          note: menuApiData.note,
          price: parseFloat(menuApiData.price)?.toFixed(2),
          // image: Image,
          is_pizza: menuApiData.is_pizza ? "1" : "0",
          status: menuApiData.status === "active" ? 1 : 0,
        })
      );
      setbtnLoading(true);
    }
    // if (errors?.image) {
    //   notifyError("Accept only image file");
    // }
  };

  const handledelete = () => {
    dispatch(deleteMenuData(deleteId));
    handledeleteclose();
  };

  const columns = [
    {
      name: "Location Name",
      selector: (row) => row.location,
      sortable: true,
    },
    {
      name: "Item Name",
      selector: (row) => row.item_name,
      sortable: true,
    },

    {
      name: "Price",
      selector: (row) => row.price,
      // width: "110px",
    },
    // {
    //   name: "Image",
    //   // selector: (row) => row.image,
    //   sortable: true,
    //   cell: (row) => (
    //     <>
    //       {/* <object data="images/menu-placeholder.jpg" type="image/png">
    //       <img src={row?.image} alt="Stack Overflow logo and icons and such" />
    //     </object> */}
    //       <img
    //         crossOrigin="anonymous"
    //         src={row?.image}
    //         height="70px"
    //         width="90px"
    //         // className="mt-2 mb-2"
    //         onError={imgDefault}
    //         className={classNames("mt-2 mb-2", {
    //           requireField: errors.image,
    //         })}
    //         // onerror="this.src='/images/menu-placeholder.jpg';"
    //       />
    //     </>
    //   ),
    // },
    {
      name: "Is_Pizza",
      selector: (row) => row.is_pizza,
      // width: "110px",
    },
    {
      name: "Status",
      selector: (row) => row.status,
      // width: "110px",
    },

    {
      name: "Action",
      selector: (row) => row.action,
      width: "100px",
    },
  ];
  // const imgDefault = (e) => {
  //   e.target.src = "/images/menu-placeholder.jpg";
  //   e.target.onerror = "/images/menu-placeholder.jpg";
  // };
  const data = menuList?.map((data, i) => {
    return {
      id: data._id,
      location: data?.locationdata[0]?.location_name
        ? data?.locationdata[0]?.location_name
        : "N/A",
      item_name: data?.item_name ? data?.item_name : "N/A",
      // image: data?.image ? data?.image : "N/A",
      price: data?.price
        ? `${"$" + parseFloat(data?.price)?.toFixed(2)}`
        : "N/A",
      is_pizza:
        data?.is_pizza === 1 ? (
          <span className="badge bg-dark" style={{ width: "60px" }}>
            Yes
          </span>
        ) : (
          <span className="badge bg-red" style={{ width: "60px" }}>
            No
          </span>
        ),
      status:
        data?.status === 1 ? (
          <span className="badge bg-dark" style={{ width: "67px" }}>
            Active
          </span>
        ) : (
          <span className="badge bg-red" style={{ width: "67px" }}>
            InActive
          </span>
        ),
      action: (
        <>
          <button
            className="btn p-0 me-2"
            onClick={() => {
              setEditShow(true);
              setSelctedMenuId(data?._id);
            }}
          >
            {" "}
            <i
              className="fa fa-pencil-square-o pointer fs-4"
              aria-hidden="true"
            ></i>
          </button>
          <button
            className="btn p-0"
            onClick={() => {
              //   onDelete(v._id);
              handledeleteShow();
              setIsDelete(true);
              setDeleteId(data?._id);
            }}
          >
            <i
              className="fa fa-trash text-danger fs-4 ms-1"
              aria-hidden="true"
            ></i>
          </button>
        </>
      ),
    };
  });

  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const filteredItems = data?.filter(
    (item) =>
      (item?.location &&
        item?.location?.toLowerCase()?.includes(filterText?.toLowerCase())) ||
      (item?.item_name &&
        item?.item_name?.toLowerCase()?.includes(filterText?.toLowerCase())) ||
      (item?.price &&
        item?.price?.toLowerCase()?.includes(filterText?.toLowerCase()))
  );

  const subHeaderComponentMemo = React.useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <FilterComponent
        onFilter={(e) => setFilterText(e.target.value)}
        onClear={handleClear}
        filterText={filterText}
      />
    );
  }, [filterText, resetPaginationToggle]);

  // useEffect(() => {
  //   $(".dropify")?.dropify();
  // }, []);
  useEffect(() => {
    $(".dropify")?.dropify();
  }, []);

  useEffect(() => {
    props.resetelocationwisemenu();
  }, [location]);

  useEffect(() => {
    if (props.importExcelReducer.success) {
      toast.success("File uploaded successfully.");
      setIsImportModal(false);
      setExcelFile("");
      props.getMenuDataAction();
      props.resetFileUpload();
    } else if (props.importExcelReducer.error) {
      toast.error("Upload Error");
    }
  }, [props.importExcelReducer]);
  console.log(menuList, "menuApiData");
  return (
    <>
      <Header />
      <main className="userMain">
        <div className="content-block">
          <div className="d-flex justify-content-between align-items-center mb-4">
            <div className="d-flex justify-content-center align-items-center">
              <Link to={"/"} className="text-dark">
                <i className="fa fa-house fs-2 me-3"></i>
              </Link>
              <h3 className="me-3">/</h3>
              <h3 className="fw-semibold">Menu Items</h3>
            </div>
            <div
              className="border rounded pointer px-4 py-2 text-white bg-red"
              onClick={() => {
                handleShow();
              }}
            >
              Create
            </div>
          </div>
          <div className="d-flex">
            {" "}
            <div className="form-group event-drp col-3 mb-1">
              <Form.Label>Location:</Form.Label>
              <select
                id="location"
                name="location"
                value={location}
                onChange={(e) => setlocation(e.target.value)}
                className="form-select"
              >
                <option value="" className="d-none">
                  Select Location
                </option>
                <option value="all" className="">
                  {`${"All Location (Menu items)"}`}
                </option>
                {allLocationData?.map((v, i) => {
                  return (
                    <option value={v?._id} key={i}>
                      {v.location_name}
                    </option>
                  );
                })}
              </select>
            </div>
            <div className="form-group event-drp col-2 mb-1 ms-3">
              <Form.Group>
                <Form.Label>Status:</Form.Label>
                <Form.Select
                  name="delivery_agent"
                  value={PizzaStatus}
                  onChange={(e) => setPizzaStatus(e.target.value)}
                >
                  <option value={"All"}>All</option>
                  <option value={"Yes"}>Pizza</option>
                  <option value={"No"}>Others</option>
                </Form.Select>
              </Form.Group>
            </div>
            <div className="form-group event-drp col-2 mt-4 ms-3">
              <button
                className="border rounded pointer px-4 py-2 text-white bg-red"
                onClick={() => {
                  // handleExportExcel();
                  // setexcelLoading(true);
                  setIsImportModal(true);
                }}
              >
                Import Excel
              </button>
              <button
                className="border rounded pointer px-4 py-2 text-white bg-red ml-2"
                onClick={() => {
                  const samplejson2 = menuList?.map((el) => ({
                    location: el?.locationdata?.[0]?.location_name,
                    item_name: el?.item_name,
                    note: el?.note,
                    price: el?.price,
                    "is_pizza (is Pizza = 1, not Pizza = 0)": el?.is_pizza,
                    "status (Active = 1, Inactive = 0)": el?.status,
                    id: el?._id
                  }));
                  exportToExcel(samplejson2, "downloadfilename");
                }}
              >
                Export Excel
              </button>
            </div>
           
          </div>

          <div>
            <Modal
              show={show}
              onHide={handleClose}
              backdrop="static"
              size="lg"
              keyboard={false}
            >
              <Modal.Header closeButton>
                <Modal.Title>Create Menu Items</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="form-area">
                  <Form className="row">
                    <div className="form-group event-drp col-6">
                      <Form.Label>Location:</Form.Label>
                      <select
                        id="location"
                        name="location"
                        onChange={onChangeInput}
                        className={classNames("form-select mb-3 p-2", {
                          requireField: errors.location,
                        })}
                      >
                        <option value="" className="d-none">
                          Select Location *
                        </option>
                        {allLocationData?.map((v, i) => {
                          return (
                            <option value={v?._id} key={i}>
                              {v.location_name}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                    <div className="col-6">
                      <Form.Group className="mb-3">
                        <Form.Label>Item Name:</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter Item Name"
                          name="item_name"
                          label="item_name"
                          onChange={onChangeInput}
                          className={classNames({
                            requireField: errors.item_name,
                          })}
                        />
                      </Form.Group>
                    </div>
                    <div className="col-6">
                      <Form.Group className="mb-3">
                        <Form.Label>Price: </Form.Label>
                        <Form.Control
                          type="number"
                          placeholder="Enter Price"
                          name="price"
                          label="price"
                          onChange={onChangeInput}
                          className={classNames({
                            requireField: errors.price,
                          })}
                        />
                      </Form.Group>
                    </div>
                    {/* <div className="col-12"> */}
                    {/* <Form.Group className="mb-3">
                      <Form.Label>Image: </Form.Label>
                      <div className="custom-file-upload">
                        <input
                          type="file"
                          name="image"
                          id="image"
                          accept="image/*"
                          label="image"
                          onChange={onChangeInput}
                          className={classNames({
                            requireField: errors.image,
                          })}
                        />
                        <label htmlFor="image">
                          {prevImg ? (
                            <div className="uploader-prev">
                              <img
                                className="img-fluid"
                                alt="droped image"
                                src={prevImg}
                              />
                              <span className="replace-btn">Replace</span>
                            </div>
                          ) : (
                            <div className="upload-placeholder text-center">
                              <i
                                className="fa fa-cloud-upload fa-3x"
                                aria-hidden="true"
                              ></i>
                              <h3>Upload File</h3>
                            </div>
                          )}
                        </label>
                      </div>
                    </Form.Group> */}
                    {/* </div> */}
                    <div className="d-flex">
                      {" "}
                      <div className="col-12 col-md-3">
                        {" "}
                        <Form.Group className="mb-2">
                          <Form.Label className="d-block">Is Pizza?</Form.Label>
                          <Form.Check
                            name="is_pizza"
                            type="switch"
                            id="custom-switch"
                            className={"pizzaBtn d-block ms-0"}
                            onChange={(e) =>
                              setmenuApiData((prevState) => ({
                                ...prevState,
                                is_pizza: e.target.checked,
                              }))
                            }
                          />
                        </Form.Group>
                      </div>
                      <div className="col-md-3 col-4">
                        <Form.Label>Status:</Form.Label>
                        <Form.Select
                          value={menuApiData.status}
                          onChange={(e) => {
                            setmenuApiData((prevState) => ({
                              ...prevState,
                              status: e.target.value,
                            }));
                          }}
                        >
                          <option value="active">Active</option>
                          <option value="Inactive">Inactive</option>
                        </Form.Select>
                      </div>
                    </div>
                    <div className="col-12 mt-2">
                      <Form.Group className="mb-3 ">
                        <Form.Label>Note:</Form.Label>
                        <Form.Control
                          as="textarea"
                          rows={3}
                          type="text"
                          placeholder="Enter Note"
                          name="note"
                          label="note"
                          id="note"
                          value={menuApiData?.note}
                          onChange={onChangeInput}
                          className={classNames("mb-3", {
                            requireField: errors.note,
                          })}
                        />
                      </Form.Group>
                    </div>

                    {/* <div className="">
                      <Button variant="primary" type="submit" className="mt-4">
                        Submit
                      </Button>
                    </div> */}
                  </Form>
                </div>{" "}
                <div className="text-end">
                  <Button
                    type="button"
                    className="border rounded pointer px-4 py-2 text-white bg-red"
                    onClick={onSubmite}
                  >
                    {btnLoading ? <Loader /> : "Submit"}
                  </Button>
                </div>
              </Modal.Body>
            </Modal>

            {allMenuData && allMenuData.length !== 0 ? (
              <>
                {/* <DataTable
                  columns={columns}
                  data={data}
                  pagination
                  fixedHeader
                  fixedHeaderScrollHeight="550px"
                /> */}
                <DataTable
                  columns={columns}
                  data={filteredItems}
                  pagination
                  paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
                  subHeader
                  subHeaderComponent={subHeaderComponentMemo}
                />
              </>
            ) : (
              <>
                {allMenuData && allMenuData.length !== 0 ? (
                  <>
                    <div className="d-flex justify-content-end">
                      <div
                        className="border rounded pointer px-4 py-2 text-white bg-red"
                        onClick={handleShow}
                      >
                        Create Menu Items
                      </div>
                    </div>
                    <DataTable
                      columns={columns}
                      data={data}
                      pagination
                      fixedHeader
                      fixedHeaderScrollHeight="550px"
                    />
                  </>
                ) : (
                  <>
                    {" "}
                    <div className="text-center h5 mt-5">
                      {" "}
                      No Menu Items available
                    </div>
                  </>
                )}
              </>
            )}
          </div>
        </div>
      </main>
      <Toaster />
      <Footer />
      {/* <DeletePopUp
        isOpen={isDelete}
        isClose={setIsDelete}
        handleEvent={() => dispatch(deleteMenuData(deleteId))}
      /> */}
      <Modal
        show={deleteShow}
        onHide={handledeleteShow}
        backdrop="static"
        keyboard={false}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <div className="d-flex justify-content-center align-items-center my-4 py-4">
          <div className="text-center">
            <h4 className="text-center mt-3">
              Are you sure about deleting this item?
            </h4>
            <div className="d-flex justify-content-center align-items-center mt-5">
              <button
                onClick={() => handledeleteclose()}
                className="text-white bg-red border-0 fs-5 rounded py-2 px-5 me-4"
              >
                Cancel
              </button>
              <button
                onClick={() => handledelete()}
                className="text-white bg-red border-0 fs-5 rounded py-2 px-5"
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      </Modal>
      {selectedMenuId && (
        <Modal
          show={EditShow}
          onHide={() => setEditShow(false)}
          size="lg"
          backdrop="static"
        >
          <EditMenu selectedMenuId={selectedMenuId} setEditShow={setEditShow} />
        </Modal>
      )}
      <Modal
        show={isImportModal}
        onHide={() => {
          setIsImportModal(false);
          setExcelFile("");
        }}
        size="lg"
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title>Upload Csv</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <a
            className="pointer"
            href="/Assets/sample_superadmin.xlsx"
            download="sample.xlsx"
          >
            <u>
              <h6>Download Sample File</h6>
            </u>
          </a>
          <div className="mt-4">
            {excelFile == "" && (
              <>
                <input
                  type="file"
                  onChange={(e) => {
                    setExcelFile(e.target.files[0]);
                  }}
                  accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                />
              </>
            )}
            {excelFile !== "" && (
              <div className="row mx-0 align-items-center">
                <div className="col-10 pointer">
                  <u>
                    <h5>{excelFile?.name}</h5>
                  </u>
                </div>
                <div className="col-2">
                  <button
                    className="btn p-0"
                    onClick={() => {
                      setExcelFile("");
                    }}
                  >
                    <i
                      className="fa fa-trash text-danger fs-4"
                      aria-hidden="true"
                    ></i>
                  </button>
                </div>
              </div>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div
            className="border rounded pointer px-4 py-2 text-white bg-red"
            onClick={() => {
              setIsImportModal(false);
              setExcelFile("");
            }}
          >
            Cancel
          </div>
          <div
            className="border rounded pointer px-4 py-2 text-white bg-red"
            onClick={() => {
              if (excelFile !== "") {
                props.UploadCsvMenuItem({
                  file: excelFile,
                });
                setlocation("all")
                setPizzaStatus("All")
              } else {
                notifyError("Selcet Excel file");
              }
            }}
          >
            Upload
          </div>
        </Modal.Footer>
        {/* <EditMenu selectedMenuId={selectedMenuId} setEditShow={setEditShow} /> */}
      </Modal>
    </>
  );
};

const mapStateToProp = (state) => ({
  createMenusReducer: state.createMenusReducer,
  getmenureducer: state.getMenuReducer,
  DeleteMenuItemsReducer: state.DeleteMenuItemsReducer,
  editMenuReducer: state.editMenuReducer,
  getLocationByMenuReducer: state.getLocationByMenu.getLocationByMenu,
  getLocationByMenuReducer: state.getLocationByMenu.getLocationByMenu,
  importExcelReducer: state.importMenuItemExcel.importExcel,
});

const mapDispatchToProps = (dispatch) => ({
  getAgentsdata: (Details) => dispatch(getAgentsData(Details)),
  getMenuDataAction: (Details) => dispatch(getMenuDataAction(Details)),
  UploadCsvMenuItem: (Details) => dispatch(UploadCsvMenuItem(Details)),
  getLocationByMenuDataAction: (id) =>
    dispatch(getLocationByMenuDataAction(id)),
  reserMenu: () => dispatch({ type: RESET_ADD_MENU_DATA }),
  resetdeletemenu: () => dispatch({ type: RESET_DELETE_MENUITEMS_DATA }),
  resetupdatemenu: () => dispatch({ type: RESET_UPDATE_MENU_DATA }),
  resetFileUpload: () => dispatch({ type: RESET_IMPORT_MENU_EXCEL }),
  resetelocationwisemenu: () =>
    dispatch({ type: RESET_GET_SUPER_ADMIN_LOCATION_BY_MENU }),
});
export default connect(mapStateToProp, mapDispatchToProps)(Menu);
