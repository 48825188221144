import React, { useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";
import "../Css/Custom.css";
import { Link, useNavigate } from "react-router-dom";
import { connect, useDispatch, useSelector } from "react-redux";
import Header from "../Userlayout/Header";
import Footer from "../Userlayout/Footer";
import {
  createAgentsdata,
  getAgentsData,
} from "../../../Store/Action/CreateAgents/UserAction";
import {
  contactValidation,
  countryCodeValidation,
  RESET_LOCATION_DATA,
  validEmailRegex,
  validPassword,
} from "../../../Store/type/Constant";
import Toaster from "../../../Components/Toaster/Toaster";
import { toast } from "react-toastify";

const CreateLocation = (props) => {
  const notifyError = (msg) => toast.error(msg);
  const [allinputvalue, setallinputvalue] = useState({
    location_name: "",
    email: "",
    number: "",
    name: "",
    password: "",
    color: "",
    confirmpassword: "",
    countrycode: "",
    status: "",
  });
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [errors, setErrors] = useState({
    location_name: false,
    email: false,
    number: false,
    name: false,
    password: false,
    color: false,
    confirmpassword: false,
    countrycode: false,
  });

  useEffect(() => {
    if (props.createAgentsReducer.success) {
      navigate("/superadmin/location");
    }
  }, [props.createAgentsReducer]);

  const agents = useSelector((state) => state.getAgentsReducer.user.data);

  useEffect(() => {
    dispatch(
      getAgentsData({
        page: 1,
        limit: 10,
      })
    );
  }, [getAgentsData]);

  useEffect(() => {
    setallinputvalue(agents);
  }, [agents]);

  const getValue = (e) => {
    const { name, value } = e.target;
    setallinputvalue((prevState) => ({
      ...prevState,
      [name]: value,
    }));

    switch (name) {
      case "location_name":
        if (e.target.value.length == 0) {
          setErrors((prevState) => ({
            ...prevState,
            location_name: "Location name can not be empty",
          }));

          document.getElementById("location_name").classList.add("error");
        } else {
          setErrors((prevState) => ({
            ...prevState,
            location_name: false,
          }));

          document.getElementById("location_name").classList.remove("error");
        }
        break;

      case "name":
        if (e.target.value.length == 0) {
          setErrors((prevState) => ({
            ...prevState,
            name: "name can not be empty",
          }));

          document.getElementById("name").classList.add("error");
        } else {
          setErrors((prevState) => ({
            ...prevState,
            name: false,
          }));

          document.getElementById("name").classList.remove("error");
        }
        break;

      case "number":
        if (e.target.value.length == 0) {
          setErrors((prevState) => ({
            ...prevState,
            number: "number can not be empty",
          }));

          document.getElementById("number").classList.add("error");
        } else if (!contactValidation.test(e.target.value)) {
          setErrors((prevState) => ({
            ...prevState,
            number: "Enter valid number",
          }));
          document.getElementById("number").classList.add("error");
        } else {
          setErrors((prevState) => ({
            ...prevState,
            number: false,
          }));

          document.getElementById("number").classList.remove("error");
        }
        break;

      case "countrycode":
        if (e.target.value.length == 0) {
          setErrors((prevState) => ({
            ...prevState,
            countrycode: "countrycode can not be empty",
          }));

          // document.getElementById("countrycode").classList.add("error");
        } else if (!countryCodeValidation.test(e.target.value)) {
          setErrors((prevState) => ({
            ...prevState,
            countrycode: "confirmpassword can not be match",
          }));
          document.getElementById("countrycode").classList.remove("error");
        } else {
          setErrors((prevState) => ({
            ...prevState,
            countrycode: false,
          }));

          // document.getElementById("countrycode").classList.remove("error");
        }
        break;

      case "email":
        if (e.target.value === "") {
          setErrors((prevState) => ({
            ...prevState,
            email: "Location Email can not be empty",
          }));

          document.getElementById("email").classList.add("error");
        } else if (!validEmailRegex.test(e.target.value)) {
          setErrors((prevState) => ({
            ...prevState,
            email: "Enter valid email",
          }));
          // document.getElementById("email").classList.add("error");
        } else {
          setErrors((prevState) => ({
            ...prevState,
            email: false,
          }));
          document.getElementById("email").classList.remove("error");
        }
        break;

      case "password":
        if (e.target.value.length == 0) {
          setErrors((prevState) => ({
            ...prevState,
            password: "Password can not be empty",
          }));

          document.getElementById("password").classList.add("error");
        } else if (!validPassword.test(e.target.value)) {
          setErrors((prevState) => ({
            ...prevState,
            password: "Enter valid alphanumeric password",
          }));
          document.getElementById("password").classList.add("error");
        } else {
          setErrors((prevState) => ({
            ...prevState,
            password: false,
          }));

          document.getElementById("password").classList.remove("error");
        }
        break;

      case "confirmpassword":
        if (e.target.value.length == 0) {
          setErrors((prevState) => ({
            ...prevState,
            confirmpassword: "confirmpassword can not be empty",
          }));

          document.getElementById("confirmpassword").classList.add("error");
        } else if (allinputvalue.password === allinputvalue.confirmpassword) {
          setErrors((prevState) => ({
            ...prevState,
            confirmpassword: "confirmpassword can not be match",
          }));
          document.getElementById("confirmpassword").classList.add("error");
        } else {
          setErrors((prevState) => ({
            ...prevState,
            confirmpassword: false,
          }));

          document.getElementById("confirmpassword").classList.remove("error");
        }
        break;

      case "color":
        if (e.target.value.length == 0) {
          setErrors((prevState) => ({
            ...prevState,
            color: "Location Color can not be empty",
          }));

          document.getElementById("color").classList.add("error");
        } else {
          setErrors((prevState) => ({
            ...prevState,
            color: false,
          }));

          document.getElementById("color").classList.remove("error");
        }
        break;

      default:
    }
  };

  const submiteForm = (e) => {
    e.preventDefault();

    for (let i = 0; i < agents.length; i++) {
      if (agents[i]?.locationdata[0]?.email === allinputvalue.email) {
        setErrors((prevState) => ({
          ...prevState,
          email: "Email already used",
        }));
      }
    }

    if (allinputvalue.location_name == "") {
      setErrors((prevState) => ({
        ...prevState,
        location_name: "Location name can not be empty",
      }));
      document.getElementById("location_name").classList.add("error");
    }

    if (allinputvalue.name == "") {
      setErrors((prevState) => ({
        ...prevState,
        name: "name can not be empty",
      }));
      document.getElementById("name").classList.add("error");
    }
    if (allinputvalue.email === "") {
      setErrors((prevState) => ({
        ...prevState,
        email: "Location email can not be empty",
      }));
      document.getElementById("email").classList.add("error");
    } else if (!validEmailRegex.test(allinputvalue.email)) {
      setErrors((prevState) => ({
        ...prevState,
        email: "Enter valid email",
      }));
      document.getElementById("email").classList.add("error");
    } else {
      setErrors((prevState) => ({
        ...prevState,
        email: false,
      }));
      document.getElementById("email").classList.remove("error");
    }

    if (allinputvalue.password == "") {
      setErrors((prevState) => ({
        ...prevState,
        password: "password can not be empty",
      }));
      document.getElementById("password").classList.add("error");
    } else if (!validPassword.test(allinputvalue.password)) {
      setErrors((prevState) => ({
        ...prevState,
        password: "Enter valid alphanumeric password",
      }));
      document.getElementById("password").classList.add("error");
    } else {
      setErrors((prevState) => ({
        ...prevState,
        password: false,
      }));
      document.getElementById("password").classList.remove("error");
    }

    if (allinputvalue.password !== allinputvalue.confirmpassword) {
      setErrors((prevState) => ({
        ...prevState,
        confirmpassword: "password cant not match",
        password: "password can not match",
      }));
    }
    if (allinputvalue.color == "") {
      setErrors((prevState) => ({
        ...prevState,
        color: "Location color can not be empty",
      }));
      document.getElementById("color").classList.add("error");
    }

    if (allinputvalue.number == "") {
      setErrors((prevState) => ({
        ...prevState,
        number: "number can not be empty",
      }));
      document.getElementById("number").classList.add("error");
    } else if (!validPassword.test(allinputvalue.number)) {
      setErrors((prevState) => ({
        ...prevState,
        number: "Enter valid number",
      }));
    } else {
      setErrors((prevState) => ({
        ...prevState,
        number: false,
      }));
    }

    if (allinputvalue.confirmpassword == "") {
      setErrors((prevState) => ({
        ...prevState,
        confirmpassword: "confirmpassword can not be empty",
      }));
      document.getElementById("confirmpassword").classList.add("error");
    }

    if (
      allinputvalue.location_name !== "" &&
      allinputvalue.name !== "" &&
      allinputvalue.email !== "" &&
      allinputvalue.password !== "" &&
      allinputvalue.color !== "" &&
      allinputvalue.number.length == 10 &&
      allinputvalue.confirmpassword !== "" &&
      // allinputvalue.countrycode !== "" &&
      allinputvalue.confirmpassword === allinputvalue.password &&
      !errors.password
    ) {
      setErrors({
        location_name: false,
        email: false,
        // number: false,
        name: false,
        color: false,
        password: false,
      });
      dispatch(
        createAgentsdata({
          location_name: allinputvalue.location_name,
          name: allinputvalue.name,
          email: allinputvalue.email,
          password: allinputvalue.password,
          color: allinputvalue.color,
          number: allinputvalue.number,
          // countrycode: allinputvalue.countrycode,
          confirmpassword: allinputvalue.confirmpassword,
          status: allinputvalue.status === "active" ? "1" : "0",
        })
      );
    }
  };

  return (
    <>
      <Header />
      <main className="userMain">
        <div className="content-block"> </div>
      </main>
      <Footer />
      <Toaster />
    </>
  );
};

const mapStateToProp = (state) => ({
  createAgentsReducer: state.createAgentsReducer,
  // getAgentsReducer: state.createAgentsReducer,
});

const mapDispatchToProps = (dispatch) => ({
  getLiabilityHandler: () => dispatch(getAgentsData()),
  resetlocation: () => dispatch({ type: RESET_LOCATION_DATA }),
});
export default connect(mapStateToProp, mapDispatchToProps)(CreateLocation);
