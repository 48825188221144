import React, { useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";
import { connect, useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
  LogInDataAction,
  getSuperAdminProfileDetails,
} from "../../../Store/Action/CreateAgents/UserAction";
import {
  RESET_SIGNIN_DATA,
  validEmailRegex,
  validPassword,
} from "../../../Store/type/Constant";
import classNames from "classnames";
import Toaster from "../../../Components/Toaster/Toaster";

// import { LogInDataAction } from "../../../Componant/Store/Action/CreateAgents/UserAction";
// import videoUrl from "../assets/production ID_5106444.mp4";
const Login = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const notifyError = (msg) => toast.error(msg);

  const [visible, setvisible] = useState(false);

  const [state, setState] = useState({
    email: "",
    password: "",
  });
  const [error, setError] = useState({
    email: false,
    password: false,
  });

  useEffect(() => {
    if (props.loginReducer.success) {
      const data = props.loginReducer.data.data;
      const token = props.loginReducer.data.token;
      localStorage.setItem("token", token);
      localStorage.setItem("usertype", data.type);
      localStorage.setItem("userid", data._id);
      props.getSuperAdminProfileDetails();
      props.resetLoginReducer();
      navigate("/superadmin/dashboard");
    } else if (props.loginReducer.error) {
      notifyError(props.loginReducer.errors);
      props.resetLoginReducer();
    }
  }, [props.loginReducer]);

  const onChangeInput = (e) => {
    const { name, value } = e.target;
    setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));

    switch (name) {
      case "email":
        if (value === "") {
          setError((prevState) => ({
            ...prevState,
            email: "Enter Email",
          }));
        } else if (!validEmailRegex.test(value)) {
          setError((prevState) => ({
            ...prevState,
            email: "Enter Valid Email",
          }));
        } else {
          setError((prevState) => ({
            ...prevState,
            email: false,
          }));
        }
        break;

      case "password":
        if (value === "") {
          setError((prevState) => ({
            ...prevState,
            password: "Enter Password",
          }));
        } else if (!validPassword.test(value)) {
          setError((prevState) => ({
            ...prevState,
            password: "Enter Valid Alphanumeric Password",
          }));
        } else {
          setError((prevState) => ({
            ...prevState,
            password: false,
          }));
        }
        break;

      default:
        break;
    }
  };

  const onSubmite = (e) => {
    e.preventDefault();

    if (state.email === "" && state.password === "") {
      setError((prevState) => ({
        ...prevState,
        email: "Enter Email",
        password: "Enter Password",
      }));
    }

    if (state.email === "") {
      setError((prevState) => ({
        ...prevState,
        email: "Enter Email",
      }));
    }
    if (state.password === "") {
      setError((prevState) => ({
        ...prevState,
        password: "Enter Password",
      }));
    }

    if (
      state.email !== "" &&
      state.password !== "" &&
      !error.email &&
      !error.password
    ) {
      // login function
      const details = {
        email: state.email,
        password: state.password,
      };
      props.LoginHandler(details);
    } else {
      Object.values(error).map((e) => e && notifyError(e));
    }
  };

  const passwordvisible = () => {
    setvisible(!visible);
  };

  return (
    <div className="login-page">
      <div className="row h-100">
        <div className="col-12 col-lg-6 col-xl-5">
          <div className="login-left">
            <div className="login-logo">
              <a href="#">
                <img crossOrigin="anonymous" src="/images/logo.png" />
              </a>
            </div>
            <h1 className="login-title text-red">Log In</h1>
            <p className="login-subtitle mb-4">
              Log in with your E-mail and Password.
            </p>
            <Form onSubmit={onSubmite}>
              <div className="form-group position-relative has-icon-left mb-4">
                <input
                  type="text"
                  className={classNames("form-control form-control-xl", {
                    requireField: error.email,
                  })}
                  placeholder="E-mail"
                  onChange={onChangeInput}
                  name="email"
                  id="email"
                  value={state.email}
                />
                <div className="form-control-icon">
                  <i className="fa fa-user-o"></i>
                </div>
              </div>
              <div className="form-group position-relative has-icon-left mb-4">
                <input
                  type={!!visible ? "text" : "password"}
                  placeholder="Password"
                  className={classNames("form-control form-control-xl", {
                    requireField: error.password,
                  })}
                  onChange={onChangeInput}
                  name="password"
                  id="password"
                  value={state.password}
                />
                <div className="form-control-icon">
                  <i className="fa fa-lock"></i>
                </div>
                <div
                  className="form-control-eye-icon pointer"
                  onClick={passwordvisible}
                >
                  {visible ? (
                    <i className="fa fa-eye"></i>
                  ) : (
                    <i className="fa fa-eye-slash"></i>
                  )}
                </div>
              </div>
              <button
                type="submit"
                className="btn bg-red text-white pointer btn-block btn-lg shadow-lg mt-xxl-5 mt-3"
              >
                Log In
              </button>
              <div className="text-center mt-3 text-lg fs-4">
                <p>
                  <a
                    className="text-red pointer"
                    onClick={() => navigate("/superadmin/forgot-password")}
                  >
                    Forgot password?
                  </a>
                </p>
              </div>
            </Form>
          </div>
        </div>
        <div className="col-lg-6 col-xl-7 d-none d-lg-block">
          <div
            className="d-flex justify-content-center align-items-center"
            style={{
              height: "100%",
              backgroundImage: `url('/images/panos-bg.jpg')`,
            }}
          >
            {/* <h1 className="text-white display-1 fw-bold">Panos</h1> */}
          </div>
        </div>
      </div>
      <Toaster />
    </div>
  );
};

const mapStateToProp = (state) => ({
  loginReducer: state.signinReducer.loginReducer,
  getSuperAdminProfileReducer: state.getSuperAdminProfileReducer,
});

const mapDispatchToProps = (dispatch) => ({
  LoginHandler: (details) => dispatch(LogInDataAction(details)),
  getSuperAdminProfileDetails: () => dispatch(getSuperAdminProfileDetails()),
  resetLoginReducer: () => dispatch({ type: RESET_SIGNIN_DATA }),
});
export default connect(mapStateToProp, mapDispatchToProps)(Login);
