import { ADD_CUSTOMER_ADDRESS_ERROR, ADD_CUSTOMER_ADDRESS_LOADING, ADD_CUSTOMER_ADDRESS_SUCCESS } from "../type/Constant";

let initialState = {
  loading: false,
  user: [{}],
  error: false,
  errors: [],
  success: false,
  payload: {}
};

export default function createcustomerAddressReducer(state = initialState, action) {
    
  switch (action.type) {

    case ADD_CUSTOMER_ADDRESS_LOADING:
      return { ...state, error: false, loading: true, success: false };

    case ADD_CUSTOMER_ADDRESS_SUCCESS:
      return { ...state, error: false, loading: false, success: true, message:action.msg };

    case ADD_CUSTOMER_ADDRESS_ERROR:
      return { ...state, error: true, loading: false, success: false, errors: [] }

    default:
      return state;
  }
}


