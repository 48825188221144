import React, { useState } from "react";
import { ViewLocationTotaltipAction } from "../../../Store/Action/CreateAgents/UserAction";
import { connect } from "react-redux";
import { useEffect } from "react";
import { Modal, Table } from "react-bootstrap";

const ViewTipDetails = (props) => {
  const [orderdata, setorderdata] = useState();
  const [Tipamounts, setTipamounts] = useState();
  useEffect(() => {
    props.ViewLocationTotaltipAction(props.selectedId);
  }, []);

  const tipDetails = props.getViewLocationByIdReducer?.data;

  return (
    <>
      <Modal.Header closeButton>
        <Modal.Title>Tip Amount</Modal.Title>
      </Modal.Header>
      <Modal.Body className="">
        <Table responsive="sm">
          {tipDetails?.data?.length !== 0 ? (
            <>
              <thead>
                <tr>
                  <th>Sr. No</th>
                  <th>Order Id</th>
                  <th>Name</th>
                  <th>Tip Amount</th>
                </tr>
              </thead>
              <tbody>
                {tipDetails?.data?.map((v, i) => {
                  if (v?.name !== "" && v?.amount !== "") {
                    return (
                      <tr key={i}>
                        <td>{i + 1}</td>
                        <td>{(v?.order_id?.order_name).toUpperCase()}</td>
                        <td>{v?.name ? v?.name : "N/A"}</td>
                        <td>${v?.amount ? parseFloat(v?.amount)?.toFixed(2) : "N/A"}</td>
                      </tr>
                    );
                  }
                })}
              </tbody>
            </>
          ) : (
            <div className="text-center h5" style={{ fontWeight: "bold" }}>
              There is no tip record in this location
            </div>
          )}
        </Table>
      </Modal.Body>
    </>
  );
};

const mapStateToProp = (state) => ({
  getViewLocationByIdReducer: state.getViewLocationByIdReducer.LocationTotalTip,
});

const mapDispatchToProps = (dispatch) => ({
  ViewLocationTotaltipAction: (details) =>
    dispatch(ViewLocationTotaltipAction(details)),
});
export default connect(mapStateToProp, mapDispatchToProps)(ViewTipDetails);
