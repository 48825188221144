import React, { useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import classNames from "classnames";
import { connect } from "react-redux";
import {
  adminLogin,
  getProfileDetails,
} from "../../Services/Actions/AuthAction";
import Toaster from "../../../Components/Toaster/Toaster";
import { toast } from "react-toastify";
import {
  RESET_FORGOT_PASSWORD,
  RESET_LOGIN,
  validEmailRegex,
  validPassword,
} from "../../Constants/Constants";

function AdminLogin(props) {
  // const validEmailRegex = RegExp(/^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i);
  // const validPassword = RegExp(/^(?=.*[0-9])(?=.*[a-zA-Z])([a-zA-Z0-9]+)([_@]*)$/)
  const notifyError = (msg) => toast.error(msg);

  const navigate = useNavigate();

  // const [loading, setLoading] = useState(true)
  const [state, setState] = useState({
    email: "",
    password: "",
  });
  const [error, setError] = useState({
    email: false,
    password: false,
  });

  const [visible, setvisible] = useState(false);

  useEffect(() => {
    if (props.loginReducer.success) {
      const data = props.loginReducer.data.data;
      const token = props.loginReducer.data.token;
      localStorage.setItem("token", token);
      localStorage.setItem("usertype", data.type);
      localStorage.setItem("userid", data.location);
      navigate("/admin/dashboard");
      props.resetLoginReducer();
      props.getProfileDetails();
    } else if (props.loginReducer.error) {
      notifyError(props.loginReducer.errors);
      setError((prevState) => ({
        ...prevState,
        email: false,
        password: false,
      }));
      setTimeout(() => {
        props.resetLoginReducer();
      }, 2500);
    }
  }, [props.loginReducer]);

  const onChangeInput = (e) => {
    const { name, value } = e.target;
    setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));

    switch (name) {
      case "email":
        if (value === "") {
          setError((prevState) => ({
            ...prevState,
            email: "Enter Email",
          }));
        } else if (!validEmailRegex.test(value)) {
          setError((prevState) => ({
            ...prevState,
            email: "Enter Valid Email",
          }));
        } else {
          setError((prevState) => ({
            ...prevState,
            email: false,
          }));
        }
        break;

      case "password":
        if (value === "") {
          setError((prevState) => ({
            ...prevState,
            password: "Enter Password",
          }));
        } else if (!validPassword.test(value)) {
          setError((prevState) => ({
            ...prevState,
            password: "Enter Valid Alphanumeric Password",
          }));
        } else {
          setError((prevState) => ({
            ...prevState,
            password: false,
          }));
        }
        break;

      default:
        break;
    }
  };

  const handleLogin = (e) => {
    e.preventDefault();

    if (state.email === "") {
      setError((prevState) => ({
        ...prevState,
        email: "Enter Email",
      }));
    }
    if (state.password === "") {
      setError((prevState) => ({
        ...prevState,
        password: "Enter Password",
      }));
    }

    if (
      state.email !== "" &&
      state.password !== "" &&
      !error.email &&
      !error.password
    ) {
      // login function
      const details = {
        email: state.email,
        password: state.password,
      };
      props.login(details);
    } else {
      Object.values(error).map((e) => e && notifyError(e));
    }
  };

  const passwordvisible = () => {
    setvisible(!visible);
  };

  useEffect(() => {
    if (props.forgotPasswordReducer?.success) {
      props.resetforgotpassword();
    }
  }, [props.forgotPasswordReducer]);

  return (
    <div className="login-page">
      <div className="row h-100">
        <div className="col-12 col-lg-6 col-xl-5">
          <div className="login-left">
            <div className="login-logo">
              <a href="#">
                <img crossOrigin="anonymous" src="/images/logo.png" />
              </a>
            </div>
            <h1 className="login-title text-red">Log In</h1>
            <p className="login-subtitle mb-4">
              Log in with your E-mail and Password.
            </p>
            <Form onSubmit={handleLogin}>
              <div className="form-group position-relative has-icon-left mb-4">
                <input
                  type="email"
                  className={classNames("form-control form-control-xl", {
                    requireField: error.email,
                  })}
                  placeholder="E-mail"
                  onChange={onChangeInput}
                  name="email"
                  value={state.email}
                  // autoComplete="off"
                />
                <div className="form-control-icon">
                  <i className="fa fa-user-o"></i>
                </div>
              </div>
              <div className="form-group position-relative has-icon-left mb-4">
                <input
                  type={!!visible ? "text" : "password"}
                  placeholder="Password"
                  className={classNames("form-control form-control-xl", {
                    requireField: error.password,
                  })}
                  onChange={onChangeInput}
                  name="password"
                  value={state.password}
                />
                <div className="form-control-icon">
                  <i className="fa fa-lock"></i>
                </div>
                <div
                  className="form-control-eye-icon pointer"
                  onClick={passwordvisible}
                >
                  {visible ? (
                    <i className="fa fa-eye"></i>
                  ) : (
                    <i className="fa fa-eye-slash"></i>
                  )}
                </div>
              </div>
              <button
                type="submit"
                className="btn bg-red text-white pointer btn-block btn-lg shadow-lg mt-xxl-5 mt-3"
              >
                Log In
              </button>
              <div className="text-center mt-3 text-lg fs-4">
                <div
                  className="text-red pointer"
                  onClick={() => navigate("/admin/forgotpassword")}
                >
                  Forgot password?
                </div>
              </div>
            </Form>
          </div>
        </div>
        <div className="col-lg-6 col-xl-7 d-none d-lg-block">
          <div
            className="d-flex justify-content-center align-items-center"
            style={{
              height: "100%",
              backgroundImage: `url('/images/panos-bg.jpg')`,
            }}
          ></div>
        </div>
      </div>
      <Toaster />
    </div>
  );
}
const mapStateToProp = (state) => ({
  loginReducer: state.AuthReducer.loginReducer,
  forgotPasswordReducer: state.PasswordReducer.forgotPassword,
});

const mapDispatchToProps = (dispatch) => ({
  login: (details) => dispatch(adminLogin(details)),
  getProfileDetails: () => dispatch(getProfileDetails()),
  resetLoginReducer: () => dispatch({ type: RESET_LOGIN }),
  resetforgotpassword: () => dispatch({ type: RESET_FORGOT_PASSWORD }),
});

export default connect(mapStateToProp, mapDispatchToProps)(AdminLogin);
