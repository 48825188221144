import React, { useEffect, useState } from "react";
import { Button, Form, Modal, Table } from "react-bootstrap";
import { connect, useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import DeletePopUp from "../../DeletePopup";
import {
  createPrmDataAction,
  deletePrmData,
  getAgentsData,
  getLocationByOrderDataAction,
  getLocationByPRMDataAction,
  getPrmDataAction,
} from "../../../../Store/Action/CreateAgents/UserAction";
import Footer from "../../Userlayout/Footer";
import Header from "../../Userlayout/Header";
import {
  contactValidation,
  countryCodeValidation,
  formatPhoneNumber,
  RESET_ADD_PRM_DATA,
  RESET_DELETE_PRM_DATA,
  RESET_LOCATION_DATA,
  RESET_UPDATE_PRM_DATA,
  Us_Pattern_Number,
  validEmailRegex,
  validPassword,
} from "../../../../Store/type/Constant";
import EditPrm from "./EditPrm";
import classNames from "classnames";
import { Pagination } from "@mui/material";
import Toaster from "../../../../Components/Toaster/Toaster";
import { toast } from "react-toastify";
import DataTable from "react-data-table-component";
import Spinner from "../../../../Components/Spinner/Spinner";
import { deletemenuitem } from "../../../../Admin/Services/Actions/menuAction";
import MaskedInput from "react-text-mask";
import { Loader } from "rsuite";

const FilterComponent = ({ filterText, onFilter, onClear }) => (
  <>
    <div className="form-outline prm-search mb-3">
      <label className="form-label me-2 mb-0" htmlFor="form1">
        {/* Search : */}
      </label>
      <input
        id="search"
        type="text"
        className="form-control"
        placeholder="Search"
        aria-label="Search Input"
        value={filterText}
        onChange={onFilter}
      />
    </div>
    {/* <ClearButton type="button" onClick={onClear}>
      X
    </ClearButton> */}
  </>
);

const Prm = (props) => {
  const [loading, setloading] = useState(false);
  const [isDelete, setIsDelete] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const dispatch = useDispatch();
  // const [prmApidata, setprmApidata] = useState([]);
  const [isContactAvailable, setIsContactAvailable] = useState(false);
  const successMsg = (msg) => toast.success(msg);

  const { user } = useSelector((state) => state);

  const [selectedPrmId, setSelctedPrmId] = useState();
  const [EditShow, setEditShow] = useState(false);

  const [isDeleteItem, setIsDeleteItem] = useState(false);
  const [deleteItemId, setDeleteItemId] = useState(null);

  const [visible, setvisible] = useState(false);
  const [Confirmvisible, setConfirmvisible] = useState(false);

  const [show, setShow] = useState(false);
  const [location, setlocation] = useState("");
  const [orderList, setOrderList] = useState([]);

  const [primaryAdminshow, setprimaryAdminShow] = useState(false);
  const handlePrimaryClose = () => setprimaryAdminShow(false);
  const handlePrimaryShow = () => setprimaryAdminShow(true);
  const [btnLoading, setbtnLoading] = useState(false);

  const [AllPRMdata, setAllPRMdata] = useState();

  const [prmApiData, setprmApiData] = useState({
    name: "",
    email: "",
    countrycode: "",
    password: "",
    confirmpassword: "",
    number: "",
    location: "",
    countrycode: "",
    status: "active",
  });
  const [errors, setErrors] = useState({
    name: false,
    email: false,
    countrycode: false,
    password: false,
    confirmpassword: false,
    number: false,
    location: false,
  });

  const [passwordCheck, setpasswordCheck] = useState({
    spacialcharacter: false,
    capitalLatter: false,
    smallLatter: false,
    passwordNumber: false,
    length: false,
  });

  const handleClose = () => {
    setprmApiData({
      name: "",
      email: "",
      countrycode: "",
      password: "",
      confirmpassword: "",
      number: "",
      location: "",
      countrycode: "",
      status: "active",
    });
    setErrors({
      name: false,
      email: false,
      countrycode: false,
      password: false,
      confirmpassword: false,
      number: false,
      location: false,
    });
    setpasswordCheck({
      spacialcharacter: false,
      capitalLatter: false,
      smallLatter: false,
      passwordNumber: false,
      length: false,
    });
    setShow(false);
  };

  var res = prmApiData?.number
    ?.replace(/\D+/g, "")
    .replace(/^(\d{3})(\d{3})(\d{4}).*/, "$1$2$3");

  const handleShow = () => setShow(true);

  const [deleteShow, setdeleteShow] = useState(false);

  const handledeleteShow = () => setdeleteShow(true);
  const handledeleteclose = () => setdeleteShow(false);

  const navigate = useNavigate();

  const notifyError = (msg) => toast.error(msg);

  const [pagination, setpagination] = useState({
    page: 1,
    limit: 10,
    totalpage: 1,
  });

  const allPrmData = useSelector((state) => state.getPrmReducer.user.data);

  const LocationByPRM = useSelector(
    (state) => state.getLocationByPRM.getLocationByPRM.data
  );

  useEffect(() => {
    dispatch(getAgentsData());
  }, []);

  useEffect(() => {
    if (!!LocationByPRM) {
      setOrderList([...LocationByPRM]);
    }
  }, [LocationByPRM]);

  useEffect(() => {
    if (!!location && location !== "" && location !== "all") {
      dispatch(getLocationByPRMDataAction(location));
    } else if (location == "all") {
      setOrderList([...allPrmData]);
    }
  }, [location]);

  useEffect(() => {
    if (props.createPrmReducer?.success) {
      setTimeout(() => {
        successMsg("PRM created successfully.... ");
      }, 100);
      props.resetprm();
      props.getPrmDataAction();
      handleClose();
      setbtnLoading(false);
    }
  }, [props.createPrmReducer]);

  useEffect(() => {
    if (props.editPrmReducer?.success) {
      setTimeout(() => {
        successMsg("PRM updated successfully. ");
      }, 100);
      props.resetUpdateprm();
      props.getPrmDataAction();
    }
  }, [props.editPrmReducer]);

  useEffect(() => {
    props.getPrmDataAction();
  }, []);

  useEffect(() => {
    setloading(props.getPrmReducer.loading);
    if (props.getPrmReducer?.success) {
      const data = props.getPrmReducer.data;
      const _pagination = props.getPrmReducer.pagination;
      // setpagination({ ..._pagination, page: parseInt(_pagination.page) - 1 });
      // setOrderList([...data])
    }
  }, [props.getPrmReducer]);

  useEffect(() => {
    if (props.createPrmReducer?.error) {
      notifyError(props.createPrmReducer.errors);
      setbtnLoading(false);
    }
  }, [props.createPrmReducer]);

  useEffect(() => {
    if (props.getPrmReducer?.success) {
      const data = props.getPrmReducer.user.data;
      setOrderList(data);
    }
  }, [props.getPrmReducer]);

  const [apiData, setapiData] = useState([]);

  const allLocationData = useSelector(
    (state) => state.getAgentsReducer.user.data
  );

  useEffect(() => {
    if (props.getPrmReducer.loading) {
      setloading(true);
    }
  }, [props.getPrmReducer]);

  useEffect(() => {
    if (props.DeletePrmReducer.success) {
      if (props.DeletePrmReducer?.data?.prm_deleted === 1) {
        setlocation("all");
        setTimeout(() => {
          successMsg("PRM deleted successfully. ");
        }, 100);
        setTimeout(() => {
          props.resetdeleteprm();
        }, 100);
        setIsDeleteItem(false);
        setDeleteItemId(null);
        props.getPrmDataAction();
      } else {
        notifyError(props.DeletePrmReducer.errors);
        props.resetdeleteprm();
      }
    } else {
      notifyError(props.DeletePrmReducer.errors);
    }
  }, [props.DeletePrmReducer]);

  const onChangeInput = (e) => {
    if (e.target.name === "number") {
      if (e.target.value.length <= 14) {
        setprmApiData((prevState) => ({
          ...prevState,
          [e.target.name]: e.target.value.replace(
            /^(\([0-9]{3}\) |[0-9]{3}-)[0-9]{3}-[0-9]{4}$/
          ),
        }));
      }
    }
    if (e.target.name === "password") {
      if (/[A-Z]/.test(e.target.value)) {
        setpasswordCheck((prevState) => ({
          ...prevState,
          capitalLatter: "Capital Latter",
        }));
      } else {
        setpasswordCheck((prevState) => ({
          ...prevState,
          capitalLatter: false,
        }));
      }
      if (/[a-z]/.test(e.target.value)) {
        setpasswordCheck((prevState) => ({
          ...prevState,
          smallLatter: "Small Latter",
        }));
      } else {
        setpasswordCheck((prevState) => ({
          ...prevState,
          smallLatter: false,
        }));
      }
      if (/[0-9]/.test(e.target.value)) {
        setpasswordCheck((prevState) => ({
          ...prevState,
          passwordNumber: "Number",
        }));
      } else {
        setpasswordCheck((prevState) => ({
          ...prevState,
          passwordNumber: false,
        }));
      }
      if (e.target.value.length >= 8) {
        setpasswordCheck((prevState) => ({
          ...prevState,
          length: "length",
        }));
      } else {
        setpasswordCheck((prevState) => ({
          ...prevState,
          length: false,
        }));
      }
      if (/[$&+,:;=?@#|'<>.^*()%!-]/.test(e.target.value)) {
        setpasswordCheck((prevState) => ({
          ...prevState,
          spacialcharacter: "Spacial Character",
        }));
      } else {
        setpasswordCheck((prevState) => ({
          ...prevState,
          spacialcharacter: false,
        }));
      }
    }
    setprmApiData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));

    switch (e.target.name) {
      case "location":
        if (e.target.value.length == 0) {
          setErrors((prevState) => ({
            ...prevState,
            location: "location can not be empty",
          }));
        } else {
          setErrors((prevState) => ({
            ...prevState,
            location: false,
          }));
        }
        break;

      case "name":
        if (e.target.value.length == 0) {
          setErrors((prevState) => ({
            ...prevState,
            name: "name can not be empty",
          }));
        } else {
          setErrors((prevState) => ({
            ...prevState,
            name: false,
          }));
        }
        break;

      case "email":
        if (e.target.value.length === "") {
          setErrors((prevState) => ({
            ...prevState,
            email: "Location Email can not be empty",
          }));
        } else if (!validEmailRegex.test(e.target.value)) {
          setErrors((prevState) => ({
            ...prevState,
            email: "Enter valid email",
          }));
        } else {
          setErrors((prevState) => ({
            ...prevState,
            email: false,
          }));
        }
        break;

      case "password":
        if (e.target.value.length == 0) {
          setErrors((prevState) => ({
            ...prevState,
            password: "Password can not be empty",
          }));
        } else {
          setErrors((prevState) => ({
            ...prevState,
            password: false,
          }));
        }
        break;

      case "confirmpassword":
        if (e.target.value.length == 0) {
          setErrors((prevState) => ({
            ...prevState,
            confirmpassword: "confirmpassword can not be empty",
          }));
        } else {
          setErrors((prevState) => ({
            ...prevState,
            confirmpassword: false,
          }));
        }
        break;

      case "number":
        if (e.target.value.length == 0) {
          setErrors((prevState) => ({
            ...prevState,
            number: "number can not be empty",
          }));
        } else if (!Us_Pattern_Number.test(e.target.value)) {
          setErrors((prevState) => ({
            ...prevState,
            number: "Enter valid number",
          }));
        } else {
          setErrors((prevState) => ({
            ...prevState,
            number: false,
          }));
        }
        break;

      case "countrycode":
        if (e.target.value.length == 0) {
          setErrors((prevState) => ({
            ...prevState,
            countrycode: "countrycode can not be empty",
          }));
        } else if (!countryCodeValidation.test(e.target.value)) {
          setErrors((prevState) => ({
            ...prevState,
            countrycode: "countrycode can not be match",
          }));
        } else {
          setErrors((prevState) => ({
            ...prevState,
            countrycode: false,
          }));
        }
        break;

      default:
    }
  };

  const onSubmite = (e) => {
    e.preventDefault();

    if (prmApiData.location == "") {
      setErrors((prevState) => ({
        ...prevState,
        location: "location can not be empty",
      }));
    }

    if (prmApiData.name == "") {
      setErrors((prevState) => ({
        ...prevState,
        name: "name can not be empty",
      }));
    }

    if (prmApiData.number == "") {
      setErrors((prevState) => ({
        ...prevState,
        number: "number can not be empty",
      }));
    } else if (!Us_Pattern_Number.test(prmApiData.number)) {
      setErrors((prevState) => ({
        ...prevState,
        number: "Enter valid number",
      }));
    } else {
      setErrors((prevState) => ({
        ...prevState,
        number: false,
      }));
    }

    if (prmApiData.email == "") {
      setErrors((prevState) => ({
        ...prevState,
        email: "email can not be empty",
      }));
    }

    if (prmApiData.password == "") {
      setErrors((prevState) => ({
        ...prevState,
        password: "password can not be empty",
      }));
    } else if (!validPassword.test(prmApiData.password)) {
      setErrors((prevState) => ({
        ...prevState,
        password: "Enter valid alphanumeric password",
      }));
    } else {
      setErrors((prevState) => ({
        ...prevState,
        password: false,
      }));
    }

    if (prmApiData.confirmpassword == "") {
      setErrors((prevState) => ({
        ...prevState,
        confirmpassword: "confirmpassword can not be empty",
      }));
    }
    // else if (!validPassword.test(prmApiData.confirmpassword)) {
    //   setErrors((prevState) => ({
    //     ...prevState,
    //     confirmpassword: "Enter valid alphanumeric confirmpassword",
    //   }));
    // }
    else {
      setErrors((prevState) => ({
        ...prevState,
        confirmpassword: false,
      }));
    }

    if (prmApiData.password !== prmApiData.confirmpassword) {
      notifyError("Password does not match");
    }

    if (prmApiData.countrycode === "") {
      setErrors((prevState) => ({
        ...prevState,
        countrycode: "countrycode can not be empty",
      }));
    }

    if (
      prmApiData.name !== "" &&
      prmApiData.location !== "" &&
      prmApiData.email !== "" &&
      prmApiData.password !== "" &&
      !errors.password &&
      prmApiData.number !== "" &&
      !errors.number &&
      prmApiData.confirmpassword !== "" &&
      prmApiData.password == prmApiData.confirmpassword
    ) {
      dispatch(
        createPrmDataAction({
          name: prmApiData.name,
          location: prmApiData.location,
          email: prmApiData.email,
          password: prmApiData.password,
          number: res,
          // countrycode: prmApiData.countrycode,
          confirmpassword: prmApiData.confirmpassword,
          status: prmApiData.status === "active" ? 1 : 0,
        })
      );
      setbtnLoading(true);
    }
    // notifyError("PRM field can not b empty");
  };

  useEffect(() => {
    setapiData(allLocationData);
  }, [allLocationData]);

  const columns = [
    {
      name: "Name",
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: "Contact no.",
      selector: (row) => row.number,
      sortable: true,
    },
    {
      name: "Location Name",
      selector: (row) => row.location,
      sortable: true,
    },
    {
      name: "Email Address",
      selector: (row) => row.email,
      sortable: true,
    },
    {
      name: "Status",
      selector: (row) => row.status,
      // width: "110px",
    },
    {
      name: "Action",
      selector: (row) => row.action,
      width: "100px",
    },
    // {
    //   name: "Color",
    //   selector: (row) => row.color,
    //   width: "100px",
    // },
  ];

  const data = orderList?.map((data, i) => {
    return {
      id: data._id,
      location: data.locationdata[0]?.location_name
        ? data.locationdata[0]?.location_name
        : "N/A",
      name: data?.name ? data?.name : "N/A",
      number: data?.number ? formatPhoneNumber(data?.number) : "N/A",
      email: data?.email ? data?.email : "N/A",
      color: (
        <div
          className="colorBox"
          style={{
            backgroundColor: data.color,
          }}
        ></div>
      ),
      status:
        data.status === 1 ? (
          <span className="badge bg-dark" style={{ width: "67px" }}>
            Active
          </span>
        ) : (
          <span className="badge bg-red" style={{ width: "67px" }}>
            InActive
          </span>
        ),
      action: (
        <>
          <button
            className="btn p-0 me-2"
            onClick={() => {
              setEditShow(true);
              setSelctedPrmId(data?._id);
            }}
          >
            <i
              className="fa fa-pencil-square-o pointer fs-4"
              aria-hidden="true"
            ></i>
          </button>
          <button
            className="btn p-0"
            onClick={() => {
              checkPrimaryadmin(data);
              setDeleteId(data?._id);
            }}
          >
            <i
              className="fa fa-trash pointer fs-4 ms-1 text-danger"
              aria-hidden="true"
            ></i>
          </button>
        </>
      ),
    };
  });

  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const filteredItems = data?.filter(
    (item) =>
      (item.location &&
        item.location.toLowerCase().includes(filterText.toLowerCase())) ||
      (item.name &&
        item.name.toLowerCase().includes(filterText.toLowerCase())) ||
      (item.email &&
        item.email.toLowerCase().includes(filterText.toLowerCase()))
  );

  const subHeaderComponentMemo = React.useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <FilterComponent
        onFilter={(e) => setFilterText(e.target.value)}
        onClear={handleClear}
        filterText={filterText}
      />
    );
  }, [filterText, resetPaginationToggle]);

  const passwordvisible = () => {
    setvisible(!visible);
  };
  const Confirmpasswordvisible = () => {
    setConfirmvisible(!Confirmvisible);
  };

  const handledelete = () => {
    dispatch(deletePrmData(deleteId));
    handledeleteclose();
  };

  const checkPrimaryadmin = (props) => {
    if (props.primary_admin === 1) {
      handlePrimaryShow();
    } else {
      handledeleteShow();
    }
  };

  return (
    <>
      <Header />
      <main className="userMain">
        <div className="content-block">
          <div className="d-flex justify-content-between align-items-center mb-4">
            <div className="d-flex justify-content-center align-items-center">
              <Link to={"/"} className="text-dark">
                <i className="fa fa-house fs-2 me-3"></i>
              </Link>
              <h3 className="me-3">/</h3>
              <h3 className="fw-semibold">PRM</h3>
            </div>
            <div
              className="border rounded pointer px-4 py-2 text-white bg-red"
              onClick={handleShow}
            >
              Create
            </div>
          </div>

          <div className="form-group event-drp col-lg-3 col-md-4 mb-1">
            <Form.Label>Location :</Form.Label>
            <select
              id="location"
              name="location"
              value={location}
              onChange={(e) => setlocation(e.target.value)}
              className="form-select"
            >
              <option value="" className="d-none">
                Select Location
              </option>
              <option value="all" className="">
                {`${"All Location (PRM)"}`}
              </option>
              {allLocationData?.map((v, i) => {
                return (
                  <option value={v?._id} key={i}>
                    {v.location_name}
                  </option>
                );
              })}
            </select>
          </div>

          <div>
            <Modal
              show={show}
              onHide={handleClose}
              backdrop="static"
              size="lg"
              keyboard={false}
            >
              <Modal.Header closeButton>
                <Modal.Title>Create PRM</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="form-area">
                  <Form className="row" autoComplete="off">
                    <div className="col-md-6 mt-24">
                      <Form.Label className="payment">Location:</Form.Label>
                      <div className="form-group event-drp ">
                        <select
                          // className={classNames("form-control team-name payterm", {
                          //   error: errors.location,
                          // })}
                          id="location"
                          name="location"
                          autoComplete="new-password"
                          onChange={onChangeInput}
                          className={classNames("col-6 mb-3 form-select", {
                            requireField: errors.location,
                          })}
                        >
                          <option value="" className="d-none">
                            Select Location *
                          </option>
                          {allLocationData?.map((v, i) => {
                            return (
                              <option value={v?._id} key={i}>
                                {v.location_name}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    </div>
                    <div className="col-6">
                      <Form.Group className="mb-3">
                        <Form.Label>Name:</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter PRM Name"
                          name="name"
                          label="name"
                          autoComplete="off"
                          onChange={onChangeInput}
                          className={classNames({
                            requireField: errors.name,
                          })}
                        />
                      </Form.Group>
                    </div>
                    <div className="col-6">
                      <Form.Group className="mb-3">
                        <Form.Label>Email Address: </Form.Label>
                        <Form.Control
                          type="email"
                          placeholder="Enter Email Address"
                          name="email"
                          label="email"
                          autoComplete="new-password"
                          onChange={onChangeInput}
                          className={classNames({
                            requireField: errors.email,
                          })}
                        />
                      </Form.Group>
                    </div>

                    <div className="col-6">
                      {/* <div className="col-2">
                          <Form.Group className="mb-3">
                            <Form.Label>Code :</Form.Label>
                            <Form.Control
                              type="number"
                              placeholder="+91"
                              // value={`+${prmApiData.countrycode}`}
                              name="countrycode"
                              label="code"
                              onChange={onChangeInput}
                              id="countrycode"
                              className={classNames({
                                requireField: errors.countrycode,
                              })}
                            />
                          </Form.Group>
                        </div> */}

                      <Form.Group className="col-6">
                        <Form.Label>Contact No:</Form.Label>
                        {/* <Form.Control
                          type="number"
                          placeholder="Enter Contact No."
                          name="number"
                          label="number"
                          maxLength={10}
                          value={prmApiData?.number}
                          onChange={onChangeInput}
                          className={classNames({
                            requireField: errors.number,
                          })}
                        /> */}{" "}
                        <MaskedInput
                          mask={[
                            "(",
                            /[0-9]/,
                            /[0-9]/,
                            /[0-9]/,
                            ")",
                            " ",
                            /[0-9]/,
                            /[0-9]/,
                            /[0-9]/,
                            "-",
                            /[0-9]/,
                            /[0-9]/,
                            /[0-9]/,
                            /[0-9]/,
                          ]}
                          // type="number"
                          name="number"
                          placeholder="Enter Contact No."
                          guide={false}
                          autoComplete="new-password"
                          id="my-input-id number"
                          value={prmApiData?.number}
                          onChange={onChangeInput}
                          className={classNames("form-control mb-3", {
                            requireField: errors.number,
                          })}
                        />
                      </Form.Group>
                    </div>
                    {/* <div className="row"> */}
                    <Form.Group className="mb-3 col-6">
                      <Form.Label style={{ fontWeight: "bold" }}>
                        Password:
                      </Form.Label>
                      <div className="password-field">
                        <Form.Control
                          name="password"
                          autocomplete="new-password"
                          type={!!visible ? "text" : "password"}
                          placeholder="Enter Password"
                          value={prmApiData.password}
                          onChange={onChangeInput}
                          className={classNames({
                            requireField: errors.password,
                          })}
                        />
                        <div
                          className="form-control-eye-icon pointer"
                          onClick={passwordvisible}
                        >
                          {visible ? (
                            <i
                              className="fa fa-eye"
                              style={{ marginLeft: "250px" }}
                            ></i>
                          ) : (
                            <i
                              className="fa fa-eye-slash "
                              style={{ marginLeft: "250px" }}
                            ></i>
                          )}
                        </div>
                      </div>
                    </Form.Group>
                    <Form.Group className="mb-3 col-6">
                      <Form.Label style={{ fontWeight: "bold" }}>
                        Confirm Password:
                      </Form.Label>
                      <div className="password-field">
                        <Form.Control
                          name="confirmpassword"
                          autoComplete="new-password"
                          placeholder="Enter Confirm Password"
                          type={!!Confirmvisible ? "text" : "password"}
                          value={prmApiData.confirmpassword}
                          onChange={onChangeInput}
                          className={classNames({
                            requireField: errors.confirmpassword,
                          })}
                        />
                        <div
                          className="form-control-eye-icon pointer"
                          onClick={Confirmpasswordvisible}
                        >
                          {Confirmvisible ? (
                            <i
                              className="fa fa-eye"
                              style={{ marginLeft: "250px" }}
                            ></i>
                          ) : (
                            <i
                              className="fa fa-eye-slash"
                              style={{ marginLeft: "250px" }}
                            ></i>
                          )}
                        </div>
                      </div>
                    </Form.Group>
                    {/* </div> */}
                    <div className="row">
                      <div
                        className="col-md-12 col-12 mt-2"
                        style={{ fontWeight: "bold" }}
                      >
                        <div
                          style={{
                            color: passwordCheck?.length ? "green" : "red",
                          }}
                        >
                          <span>
                            <i
                              className={`fa ${
                                passwordCheck?.length
                                  ? "fa-circle-check text-success"
                                  : "fa-circle-xmark text-danger"
                              } text-success fs-5 me-2`}
                            ></i>
                            Must be at least 8 characters.
                          </span>
                        </div>
                      </div>

                      <div
                        className="col-md-12 col-12 mt-2"
                        style={{ fontWeight: "bold" }}
                      >
                        <div
                          style={{
                            color: passwordCheck?.capitalLatter
                              ? "green"
                              : "red",
                          }}
                        >
                          <span>
                            <i
                              className={`fa ${
                                passwordCheck?.capitalLatter
                                  ? "fa-circle-check text-success"
                                  : "fa-circle-xmark text-danger"
                              } text-success fs-5 me-2`}
                            ></i>
                            Must be at least One upper case character (
                            A,B,C...etc )
                          </span>
                        </div>
                      </div>
                      <div
                        className="col-md-12 col-12 mt-2"
                        style={{ fontWeight: "bold" }}
                      >
                        <div
                          style={{
                            color: passwordCheck?.smallLatter ? "green" : "red",
                          }}
                        >
                          <span>
                            <i
                              className={`fa ${
                                passwordCheck?.smallLatter
                                  ? "fa-circle-check text-success"
                                  : "fa-circle-xmark text-danger"
                              } text-success fs-5 me-2`}
                            ></i>
                            Must be at least One lower case character (
                            a,b,c...etc )
                          </span>
                        </div>
                      </div>

                      <div
                        className="col-md-12 col-12 mt-2"
                        style={{ fontWeight: "bold" }}
                      >
                        <div
                          style={{
                            color: passwordCheck?.spacialcharacter
                              ? "green"
                              : "red",
                          }}
                        >
                          <span>
                            {" "}
                            <i
                              className={`fa ${
                                passwordCheck?.spacialcharacter
                                  ? "fa-circle-check text-success"
                                  : "fa-circle-xmark text-danger"
                              } text-success fs-5 me-2`}
                            ></i>
                            Must conatin at least special character (
                            #,@,$,...etc )
                          </span>
                        </div>
                      </div>
                      <div
                        className="col-md-12 col-12 mt-2"
                        style={{ fontWeight: "bold" }}
                      >
                        <div
                          style={{
                            color: passwordCheck?.passwordNumber
                              ? "green"
                              : "red",
                          }}
                        >
                          <i
                            className={`fa ${
                              passwordCheck?.passwordNumber
                                ? "fa-circle-check text-success"
                                : "fa-circle-xmark text-danger"
                            } text-success fs-5 me-2`}
                          ></i>
                          {/* <i className="fa fa-circle-xmark text-danger fs-4"></i> */}
                          Must conatin at least one number ( 1,2,3,...etc )
                        </div>
                      </div>
                    </div>
                    <div className="col-md-3 col-4 mt-2">
                      <Form.Label>Status:</Form.Label>
                      <Form.Select
                        value={prmApiData?.status}
                        onChange={(e) => {
                          setprmApiData((prevState) => ({
                            ...prevState,
                            status: e.target.value,
                          }));
                        }}
                      >
                        <option value="active">Active</option>
                        <option value="Inactive">Inactive</option>
                      </Form.Select>
                    </div>
                  </Form>
                </div>
                <div className="text-end">
                  <Button
                    type="button"
                    className="border rounded pointer px-4 py-2 text-white bg-red mt-4"
                    onClick={onSubmite}
                  >
                    {btnLoading ? <Loader /> : "Submit"}
                  </Button>
                </div>
              </Modal.Body>
            </Modal>

            {loading ? (
              <Spinner />
            ) : allPrmData && allPrmData.length !== 0 ? (
              <>
                <DataTable
                  columns={columns}
                  data={filteredItems}
                  pagination
                  paginationResetDefaultPage={resetPaginationToggle}
                  subHeader
                  subHeaderComponent={subHeaderComponentMemo}
                />
              </>
            ) : (
              <></>
            )}
          </div>
        </div>
      </main>
      <Toaster />
      <Footer />
      {/* <Button variant="primary" onClick={handlePrimaryShow}>
        Launch static backdrop modal
      </Button> */}

      <Modal
        show={primaryAdminshow}
        onHide={handlePrimaryClose}
        backdrop="static"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <div className="d-flex justify-content-center align-items-center my-4 py-4">
          <div className="text-center">
            <h4> Oops ...!</h4>
            <h4 className="text-center mt-3">
              This is a primary PRM at this location
            </h4>
            <h4 className="text-center mt-3">You can't delete this PRM user</h4>
            <div className="d-flex justify-content-center align-items-center mt-5">
              <button
                onClick={() => handlePrimaryClose()}
                className="text-white bg-red border-0 fs-5 rounded py-2 px-5"
              >
                OK
              </button>
            </div>
          </div>
        </div>
      </Modal>
      {/* <DeletePopUp
        isOpen={isDelete}
        isClose={setIsDelete}
        handleEvent={() => dispatch(deletePrmData(deleteId))}
      /> */}
      <Modal
        show={deleteShow}
        onHide={handledeleteShow}
        backdrop="static"
        keyboard={false}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <div className="d-flex justify-content-center align-items-center my-4 py-4">
          <div className="text-center">
            <h4 className="text-center mt-3">
              Are you sure about deleting this PRM?
            </h4>
            <div className="d-flex justify-content-center align-items-center mt-5">
              <button
                onClick={() => handledeleteclose()}
                className="text-white bg-red border-0 fs-5 rounded py-2 px-5 me-4"
              >
                Cancel
              </button>
              <button
                onClick={() => handledelete()}
                className="text-white bg-red border-0 fs-5 rounded py-2 px-5"
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      </Modal>
      {selectedPrmId && (
        <Modal
          show={EditShow}
          onHide={() => setEditShow(false)}
          size="lg"
          backdrop="static"
        >
          <EditPrm selectedPrmId={selectedPrmId} setEditShow={setEditShow} />
        </Modal>
      )}
    </>
  );
};

const mapStateToProp = (state) => ({
  createPrmReducer: state.createPrmReducer,
  getPrmReducer: state.getPrmReducer,
  DeletePrmReducer: state.DeletePrmReducer,
  editPrmReducer: state.editPrmReducer,
  getLocationByPRMreducer: state.getLocationByPRM.getLocationByPRM,
});

const mapDispatchToProps = (dispatch) => ({
  getPrmDataAction: (Details) => dispatch(getPrmDataAction(Details)),
  getLocationByPRMDataAction: (id) => dispatch(getLocationByPRMDataAction(id)),
  resetprm: () => dispatch({ type: RESET_ADD_PRM_DATA }),
  resetdeleteprm: () => dispatch({ type: RESET_DELETE_PRM_DATA }),
  resetUpdateprm: () => dispatch({ type: RESET_UPDATE_PRM_DATA }),
});
export default connect(mapStateToProp, mapDispatchToProps)(Prm);
