import {
  REPORTS_LOCATION_ORDER_COUNT_ERROR,
  REPORTS_LOCATION_ORDER_COUNT_LOADING,
  REPORTS_LOCATION_ORDER_COUNT_SUCCESS,
  REPORTS_ORDER_COUNT_ERROR,
  REPORTS_ORDER_COUNT_LOADING,
  REPORTS_ORDER_COUNT_SUCCESS,
  RESET_REPORTS_LOCATION_ORDER_COUNT,
  RESET_REPORTS_ORDER_COUNT,
} from "../type/Constant";

let initialState = {
  dateWiseOrder: {
    loading: false,
    data: null,
    error: false,
    errors: null,
    success: false,
  },
  dateWiseLocationOrder: {
    loading: false,
    data: null,
    error: false,
    errors: null,
    success: false,
  },
};

export default function reportsOrderReducer(state = initialState, action) {
  switch (action.type) {
    case REPORTS_ORDER_COUNT_LOADING:
      return {
        ...state,
        dateWiseOrder: {
          ...state.dateWiseOrder,
          loading: true,
          error: false,
          errors: [],
          success: false,
        },
      };

    case REPORTS_ORDER_COUNT_SUCCESS:
      return {
        ...state,
        dateWiseOrder: {
          ...state.dateWiseOrder,
          loading: false,
          error: false,
          errors: [],
          success: true,
          data: action.response.data,
        },
      };

    case REPORTS_ORDER_COUNT_ERROR:
      return {
        ...state,
        dateWiseOrder: {
          ...state.dateWiseOrder,
          loading: false,
          error: true,
          errors: action.errors,
          success: false,
          data: null,
        },
      };

    case RESET_REPORTS_ORDER_COUNT:
      return {
        ...state,
        dateWiseOrder: {
          ...state.dateWiseOrder,
          loading: false,
          error: false,
          errors: null,
          success: false,
        },
      };

    //   .....................................................

    case REPORTS_LOCATION_ORDER_COUNT_LOADING:
      return {
        ...state,
        dateWiseLocationOrder: {
          ...state.dateWiseLocationOrder,
          loading: true,
          error: false,
          errors: [],
          success: false,
        },
      };

    case REPORTS_LOCATION_ORDER_COUNT_SUCCESS:
      return {
        ...state,
        dateWiseLocationOrder: {
          ...state.dateWiseLocationOrder,
          loading: false,
          error: false,
          errors: [],
          success: true,
          data: action.response.data,
        },
      };

    case REPORTS_LOCATION_ORDER_COUNT_ERROR:
      return {
        ...state,
        dateWiseLocationOrder: {
          ...state.dateWiseLocationOrder,
          loading: false,
          error: true,
          errors: action.errors,
          success: false,
          data: null,
        },
      };

    case RESET_REPORTS_LOCATION_ORDER_COUNT:
      return {
        ...state,
        dateWiseLocationOrder: {
          ...state.dateWiseLocationOrder,
          loading: false,
          error: false,
          errors: null,
          success: false,
        },
      };

    default:
      return state;
  }
}
