import {
  GET_AGENTS_DATA_ERROR,
  GET_AGENTS_DATA_LOADING,
  GET_AGENTS_DATA_SUCCESS,
  RESET_LOCATION_DATA,
} from "../../type/Constant";

let initialState = {
  loading: false,
  user: [],
  pagination: {},
  error: false,
  errors: [],
  success: false,
};

export default function getAgentsReducer(state = initialState, action) {
  switch (action.type) {
    case GET_AGENTS_DATA_LOADING:
      return { ...state, success: false, loading: true };

    case GET_AGENTS_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        user: action.payload,
        pagination: action.pagination,
      };

    case GET_AGENTS_DATA_ERROR:
      return { ...state, error: true, errors: action.errors };

    // case RESET_LOCATION_DATA:
    //   return {
    //     ...state,loading: false,error: false,errors: action.errors,success: false,
    //   };

    default:
      return state;
  }
}
