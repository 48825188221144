import React, { useEffect, useState } from "react";
import { Button, Form, Modal, Table } from "react-bootstrap";
import { connect, useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import {
  contactValidation,
  countryCodeValidation,
  LOGIN_ERROR,
  pinCodeValidation,
  validEmailRegex,
} from "../../../Admin/Constants/Constants";
import {
  createCutomerDataAction,
  deleteCutomerData,
  getAgentsData,
  getCutomerDataAction,
  getlocationwisecustomerAction,
} from "../../../Store/Action/CreateAgents/UserAction";
import {
  formatPhoneNumber,
  onlyNumberValidation,
  RESET_ADD_CUSTOMER,
  RESET_DELETE_CUSTOMER_DATA,
  RESET_GET_CUSTOMER,
  RESET_LOCATION_WISE_CUSTOMER,
  RESET_UPDATE_CUSTOMER,
  Us_Pattern_Number,
} from "../../../Store/type/Constant";
import DeletePopUp from "../DeletePopup";
import Footer from "../Userlayout/Footer";
import Header from "../Userlayout/Header";
import EditCustomer from "./EditCustomer";
import classNames from "classnames";
import Toaster from "../../../Components/Toaster/Toaster";
import DataTable from "react-data-table-component";
import Spinner from "../../../Components/Spinner/Spinner";
import moment from "moment";
import { DatePicker, Loader } from "rsuite";
import { DatePickerComponent } from "@syncfusion/ej2-react-calendars";
import ReactDatePicker from "react-datepicker";
import $, { error } from "jquery";
import MaskedInput from "react-text-mask";
import CustomerDetails from "./CustomerDetails";

const FilterComponent = ({ filterText, onFilter, onClear }) => (
  <>
    <div className="form-outline order-search">
      <label className="form-label me-2 mb-0" htmlFor="form1">
        {/* Search: */}
      </label>
      <input
        id="search"
        type="text"
        className="form-control"
        placeholder="Search"
        aria-label="Search Input"
        value={filterText}
        onChange={onFilter}
      />
    </div>
    {/* <ClearButton type="button" onClick={onClear}>
      X
    </ClearButton> */}
  </>
);

const Customer = (props) => {
  const [loading, setloading] = useState(false);
  const dispatch = useDispatch();
  const [selectedCustomerID, setSelctedCustomerID] = useState();
  const [EditShow, setEditShow] = useState(false);
  const [DetailsShow, setDetailsShow] = useState(false);
  const [isDeleteItem, setIsDeleteItem] = useState(false);
  const [deleteItemId, setDeleteItemId] = useState(null);
  const [isCreateCustomers, setIsCreateCustomers] = useState(false);
  const [btnLoading, setbtnLoading] = useState(false);
  const [location, setlocation] = useState("");
  const [sorting, setsorting] = useState("");
  const [AllCustomerData, setAllCustomerData] = useState([]);

  const [isOpen, setIsOpen] = useState(true);
  const [dateRange, setDateRange] = useState(["", ""]);
  const [startDatee, endDatee] = dateRange;
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [orderList, setOrderList] = useState([]);

  const [deleteShow, setdeleteShow] = useState(false);

  const handledeleteShow = () => setdeleteShow(true);
  const handledeleteclose = () => setdeleteShow(false);

  const [SelectedCustomerDetailsId, setSelectedCustomerDetailsId] = useState();

  const successMsg = (msg) => toast.success(msg);

  const customer = useSelector((state) => state.getCustomerReducer.user.data);

  const allLocationData = useSelector(
    (state) => state.getAgentsReducer.user.data
  );

  const allorder = useSelector(
    (state) => state.SuperAdminOrderReducer.getOrder.data
  );
  // console.log("customer", customer);
  const [isDelete, setIsDelete] = useState(false);
  const [deleteId, setDeleteId] = useState(null);

  const [show, setShow] = useState(false);

  const [allCutomerValue, setallCutomerValue] = useState({
    name: "",
    email: "",
    dob: "",
    number: "",
    note: "",
    location: "",
  });

  const [errors, setErrors] = useState({
    name: false,
    email: false,
    number: false,
    dob: false,
    city: false,
    state: false,
    client_name: false,
    address1: false,
    address2: false,
    pincode: false,
    location: false,
  });

  const handleClose = () => {
    setallCutomerValue({
      name: "",
      email: "",
      dob: "",
      number: "",
      note: "",
      location: "",
    });
    setErrors({
      name: false,
      email: false,
      number: false,
      dob: false,
      city: false,
      state: false,
      client_name: false,
      address1: false,
      address2: false,
      location: false,
    });
    setShow(false);
  };

  var res = allCutomerValue?.number
    ?.replace(/\D+/g, "")
    .replace(/^(\d{3})(\d{3})(\d{4}).*/, "$1$2$3");

  const handleShow = () => setShow(true);

  const notifyError = (msg) => toast.error(msg);

  const [pagination, setpagination] = useState({
    page: 1,
    limit: 10,
    totalpage: 1,
  });

  const allCustomerdata = useSelector(
    (state) => state.getCustomerReducer.user.data
  );

  const locationwisecustomerdata = useSelector(
    (state) => state.getlocationwisecustomerReducer.getlocationwisecustomer.data
  );

  useEffect(() => {
    // if (!!location && location !== "" && location !== "all") {
    if (!!location && location !== "") {
      dispatch(
        getlocationwisecustomerAction({
          location: location,
          sorting: sorting,
          startDate:
            startDate !== "" ? moment(startDate).format("yyyy-MM-DD") : "",
          endDate: endDate !== "" ? moment(endDate).format("yyyy-MM-DD") : "",
        })
      );
    }
  }, [location]);

  useEffect(() => {
    // console.log("sorting", sorting);
    dispatch(
      getlocationwisecustomerAction({
        location: location,
        sorting: sorting,
        startDate:
          startDate !== "" ? moment(startDate).format("yyyy-MM-DD") : "",
        endDate: endDate !== "" ? moment(endDate).format("yyyy-MM-DD") : "",
      })
    );
    dispatch({ type: RESET_LOCATION_WISE_CUSTOMER });
  }, [sorting]);

  useEffect(() => {
    if (!!locationwisecustomerdata) {
      setAllCustomerData([...locationwisecustomerdata]);
      // props.resetlocationwisecustomer()
    }
  }, [locationwisecustomerdata]);

  useEffect(() => {
    if (props.getCustomerReducer?.loading) {
      setloading(true);
    }
  }, [props.getCustomerReducer]);

  useEffect(() => {
    props.getCustomerHandler();
  }, []);
  // .................. Create Customer .............

  const [addInput, setaddInput] = useState([
    {
      client_name: "",
      address1: "",
      address2: "",
      pincode: "",
      city: "",
      state: "",
    },
  ]);

  const [addInputError, setaddInputError] = useState([
    {
      client_name: false,
      address1: false,
      address2: false,
      pincode: false,
      city: false,
      state: false,
    },
  ]);

  const addInputValue = () => {
    const addtipvalidation = addInput?.map((e, i) => {
      if (
        e.client_name !== "" &&
        e.address1 !== "" &&
        // e.address2 !== "" &&
        e.pincode !== "" &&
        e.pincode.toString().length === 5 &&
        e.city !== "" &&
        e.state !== ""
      ) {
        return true;
      } else return false;
    });

    const x = addtipvalidation?.every((e, i) => {
      return e;
    });
    if (x) {
      setaddInput([
        ...addInput,
        {
          client_name: "",
          address1: "",
          address2: "",
          pincode: "",
          city: "",
          state: "",
        },
      ]);
      setaddInputError([
        ...addInputError,
        {
          client_name: false,
          address1: false,
          address2: false,
          pincode: false,
          city: false,
          state: false,
        },
      ]);
    } else {
      const error = [...addInputError];
      const findIndex = addtipvalidation.findIndex((e) => !e);
      const list = {
        client_name: addInput[findIndex].client_name === "" ? "" : false,
        address1: addInput[findIndex].address1 === "" ? "" : false,
        address2: addInput[findIndex].address2 === "" ? "" : false,
        state: addInput[findIndex].state === "" ? "" : false,
        city: addInput[findIndex].city === "" ? "" : false,
        pincode: addInput[findIndex].pincode === "" ? "" : false,
      };
      error.splice(findIndex, 1, list);
      setaddInputError(error);
    }
    // addInputValueError();
  };
  // const addInputValueError = () => {
  //   const addtipvalidation = addInputError?.map((e, i) => {
  //     if (
  //       e.client_name === false &&
  //       e.address1 === false &&
  //       e.address2 === false &&
  //       e.pincode === false &&
  //       e.city === false &&
  //       e.state === false
  //     ) {
  //       return true;
  //     } else return false;
  //   });

  //   const x = addtipvalidation?.every((e, i) => {
  //     return e;
  //   });
  //   if (x) {
  // setaddInputError([
  //   ...addInputError,
  //   {
  //     client_name: false,
  //     address1: false,
  //     address2: false,
  //     pincode: false,
  //     city: false,
  //     state: false,
  //   },
  // ]);
  //   }
  // };

  const removeInputeValue = (index) => {
    const list = [...addInput];
    list.splice(index, 1);
    setaddInput(list);

    for (let i = 0; i < addInput.length; i++) {
      if (addInput?.length === 1) {
        const list = [...addInput];
        list.splice(index, 0);
        setaddInput(list);
      }
    }
  };

  // const allCustomerdata = useSelector(
  //   (state) => state.getCustomerReducer.user.data
  // );

  useEffect(() => {
    setloading(props.getCustomerReducer.loading);
    if (props.getCustomerReducer?.success) {
      const data = props.getCustomerReducer?.user?.data;
      setAllCustomerData(data);
      handleClose();
    }
  }, [props.getCustomerReducer]);

  useEffect(() => {
    if (props.createCustomerReducer?.error) {
      notifyError(props.createCustomerReducer.errors);
      props.resetaddCustomer();
      setbtnLoading(false);
    }
  }, [props.createCustomerReducer]);

  useEffect(() => {
    if (props.DeleteCustomerReducer.success) {
      setIsDeleteItem(false);
      setDeleteItemId(null);
      props.getCustomerHandler();
      props.resetdeletecustomer();
    }
  }, [props.DeleteCustomerReducer]);

  useEffect(() => {
    dispatch(
      getAgentsData({
        page: 1,
        limit: 10,
      })
    );
  }, [getAgentsData]);

  useEffect(() => {
    if (props.createCustomerReducer.success) {
      props.getCustomerHandler({
        page: 1,
        limit: 10,
      });
      setTimeout(() => {
        successMsg("Customer created successfully. ");
      }, 100);
      setTimeout(() => {
        props.resetaddCustomer();
      }, 100);
      setbtnLoading(false);
    }
  }, [props.createCustomerReducer]);

  useEffect(() => {
    if (props.editCustomerReducer.success) {
      setTimeout(() => {
        successMsg("Customer updated successfully. ");
      }, 100);
      setTimeout(() => {
        props.resetupdateCustomer();
      }, 100);
      props.getCustomerHandler();
    }
  }, [props.editCustomerReducer]);

  useEffect(() => {
    if (props.DeleteCustomerReducer.success) {
      setTimeout(() => {
        successMsg("Customer deleted successfully. ");
      }, 100);
    }
  }, [props.DeleteCustomerReducer]);

  const getValue = (e, index) => {
    const nameregex = /^[A-Za-z]+$/;
    if (e.target.name === "number") {
      if (e.target.value.length <= 14) {
        setallCutomerValue((prevState) => ({
          ...prevState,
          [e.target.name]: e.target.value.replace(
            /^(\([0-9]{3}\) |[0-9]{3}-)[0-9]{3}-[0-9]{4}$/
          ),
        }));
      } else return;
    } else if (e.target.name === "note") {
      if (e.target.value.length <= 256) {
        setallCutomerValue((prevState) => ({
          ...prevState,
          [e.target.name]: e.target.value,
        }));
      } else return;
    } else if (e.target.name === "pincode") {
      if (e.target.value.length <= 5) {
        setaddInput((prevState) => ({
          ...prevState,
          [e.target.name]: e.target.value,
        }));
      } else return;
    } else if (e.target.name === "name") {
      if (e.target.value.length <= 65) {
        setallCutomerValue((prevState) => ({
          ...prevState,
          [e.target.name]: e.target.value,
        }));
      } else return;
    }
    setallCutomerValue((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));

    if (
      e.target.name === "client_name" ||
      e.target.name === "address1" ||
      e.target.name === "address2" ||
      e.target.name === "city" ||
      e.target.name === "state" ||
      e.target.name === "pincode"
    ) {
      const list = [...addInput];
      list[index][e.target.name] = e.target.value;
      setaddInput(list);
    }

    switch (e.target.name) {
      case "name":
        if (e.target.value === "") {
          setErrors((prevState) => ({
            ...prevState,
            name: "name can not be empty",
          }));
          setallCutomerValue((prevState) => ({
            ...prevState,
            name: e.target.value,
          }));
        } else {
          setErrors((prevState) => ({
            ...prevState,
            name: false,
          }));
        }
        break;

      case "state":
        if (e.target.value === "") {
          const list = addInputError.map((e, i) => {
            if (i === index) {
              return {
                ...e,
                state: "",
              };
            } else return e;
          });
          setaddInputError(list);
        } else {
          const list = addInputError.map((e, i) => {
            if (i === index) {
              return {
                ...e,
                state: false,
              };
            } else return e;
          });
          setaddInputError(list);
        }
        break;

      case "city":
        if (e.target.value === "") {
          const list = addInputError.map((e, i) => {
            if (i === index) {
              return {
                ...e,
                city: "",
              };
            } else return e;
          });
          setaddInputError(list);
        } else {
          const list = addInputError.map((e, i) => {
            if (i === index) {
              return {
                ...e,
                city: false,
              };
            } else return e;
          });
          setaddInputError(list);
        }
        break;

      case "number":
        if (e.target.value.length == 0) {
          setErrors((prevState) => ({
            ...prevState,
            number: "number can not be empty",
          }));
        } else if (!Us_Pattern_Number.test(e.target.value)) {
          setErrors((prevState) => ({
            ...prevState,
            number: "Enter valid number",
          }));
        } else {
          setErrors((prevState) => ({
            ...prevState,
            number: false,
          }));
        }
        break;

      case "countrycode":
        if (e.target.value.length == 0) {
          setErrors((prevState) => ({
            ...prevState,
            countrycode: "countrycode can not be empty",
          }));

          document.getElementById("countrycode").classList.add("error");
        } else if (countryCodeValidation.test(e.target.value)) {
          setErrors((prevState) => ({
            ...prevState,
            countrycode: "confirmpassword can not be match",
          }));
          document.getElementById("countrycode").classList.remove("error");
        } else {
          setErrors((prevState) => ({
            ...prevState,
            countrycode: false,
          }));
          document.getElementById("countrycode").classList.remove("error");
        }
        break;

      case "email":
        if (e.target.value === "") {
          setErrors((prevState) => ({
            ...prevState,
            email: false,
          }));
        } else if (!validEmailRegex.test(e.target.value)) {
          setErrors((prevState) => ({
            ...prevState,
            email: "Enter valid email",
          }));
        } else {
          setErrors((prevState) => ({
            ...prevState,
            email: false,
          }));
        }
        break;

      case "note":
        if (e.target.value === "") {
          setErrors((prevState) => ({
            ...prevState,
            note: "note can not be empty",
          }));

          document.getElementById("note").classList.add("error");
        } else {
          setErrors((prevState) => ({
            ...prevState,
            note: false,
          }));

          document.getElementById("note").classList.remove("error");
        }
        break;

      case "client_name":
        if (e.target.value === "") {
          const list = addInputError.map((e, i) => {
            if (i === index) {
              return {
                ...e,
                client_name: "",
              };
            } else return e;
          });
          setaddInputError(list);
        } else {
          const list = addInputError.map((e, i) => {
            if (i === index) {
              return {
                ...e,
                client_name: false,
              };
            } else return e;
          });
          setaddInputError(list);
        }
        break;
      case "address1":
        if (e.target.value === "") {
          const list = addInputError.map((e, i) => {
            if (i === index) {
              return {
                ...e,
                address1: "",
              };
            } else return e;
          });
          setaddInputError(list);
        } else {
          const list = addInputError.map((e, i) => {
            if (i === index) {
              return {
                ...e,
                address1: false,
              };
            } else return e;
          });
          setaddInputError(list);
        }
        break;
      // case "address2":
      //   if (e.target.value === "") {
      //     const list = addInputError.map((e, i) => {
      //       if (i === index) {
      //         return {
      //           ...e,
      //           address2: "",
      //         };
      //       } else return e;
      //     });
      //     setaddInputError(list);
      //   } else {
      //     const list = addInputError.map((e, i) => {
      //       if (i === index) {
      //         return {
      //           ...e,
      //           address2: false,
      //         };
      //       } else return e;
      //     });
      //     setaddInputError(list);
      //   }
      //   break;

      case "pincode":
        if (e.target.value === "") {
          const list = addInputError.map((e, i) => {
            if (i === index) {
              return {
                ...e,
                pincode: "",
              };
            } else return e;
          });
          setaddInputError(list);
        } else if (e.target.value.length < 5) {
          const list = addInputError.map((e, i) => {
            if (i === index) {
              return {
                ...e,
                pincode: "",
              };
            } else return e;
          });
          setaddInputError(list);
        } else {
          const list = addInputError.map((e, i) => {
            if (i === index) {
              return {
                ...e,
                pincode: false,
              };
            } else return e;
          });
          setaddInputError(list);
        }
        break;

      default:
    }
  };

  const submiteForm = (e, i) => {
    e.preventDefault();
    const addtipvalidation = addInput?.map((e, i) => {
      if (
        e.client_name !== "" &&
        e.address1 !== "" &&
        // e.address2 !== "" &&
        e.pincode !== "" &&
        e.city !== "" &&
        e.state !== ""
      ) {
        return true;
      } else return false;
    });

    const x = addtipvalidation?.every((e, i) => {
      return e;
    });

    const addtipvalidationError = addInputError?.map((e, i) => {
      if (
        e.client_name !== "" &&
        e.address1 !== "" &&
        // e.address2 !== "" &&
        e.pincode !== "" &&
        e.city !== "" &&
        e.state !== ""
      ) {
        return true;
      } else return false;
    });

    const y = addtipvalidationError?.every((e, i) => {
      return e;
    });

    // for (let i = 0; i < allCustomerdata.length; i++) {
    //   if (allCustomerdata[i].email === allCutomerValue.email) {
    //     setErrors((prevState) => ({
    //       ...prevState,
    //       email: "already used email",
    //     }));
    //     document.getElementById("email").classList.add("error");
    //   }
    // }

    if (allCutomerValue.name == "") {
      setErrors((prevState) => ({
        ...prevState,
        name: "Location name can not be empty",
      }));
    }

    if (allCutomerValue.countrycode == "") {
      setErrors((prevState) => ({
        ...prevState,
        countrycode: "countrycode can not be empty",
      }));
      document.getElementById("countrycode").classList.add("error");
    }

    if (allCutomerValue.email == "") {
      setErrors((prevState) => ({
        ...prevState,
        email: false,
      }));
    } else if (
      allCutomerValue?.email !== "" &&
      !validEmailRegex.test(allCutomerValue.email)
    ) {
      setErrors((prevState) => ({
        ...prevState,
        email: "Enter valid emailllll",
      }));
      document.getElementById("email").classList.add("error");
    } else {
      setErrors((prevState) => ({
        ...prevState,
        email: false,
      }));
      document.getElementById("email").classList.remove("error");
    }

    if (allCutomerValue.number == "") {
      setErrors((prevState) => ({
        ...prevState,
        number: "number can not be empty",
      }));
    } else if (!Us_Pattern_Number.test(allCutomerValue.number)) {
      setErrors((prevState) => ({
        ...prevState,
        number: "Enter valid number",
      }));
    } else {
      setErrors((prevState) => ({
        ...prevState,
        number: false,
      }));
    }
    if (allCutomerValue.note == "") {
      setErrors((prevState) => ({
        ...prevState,
        note: "note can not be empty",
      }));
      document.getElementById("note").classList.add("error");
    }
    if (addInput.client_name === "") {
      setErrors((prevState) => ({
        ...prevState,
        client_name: "name can not be empty",
      }));
    }
    if (addInput.address1 === "") {
      setErrors((prevState) => ({
        ...prevState,
        address1: "address line 1 can not be empty",
      }));
    }
    // if (addInput.address2 === "") {
    //   setErrors((prevState) => ({
    //     ...prevState,
    //     address2: "address line 1 can not be empty",
    //   }));
    // }
    if (addInput.pincode === "") {
      setErrors((prevState) => ({
        ...prevState,
        pincode: "pincode can not be empty",
      }));
    }

    if (addInput.city === "") {
      setErrors((prevState) => ({
        ...prevState,
        city: "city can not be empty",
      }));
    }
    if (addInput.state === "") {
      setErrors((prevState) => ({
        ...prevState,
        state: "state can not be empty",
      }));
    }
    if (
      allCutomerValue.name !== "" &&
      // allCutomerValue.email !== "" &&
      allCutomerValue.number !== "" &&
      // allCutomerValue.dob !== "" &&
      !errors.number &&
      x &&
      y
    ) {
      dispatch(
        createCutomerDataAction({
          name: allCutomerValue.name,
          email: allCutomerValue.email,
          number: res,
          note: allCutomerValue.note,
          dob: allCutomerValue?.dob
            ? moment(allCutomerValue.dob).format("MM-DD-yyyy")
            : "",
          countrycode: allCutomerValue.countrycode,
          address: addInput.map((e) => {
            const list = {
              ...e,
              name: e.client_name,
            };
            delete list["client_name"];
            return list;
          }),
          state: allCutomerValue.state,
          city: allCutomerValue.city,
          customer_id: props.createCustomerReducer.data?._id,
          state: allCutomerValue.state,
          type: "1",
        })
      );
      setbtnLoading(true);
    }
  };

  const customSort = (rowA, rowB, field) => {
    const a = rowA[field].replace("$", "").replace(",", "");
    const b = rowB[field].replace("$", "").replace(",", "");
    if (Number(a) > Number(b)) {
      return 1;
    }
    if (Number(b) > Number(a)) {
      return -1;
    }
    return 0;
  };

  const columns = [
    {
      name: "Business Name",
      selector: (row) => row.name,
      sortable: true,
      // width:"360px"
    },
    {
      name: "Total Spend",
      selector: (row) => row.spend_amount,
      sortable: true,
      sortFunction: (rowA, rowB) => customSort(rowA, rowB, "spend_amount"),
      // width:"360px"
    },
    {
      name: "Total Orders",
      selector: (row) => row.total_order,
      sortable: true,
      // width:"360px"
    },
    {
      name: "Average Order Value",
      selector: (row) => row.avg_val,
      width: "225px",
      sortable: true,
      sortFunction: (rowA, rowB) => customSort(rowA, rowB, "avg_val"),
    },

    {
      name: "Action",
      selector: (row) => row.action,
      width: "150px",
    },
  ];

  const getDate = (e) => {
    setallCutomerValue((prevState) => ({
      ...prevState,
      dob: Number(e)
        ? moment(e).format("MM-DD-yyyy")
        : moment(new Date()).format("MM-DD-yyyy"),
    }));
  };

  const handledelete = () => {
    dispatch(deleteCutomerData(deleteId));
    handledeleteclose();
  };

  const data = AllCustomerData?.map((data, i) => {
    return {
      id: data?._id,
      name:
        data?.addresses.length == 1
          ? data?.addresses[0]?.name
          : data?.name
          ? data?.name
          : "N/A",
      number: data?.number ? formatPhoneNumber(data?.number) : "N/A",
      dob:
        data?.dob !== "" ? moment(data?.dob)?.format("MMMM Do, YYYY") : "N/A",
      email: data?.email ? data?.email : "N/A",
      status:
        data?.status == 1 ? (
          <span className="badge bg-dark">Active</span>
        ) : (
          <span className="badge bg-red">InActive</span>
        ),
      total_order: data?.total_order ? parseInt(data.total_order) : 0,
      avg_val: data?.avg_val
        ? `$${parseFloat(data.avg_val).toLocaleString("en-US", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}`
        : `$0`,
      spend_amount: data?.spend_amount
        ? `$${parseFloat(data?.spend_amount).toLocaleString("en-US", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}`
        : `$0`,
      action: (
        <>
          <button
            className="btn p-0 me-2"
            onClick={() => {
              setDetailsShow(true);
              setSelectedCustomerDetailsId(data?._id);
            }}
          >
            <i className="fa fa-eye fs-4 me-2 pointer" aria-hidden="true"></i>
          </button>
          <button
            className="btn p-0 me-2"
            onClick={() => {
              setEditShow(true);
              setSelctedCustomerID(data?._id);
            }}
          >
            <i
              className="fa fa-pencil-square-o pointer fs-4 "
              aria-hidden="true"
            ></i>
          </button>
          <button
            className="btn p-0"
            onClick={() => {
              handledeleteShow();
              setIsDelete(true);
              setDeleteId(data?._id);
            }}
          >
            <i
              className="fa fa-trash text-danger fs-4 ms-1"
              aria-hidden="true"
            ></i>
          </button>
        </>
      ),
    };
  });
  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const filteredItems = data?.filter(
    (item) =>
      (item.location &&
        item.location.toLowerCase().includes(filterText.toLowerCase())) ||
      (item.name &&
        item.name.toLowerCase().includes(filterText.toLowerCase())) ||
      (item.email &&
        item.email.toLowerCase().includes(filterText.toLowerCase()))
  );
// console.log("filteredItems", filteredItems)
  const subHeaderComponentMemo = React.useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <FilterComponent
        onFilter={(e) => setFilterText(e.target.value)}
        onClear={handleClear}
        filterText={filterText}
      />
    );
  }, [filterText, resetPaginationToggle]);

  // useEffect(() => {
  //   $("#someElem")?.datepicker();
  // }, [])

  useEffect(() => {
    if (
      startDate !== "" &&
      startDate !== "Invalid date" &&
      endDate !== "" &&
      endDate !== "Invalid date"
    ) {
      props.getlocationwisecustomerAction({
        location: location,
        sorting: sorting,
        startDate:
          startDate !== "" ? moment(startDate).format("yyyy-MM-DD") : "",
        endDate: endDate !== "" ? moment(endDate).format("yyyy-MM-DD") : "",
      });
    }
  }, [startDate, endDate]);

  useEffect(() => {
    if (
      startDate !== "" &&
      startDate !== null &&
      (endDate === "" || endDate === null) &&
      !isOpen
    ) {
      dateRange[1] = new Date();
      setEndDate(new Date());
    }
  }, [isOpen]);

  return (
    <>
      <Header />
      <main className="userMain">
        <div className="content-block">
          <div className="d-flex justify-content-between align-items-center mb-4">
            <div className="d-flex justify-content-center align-items-center">
              <Link to={"/"} className="text-dark">
                <i className="fa fa-house fs-2 me-3"></i>
              </Link>
              <h3 className="me-3">/</h3>
              <h3 className="fw-semibold">Customers</h3>
            </div>
            <div
              className="border rounded pointer px-4 py-2 text-white bg-red"
              onClick={() => {
                handleShow();
                setaddInput([
                  {
                    client_name: "",
                    address1: "",
                    address2: "",
                    pincode: "",
                    city: "",
                    state: "",
                  },
                ]);
                setaddInputError([
                  {
                    client_name: false,
                    address1: false,
                    address2: false,
                    pincode: false,
                    city: false,
                    state: false,
                  },
                ]);
              }}
            >
              Create
            </div>
          </div>

          <div className="row gx-2">
            <Form.Group className="col col-lg-2 col-md-3">
              <Form.Label>Location :</Form.Label>
              <select
                id="location"
                name="location"
                value={location}
                onChange={(e) => setlocation(e.target.value)}
                className="form-select"
              >
                <option value="" className="d-none">
                  Select Location
                </option>
                <option value="all" className="">
                  {`${"All Location (Customer)"}`}
                </option>
                {allLocationData?.map((v, i) => {
                  return (
                    <option value={v?._id} key={i}>
                      {v.location_name}
                    </option>
                  );
                })}
              </select>
            </Form.Group>

            <Form.Group className="col col-lg-2 col-md-3">
              <Form.Label>Sort :</Form.Label>
              <select
                id="sorting"
                name="sorting"
                value={sorting}
                onChange={(e) => setsorting(e.target.value)}
                className="form-select"
              >
                <option value="" className="d-none">
                  {!!sorting
                    ? sorting === "true"
                      ? "Spending ascending"
                      : "Spending descending"
                    : "Sorting"}
                </option>
                <option value="" className="">
                  None
                </option>
                <option value="true" className="">
                  Spending ascending
                </option>
                <option value="false" className="">
                  Spending descending
                </option>
              </select>
            </Form.Group>

            <Form.Group className="col col-lg-2 col-md-3">
              <Form.Label>From / To Date: </Form.Label>
              <ReactDatePicker
                onInputClick={() => setIsOpen(true)}
                onClickOutside={() => setIsOpen(false)}
                showYearDropdown
                selectsRange={true}
                startDate={startDatee}
                endDate={endDatee}
                placeholderText="MM-DD-YYYY"
                dateFormat={"MM-dd-yyyy"}
                className={classNames("form-control")}
                onChange={(date) => {
                  if (!!date) {
                    setDateRange(date);
                    setStartDate(date[0]);
                    setEndDate(date[1]);
                  } else {
                    setOrderList([...locationwisecustomerdata]);
                  }
                  if (date[1]) {
                    setIsOpen(false);
                  }
                }}
                // isClearable={true}
              />
            </Form.Group>
          </div>

          {loading ? (
            <Spinner />
          ) : (
            <>
              {customer && customer.length !== 0 ? (
                <>
                  <DataTable
                    columns={columns}
                    data={filteredItems}
                    pagination
                    paginationResetDefaultPage={resetPaginationToggle}
                    // title="Customer List"
                    subHeader
                    subHeaderComponent={subHeaderComponentMemo}
                  />
                  {/* <Pagination
                  count={pagination.totalpage}
                  page={parseInt(pagination.page)}
                  onChange={handleChangePage}
                /> */}
                </>
              ) : (
                <>
                  {" "}
                  <div className="text-center text-red h5 mt-5">
                    {" "}
                    No Customers are available
                  </div>
                </>
              )}
            </>
          )}
        </div>
      </main>
      <Toaster />
      <Footer />
      {/* <DeletePopUp
        isOpen={isDelete}
        isClose={setIsDelete}
        handleEvent={() => dispatch(deleteCutomerData(deleteId))}
      /> */}
      <Modal
        show={show}
        onHide={() => {
          handleClose();
          setallCutomerValue({
            name: "",
            email: "",
            dob: "",
            number: "",
            note: "",
          });
        }}
        backdrop="static"
        size="lg"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Create Customer</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="form-area">
            <Form className="row" onSubmit={submiteForm}>
              <div className="col-6">
                <Form.Group className="mb-3">
                  <Form.Label>Name:</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Customer Name"
                    name="name"
                    onChange={getValue}
                    // onChange={handleCustomerName}
                    label="name"
                    value={allCutomerValue.name}
                    id="name"
                    className={classNames({
                      requireField: errors.name,
                    })}
                  />
                </Form.Group>
              </div>
              <div className="col-6">
                {" "}
                <Form.Group className="mb-3">
                  <Form.Label>Email Address (Optional):</Form.Label>
                  <Form.Control
                    id="email"
                    type="email"
                    placeholder="Enter Email Address"
                    name="email"
                    onChange={getValue}
                    value={allCutomerValue.email}
                    label="email"
                    className={classNames({
                      requireField: errors.email,
                    })}
                  />
                </Form.Group>
              </div>
              <div className="">
                <div className="row">
                  <div className="col-6">
                    <Form.Label>Contact No.:</Form.Label>
                    <MaskedInput
                      mask={[
                        "(",
                        /[0-9]/,
                        /[0-9]/,
                        /[0-9]/,
                        ")",
                        " ",
                        /[0-9]/,
                        /[0-9]/,
                        /[0-9]/,
                        "-",
                        /[0-9]/,
                        /[0-9]/,
                        /[0-9]/,
                        /[0-9]/,
                      ]}
                      name="number"
                      placeholder="Enter Contact No"
                      guide={false}
                      id="my-input-id number"
                      value={allCutomerValue?.number}
                      onChange={getValue}
                      className={classNames("form-control mb-3", {
                        requireField: errors.number,
                      })}
                    />
                  </div>
                  <div className="col-6">
                    <Form.Label style={{ fontWeight: "bold" }}>
                      Date of Birth (Optional):
                    </Form.Label>
                    <ReactDatePicker
                      showYearDropdown
                      id="someElem"
                      // type="date"
                      name="dob"
                      // disabled={isContactAvailable}
                      autoComplete="off"
                      placeholderText="MM-DD-YYYY"
                      dateFormat={"MM-dd-yyyy"}
                      selected={allCutomerValue.dob}
                      className={classNames("form-control", {
                        requireField: errors.dob,
                      })}
                      maxDate={new Date()}
                      onChange={(date) => {
                        setallCutomerValue((prevState) => ({
                          ...prevState,
                          dob: date,
                        }));
                      }}
                    />
                  </div>

                  <div className="col-md-12">
                    <div className="address-container">
                      {addInput?.map((v, i) => {
                        return (
                          <div
                            className="col-12 border-bottom mb-3"
                            key={"cust" + i}
                          >
                            <Form.Group className="mb-3">
                              <Form.Label style={{ fontWeight: "bold" }}>
                                Business Name:
                              </Form.Label>
                              <Form.Control
                                onChange={(e) => getValue(e, i)}
                                type="text"
                                placeholder="Enter Business Name"
                                name="client_name"
                                label="client_name"
                                value={v.client_name}
                                id="client_name"
                                className={classNames({
                                  requireField:
                                    addInputError[i]?.client_name === "",
                                })}
                              />
                            </Form.Group>
                            <Form.Group className="mb-3">
                              <Form.Label style={{ fontWeight: "bold" }}>
                                Address Line 1:
                              </Form.Label>
                              <Form.Control
                                onChange={(e) => getValue(e, i)}
                                type="text"
                                placeholder="Enter Address line 1"
                                name="address1"
                                label="address1"
                                value={v.address1}
                                id="address1"
                                className={classNames({
                                  requireField:
                                    addInputError[i]?.address1 === "",
                                })}
                              />
                            </Form.Group>
                            <Form.Group className="mb-3">
                              <Form.Label style={{ fontWeight: "bold" }}>
                                Address Line 2 (Optional):
                              </Form.Label>
                              <Form.Control
                                onChange={(e) => getValue(e, i)}
                                type="text"
                                placeholder="Enter Address line 2"
                                name="address2"
                                label="address2"
                                value={v.address2}
                                id="address2"
                                // className={classNames({
                                //   requireField:
                                //     addInputError[i]?.address2 === "",
                                // })}
                              />
                            </Form.Group>
                            <div className="d-flex align-items-end  w-100">
                              <div className="row gx-2 w-100">
                                <div className="col-4">
                                  <Form.Group className="mb-3">
                                    <Form.Label
                                      style={{
                                        fontWeight: "bold",
                                      }}
                                    >
                                      City:
                                    </Form.Label>
                                    <Form.Control
                                      type="text"
                                      placeholder="Enter city"
                                      name="city"
                                      onChange={(e) => getValue(e, i)}
                                      value={v.city}
                                      label="city"
                                      id="city"
                                      className={classNames({
                                        requireField:
                                          addInputError[i]?.city === "",
                                      })}
                                    />
                                  </Form.Group>
                                </div>
                                <div className="col-4">
                                  <Form.Group className="mb-3">
                                    <Form.Label
                                      style={{
                                        fontWeight: "bold",
                                      }}
                                    >
                                      State:
                                    </Form.Label>
                                    <Form.Control
                                      type="text"
                                      placeholder="Enter State"
                                      name="state"
                                      onChange={(e) => getValue(e, i)}
                                      value={v.state}
                                      label="state"
                                      id="state"
                                      className={classNames({
                                        requireField:
                                          addInputError[i]?.state === "",
                                      })}
                                    />
                                  </Form.Group>
                                </div>
                                <div className="col-4">
                                  <Form.Group className="mb-3">
                                    <Form.Label
                                      style={{
                                        fontWeight: "bold",
                                      }}
                                    >
                                      Zip Code:
                                    </Form.Label>
                                    <Form.Control
                                      type="number"
                                      name="pincode"
                                      label="pincode"
                                      onChange={(e) => getValue(e, i)}
                                      value={v.pincode}
                                      placeholder="Enter Zip Code"
                                      id="pincode"
                                      className={classNames({
                                        requireField:
                                          addInputError[i]?.pincode === "",
                                      })}
                                    />
                                  </Form.Group>
                                </div>
                              </div>
                              {addInput?.length !== 1 && (
                                <button
                                  className="btn bg-dark text-white px-3 ms-2 mb-3"
                                  type="button"
                                  onClick={() => removeInputeValue(i)}
                                >
                                  <i className="fa fa-minus"></i>
                                </button>
                              )}
                            </div>
                          </div>
                        );
                      })}
                    </div>
                    <button
                      className="btn bg-red py-1 text-white add-more_Address"
                      type="button"
                      onClick={addInputValue}
                    >
                      <i className="fa-solid fa-plus"></i> Add Address
                    </button>
                  </div>

                  <div className="col-12 mt-2">
                    <Form.Group className="mb-3 ">
                      <Form.Label>Note:</Form.Label>
                      <Form.Control
                        as="textarea"
                        rows={3}
                        className="mb-3"
                        type="text"
                        placeholder="Enter Note"
                        name="note"
                        label="note"
                        id="note"
                        value={allCutomerValue?.note}
                        onChange={getValue}
                      />
                    </Form.Group>
                  </div>
                </div>
              </div>
              <div className="text-end">
                <Button
                  variant="primary"
                  type="submit"
                  className="border rounded pointer px-4 py-2 text-white bg-red mt-4"
                >
                  {btnLoading ? <Loader /> : "Submit"}
                </Button>
              </div>
            </Form>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={deleteShow}
        onHide={handledeleteShow}
        backdrop="static"
        keyboard={false}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <div className="d-flex justify-content-center align-items-center my-4 py-4">
          <div className="text-center">
            <h4 className="text-center mt-3">
              Are you sure about deleting this customer?
            </h4>
            <div className="d-flex justify-content-center align-items-center mt-5">
              <button
                onClick={() => handledeleteclose()}
                className="text-white bg-red border-0 fs-5 rounded py-2 px-5 me-4"
              >
                Cancel
              </button>
              <button
                onClick={() => handledelete()}
                className="text-white bg-red border-0 fs-5 rounded py-2 px-5"
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      </Modal>
      {selectedCustomerID && (
        <Modal
          show={EditShow}
          onHide={() => setEditShow(false)}
          size="lg"
          backdrop="static"
        >
          <EditCustomer
            selectedCustomerID={selectedCustomerID}
            setEditShow={setEditShow}
          />
        </Modal>
      )}
      {SelectedCustomerDetailsId && (
        <Modal
          show={DetailsShow}
          onHide={() => setDetailsShow(false)}
          size="md"
          backdrop="static"
        >
          <CustomerDetails
            SelectedCustomerDetailsId={SelectedCustomerDetailsId}
            setDetailsShow={setDetailsShow}
          />
        </Modal>
      )}
    </>
  );
};

const mapStateToProp = (state) => ({
  createCustomerReducer: state.createCustomerReducer,
  getCustomerReducer: state.getCustomerReducer,
  DeleteCustomerReducer: state.DeleteCustomerReducer,
  editCustomerReducer: state.editCustomerReducer,
  getlocationwisecustomerReducer:
    state.getlocationwisecustomerReducer.getlocationwisecustomer,
});

const mapDispatchToProps = (dispatch) => ({
  getCustomerHandler: (Details) => dispatch(getCutomerDataAction(Details)),
  getlocationwisecustomerAction: (Details) =>
    dispatch(getlocationwisecustomerAction(Details)),
  resetdeletecustomer: () => dispatch({ type: RESET_DELETE_CUSTOMER_DATA }),
  resetaddCustomer: () => dispatch({ type: RESET_ADD_CUSTOMER }),
  resetupdateCustomer: () => dispatch({ type: RESET_UPDATE_CUSTOMER }),
  resetCustomer: () => dispatch({ type: RESET_GET_CUSTOMER }),
  resetlocationwisecustomer: () =>
    dispatch({ type: RESET_LOCATION_WISE_CUSTOMER }),
});
export default connect(mapStateToProp, mapDispatchToProps)(Customer);
