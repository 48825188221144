import React, { useEffect, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { connect, useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  agentschangePassword,
  deleteCutomerAddressData,
  getDeliveryAgentsById,
  getDeliveryAgentsDataAction,
  updateDeliveryAgentsDataAction,
} from "../../../Store/Action/CreateAgents/UserAction";
import {
  contactValidation,
  countryCodeValidation,
  formatPhoneNumber,
  RESET_AGENTS_CHANGE_PASSWORD,
  RESET_GET_DELIVERY_BY_ID_AGENTS_DATA_SUCCESS,
  RESET_UPDATE_DELIVERY_AGENTS_DATA_SUCCESS,
  Us_Pattern_Number,
  validEmailRegex,
  validPassword,
} from "../../../Store/type/Constant";
import classNames from "classnames";
import { useNavigate } from "react-router";
import { type } from "@testing-library/user-event/dist/type";
import MaskedInput from "react-text-mask";
import { Loader } from "rsuite";
import Toaster from "../../../Components/Toaster/Toaster";

const EditDeliveryAgents = (props) => {
  const navigate = useNavigate();

  const notifyError = (msg) => toast.error(msg);
  const [ShowChangePassword, setShowChangePassword] = useState(false);

  const [visible, setvisible] = useState(false);
  const [Confirmvisible, setConfirmvisible] = useState(false);
  const [OldVisible, setOldVisible] = useState(false);
  const [btnLoading, setbtnLoading] = useState(false);

  const errorNotify = (msg) => toast.error(msg);
  const successTost = (msg) => toast.success(msg);

  const [state, setState] = useState({
    name: "",
    number: "",
    location: "",
  });
  const [error, setError] = useState({
    name: "",
  });

  const allLocationData = useSelector(
    (state) => state.getAgentsReducer.user.data
  );

  const [passwordState, setpasswordState] = useState({
    old_password: "",
    new_password: "",
    confirm_password: "",
  });

  const [passwordError, setpasswordError] = useState({
    old_password: false,
    new_password: false,
    confirm_password: false,
  });

  useEffect(() => {
    if (props.DeliveryAgentsId) {
      props.getDeliveryAgentsById(props.DeliveryAgentsId);
    }
  }, [props.DeliveryAgentsId]);

  useEffect(() => {
    if (props.getDeliveryAgentsByIdReducer.success) {
      const data = props.getDeliveryAgentsByIdReducer.data.data;

      setState((prevState) => ({
        ...prevState,
        name: data.name,
        email: data.email,
        number: data?.number ? formatPhoneNumber(data?.number) : "N/A",
        location: data.locationdata[0]?._id,
        status: data?.status == 1 ? "active" : "Inactive",
      }));
    }
  }, [props.getDeliveryAgentsByIdReducer]);

  var res = state?.number
    ?.replace(/\D+/g, "")
    .replace(/^(\d{3})(\d{3})(\d{4}).*/, "$1$2$3");

  useEffect(() => {
    if (props.AgentschangePasswordReducer.success) {
      successTost("Password changed successfully.");
      setTimeout(() => {
        props.resetagentpassword();
        setShowChangePassword(false);
      }, 2200);
    } else if (props.AgentschangePasswordReducer.error) {
      errorNotify(props.AgentschangePasswordReducer.errors);
      props.resetagentpassword();
    }
  }, [props.AgentschangePasswordReducer]);

  useEffect(() => {
    if (props.editDeliveryAgentsReducer?.error) {
      notifyError(props.editDeliveryAgentsReducer.errors);
      props.resetupdateagent();
    }
  }, [props.editDeliveryAgentsReducer]);

  const handleInput = (e) => {
    const { name, value } = e.target;
    if (
      name == "confirm_password" ||
      name == "new_password" ||
      name == "old_password"
    ) {
      setpasswordState((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
    if (e.target.name === "number") {
      if (e.target.value.length <= 14) {
        setState((prevState) => ({
          ...prevState,
          [e.target.name]: e.target.value.replace(
            /^(\([0-9]{3}\) |[0-9]{3}-)[0-9]{3}-[0-9]{4}$/
          ),
        }));
      }
    }
    setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));

    switch (name) {
      case "number":
        if (e.target.value.length == 0) {
          setError((prevState) => ({
            ...prevState,
            number: "number can not be empty",
          }));
        } else if (!Us_Pattern_Number.test(e.target.value)) {
          setError((prevState) => ({
            ...prevState,
            number: "Enter valid number",
          }));
        } else {
          setError((prevState) => ({
            ...prevState,
            number: false,
          }));
        }
        break;
      case "location":
        if (e.target.value.length == 0) {
          setError((prevState) => ({
            ...prevState,
            location: "location can not be empty",
          }));
        } else {
          setError((prevState) => ({
            ...prevState,
            location: false,
          }));
        }
        break;
      case "name":
        if (value === "") {
          setError((prevState) => ({
            ...prevState,
            name: "Enter Driver Name",
          }));
        } else {
          setError((prevState) => ({
            ...prevState,
            name: false,
          }));
        }
        break;
      case "old_password":
        if (value === "") {
          setpasswordError((prevState) => ({
            ...prevState,
            old_password: "Enter Old Password",
          }));
        } else if (!validPassword.test(value)) {
          setpasswordError((prevState) => ({
            ...prevState,
            old_password:
              "Password contail atleast 1 number, 1 charecter and 1 symbol",
          }));
        } else {
          setpasswordError((prevState) => ({
            ...prevState,
            old_password: false,
          }));
        }
        break;

      case "new_password":
        if (value === "") {
          setpasswordError((prevState) => ({
            ...prevState,
            new_password: "Enter new Password",
          }));
        } else if (!validPassword.test(value)) {
          setpasswordError((prevState) => ({
            ...prevState,
            new_password:
              "Password contail atleast 1 number, 1 charecter and 1 symbol",
          }));
        } else {
          setpasswordError((prevState) => ({
            ...prevState,
            new_password: false,
          }));
        }
        break;

      case "confirm_password":
        if (value === "") {
          setpasswordError((prevState) => ({
            ...prevState,
            confirm_password: "Enter confirm new Password",
          }));
        } else if (!validPassword.test(value)) {
          setpasswordError((prevState) => ({
            ...prevState,
            confirm_password:
              "Password contail atleast 1 number, 1 charecter and 1 symbol",
          }));
        } else {
          setpasswordError((prevState) => ({
            ...prevState,
            confirm_password: false,
          }));
        }
        break;

      default:
        break;
    }
  };

  const handleUpdateDeliveryAgents = () => {
    if (state.name === "") {
      setError((prevState) => ({
        ...prevState,
        prnamemName: "Enter Prm name",
      }));
    }
    if (state.number == "") {
      setError((prevState) => ({
        ...prevState,
        number: "number can not be empty",
      }));
    } else if (!Us_Pattern_Number.test(state.number)) {
      setError((prevState) => ({
        ...prevState,
        number: "Enter valid number",
      }));
    } else {
      setError((prevState) => ({
        ...prevState,
        number: false,
      }));
    }
    if (state.countryCode === "") {
      setError((prevState) => ({
        ...prevState,
        countryCode: "Enter Country code",
      }));
    }

    if (
      state.name !== "" &&
      state.email !== "" &&
      state.number !== "" &&
      !error.name &&
      !error.number
    ) {
      const details = {
        name: state.name,
        status: state.status === "active" ? 1 : 0,
        number: res,
        location: state.location,
        email: state.email,
      };
      props.updateDeliveryAgentsDataAction({
        id: props.DeliveryAgentsId,
        data: details,
      });
    }
  };

  const savePassword = (e) => {
    e.preventDefault();
    if (passwordState.old_password === "") {
      setpasswordError((prevState) => ({
        ...prevState,
        old_password: "Enter Old Password",
      }));
    } else if (passwordState.new_password === "") {
      setpasswordError((prevState) => ({
        ...prevState,
        new_password: "Enter new Password",
      }));
    } else if (passwordState.confirm_password === "") {
      setpasswordError((prevState) => ({
        ...prevState,
        confirm_password: "Enter confirm new password",
      }));
    } else if (passwordState.new_password !== passwordState.confirm_password) {
      setpasswordError((prevState) => ({
        ...prevState,
        confirm_password: "new password and confirm password not match",
      }));
    }

    if (passwordState.new_password !== passwordState.confirm_password) {
      errorNotify("Password does not match");
    }

    if (
      // passwordState.old_password !== "" &&
      passwordState.new_password !== "" &&
      passwordState.confirm_password !== "" &&
      // !passwordError.old_password &&
      !passwordError.new_password &&
      !passwordError.confirm_password &&
      passwordState.confirm_password === passwordState.new_password
    ) {
      const details = {
        // current_password: passwordState.old_password,
        new_password: passwordState.new_password,
        confirm_password: passwordState.confirm_password,
        id: props.DeliveryAgentsId,
      };
      props.agentschangePassword(details);
    }
  };

  const passwordvisible = () => {
    setvisible(!visible);
  };
  const Confirmpasswordvisible = () => {
    setConfirmvisible(!Confirmvisible);
  };
  const OldPasswordVisible = () => {
    setOldVisible(!OldVisible);
  };

  const Back = () => {
    setShowChangePassword(true);
    setpasswordState({
      new_password: "",
      confirm_password: "",
    });
  };
  const BackBack = () => {
    setShowChangePassword(false);
    setpasswordState({
      new_password: "",
      confirm_password: "",
    });
  };

  const resetpassword = () => {
    setpasswordState({
      new_password: "",
      confirm_password: "",
    });
  };

  return (
    <>
      <Modal.Header closeButton>
        <Modal.Title>Edit Driver</Modal.Title>
      </Modal.Header>
      <Modal.Body className="">
        <div>
          <Form className="row">
            <div className="col-6">
              <Form.Label>Location:</Form.Label>
              <div className="form-group event-drp ">
                <select
                  id="location"
                  name="location"
                  onChange={handleInput}
                  className={classNames("form-select", {
                    requireField: error.location,
                  })}
                  value={state.location}
                >
                  <option value="" className="d-none">
                    Select Location *
                  </option>
                  {allLocationData?.map((v, i) => {
                    return (
                      <option key={i} value={v._id}>
                        {v?.location_name}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
            <div className="col-6">
              <Form.Group className="mb-3">
                <Form.Label>Driver Name:</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Driver Name"
                  name="name"
                  value={state.name}
                  onChange={handleInput}
                  className={classNames({
                    requireField: error.name,
                  })}
                />
              </Form.Group>
            </div>
            {/* <div className="col-6"></div> */}
            <div className="col-6">
              {" "}
              <Form.Group className="mb-3">
                <Form.Label>Email Address: </Form.Label>
                <Form.Control
                  type="email"
                  placeholder="Enter Email Address"
                  name="email"
                  label="email"
                  id="email"
                  value={state.email}
                  onChange={handleInput}
                  className={classNames({
                    requireField: error.email,
                  })}
                />
              </Form.Group>
            </div>
            <div className="col-6">
              {" "}
              <Form.Group className="mb-3 ">
                <Form.Label>Contact No:</Form.Label>
                <MaskedInput
                  mask={[
                    "(",
                    /[0-9]/,
                    /[0-9]/,
                    /[0-9]/,
                    ")",
                    " ",
                    /[0-9]/,
                    /[0-9]/,
                    /[0-9]/,
                    "-",
                    /[0-9]/,
                    /[0-9]/,
                    /[0-9]/,
                    /[0-9]/,
                  ]}
                  name="number"
                  placeholder="Enter Contact No"
                  guide={false}
                  id="my-input-id number"
                  value={state?.number}
                  onKeyUp={handleInput}
                  onChange={handleInput}
                  className={classNames("form-control mb-3", {
                    requireField: error.number,
                  })}
                />
              </Form.Group>
            </div>
            <div className="col-md-3 col-4 mb-3">
              <Form.Label style={{ fontWeight: "bold" }}>Status:</Form.Label>
              <Form.Select
                value={state?.status}
                onChange={(e) => {
                  setState((prevState) => ({
                    ...prevState,
                    status: e.target.value,
                  }));
                }}
              >
                <option value="active">Active</option>
                <option value="Inactive">Inactive</option>
              </Form.Select>
            </div>
            {ShowChangePassword && (
              <div className="col-md-12">
                <div className="address-container row w-100 gx-2">
                  <h4 className="text-center mb-4">Change Password</h4>
                  {/* <Form.Group className="col-4 position-relative">
                    <Form.Label>Old Password:</Form.Label>
                    <Form.Control
                      type={!!OldVisible ? "text" : "password"}
                      placeholder="Enter Old Password"
                      name="old_password"
                      value={passwordState.old_password}
                      onChange={handleInput}
                      className={classNames("", {
                        requireField: passwordError.old_password,
                      })}
                    />
                    <div onClick={OldPasswordVisible}>
                      {OldVisible ? (
                        <i className="fa fa-eye eye-location pointer fs-5" />
                      ) : (
                        <i className="fa fa-eye-slash eye-location pointer fs-5" />
                      )}
                    </div>
                  </Form.Group> */}
                  <Form.Group className="col-4 position-relative">
                    <Form.Label>New Password:</Form.Label>
                    <Form.Control
                      type={!!visible ? "text" : "password"}
                      placeholder="Enter New Password"
                      name="new_password"
                      autoComplete="new-password"
                      value={passwordState.new_password}
                      onChange={handleInput}
                      // className={classNames("", {
                      //   requireField: passwordError.new_password,
                      // })}
                    />
                    <div onClick={passwordvisible}>
                      {visible ? (
                        <i className="fa fa-eye eye-location pointer fs-5" />
                      ) : (
                        <i className="fa fa-eye-slash eye-location pointer fs-5" />
                      )}
                    </div>
                  </Form.Group>
                  <Form.Group className="col-4 position-relative">
                    <Form.Label>Confirm Password:</Form.Label>
                    <Form.Control
                      type={!!Confirmvisible ? "text" : "password"}
                      name="confirm_password"
                      autoComplete="new-password"
                      placeholder="Enter Confirm Password"
                      value={passwordState.confirm_password}
                      onChange={handleInput}
                      // className={classNames("", {
                      //   requireField: passwordError.confirm_password,
                      // })}
                    />
                    <div onClick={Confirmpasswordvisible}>
                      {Confirmvisible ? (
                        <i className="fa fa-eye eye-location pointer fs-5" />
                      ) : (
                        <i className="fa fa-eye-slash eye-location pointer fs-5" />
                      )}
                    </div>
                  </Form.Group>
                  <div className="text-end">
                    <Button
                      type="button"
                      className="me-2 border rounded pointer px-4 py-2 text-white bg-red mt-4"
                      onClick={savePassword}
                    >
                      Save Password
                    </Button>
                  </div>
                </div>
              </div>
            )}
          </Form>
          <Toaster />
        </div>
        <div className="text-end mt-3">
          {ShowChangePassword ? (
            <Button
              type="button"
              className="me-2 border rounded pointer px-4 py-2 text-white bg-red mt-4"
              onClick={BackBack}
            >
              Back
            </Button>
          ) : (
            <Button
              type="button"
              className="me-2 border rounded pointer px-4 py-2 text-white bg-red mt-4"
              onClick={Back}
            >
              Change Password
            </Button>
          )}
          <Button
            type="button"
            className="border rounded pointer px-4 py-2 text-white bg-red mt-4"
            onClick={handleUpdateDeliveryAgents}
          >
            {btnLoading ? <Loader /> : " Submit"}
          </Button>
        </div>
      </Modal.Body>
    </>
  );
};

const mapStateToProp = (state) => ({
  getDeliveryAgentsByIdReducer:
    state.getDeliveryAgentsByIdReducer.getDeliveryAgentsById,
  editDeliveryAgentsReducer: state.editDeliveryAgentsReducer,
  AgentschangePasswordReducer:
    state.SuperadminPasswordReducer.AgentschangePassword,
});

const mapDispatchToProps = (dispatch) => ({
  getDeliveryAgentsById: (id) => dispatch(getDeliveryAgentsById(id)),
  updateDeliveryAgentsDataAction: (details) =>
    dispatch(updateDeliveryAgentsDataAction(details)),
  agentschangePassword: (details) => dispatch(agentschangePassword(details)),
  resetagentpassword: () => dispatch({ type: RESET_AGENTS_CHANGE_PASSWORD }),
  resetupdateagent: () =>
    dispatch({ type: RESET_UPDATE_DELIVERY_AGENTS_DATA_SUCCESS }),
});
export default connect(mapStateToProp, mapDispatchToProps)(EditDeliveryAgents);

// export default EditDeliveryAgents;
