import { GET_PRM_BY_ID_DATA_LOADING, GET_PRM_BY_ID_DATA_SUCCESS, GET_PRM_BY_ID__DATA_ERROR, RESET_GET_PRM_BY_ID_ } from "../type/Constant";

let initialState = {
  getPrmById: {
    loading: false,
    data: null,
    error: false,
    errors: null,
    success: false
  },
};

export default function getPrmByIdReducer(state = initialState, action) {
  switch (action.type) {
   
    case GET_PRM_BY_ID_DATA_LOADING:
      return { ...state, getPrmById: { ...state.getPrmById, loading: true, error: false, errors: [], success: false } };

    case GET_PRM_BY_ID_DATA_SUCCESS:
      return { ...state, getPrmById: { ...state.getPrmById, loading: false, error: false, errors: [], success: true, data: action.response.data } };

    case GET_PRM_BY_ID__DATA_ERROR:
      return { ...state, getPrmById: { ...state.getPrmById, loading: false, error: true, errors: action.errors, success: false, data: null } };

    case RESET_GET_PRM_BY_ID_:
      return { ...state, getPrmById: { ...state.getPrmById, loading: false, error: false, errors: null, success: false } }

    default:
      return state;
  }
}
