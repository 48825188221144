import { Pagination, TablePagination } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Button, Modal, Table } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { connect, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import Spinner from "../../../Components/Spinner/Spinner";
import Toaster from "../../../Components/Toaster/Toaster";
import {
  RESET_ADD_PRM,
  RESET_DELETE_PRM,
  RESET_UPDATE_PRM,
} from "../../Constants/Constants";
import { deletePrm, getPrmList } from "../../Services/Actions/PrmAction";
import AddPrm from "./AddPrm";
import UpdatePrm from "./UpdatePrm";
import { formatPhoneNumber } from "../../../Store/type/Constant";

const FilterComponent = ({ filterText, onFilter, onClear }) => (
  <>
    <div className="form-outline mb-lg-5 mb-5">
      <label className="form-label me-2 mb-0" htmlFor="form1">
        {/* Search : */}
      </label>
      <input
        id="search"
        type="text"
        className="form-control"
        placeholder="Search"
        aria-label="Search Input"
        value={filterText}
        onChange={onFilter}
      />
    </div>
  </>
);

function AdminPRM(props) {
  const notifyError = (msg) => toast.error(msg);
  const [loading, setLoading] = useState(false);

  const [prmList, setPrmList] = useState([]);

  const [isAddPrm, setIsAddPrm] = useState(false);
  const [isUpdatePrm, setIsUpdatePrm] = useState(false);
  const [isDeletePrm, setIsDeletePrm] = useState(false);

  const [updatePrmId, setUpdatePrmId] = useState(null);
  const [deletePrmId, setDeletePrmId] = useState(null);

  const [isDelete, setIsDelete] = useState(false);
  const [deleteId, setDeleteId] = useState();

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const successMsg = (msg) => toast.success(msg);

  const [primaryAdminshow, setprimaryAdminShow] = useState(false);
  const handlePrimaryClose = () => setprimaryAdminShow(false);
  const handlePrimaryShow = () => setprimaryAdminShow(true);

  const dispatch = useDispatch();

  //  useeffect

  useEffect(() => {
    props.getPrmList();
  }, []);

  useEffect(() => {
    setLoading(props.getPrmReducer.loading);
    if (props.getPrmReducer.success) {
      const data = props.getPrmReducer.data;
      setPrmList([...data]);
    }
  }, [props.getPrmReducer]);

  useEffect(() => {
    if (props.addPrmReducer.success) {
      setTimeout(() => {
        successMsg("PRM created successfully. ");
      }, 100);
      setTimeout(() => {
        props.resetaddPrm();
      }, 100);
      props.getPrmList();
    }
    if (props.addPrmReducer.error) {
      notifyError(props.addPrmReducer.errors);
      props.resetaddPrm();
    }
  }, [props.addPrmReducer]);

  useEffect(() => {
    if (props.updatePrmReducer.success) {
      setTimeout(() => {
        successMsg("PRM updated successfully. ");
      }, 100);
      props.getPrmList();
      props.resetUpdate();
    }
    if (props.updatePrmReducer.error) {
      notifyError(props.updatePrmReducer.errors);
      props.resetUpdate();
    }
  }, [props.updatePrmReducer]);

  useEffect(() => {
    if (props.deletePrmReducer.success) {
      setTimeout(() => {
        successMsg("PRM deleted successfully. ");
      }, 100);
      setTimeout(() => {
        props.resetDeletePrm();
      }, 100);
      props.getPrmList();
      setIsDeletePrm(false);
      setDeletePrmId(null);
    }
    if (props.deletePrmReducer.error) {
      notifyError(props.deletePrmReducer.errors);
      props.resetDeletePrm();
    }
  }, [props.deletePrmReducer]);

  const handledelete = () => {
    dispatch(deletePrm(deleteId));
    handleClose();
  };

  const checkPrimaryadmin = (props) => {
    if (props.primary_admin === 1) {
      handlePrimaryShow();
    } else {
      handleShow();
    }
  };

  const columns = [
    {
      name: "Name",
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: "Contact No.",
      selector: (row) => row.number,
      sortable: true,
    },
    {
      name: "Email Address",
      selector: (row) => row.email,
      // width: "110px",
    },
    {
      name: "Status",
      selector: (row) => row.status,
      width: "200px",
    },
    {
      name: "Action",
      selector: (row) => row.action,
      width: "100px",
    },
  ];

  const data = prmList?.map((data, i) => {
    return {
      id: data._id,
      name: data?.name ? data?.name : "N/A",
      number: data?.number ? formatPhoneNumber(data?.number) : "N/A",
      email: data?.email ? data?.email : "N/A",
      status:
        data.status === 1 ? (
          <span className="badge bg-dark" style={{ width: "67px" }}>
            Active
          </span>
        ) : (
          <span className="badge bg-red" style={{ width: "67px" }}>
            InActive
          </span>
        ),
      action: (
        <>
          <button
            className="btn p-0 me-2"
            onClick={() => {
              setIsUpdatePrm(true);
              setUpdatePrmId(data?._id);
            }}
          >
            {" "}
            <i
              className="fa fa-pencil-square-o pointer fs-4"
              aria-hidden="true"
            ></i>
          </button>
          <button
            className="btn p-0"
            onClick={() => {
              checkPrimaryadmin(data);
              setDeleteId(data?._id);
            }}
          >
            <i
              className="fa fa-trash text-danger fs-4 ms-1"
              aria-hidden="true"
            ></i>
          </button>
        </>
      ),
    };
  });

  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const filteredItems = data?.filter(
    (item) =>
      (item.name &&
        item.name.toLowerCase().includes(filterText.toLowerCase())) ||
      (item.item_name &&
        item.item_name.toLowerCase().includes(filterText.toLowerCase()))
  );

  const subHeaderComponentMemo = React.useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <FilterComponent
        onFilter={(e) => setFilterText(e.target.value)}
        onClear={handleClear}
        filterText={filterText}
      />
    );
  }, [filterText, resetPaginationToggle]);

  return (
    <React.Fragment>
      <div className="d-flex justify-content-between align-items-center mb-4">
        <div className="d-flex justify-content-center align-items-center">
          <Link to={"/"} className="text-dark">
            <i className="fa fa-house fs-2 me-3"></i>
          </Link>
          <h3 className="me-3">/</h3>
          <h3 className="fw-semibold">PRM</h3>
        </div>
        <div
          className="border rounded pointer px-4 py-2 text-white bg-red"
          onClick={() => setIsAddPrm(true)}
        >
          Create
        </div>
      </div>

      {loading ? (
        <Spinner />
      ) : (
        <>
          {prmList.length === 0 ? (
            <div className="text-center h5 mt-5 text-red">
              {" "}
              No PRM available
            </div>
          ) : (
            <div>
              <div className="pay-row">
                <DataTable
                  columns={columns}
                  data={filteredItems}
                  pagination
                  paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
                  subHeader
                  subHeaderComponent={subHeaderComponentMemo}
                />
              </div>
            </div>
          )}
        </>
      )}

      {/* <DeletePopUp
        isOpen={isDelete}
        isClose={setIsDelete}
        handleEvent={() => dispatch(deletePrm(deleteId))}
      /> */}

      <Modal
        show={isAddPrm}
        size="lg"
        onHide={() => setIsAddPrm(false)}
        backdrop="static"
      >
        <AddPrm setIsAddPrm={setIsAddPrm} />
      </Modal>

      {/* update */}
      <Modal
        show={isUpdatePrm}
        size="lg"
        onHide={() => setIsUpdatePrm(false)}
        backdrop="static"
      >
        <UpdatePrm setIsUpdatePrm={setIsUpdatePrm} updatePrmId={updatePrmId} />
      </Modal>

      {/*  delete */}

      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <div className="d-flex justify-content-center align-items-center my-4 py-4">
          <div className="text-center">
            <h4 className="text-center mt-3">
              Are you sure about deleting this PRM?
            </h4>
            <div className="d-flex justify-content-center align-items-center mt-5">
              <button
                onClick={() => handleClose()}
                className="text-white bg-red border-0 fs-5 rounded py-2 px-5 me-4"
              >
                Cancel
              </button>
              <button
                onClick={() => handledelete()}
                className="text-white bg-red border-0 fs-5 rounded py-2 px-5"
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      </Modal>
      <Modal
        show={primaryAdminshow}
        onHide={handlePrimaryClose}
        backdrop="static"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <div className="d-flex justify-content-center align-items-center my-4 py-4">
          <div className="text-center">
            <h4> Oops ...!</h4>
            <h4 className="text-center mt-3">
              This is a primary PRM at this location
            </h4>
            <h4 className="text-center mt-3">You can't delete this PRM user</h4>
            <div className="d-flex justify-content-center align-items-center mt-5">
              <button
                onClick={() => handlePrimaryClose()}
                className="text-white bg-red border-0 fs-5 rounded py-2 px-5"
              >
                OK
              </button>
            </div>
          </div>
        </div>
      </Modal>
      <Toaster />
    </React.Fragment>
  );
}

const mapStateToProp = (state) => ({
  getPrmReducer: state.PrmReducer.getPrm,
  updatePrmReducer: state.PrmReducer.updatePrm,
  addPrmReducer: state.PrmReducer.addPrm,
  deletePrmReducer: state.PrmReducer.deletePrm,
});

const mapDispatchToProps = (dispatch) => ({
  getPrmList: (details) => dispatch(getPrmList(details)),
  deletePrm: (id) => dispatch(deletePrm(id)),
  resetUpdate: () => dispatch({ type: RESET_UPDATE_PRM }),
  resetaddPrm: () => dispatch({ type: RESET_ADD_PRM }),
  resetDeletePrm: () => dispatch({ type: RESET_DELETE_PRM }),
});

export default connect(mapStateToProp, mapDispatchToProps)(AdminPRM);
