import {
  CUSTOMER_SERACH_BY_CONTACT_ERROR,
  CUSTOMER_SERACH_BY_CONTACT_LOADING,
  CUSTOMER_SERACH_BY_CONTACT_SUCCESS,
  CUSTOMER_SERACH_BY_NAME_ERROR,
  CUSTOMER_SERACH_BY_NAME_LOADING,
  CUSTOMER_SERACH_BY_NAME_SUCCESS,
  FIND_SUPERADMIN_CUSTOMER_BY_CONTACT_ERROR,
  FIND_SUPERADMIN_CUSTOMER_BY_CONTACT_LOADING,
  FIND_SUPERADMIN_CUSTOMER_BY_CONTACT_SUCCESS,
  RESET_CUSTOMER_SERACH_BY_CONTACT,
  RESET_CUSTOMER_SERACH_BY_NAME,
  RESET_FIND_SUPERADMIN_CUSTOMER_BY_CONTACT,
} from "../type/Constant";

let initialState = {
  findCustomerByContact: {
    loading: false,
    data: null,
    error: false,
    errors: null,
    success: false,
  },
  CustomerSerachByContact: {
    loading: false,
    data: null,
    error: false,
    errors: null,
    success: false,
  },
  CustomerSerachByName: {
    loading: false,
    data: null,
    error: false,
    errors: null,
    success: false,
  },
};

export default function findCustomerreducer(state = initialState, action) {
  switch (action.type) {
    case FIND_SUPERADMIN_CUSTOMER_BY_CONTACT_LOADING:
      return {
        ...state,
        findCustomerByContact: {
          ...state.findCustomerByContact,
          loading: true,
          error: false,
          errors: [],
          success: false,
        },
      };

    case FIND_SUPERADMIN_CUSTOMER_BY_CONTACT_SUCCESS:
      return {
        ...state,
        findCustomerByContact: {
          ...state.findCustomerByContact,
          loading: false,
          error: false,
          errors: [],
          success: true,
          data: action.response.data,
        },
      };

    case FIND_SUPERADMIN_CUSTOMER_BY_CONTACT_ERROR:
      return {
        ...state,
        findCustomerByContact: {
          ...state.findCustomerByContact,
          loading: false,
          error: true,
          errors: action.errors,
          success: false,
          data: null,
        },
      };

    case RESET_FIND_SUPERADMIN_CUSTOMER_BY_CONTACT:
      return {
        ...state,
        findCustomerByContact: {
          ...state.findCustomerByContact,
          loading: false,
          error: false,
          errors: null,
          success: false,
        },
      };

      // ..................................................................................................

    case CUSTOMER_SERACH_BY_CONTACT_LOADING:
      return {
        ...state,
        CustomerSerachByContact: {
          ...state.CustomerSerachByContact,
          loading: true,
          error: false,
          errors: [],
          success: false,
        },
      };

    case CUSTOMER_SERACH_BY_CONTACT_SUCCESS:
      return {
        ...state,
        CustomerSerachByContact: {
          ...state.CustomerSerachByContact,
          loading: false,
          error: false,
          errors: [],
          success: true,
          data: action.response.data,
        },
      };

    case CUSTOMER_SERACH_BY_CONTACT_ERROR:
      return {
        ...state,
        CustomerSerachByContact: {
          ...state.CustomerSerachByContact,
          loading: false,
          error: true,
          errors: action.errors,
          success: false,
          data: null,
        },
      };

    case RESET_CUSTOMER_SERACH_BY_CONTACT:
      return {
        ...state,
        CustomerSerachByContact: {
          ...state.CustomerSerachByContact,
          loading: false,
          error: false,
          errors: null,
          success: false,
        },
      };
      
      // ..................................................................................................

    case CUSTOMER_SERACH_BY_NAME_LOADING:
      return {
        ...state,
        CustomerSerachByName: {
          ...state.CustomerSerachByName,
          loading: true,
          error: false,
          errors: [],
          success: false,
        },
      };

    case CUSTOMER_SERACH_BY_NAME_SUCCESS:
      return {
        ...state,
        CustomerSerachByName: {
          ...state.CustomerSerachByName,
          loading: false,
          error: false,
          errors: [],
          success: true,
          data: action.response.data,
        },
      };

    case CUSTOMER_SERACH_BY_NAME_ERROR:
      return {
        ...state,
        CustomerSerachByName: {
          ...state.CustomerSerachByName,
          loading: false,
          error: true,
          errors: action.errors,
          success: false,
          data: null,
        },
      };

    case RESET_CUSTOMER_SERACH_BY_NAME:
      return {
        ...state,
        CustomerSerachByName: {
          ...state.CustomerSerachByName,
          loading: false,
          error: false,
          errors: null,
          success: false,
        },
      };

    default:
      return state;
  }
}
