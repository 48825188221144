import React, { useEffect, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { connect, useDispatch, useSelector } from "react-redux";
import DeletePopUp from "../DeletePopup";
// import {
//   deleteMenuData,
//   getAgentsData,
//   getMenuDataAction,
//   updateMenuDataAction,
// } from "../Store/Action/CreateAgents/UserAction";
import {
  deleteMenuData,
  getMenuById,
  updateMenuDataAction,
} from "../../../Store/Action/CreateAgents/UserAction";
import { RESET_UPDATE_MENU_DATA } from "../../../Store/type/Constant";
import classNames from "classnames";
import { MenuItem } from "@mui/material";
import $ from "jquery";
import { Loader } from "rsuite";

const EditMenu = (props) => {
  const dispatch = useDispatch();
  const handleShow = () => setShow(true);
  const [show, setShow] = useState(false);
  const [isDelete, setIsDelete] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [Image, setImage] = useState(null);
  const [changeImage, setChangeImage] = useState(false);
  const [btnLoading, setbtnLoading] = useState(false);
  const handleClose = () => {
    props.setEditShow(false);
  };

  const [menuApiData, setmenuApiData] = useState({
    item_name: "",
    // image: "",
    location: "",
    price: "",
    is_pizza: false,
    note: "",
  });

  const [errors, setErrors] = useState({
    // image: false,
    item_name: false,
    price: false,
    note: false,
  });

  const allLocationData = useSelector(
    (state) => state.getAgentsReducer.user.data
  );

  useEffect(() => {
    if (props.selectedMenuId) {
      props.getMenuById(props.selectedMenuId);
    }
  }, [props.selectedMenuId]);
  const [prevImg, setPrevImg] = useState();
  useEffect(() => {
    if (props.getMenuByIdReducer.success) {
      const data = props.getMenuByIdReducer.data.data;
      setmenuApiData({
        item_name: data?.item_name,
        // image: data?.image,
        price: parseFloat(data?.price)?.toFixed(2),
        note: data?.note ? data?.note : "",
        is_pizza: data?.is_pizza == 1 ? true : false,
        status: data?.status == 1 ? "active" : "Inactive",
        location: data?.locationdata[0]?._id,
      });
      // setImage(data.image);
      // setPrevImg(data.image);
    }
  }, [props.getMenuByIdReducer]);

  useEffect(() => {
    if (props.getMenuByIdReducer?.success) {
      props.reserMenu();
    }
  }, [props.getMenuByIdReducer]);

  const onChangeInput = (e) => {
    const { id, value, name } = e.target;

    // if (name == "image") {
    //   setImage(e.target.files[0]);
    //   setPrevImg(URL.createObjectURL(e.target.files[0]));
    //   // setmenuApiData(e.target.files[0]);
    //   setChangeImage(true);
    // }

    if (name === "note") {
      if (value.length <= 256) {
        setmenuApiData((prevState) => ({
          ...prevState,
          [name]: value,
        }));
      } else return;
    } else {
      setmenuApiData((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }

    switch (name) {
      case "item_name":
        if (value.length == 0) {
          setErrors((prevState) => ({
            ...prevState,
            item_name: "name can not be empty",
          }));
        } else {
          setErrors((prevState) => ({
            ...prevState,
            item_name: false,
          }));
        }
        break;

      case "sale_tax_rate":
        if (value.length == 0) {
          setErrors((prevState) => ({
            ...prevState,
            sale_tax_rate: "sale_tax_rate can not be empty",
          }));
        } else {
          setErrors((prevState) => ({
            ...prevState,
            sale_tax_rate: false,
          }));
        }
        break;

      // case "image":
      //   if (value.length == 0) {
      //     setErrors((prevState) => ({
      //       ...prevState,
      //       image: "image can not be empty",
      //     }));
      //   } else {
      //     setErrors((prevState) => ({
      //       ...prevState,
      //       image: false,
      //     }));
      //   }
      //   break;

      case "location":
        if (value.length == 0) {
          setErrors((prevState) => ({
            ...prevState,
            location: "location can not be empty",
          }));
        } else {
          setErrors((prevState) => ({
            ...prevState,
            location: false,
          }));
        }
        break;

      case "price":
        if (value.length == 0) {
          setErrors((prevState) => ({
            ...prevState,
            price: "price can not be empty",
          }));
        } else {
          setErrors((prevState) => ({
            ...prevState,
            price: false,
          }));
        }
        break;
      case "is_pizza":
        if (value.length == 0) {
          setErrors((prevState) => ({
            ...prevState,
            is_pizza: "is_pizza can not be empty",
          }));
        } else {
          setErrors((prevState) => ({
            ...prevState,
            is_pizza: false,
          }));
        }
        break;

      case "note":
        if (value.length >= 256) {
          setErrors((prevState) => ({
            ...prevState,
            note: "Enter Only 256 Characters",
          }));
        } else {
          setErrors((prevState) => ({
            ...prevState,
            note: false,
          }));
        }
        break;

      default:
    }
  };

  const onSubmite = (e) => {
    e.preventDefault();
    if (menuApiData.item_name == "") {
      setErrors((prevState) => ({
        ...prevState,
        item_name: "name can not be empty",
      }));
    }

    if (menuApiData.location == "") {
      setErrors((prevState) => ({
        ...prevState,
        location: "name can not be empty",
      }));
    }

    // if (menuApiData.sale_tax_rate == "") {
    //   setErrors((prevState) => ({
    //     ...prevState,
    //     sale_tax_rate: "name can not be empty",
    //   }));
    //   document.getElementById("sale_tax_rate").classList.add("error");
    // }

    if (menuApiData.price == "") {
      setErrors((prevState) => ({
        ...prevState,
        price: "name can not be empty",
      }));
    }

    // if (menuApiData.image == "") {
    //   setErrors((prevState) => ({
    //     ...prevState,
    //     image: "image can not be empty",
    //   }));
    // }

    if (menuApiData.pizza == "") {
      setErrors((prevState) => ({
        ...prevState,
        pizza: "name can not be empty",
      }));
    }

    if (menuApiData?.item_name !== "" && !errors?.note) {
      const details = {
        item_name: menuApiData?.item_name,
        // ...(changeImage && { image: Image }),
        price: parseFloat(menuApiData?.price)?.toFixed(2),
        is_pizza: menuApiData.is_pizza ? "1" : "0",
        status: menuApiData?.status === "active" ? 1 : 0,
        location: menuApiData?.location,
        note: menuApiData.note,
      };
      props.updateMenuDataAction({
        id: props.selectedMenuId,
        data: details,
      });
      props.setEditShow(false);
      setbtnLoading(true);
    }
  };

  useEffect(() => {
    $(".dropify")?.dropify();
  }, []);

  // const imgDefault = (e) => {
  //   e.target.src = "/images/menu-placeholder.jpg";
  //   e.target.onerror = null;
  // };
  return (
    <>
      <Modal.Header closeButton>
        <Modal.Title>Edit Menu Items</Modal.Title>
      </Modal.Header>
      <Modal.Body className="">
        <div className="form-area">
          <Form className="row" onSubmit={onSubmite}>
            <div className="form-group event-drp col-6">
              <Form.Label style={{ fontWeight: "bold" }}>Location:</Form.Label>
              <div className="form-group event-drp ">
                <select
                  id="location"
                  name="location"
                  onChange={onChangeInput}
                  className={classNames("form-select", {
                    requireField: errors.location,
                  })}
                  value={menuApiData.location}
                >
                  <option value="" className="d-none">
                    Select Location *
                  </option>
                  {allLocationData?.map((v, i) => {
                    return (
                      <option key={i} value={v._id}>
                        {v?.location_name}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
            {/* <div className="col-6">
              <Form.Group className="mb-3">
                <Form.Label>Location :</Form.Label>
                <Form.Control value={menuApiData?.location} disabled />
              </Form.Group>
            </div> */}
            <div className="col-6">
              <Form.Group className="mb-3">
                <Form.Label>Item Name:</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Item Name"
                  name="item_name"
                  onChange={onChangeInput}
                  value={menuApiData?.item_name}
                  className={classNames({
                    requireField: errors.item_name,
                  })}
                />
              </Form.Group>
            </div>
            <div className="col-6">
              {" "}
              <Form.Group className="mb-3">
                <Form.Label>Price: </Form.Label>
                <Form.Control
                  type="number"
                  placeholder="Enter Price"
                  name="price"
                  id="price"
                  label="price"
                  onChange={onChangeInput}
                  value={menuApiData?.price}
                  className={classNames({
                    requireField: errors.price,
                  })}
                />
              </Form.Group>
            </div>

            <div className="col-12">
              {/* <Form.Group className="mb-3">
                <Form.Label>Image: </Form.Label>
                <div className="custom-file-upload">
                  <input
                    type="file"
                    name="image"
                    id="image"
                    accept="image/*"
                    crossOrigin="anonymous"
                    label="image"
                    onChange={onChangeInput}
                    className={classNames({
                      requireField: errors.image,
                    })}
                  />
                  <label htmlFor="image">
                    {Image ? (
                      <div className="uploader-prev">
                        <img
                          className="img-fluid"
                          onError={imgDefault}
                          alt="droped image"
                          crossOrigin="anonymous"
                          src={prevImg}
                        />
                        <span className="replace-btn">Replace</span>
                      </div>
                    ) : (
                      <div className="upload-placeholder text-center">
                        <i
                          className="fa fa-cloud-upload fa-3x"
                          aria-hidden="true"
                        ></i>
                        <h3>Upload File</h3>
                      </div>
                    )}
                  </label>
                </div>
              </Form.Group> */}
            </div>
            <div className="d-flex mt-2">
              <div className="col-12 col-md-3">
                <Form.Label className="d-block">Is Pizza?</Form.Label>
                <Form.Check
                  type="switch"
                  id="custom-switch"
                  checked={menuApiData.is_pizza}
                  className={"pizzaBtn d-block ms-0"}
                  onChange={(e) =>
                    setmenuApiData((prevState) => ({
                      ...prevState,
                      is_pizza: e.target.checked,
                    }))
                  }
                />
              </div>
              <div className="col-md-3 col-4">
                <Form.Label>Status:</Form.Label>
                <Form.Select
                  value={menuApiData.status}
                  onChange={(e) => {
                    setmenuApiData((prevState) => ({
                      ...prevState,
                      status: e.target.value,
                    }));
                  }}
                >
                  <option value="active">Active</option>
                  <option value="Inactive">Inactive</option>
                </Form.Select>
              </div>
            </div>
            <div className="col-12 mt-2">
              <Form.Label>Note:</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                type="text"
                placeholder="Enter Note"
                value={menuApiData?.note}
                name="note"
                label="note"
                onChange={onChangeInput}
                id="note"
                className={classNames("mb-3", {
                  requireField: errors.note,
                })}
              />
            </div>
          </Form>
        </div>
        <div className="text-end">
          <Button
            type="button"
            className="border rounded pointer px-4 py-2 text-white bg-red"
            onClick={onSubmite}
          >
            {btnLoading ? <Loader /> : "Submit"}
          </Button>
        </div>
      </Modal.Body>

      <DeletePopUp
        isOpen={isDelete}
        isClose={setIsDelete}
        handleEvent={() => dispatch(deleteMenuData(deleteId))}
        confirmMsg="Are you sure about deleting this menu?"
      />
    </>
  );
};

const mapStateToProp = (state) => ({
  // submit: state.editDivisionReducer,
  editMenuReducer: state.editMenuReducer,
  getMenuByIdReducer: state.getMenuByIdReducer.getMenuById,
  getdata: state.getMenuReducer,
});

const mapDispatchToProps = (dispatch) => ({
  updateMenuDataAction: (Details) => dispatch(updateMenuDataAction(Details)),
  getMenuById: (id) => dispatch(getMenuById(id)),
  reserMenu: () => dispatch({ type: RESET_UPDATE_MENU_DATA }),
  // resetEditDivisionReducer: () => dispatch({ type: RESET_EDIT_DIVISION }),
  // getAllDivisionHandler: user => dispatch((user))
});
export default connect(mapStateToProp, mapDispatchToProps)(EditMenu);
