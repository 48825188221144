import React, { useCallback, useMemo } from "react";
import Header from "../Userlayout/Header";
import { Bar, scales } from "react-chartjs-2";
import { CategoryScale, Chart } from "chart.js/auto";
import { useState } from "react";
import { Form } from "react-bootstrap";
import ReactDatePicker from "react-datepicker";
import { useEffect } from "react";
import moment from "moment";
import { toast } from "react-toastify";
import classNames from "classnames";
import { connect, useDispatch, useSelector } from "react-redux";
import {
  getLocationListData,
  getOrderDataAction,
  reportsLocationOrderCountAction,
  reportsOrderCountAction,
} from "../../../Store/Action/CreateAgents/UserAction";
import {
  RESET_REPORTS_LOCATION_ORDER_COUNT,
  RESET_REPORTS_ORDER_COUNT,
} from "../../../Store/type/Constant";
import zoomPlugin from "chartjs-plugin-zoom";
import { faChartArea } from "@fortawesome/free-solid-svg-icons";
import Toaster from "../../../Components/Toaster/Toaster";
import ChartDataLabels from "chartjs-plugin-datalabels";
Chart.register(zoomPlugin);
Chart.register(ChartDataLabels);
Chart.register(CategoryScale);

const Reports = (props) => {
  const [checkRadiovalue, setcheckRadiovalue] = useState("date");
  const [checkCheckboxValue, setcheckCheckboxValue] = useState(0);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [dateRange, setDateRange] = useState(["", ""]);
  const [location, setlocation] = useState("");
  const [error, seterror] = useState(false);
  const [startDatee, endDatee] = dateRange;

  const [totalSale, setTotalSale] = useState(0);
  const [yAxisText, setYAxisText] = useState("");

  useEffect(() => {
    if (checkRadiovalue === "date") {
      setYAxisText("Number of Orders");
    } else if (checkRadiovalue === "store") {
      setYAxisText("Number of Stores");
    } else if (checkRadiovalue === "customer") {
      setYAxisText("Number of Customers");
    } else if (checkRadiovalue === "sales") {
      setYAxisText("Dollar Sales $ ");
    }
  }, [checkRadiovalue]);
  const [barData, setBarData] = useState([]);
  const LocationList = useSelector(
    (state) => state.getLocationListReducer.user.data
  );

  const dateWiseOrder = useSelector(
    (state) => state.reportsOrderReducer.dateWiseOrder?.data?.TotalOrder
  );


  useEffect(() => {
    props.getLocationListData();
  }, []);

  const handleRadio = (e) => {
    const { name, checked, value } = e.target;
    setcheckRadiovalue(value);
  };

  useEffect(() => {
    if (checkRadiovalue == "sales") {
      const total = barData.reduce((a, b) => {
        return parseFloat(a) + parseFloat(b);
      }, 0);
      setTotalSale(total.toLocaleString("en-US"));
    }
  }, [barData]);

  const handleCheckbox = (e) => {
    const { name, checked, value } = e.target;
    if (checked) {
      setcheckCheckboxValue(value);
    } else {
      setcheckCheckboxValue(0);
    }
  };

  useEffect(() => {
    props.reportsOrderCountAction({
      from_date: moment(startDate).format("yyyy-MM-DD"),
      to_date: moment(endDate).format("yyyy-MM-DD"),
      location_id: location,
      ezCater: checkCheckboxValue,
      type:
        checkRadiovalue === "date"
          ? 1
          : checkRadiovalue === "store"
          ? 2
          : checkRadiovalue === "customer"
          ? 3
          : checkRadiovalue === "sales"
          ? 4
          : 1,
    });
  }, [checkRadiovalue, location, checkCheckboxValue, endDate]);

  useEffect(() => {
    if (props.reportsOrderReducer.success) {
      props.resetDateOrder();
    }
  }, [props.reportsOrderReducer]);

  var getDateArray = function (start, end) {
    var arr = new Array();
    var dt = new Date(start);
    while (dt <= end) {
      arr.push(new Date(dt));
      dt.setDate(dt.getDate() + 1);
    }
    return arr;
  };

  if (
    (startDate === "" || startDate === null) &&
    (endDate === "" || endDate === null)
  ) {
    setStartDate(moment().subtract(9, "days"));
    setEndDate(moment());
  } else {
    var dateArr = getDateArray(startDate, endDate);
  }
  const [isOpen, setIsOpen] = useState(true);
  // console.log("dateArr", dateArr);
  useEffect(() => {
    if (
      startDate !== "" &&
      startDate !== null &&
      (endDate === "" || endDate === null) &&
      !isOpen
    ) {
      toast.error("Please select the next date in the picker.");
    }
  }, [isOpen]);

  useEffect(() => {
    if (checkRadiovalue === "date" || checkRadiovalue === "sales") {
      if (props.reportsOrderReducer.success) {
        // console.log("dateWiseOrder", dateWiseOrder, "dateArr", dateArr);
        const list = dateArr?.map((v, i) => {
          let data = dateWiseOrder?.find((o) => {
            // debugger;
            const _date = o._id.split("T")[0];
            return (
              moment(_date, "YYYY-MM-DD").format("MM-DD-YYYY") ===
              moment(v).format("MM-DD-yyyy")
            );
          });
          if (checkRadiovalue === "sales") {
            return !!data?.amount ? data.amount : 0;
          } else {
            return data?.count ?? 0;
          }
        });
        setBarData(list);
      }
    } else if (checkRadiovalue === "store" || checkRadiovalue === "customer") {
      if (props.reportsOrderReducer.success) {
        const list = dateArr?.map((v, i) => {
          let data = dateWiseOrder[moment(v).format("MM-DD-yyyy")];
          return data?.length ?? 0;
        });

        setBarData(list);
      }
    }
  }, [props.reportsOrderReducer, checkRadiovalue]);

  return (
    <>
      <Header />
      <main className="userMain">
        <div className="row ms-1 mb-3">
          <div className="form-check form-check-inline col-1">
            <input
              className="form-check-input fs-5"
              type="radio"
              name="inlineRadioOptions"
              id="selectDate"
              value="date"
              checked={checkRadiovalue === "date"}
              onChange={handleRadio}
            />
            <label
              className="form-check-label mt-1"
              for="selectDate"
              style={{ fontWeight: "bold" }}
            >
              Orders
            </label>
          </div>
          <div className="form-check form-check-inline col-1">
            <input
              className="form-check-input fs-5"
              type="radio"
              name="inlineRadioOptions"
              id="selectStore"
              value="store"
              onChange={handleRadio}
            />
            <label
              className="form-check-label mt-1"
              for="selectStore"
              style={{ fontWeight: "bold" }}
            >
              Store
            </label>
          </div>
          <div className="form-check form-check-inline col-1">
            <input
              className="form-check-input fs-5"
              type="radio"
              name="inlineRadioOptions"
              id="selectCustomer"
              value="customer"
              onChange={handleRadio}
            />
            <label
              className="form-check-label mt-1"
              for="selectCustomer"
              style={{ fontWeight: "bold" }}
            >
              Customer
            </label>
          </div>
          <div className="form-check form-check-inline col-1 ms-3">
            <input
              className="form-check-input fs-5"
              type="radio"
              name="inlineRadioOptions"
              id="selectSales"
              value="sales"
              onChange={handleRadio}
            />
            <label
              className="form-check-label mt-1"
              for="selectSales"
              style={{ fontWeight: "bold" }}
            >
              Sales
            </label>
          </div>
        </div>
        {/* ......................Dropdown................ */}

        {checkRadiovalue === "date" && (
          <div className="row gx-2 mb-3 ms-1">
            {/* <div className=" col-2 mt-4"> */}
            <Form.Group className="col col-lg-2 col-md-3">
              <Form.Label className="mb-1" style={{ marginBottom: "-20px" }}>
                From / To Date:
              </Form.Label>
              <ReactDatePicker
                showYearDropdown
                onInputClick={() => setIsOpen(true)}
                onClickOutside={() => setIsOpen(false)}
                selectsRange={true}
                startDate={startDatee}
                endDate={endDatee}
                placeholderText="MM-DD-YYYY"
                dateFormat={"MM-dd-yyyy"}
                className={classNames("form-control", {
                  requireField: error,
                })}
                onChange={(date) => {
                  if (!!date) {
                    setDateRange(date);
                    setStartDate(date[0]);
                    setEndDate(date[1]);
                  } else {
                  }
                  if (date[1]) {
                    setIsOpen(false);
                  }
                }}
                // isClearable={true}
              />
            </Form.Group>
            {/* </div> */}
            <div className="form-group event-drp col col-lg-2 col-md-3 mb-1">
              <Form.Label>Location:</Form.Label>
              <select
                id="location"
                name="location"
                value={location}
                onChange={(e) => setlocation(e.target.value)}
                className="form-select"
              >
                <option value="" className="d-none">
                  Select Location
                </option>
                <option value="" className="">
                  {`${"All Location (Orders)"}`}
                </option>
                {LocationList?.map((v, i) => {
                  return (
                    <option value={v?._id} key={i}>
                      {v.location_name}
                    </option>
                  );
                })}
              </select>
            </div>

            <div className="col-xl-4 col-lg-12 d-flex align-items-center mx-3 mt-3">
              <div
                className="row mx-0 "
                style={{
                  width: "100%",
                }}
              >
                <div className="form-check col-6 col-sm-4">
                  <Form.Label className="mt-1">ezCater</Form.Label>
                  <input
                    className="form-check-input fs-5"
                    type="checkbox"
                    value="1"
                    checked={checkCheckboxValue == 1}
                    id="flexCheckChecked"
                    onChange={handleCheckbox}
                  />
                </div>
                <div className="form-check col-6 col-sm-4">
                  <Form.Label className="mt-1">OLO Catering</Form.Label>
                  <input
                    className="form-check-input fs-5"
                    type="checkbox"
                    value="2"
                    checked={checkCheckboxValue == 2}
                    id="flexCheckChecked"
                    onChange={handleCheckbox}
                  />
                </div>
              </div>
            </div>
          </div>
        )}

        {checkRadiovalue === "store" && (
          <div className="row gx-2 mb-3 ms-1">
            {/* <div className=" col-2 mt-4"> */}
            <Form.Group className="col col-lg-2 col-md-3">
              <Form.Label className="mb-1" style={{ marginBottom: "-20px" }}>
                From / To Date:
              </Form.Label>
              <ReactDatePicker
                showYearDropdown
                onInputClick={() => setIsOpen(true)}
                onClickOutside={() => setIsOpen(false)}
                selectsRange={true}
                startDate={startDatee}
                endDate={endDatee}
                placeholderText="MM-DD-YYYY"
                dateFormat={"MM-dd-yyyy"}
                className={classNames("form-control", {
                  requireField: error,
                })}
                onChange={(date) => {
                  if (!!date) {
                    setDateRange(date);
                    setStartDate(date[0]);
                    setEndDate(date[1]);
                  } else {
                  }
                  if (date[1]) {
                    setIsOpen(false);
                  }
                }}
                // isClearable={true}
              />
            </Form.Group>
            {/* </div> */}
            <div className="form-group event-drp col col-lg-2 col-md-3 mb-1">
              <Form.Label>Location:</Form.Label>
              <select
                id="location"
                name="location"
                value={location}
                onChange={(e) => setlocation(e.target.value)}
                className="form-select"
              >
                <option value="" className="d-none">
                  Select Location
                </option>
                <option value="" className="">
                  {`${"All Location (Orders)"}`}
                </option>
                {LocationList?.map((v, i) => {
                  return (
                    <option value={v?._id} key={i}>
                      {v.location_name}
                    </option>
                  );
                })}
              </select>
            </div>
            <div className="col-xl-4 col-lg-12 d-flex align-items-center mx-3 mt-3">
              <div
                className="row mx-0 "
                style={{
                  width: "100%",
                }}
              >
                <div className="form-check col-6 col-sm-4">
                  <Form.Label className="mt-1">ezCater</Form.Label>
                  <input
                    className="form-check-input fs-5"
                    type="checkbox"
                    value="1"
                    checked={checkCheckboxValue == 1}
                    id="flexCheckChecked"
                    onChange={handleCheckbox}
                  />
                </div>
                <div className="form-check col-6 col-sm-4">
                  <Form.Label className="mt-1">OLO Catering</Form.Label>
                  <input
                    className="form-check-input fs-5"
                    type="checkbox"
                    value="2"
                    checked={checkCheckboxValue == 2}
                    id="flexCheckChecked"
                    onChange={handleCheckbox}
                  />
                </div>
              </div>
            </div>
          </div>
        )}

        {checkRadiovalue === "customer" && (
          <div className="row gx-2 mb-3 ms-1">
            {/* <div className=" col-2 mt-4"> */}
            <Form.Group className="col col-lg-2 col-md-3">
              <Form.Label className="mb-1" style={{ marginBottom: "-20px" }}>
                From / To Date:
              </Form.Label>
              <ReactDatePicker
                showYearDropdown
                onInputClick={() => setIsOpen(true)}
                onClickOutside={() => setIsOpen(false)}
                selectsRange={true}
                startDate={startDatee}
                endDate={endDatee}
                placeholderText="MM-DD-YYYY"
                dateFormat={"MM-dd-yyyy"}
                className={classNames("form-control", {
                  requireField: error,
                })}
                onChange={(date) => {
                  if (!!date) {
                    setDateRange(date);
                    setStartDate(date[0]);
                    setEndDate(date[1]);
                  } else {
                  }
                  if (date[1]) {
                    setIsOpen(false);
                  }
                }}
                // isClearable={true}
              />
            </Form.Group>
            {/* </div> */}
            <div className="form-group event-drp col col-lg-2 col-md-3 mb-1">
              <Form.Label>Location:</Form.Label>
              <select
                id="location"
                name="location"
                value={location.location}
                onChange={(e) => setlocation(e.target.value)}
                className="form-select"
              >
                <option value="" className="d-none">
                  Select Location
                </option>
                <option value="" className="">
                  {`${"All Location (Orders)"}`}
                </option>
                {LocationList?.map((v, i) => {
                  return (
                    <option value={v?._id} key={i}>
                      {v.location_name}
                    </option>
                  );
                })}
              </select>
            </div>
            <div className="col-xl-4 col-lg-12 d-flex align-items-center mx-3 mt-3">
              <div
                className="row mx-0 "
                style={{
                  width: "100%",
                }}
              >
                <div className="form-check col-6 col-sm-4">
                  <Form.Label className="mt-1">ezCater</Form.Label>
                  <input
                    className="form-check-input fs-5"
                    type="checkbox"
                    value="1"
                    checked={checkCheckboxValue == 1}
                    id="flexCheckChecked"
                    onChange={handleCheckbox}
                  />
                </div>
                <div className="form-check col-6 col-sm-4">
                  <Form.Label className="mt-1">OLO Catering</Form.Label>
                  <input
                    className="form-check-input fs-5"
                    type="checkbox"
                    value="2"
                    checked={checkCheckboxValue == 2}
                    id="flexCheckChecked"
                    onChange={handleCheckbox}
                  />
                </div>
              </div>
            </div>
          </div>
        )}

        {checkRadiovalue === "sales" && (
          <div className="row gx-2 mb-3">
            {/* <div className=" col-2 mt-4"> */}
            <Form.Group className="col col-lg-2 col-md-3">
              <Form.Label className="mb-1" style={{ marginBottom: "-20px" }}>
                From / To Date:
              </Form.Label>
              <ReactDatePicker
                onInputClick={() => setIsOpen(true)}
                onClickOutside={() => setIsOpen(false)}
                showYearDropdown
                selectsRange={true}
                startDate={startDatee}
                endDate={endDatee}
                placeholderText="MM-DD-YYYY"
                dateFormat={"MM-dd-yyyy"}
                className={classNames("form-control", {
                  requireField: error,
                })}
                onChange={(date) => {
                  if (!!date) {
                    setDateRange(date);
                    setStartDate(date[0]);
                    setEndDate(date[1]);
                  } else {
                  }
                  if (date[1]) {
                    setIsOpen(false);
                  }
                }}
                // isClearable={true}
              />
            </Form.Group>
            {/* </div> */}
            <div className="form-group event-drp col col-lg-2 col-md-3 mb-1">
              <Form.Label>Location:</Form.Label>
              <select
                id="location"
                name="location"
                value={location.location}
                onChange={(e) => setlocation(e.target.value)}
                className="form-select"
              >
                <option value="" className="d-none">
                  Select Location
                </option>
                <option value="" className="">
                  {`${"All Location (Orders)"}`}
                </option>
                {LocationList?.map((v, i) => {
                  return (
                    <option value={v?._id} key={i}>
                      {v.location_name}
                    </option>
                  );
                })}
              </select>
            </div>
            <div className="col-xl-4 col-lg-12 d-flex align-items-center mx-3 mt-3">
              <div
                className="row mx-0 "
                style={{
                  width: "100%",
                }}
              >
                <div className="form-check col-6 col-sm-4">
                  <Form.Label className="mt-1">ezCater</Form.Label>
                  <input
                    className="form-check-input fs-5"
                    type="checkbox"
                    value="1"
                    checked={checkCheckboxValue == 1}
                    id="flexCheckChecked"
                    onChange={handleCheckbox}
                  />
                </div>
                <div className="form-check col-6 col-sm-4">
                  <Form.Label className="mt-1">OLO Catering</Form.Label>
                  <input
                    className="form-check-input fs-5"
                    type="checkbox"
                    value="2"
                    checked={checkCheckboxValue == 2}
                    id="flexCheckChecked"
                    onChange={handleCheckbox}
                  />
                </div>
              </div>
            </div>
          </div>
        )}

        {/* ........................Graph...................... */}

        <div className="content-block">
          <Bar
            data={{
              labels: dateArr?.map((v, i) => {
                return moment(v).format("MM-DD-yyyy");
              }),
              datasets: [
                {
                  label:
                    checkRadiovalue === ""
                      ? "Number of Orders"
                      : checkRadiovalue === "date"
                      ? "Number of Orders"
                      : checkRadiovalue === "store"
                      ? "Number of Stores"
                      : checkRadiovalue === "customer"
                      ? "Number of Customers"
                      : checkRadiovalue === "sales"
                      ? `Dollar Sales $, Total Sales - $${totalSale}`
                      : "Number of Orders",
                  // label: yAxisText,
                  backgroundColor: "#c3262d",
                  data: barData,
                },
              ],
            }}
            height={400}
            width={50}
            options={{
              maintainAspectRatio: false,
              scales: {
                x: {
                  grid: {
                    display: false,
                  },
                  title: {
                    display: true,
                    text: "Date",
                  },
                },
                y: {
                  ticks: {
                    callback: (label, a) => {
                      if (label < 10) {
                        return "0" + label;
                      } else {
                        if (checkRadiovalue === "sales") {
                          return label.toLocaleString("en-US");
                        } else return label;
                      }
                    },
                  },
                  grid: {
                    display: false,
                  },
                  title: {
                    display: true,
                    text: yAxisText,
                  },
                  suggestedMin: 1,
                  suggestedMax: 10,
                },
              },
              responsive: true,
              plugins: {
                datalabels: {
                  color: "#ffffff",
                  display: (context) => {
                    return context.dataset.data[context.dataIndex] === 0 ||
                      context.dataset.data[context.dataIndex] === ""
                      ? false
                      : true;
                  },
                  formatter: (value, context) => {
                    if (checkRadiovalue === "sales") {
                      return `$${value.toLocaleString("en-US")}`;
                    } else return value;
                  },
                },
                title: {
                  display: true,
                },
                zoom: {
                  pan: {
                    enabled: true,
                    mode: "x",
                  },
                  zoom: {
                    pinch: {
                      enabled: true,
                    },
                    wheel: {
                      enabled: true,
                    },
                    mode: "x",
                  },
                },
                tooltip: {
                  enabled: true,
                  xAlign: "center",
                  yAlign: "bottom",
                  callbacks: {
                    label: function (context) {
                      if (checkRadiovalue === "sales") {
                        return `Dollar Sales $ : $${parseFloat(
                          context.formattedValue.replace(/\,/g, "")
                        ).toLocaleString("en-US")}`;
                      } else return;
                    },
                  },
                },
              },
            }}
          />
        </div>
      </main>
      <Toaster />
    </>
  );
};

const mapStateToProp = (state) => ({
  SuperAdminOrderReducer: state.SuperAdminOrderReducer.getOrder,
  getAgentsReducer: state.getAgentsReducer,
  reportsOrderReducer: state.reportsOrderReducer.dateWiseOrder,
  reportslocationOrderReducer: state.reportsOrderReducer.dateWiseLocationOrder,
});

const mapDispatchToProps = (dispatch) => ({
  getOrder: (Details) => dispatch(getOrderDataAction(Details)),
  getLocationListData: (Details) => dispatch(getLocationListData(Details)),
  reportsOrderCountAction: (Details) =>
    dispatch(reportsOrderCountAction(Details)),
  reportsLocationOrderCountAction: (Details) =>
    dispatch(reportsLocationOrderCountAction(Details)),
  resetDateOrder: () => dispatch({ type: RESET_REPORTS_ORDER_COUNT }),
  resetLocationOrder: () =>
    dispatch({ type: RESET_REPORTS_LOCATION_ORDER_COUNT }),
});
export default connect(mapStateToProp, mapDispatchToProps)(Reports);
