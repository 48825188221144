import React from "react";
import { Modal, Table } from "react-bootstrap";
import { getagentsByOrderDataAction } from "../../../Store/Action/CreateAgents/UserAction";
import { useEffect } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { formatPhoneNumber } from "../../../Store/type/Constant";

const AgentDetails = (props) => {
  const [agent, setagent] = useState("");
  const [orderList, setOrderList] = useState([]);

  const dispatch = useDispatch();

  const AgentByorder = useSelector(
    (state) => state.getagentsByOrderReducer.getagentsByOrder.data
  );

  useEffect(() => {
    dispatch(getagentsByOrderDataAction(props.SelectedAgentDetailsId));
  }, []);
  return (
    <>
      {" "}
      <Modal.Header closeButton>
        <Modal.Title>Orders</Modal.Title>
      </Modal.Header>
      <Modal.Body className="">
        <Table responsive="sm">
          {AgentByorder?.length !== 0 ? (
            <>
              <thead>
                <tr>
                  <th>Sr. No</th>
                  <th>Customer Name</th>
                  <th>Contact No.</th>
                  <th>location</th>
                  <th>Order Status</th>
                  <th>ezCater</th>
                </tr>
              </thead>
              <tbody>
                {AgentByorder?.map((v, i) => {
                  if (v?.name !== "" && v?.amount !== "") {
                    return (
                      <tr key={i}>
                        <td>{i + 1}</td>
                        <td>{v?.customers[0]?.name}</td>
                        <td>{formatPhoneNumber(v?.customers[0]?.number)}</td>
                        <td>{v?.locationdata[0]?.location_name}</td>
                        <td>
                          {v?.AgentByorder?.menu[0]?.status == 0
                            ? "Delivered"
                            : "Ordered"}
                        </td>
                        <td>
                          {v?.ezCater == 1 ? (
                            <span
                              className="badge bg-dark"
                              style={{ width: "60px" }}
                            >
                              Yes
                            </span>
                          ) : (
                            <span
                              className="badge bg-red"
                              style={{ width: "60px" }}
                            >
                              No
                            </span>
                          )}
                        </td>
                      </tr>
                    );
                  }
                })}
              </tbody>
            </>
          ) : (
            <div className="text-center h5" style={{ fontWeight: "bold" }}>
              There are no orders assigned to this agent as of now.
            </div>
          )}
        </Table>
      </Modal.Body>
    </>
  );
};

const mapStateToProp = (state) => ({
  getagentsByOrderReducer: state.getagentsByOrderReducer.getagentsByOrder,
});

const mapDispatchToProps = (dispatch) => ({
  getagentsByOrderDataAction: (id) => dispatch(getagentsByOrderDataAction(id)),
});
export default connect(mapStateToProp, mapDispatchToProps)(AgentDetails);
